import { Component, OnInit, inject } from '@angular/core';
import { RolesAndPermissionService } from '../../roles-and-permission.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-role-forms',
  templateUrl: './user-role-forms.component.html',
  styleUrls: ['./user-role-forms.component.scss']
})
export class UserRoleFormsComponent implements OnInit {
  //inject
  rolesAndPermission = inject(RolesAndPermissionService)
  route = inject(ActivatedRoute)

  constructor() { }

  ngOnInit() {
    //this.rolesAndPermission.initRoleForms()
    //this.rolesAndPermission.initUserForms()

    const snapshot = this.route.snapshot;
    if(snapshot.params['id']){
      const id = snapshot.params['id']
      if(snapshot.routeConfig?.path?.includes('user-forms')){
        this.rolesAndPermission.setQueryParams({userId:id})
        this.rolesAndPermission.formType = 'user';
        this.rolesAndPermission.initUserForms()
      }else if(snapshot.routeConfig?.path?.includes('role-forms')){
        this.rolesAndPermission.setQueryParams({roleId:id})
        this.rolesAndPermission.formType = 'role';
        console.log('pasok sa role-forms')
        this.rolesAndPermission.initRoleForms()
      }
    }
  }

}
