import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RoleHeader } from '@app/modules/groups-and-permissions';
import { RolesAndPermissionService } from '@app/modules/groups-and-permissions/roles-and-permission.service';
import { UserService } from '@app/core/services/user.service';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'role-body-component',
  templateUrl: './role-body.component.html',
  styleUrls: ['./role-body.component.scss']
})
export class RoleBodyComponent implements OnInit, OnDestroy{
  // inject
  rolesAndPermission = inject(RolesAndPermissionService)
  user = inject(UserService);

  //props
  @ViewChild('roleName') roleName:ElementRef;
  formGroup = new FormGroup({
    id: new FormControl(0),
    baseRoleId: new FormControl(0),
    companyId: new FormControl(0),
    name: new FormControl('New Role'),
    description: new FormControl(''),
    isAdmin: new FormControl(false),
    isFinal: new FormControl(true),
    userCount: new FormControl(0),
    isDefault: new FormControl(0),
    enableDeleteProtection: new FormControl(false),
  })
  mode: 'view' | 'edit' = 'view';
  prev:RoleHeader;
  isOverride = false;
  tooltip = this._getTooltipHtml();
  tooltipEndabled = this._getDeleteTooltip();
  tooltipDisabled = this._getDeleteTooltip(false);
  isBaseRole = false;
  private _isCascade = false;
  private _subs = new Subscription();

  constructor() { }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  ngOnInit(){
    this.rolesAndPermission.roleHeader$
    .pipe(filter(v=> v!=null))
    .subscribe(v=>{
      //console.log('role', v);
      this.formGroup.setValue(v!)
      this.prev = v!;
      this.isBaseRole = v!.baseRoleId == v!.id;
    })

    if(this.rolesAndPermission.withCompany){
      this.formGroup.get('companyId')?.setValue(this.rolesAndPermission.moduleCompanyId)
    }

    this._subs.add(
      this.rolesAndPermission.isRoleOverride$.subscribe(v=>{
        this.isOverride = v;
      })
    )

    this._subs.add(
      this.rolesAndPermission.isCascade$.subscribe(v=>this._isCascade=v)
    )
  }

  // public method
  getSwitch(){
    return this.formGroup.get('isAdmin')?.value? 'switch-on': 'switch-off';
  }

  getSwitchCascade(){
    return this._isCascade? 'switch-on': 'switch-off';
  }
  switchAdmin(){
    const old = this.formGroup.get('isAdmin')?.value;
    this.formGroup.get('isAdmin')?.setValue(!old)
    this.rolesAndPermission.setStateIsAdmin(!old)
    this._updateRoleHeader()
    this.rolesAndPermission.loadUserMenu(null, this.formGroup.get('companyId')!.value, !old)
    //console.log('header', this.formGroup.value);
  }
  switchCascade(){
    this._isCascade ? this.rolesAndPermission.setIsNotCascade(): this.rolesAndPermission.setIsCascade();
  }
  saveName(){
    this.mode = 'view';
    if(this.prev?.name != this.formGroup.controls['name'].value)
      this._updateRoleHeader();
  }
  editName(){
    this.mode = 'edit';
    setTimeout(()=>{
      this.roleName.nativeElement.focus();
    })
  }
  saveDescription(){
    if(this.prev?.description != this.formGroup.get('description')?.value)
      this._updateRoleHeader();
  }
  saveHeaderOnly(){
    this.rolesAndPermission.saveHeaderOnly();
  }

  toggleDelProtection() {
    const value = this.formGroup.controls.enableDeleteProtection.value;
    this.formGroup.controls.enableDeleteProtection.setValue(!value);
    this._updateRoleHeader();
  }

  // private method
  private _updateRoleHeader(){
    this.rolesAndPermission.setRoleHeader(this.formGroup.value as any)
  }

  //setter and getters
  get isAdmin(){
    if(this.formGroup.get('baseRoleId')?.value) return false;
    else return true;
  }

  get isModeAdd(){
    return this.rolesAndPermission.modeState === 'add'
  }

  get isUserCount(){
    return this.formGroup.get('userCount')!.value! > 0
  }

  get isDefault(){
    return !!this.formGroup.get('isDefault')?.value
  }

  get isShowAdminRole(){
    return this.isModeAdd 
      || (this.formGroup.get('isDefault')?.value == 0
          && this.formGroup.get('userCount')!.value! <=0)
  }

  get isCreateHeaderSaved(){
    return this.rolesAndPermission.stateIsCreateHeaderSaved;
  }

  get isShowPermissions(){
    if(
      (
        this.rolesAndPermission.modeState === 'edit' 
        || this.rolesAndPermission.modeState === 'override'
        || this.rolesAndPermission.modeState === 'view'
        || this.rolesAndPermission.modeState === 'company-override'
      )
      || (this.rolesAndPermission.modeState === 'add' && this.rolesAndPermission.stateIsCreateHeaderSaved)
    )
      return true;
    else return false;
  }

  private _getTooltipHtml(){
    return `<div class="tooltip-cascade">
      Ensures the Role will inherit all future additions/modifications to permissions.
      <br/>
      <i class="tooltip-note">
        (Note: This will revert any Override preferences back to the default role setup)
      </i>
    </div>`;
  }

  private _getDeleteTooltip(isEnabled = true) {
		return `<p class="font-b1 font-w-700">Delete Protection - <span class="font-b1 font-w-700 underline">
    ${isEnabled ? 'Enabled' : 'Disabled'}</span></p>
    <p class="font-b3 q-mt-8">If enabled, this role cannot be deleted</p>`;
	}
}
