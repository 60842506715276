import { todayNewUsers } from './portal-usage.selector';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as action from './portal-usage.action';
import { exhaustMap, finalize, map, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { PortalUsageService } from '../portal-usage.service';
import { SpinnerService } from '@app/core/services/spinner.service';

@Injectable()
export class PortalUsageEffects {
	fetchMonthlyUsageEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchMonthlyUsageAction),
			exhaustMap((data: any) => {
				//console.log('action params')
				this._spinner.start();
				return this.portalUsage.getMonthlyUsage(data.id).pipe(
					tap(console.log),
					map((monthly) =>
						action.fetchMonthlyUsageSuccessAction({
							monthlyReport: monthly.portalUsage,
							currentMonthActivity: monthly.currentMonthActivity,
							currentMonthUsers: monthly.currentMonthUsers,
							currentMonthNewUsers: monthly.currentMonthNewUsers,
							pastWeekActivity: monthly.pastWeekActivity,
							pastWeekUsers: monthly.pastWeekUsers,
							pastWeekNewUsers: monthly.pastWeekNewUsers,
							todayActivity: monthly.todayActivity,
							todayUsers: monthly.todayUsers,
							todayNewUsers: monthly.todayNewUsers,
						})
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchCompanyUsageEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchCompanyUsageAction),
			exhaustMap((data: any) => {
				//console.log('action params', data);
				this._spinner.start();
				return this.portalUsage.getCompanyUsage(data.id).pipe(
					map((company) => action.fetchCompanyUsageSuccessAction({ company })),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchCompanyDataEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchCompanyDataAction),
			exhaustMap((data: any) => {
				//console.log('action params', data);
				this._spinner.start();
				return this.portalUsage.getCompanyDetails(data.companyId).pipe(
					map((companyData) =>
						action.fetchCompanyDataSuccessAction({ companyData: companyData })
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchUsersActivityEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchUsersActivityAction),
			exhaustMap((data: any) => {
				//console.log('action params', data);
				this._spinner.start();
				return this.portalUsage.getUsersUsage().pipe(
					map((usersActivity) =>
						action.fetchUsersActivitySuccessAction({ usersActivity })
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchUsersActivityGraphEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchUsersActivityGraphAction),
			exhaustMap((data: any) => {
				//console.log('action params', data);
				this._spinner.start();
				return this.portalUsage.getUsersActivityGraph().pipe(
					map((usersActivity) =>
						action.fetchUsersActivityGraphSuccessAction({ usersActivity })
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchUserDetailsEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchUserDetailsAction),
			exhaustMap((data: any) => {
				//console.log('action params', data);
				this._spinner.start();
				return this.portalUsage.getUserDetails(data.userId).pipe(
					map((userDetails) =>
						action.fetchUserDetailsSuccessAction({ userDetails: userDetails })
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchUserActivityHistoryEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchUserActivityHistoryAction),
			exhaustMap(() => {
				//console.log('action params', data);
				this._spinner.start();
				return this.portalUsage.getUserActivityHistory().pipe(
					map((userActivityHistory) =>
						action.fetchUserActivityHistorySuccessAction({
							userActivityHistory,
						})
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchDailyUsageEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchDailyUsageAction),
			exhaustMap(() => {
				//console.log('action params') // dailyUsage, currentDailyActivity, currentDailyUsers
				this._spinner.start();
				return this.portalUsage.getDailyUsage().pipe(
					map((daily) => action.fetchDailyUsageSuccessAction(daily)),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchLoginReportSummaryEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchLoginReportSummary),
			exhaustMap(() => {
				//console.log('action params')
				this._spinner.start();
				return this.portalUsage.getUserLoginReportSummary().pipe(
					map((iListPage) =>
						action.fetchLoginReportSummarySuccess({ iListPage })
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchLoginReportDetailEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchLoginReportDetail),
			exhaustMap(() => {
				//console.log('action params')
				this._spinner.start();
				return this.portalUsage.getUserLoginReportDetail().pipe(
					map((iListPage) =>
						action.fetchLoginReportDetailSuccess({ iListPage })
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchCompanyDropdownEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchCompanyDropdown),
			exhaustMap(() => {
				//console.log('action params')
				this._spinner.start();
				return this.portalUsage.getCompanyDropdown().pipe(
					map((companyDropdown) =>
						action.fetchCompanyDropdownSuccess({ companyDropdown })
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchUserDropdownEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchUserDropdown),
			exhaustMap((data: any) => {
				//console.log('action params')
				return this.portalUsage
					.getUsersDropdownByCompanyId(data.companyId, data.query)
					.pipe(
						map((usersDropdown) =>
							action.fetchUserDropdownSuccess({ usersDropdown })
						)
					);
			})
		);
	});

	fetchDAUTypeDropdownEffect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchDAUTypeDropdown),
			exhaustMap((data: any) => {
				//console.log('action params')
				this._spinner.start();
				return this.portalUsage.getDAUTypeDropdown().pipe(
					map((DAUType) => action.fetchDAUTypeDropdownSuccess({ DAUType })),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchCompanyReportTop5Effect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchCompanyReportTop5Action),
			exhaustMap((data: any) => {
				//console.log('action params')
				this._spinner.start();
				return this.portalUsage.getCompanyUsageTop5().pipe(
					map((company) =>
						action.fetchCompanyReportTop5SuccessAction({ company })
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	fetchCompanyReportLeast5Effect = createEffect(() => {
		return this.actions$.pipe(
			ofType(action.fetchCompanyReportLeast5Action),
			exhaustMap((data: any) => {
				//console.log('action params')
				this._spinner.start();
				return this.portalUsage.getCompanyUsageLeast5().pipe(
					map((company) =>
						action.fetchCompanyReportLeast5SuccessAction({ company })
					),
					finalize(() => this._spinner.stop())
				);
			})
		);
	});

	constructor(
		private actions$: Actions,
		private portalUsage: PortalUsageService,
		private _spinner: SpinnerService
	) {}
}
