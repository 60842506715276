import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, map, of, tap } from 'rxjs';

import { NotificationsClienthubService } from './notifications-clienthub.service';
import { UserService } from '@app/core/services/user.service';

import {
	UserMessageNotificationCount,
	UserMessagePendingNotificationDetail,
} from '../interfaces/usermessage.interface';
import {
	Notification,
	NotificationCategory,
} from '@app/modules/communications/interfaces/notifications.interface';

import { environment } from 'environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UserNotificationService {
	private _messageNotificationCount = new BehaviorSubject<number>(0);
	notificationCount$ = this._messageNotificationCount.asObservable();

	constructor(
		private _userService: UserService,
		private _notificationHub: NotificationsClienthubService,
		private _http: HttpClient
	) {
		this.getNotificationCount().subscribe();

		this._notificationHub.messageNotificationReceived.subscribe((s) =>
			this._messageNotificationReceived(s)
		);
	}

	getNotifications(unreadOnly = false) {
		if (this._userService.user?.impersonatingBy) return of([]);
		return this._http
			.get<Notification[]>(
				environment.apiBaseUrl +
					'UserMessages/GetNotifications?unreadOnly=' +
					unreadOnly
			)
			.pipe(
				tap({
					next: (res) =>
						unreadOnly ? this._messageNotificationCount.next(res.length) : null,
				}),
				map((ns) => this._formatNotifications(ns))
			);
	}

	getNotificationCount() {
		if (this._userService.user?.impersonatingBy) return of([]);
		return this._http
			.get<UserMessageNotificationCount[]>(
				environment.apiBaseUrl + 'UserMessages/GetNotificationCount'
			)
			.pipe(tap({ next: (res) => this._messageNotificationReceived(res) }));
	}

	markReadNotifications(notifs: number[] = []) {
		if (this._userService.user?.impersonatingBy) return of(false);
		return this._http
			.put(environment.apiBaseUrl + 'UserMessages/ReadNotifications', {
				id: notifs,
			})
			.pipe(tap({ next: (_) => this._deductNotifCount(notifs.length) }));
	}

	getPendingNotificationDetail() {
		return this._http.get<UserMessagePendingNotificationDetail[]>(
			environment.apiBaseUrl + 'usermessages/Web/GetPendingNotificationDetail'
		);
	}

	getMessageNotifications() {
		return this._http.get<UserMessagePendingNotificationDetail[]>(
			environment.apiBaseUrl + 'usermessages/GetMessageNotifications'
		);
	}

	clearAllMessageNotifications() {
		if (!this._userService.user?.impersonatingBy)
			this._http
				.get<UserMessageNotificationCount[]>(
					environment.apiBaseUrl + 'usermessages/clearAllNotifications'
				)
				.subscribe();
	}

	clearNotificationById(id: number) {
		if (!this._userService.user?.impersonatingBy)
			this._http
				.get(
					environment.apiBaseUrl + 'usermessages/Web/ClearNotification/' + id
				)
				.subscribe();
	}

	private _messageNotificationReceived(s: UserMessageNotificationCount[]) {
		this._messageNotificationCount.next(
			s.map((o) => o.notificationCount).reduce((a, c) => a + c, 0)
		);
	}

	private _deductNotifCount(count: number) {
		const val = this._messageNotificationCount.value - count;
		if (val <= 0 || count == 0) this.getNotificationCount().subscribe();
		else this._messageNotificationCount.next(val);
	}

	private _formatNotifications(ns: Notification[]) {
		const approvals = ns.filter(
			(n) => n.categoryId == NotificationCategory.Approvals && !n.isRead
		);
		if (approvals.length) {
			ns = ns.filter(
				(n) => n.categoryId != NotificationCategory.Approvals || n.isRead
			);

			if (approvals.length > 1) {
				approvals[0].ids = approvals.map((a) => a.id);
				approvals[0].message = approvals[0].message
					.split(' ')
					.map((i) => {
						if (parseInt(i)) return approvals.length;
						else if (i == 'request') return 'requests';
						else return i;
					})
					.join(' ');
			}

			ns.unshift(approvals[0]);
		}

		const unread = ns.filter((n) => !n.isRead);
		unread.forEach((n) => {
			const ids = unread
				.filter((u) => u.refId == n.refId && u.categoryId == n.categoryId)
				.map((u) => u.id);
			if (ids.length > 1) n.ids = ids;
		});

		return ns;
	}
}
