export interface SRMainCategory {
	id: number;
	name: string;
	description: string;
	color: string;
	iconName: string;
	figmaIconId: string;
	mainCategoryId: number;
	mainCategory: string;
	isSmart: boolean;
}

export interface SRSmartCategory extends SRMainCategory {
	forms: SRFormByCategory[];
}

export interface SRCategory {
	id: number;
	categoryType: number;
	name: string;
	iconName: string;
	color: string;
}

export interface SRFormByCategory {
	id: number;
	title: string;
	description: string;
	categoryName: string;
	categoryIcon: string;
	color: string;
	modifiedBy: number;
	modifiedDate: string;
	lastModifiedBy: string | null;
	categoryId: number;
}

export interface GenericForm {
	summary: string;
	description: string;
	files: FileList | File[];
	isAnotherUser: boolean;
	contactUserId?: number;
	contactEmailAddress?: string;
	isNotListed: boolean;
	firstName?: string;
	lastName?: string;
	email?: string;
	othersToNotify: string;
	siteId: number;
	priorityId: number;
}

export interface NameId {
	id: number;
	name: string;
}

export interface SelectItem {
	value: number | string;
	display: string;
}

export interface SRCompanyBoard {
	companyId: number;
	companyName: string;
	hasDefault: boolean;
	defaultBoardId: number;
}

export interface SRCompanyBoardByMarket extends SRCompanyBoard {
	marketId: number;
}

export interface SRCompanyBoards {
	companyId: number;
	companyName?: string;
	defaultBoardId?: number;
	boardName?: string;
	boards: NameId[];
}

export interface SSSearchResult {
	id: number;
	title: string;
	description: string;
	mainCategoryId: number;
	subCategoryId: number;
	url: string;
	iconName: string;
	figmaIconId: string;
	isKbTopic: boolean;
	kbCategoryId: number;
}

export interface SSCommonIssue {
	id: number;
	topic: string;
	category: string;
	categoryId: number;
	categoryColor: number;
	categoryIconName: string;
}

export enum SearchStatus {
	default,
	typing,
	searching,
	complete,
}
