import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '@app/core/services/user.service';
import { RolesAndPermissionService } from '@app/modules/groups-and-permissions/roles-and-permission.service';

@Component({
  selector: 'role-header-component',
  templateUrl: './role-header.component.html',
  styleUrls: ['./role-header.component.scss']
})
export class RoleHeaderComponent implements OnInit, AfterViewInit {
  //inject
  rolesAndPermission = inject(RolesAndPermissionService)
  private _user = inject(UserService)


  //props
  company = new FormControl();
  backLink = '';

  constructor() { }
  ngAfterViewInit(): void {
    if(this.withCompany){
      this.company.setValue(this.companySelector)
      //this.parentChildSelector.companyForm
    }
  }

  ngOnInit() {
    if(this.isOverride) this.backLink = this.rolesAndPermission.stateOverrideBackLink!;
    else if(this.rolesAndPermission.stateParent==='company') this.backLink = this.rolesAndPermission.stateCompanyBackLink!
    else this.backLink = this.rolesAndPermission.backLink;
  }

  setCompanyId(company:any){
    //console.log('companyevent', company)
  }


  // setters and getters
  get withCompany(){return !!this.rolesAndPermission.moduleCompanyId}
  get companyId(){return this.rolesAndPermission.moduleCompanyId}
  get companySelector(){return this.rolesAndPermission.moduleCompany}
  get isSpAdmin(){return this._user.isSpAdmin}
  get isOverride(){return this.rolesAndPermission.modeState === 'override'}
  get isShowButton(){return this.rolesAndPermission.stateParent === 'default'}

}
