import { RolesAndPermissionService } from './../../../../modules/groups-and-permissions/roles-and-permission.service';
import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { DataModalService } from '../../data-modal.service';
import { Menu, ModulePages2 } from '@app/modules/groups-and-permissions';

@Component({
	selector: 'app-override-permission',
	templateUrl: './override-permission.component.html',
	styleUrls: ['./override-permission.component.scss'],
	standalone: true,
	imports: [CommonModule, SharedModule],
})
export class OverridePermissionComponent implements OnInit {
	@Input() data: any;

	dataModalService = inject(DataModalService);
	rNpService = inject(RolesAndPermissionService);
	menu: Menu[];
	constructor() {}

	ngOnInit(): void {
		this.rNpService._updateEditedPages();
		// console.log(this.rNpService._roleHeader$.value);
		this.menu = this.rNpService.mapGroupToOverrideModal();
		console.log(this.menu);
	}

	closeModal() {
		this.dataModalService.closeModal();
	}

	getRadioValue(evt: { name: string; value: boolean }) {
		const radioValue = {
			id: Number(evt.name),
			isPush: evt.value,
		};
		// update array
		this.rNpService.updateMenuActionsIsPush(radioValue);
	}

	skipAll() {
		this.menu.forEach((item) => {
			item.menuActions.forEach((action) => {
				action.isPush = false;
			});
		});
	}

	pushAll() {
		this.menu.forEach((item) => {
			item.menuActions.forEach((action) => {
				action.isPush = true;
			});
		});
	}

	submit() {
		this.rNpService.saveChanges();
		this.closeModal();
	}
}
