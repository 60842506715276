import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { RolesAndPermissionService } from '../../roles-and-permission.service';
import { Subscription } from 'rxjs';
import { HistoryUrl } from '@app/shared/constants/global-enum';
import { UserService } from '@app/core/services/user.service';
import { AccountService } from '@app/modules/account/account.service';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-add-update-role',
  templateUrl: './add-update-role.component.html',
  styleUrls: ['./add-update-role.component.scss']
})
export class AddUpdateRoleComponent implements OnInit, OnDestroy {
  // inject 
  private _route = inject(ActivatedRoute)
  private _rolesAndPermission = inject(RolesAndPermissionService)
  private _router = inject(Router);
  historyUrl = HistoryUrl;
  private _user = inject(UserService);
  private _accountService = inject(AccountService);
  private _breadcrumbService = inject(BreadcrumbService);

  // props
  isOverride = false;
  private _id: number;
  private _subs = new Subscription()

  constructor(
    private _activatedRoute: ActivatedRoute
  ) {
    this._id = Number(this._activatedRoute.snapshot.params['userId']);
  }

  ngOnInit() {
    const snapshot = this._route.snapshot;
    const routeConfig = this._route.routeConfig;

    this._rolesAndPermission.setStateIsEditPageLoading(true);
    this._rolesAndPermission.setIsNotOverride();
    
    if(routeConfig?.path?.includes('edit') && snapshot.params['id']){
      this._rolesAndPermission.setCurrentRoleId(snapshot.params['id']);
      this._rolesAndPermission.setState('edit')
      this._rolesAndPermission.initRoleEditPage();
    }else if(routeConfig?.path?.includes('add')){
      this._rolesAndPermission.setState('add')
      this._rolesAndPermission.setCurrentRoleId(0);
      this._rolesAndPermission.initRoleAddPage();
      
    }else if(routeConfig?.path?.includes(":roleId/:userId") // this is for override
      && snapshot.params['roleId']
      && snapshot.params['userId']
    ){
      this._rolesAndPermission.setOverrideParams(snapshot.params['roleId'], snapshot.params['userId'])
      this._rolesAndPermission.initOverridePage();
      this._rolesAndPermission.setIsOverride();
      //this.isOverride = true;
    }else if(routeConfig?.path?.includes('view')){
      this._rolesAndPermission.setCurrentRoleId(snapshot.params['id']);
      this._rolesAndPermission.setState('view')
      this._rolesAndPermission.initRoleViewPage();
    }
    else{
      // redirect to roles list
      this._router.navigateByUrl('roles-and-permissions')
    }

    if(snapshot.params['companyId']){
      this._rolesAndPermission.setModuleCompanyId(snapshot.params['companyId']);
      this._rolesAndPermission.setIsOverride();
      //this.isOverride = true
    }

    this._initBreadcrums();
    this._eventListener();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe()
  }

  private _eventListener(){
    this._subs.add(
      this._rolesAndPermission.isRoleOverride$.subscribe(v=>this.isOverride = v)
    )
  }

  // getters
  get isShowPermissions(){
    if(
      (
        this._rolesAndPermission.modeState === 'edit' 
        || this._rolesAndPermission.modeState === 'override'
        || this._rolesAndPermission.modeState === 'company-override'
      )
      || (this._rolesAndPermission.modeState === 'add' && this._rolesAndPermission.stateIsCreateHeaderSaved)
    )
      return true;
    else return false;
  }

  get historyType() {
    let urlPath: string = '',
    isOverride = this.isOverride,
    id: number = this._route.snapshot.params['id'],
    userId = this._id,
    isEditType = this._route.routeConfig?.path?.includes('edit');

    if (this.isOverride) {
      urlPath = this.historyUrl.ROLES_AND_PERMISSIONS_V2_USER_MENU_ACCESS_OVERRIDE;
      id = this._route.snapshot.params['roleId'];
    } else {
      if (this._route.routeConfig?.path?.includes('edit')) {
        urlPath = this.historyUrl.ROLES_AND_PERMISSIONS_V2_ROLE_AND_MENU_ACCESS;
        id = this._route.snapshot.params['id'];
      }
    }

    return { id, urlPath, isOverride, isEditType, userId };
  }

  private _initBreadcrums() {
    this._accountService.getUserData(this._id).subscribe((response: any) => {
      this._breadcrumbService.updateBreadCrumbsText('_contactId',btoa(this._id.toString()));
      this._breadcrumbService.updateBreadCrumbsText('_userName', (response!.lastName ? (response!.lastName + ', ') : '') + response!.firstName,);
    });
  }
}
