import { AuditTrailModule } from './../../../../shared/components/audit-trail/audit-trail.module';
import { StoreModule } from '@ngrx/store';
import { invoicesReducer } from './store/user/invoices.user.reducer';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { MultipleSelectionModule } from './../../../../shared/directives/multiple-selection/multiple-selection.module';
import { InvoicesComponent } from './invoices.component';
import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { PaymentMethodComponent } from '../payment-method/payment-method.component';
import { CompanyViewComponent } from './company-view/company-view.component';
import { ManageAutopayModalComponent } from '../manage-autopay-modal/manage-autopay-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ModalV2Module } from '@app/shared/components/modal/modal-v2/modal-v2.module';
import { CreditsTableComponent } from '../credits-table/credits-table.component';
import { PaymentsTableComponent } from '../payments-table/payments-table.component';
import { AccountActivityComponent } from '../account-activity/account-activity.component';
import { BillingContactComponent } from '../billing-contact/billing-contact.component';
import { InvoiceTableComponent } from './invoice-table/invoice-table.component';
import { WidgetLoadingModule } from '@app/shared/components/widget-loading/widget-loading.module';

const module = [
  CommonModule,
  SharedModule,
  MultipleSelectionModule,
  MatMenuModule,
  RouterModule,
  AuditTrailModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  AsyncPipe,
  FormsModule,
  MatFormFieldModule,
  MatInputModule,
  ModalV2Module,
  WidgetLoadingModule,
]

@NgModule({
  declarations: [
    InvoicesComponent, 
    PaymentMethodComponent,
    CompanyViewComponent,
    ManageAutopayModalComponent,
    CreditsTableComponent,
    PaymentsTableComponent,
    AccountActivityComponent,
    BillingContactComponent,
    InvoiceTableComponent
  ],

  imports: [
    module,
    StoreModule.forFeature('userInvoices', invoicesReducer)
  ],
  exports: [CompanyViewComponent]
})
export class InvoicesModule { }
