import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { AuditTrailModule } from '@app/shared/components/audit-trail/audit-trail.module';
import { ListsDragDropInputComponent } from '@app/shared/components/form-input/lists-drag-drop-input/lists-drag-drop-input.component';
import { SearchInputModule } from '@app/shared/components/form-input/search-input/search-input.module';
import { FormTooltipModule } from '@app/shared/components/form-tooltip/form-tooltip.module';
import { TipComponent } from '@app/shared/components/tip/tip.component';
import { ViewCompanyModule } from '@app/shared/components/view-company/view-company.module';
import { TrimPipeModule } from '@app/shared/pipes/trim.pipe.module';
import { SharedModule } from '@app/shared/shared.module';
import { InvoicesModule } from '../finance/pages/invoices/invoices.module';
import { ManageUserGroupComponent } from '../groups/pages/manage-user-group/manage-user-group.component';
import { AddUpdateRoleComponent, HeaderRoleFormsComponent, ModuleListComponent, ModulePermissionComponent, PermissionHeaderComponent, RoleBodyComponent, RoleButtonsComponent, RoleHeaderComponent, RolesAndModulesComponent, RolesAndPermissionsComponent, TableRoleFormsComponent, UserRoleFormsComponent, UserRoleFormsService } from './new-pages';
import { RPManageFormsAccess } from './new-pages/manage-forms-access/manage-forms-access.component';
import { RPManageUsers } from './new-pages/manage-users/manage-users.component';
import { GroupsAndPermissionsListComponent } from './pages/groups-and-permissions-list/groups-and-permissions-list.component';
import { RolesAndPermissionDummyService } from './roles-and-permission-dummy.service';


const components = [
  PermissionHeaderComponent,
  RolesAndModulesComponent,
  RolesAndPermissionsComponent,

  AddUpdateRoleComponent,
  RoleHeaderComponent,
  RoleBodyComponent,
  RoleButtonsComponent,
  ModuleListComponent,
  ModulePermissionComponent, 
  UserRoleFormsComponent,
  TableRoleFormsComponent,
  HeaderRoleFormsComponent,
  
  RPManageUsers,
  RPManageFormsAccess,

  GroupsAndPermissionsListComponent,
  ManageUserGroupComponent,
]

@NgModule({
  declarations: [
    components,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    SearchInputModule,
    ListsDragDropInputComponent,
    TipComponent,
    InvoicesModule,
    TrimPipeModule,
    FormTooltipModule,
    MatTabsModule,
    MatSlideToggleModule,
    AuditTrailModule,
    ViewCompanyModule,
    SearchInputModule,
  ],
  providers:[
    RolesAndPermissionDummyService,
    UserRoleFormsService,
  ],
  exports: [
    components
  ]
})
export class GroupsAndpermissionsModule { }
