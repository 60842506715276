import { Component, OnInit, inject, ViewChild } from '@angular/core';
import { RolesAndPermissionService } from '../../index';
import { ActivatedRoute, Router } from '@angular/router';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { filter } from 'rxjs';
import { UserService } from '@app/core/services/user.service';
import { HistoryUrl } from '@app/shared/constants/global-enum';
import { AuditTrailComponent } from '@app/shared/components/audit-trail/audit-trail.component';

@Component({
	selector: 'app-roles-and-permissions',
	templateUrl: './roles-and-permissions.component.html',
	styleUrls: ['./roles-and-permissions.component.scss'],
})
export class RolesAndPermissionsComponent implements OnInit {
	@ViewChild(AuditTrailComponent) auditTrailComponent: AuditTrailComponent;
	// inject
	private _rAndP = inject(RolesAndPermissionService);
	private _activated = inject(ActivatedRoute);
	private _companies = inject(CompaniesService);
	private _router = inject(Router);

	historyUrl = HistoryUrl;

	private _user = inject(UserService);

	constructor() {}

	ngOnInit() {
		const snapshot = this._activated.snapshot;

		if (snapshot.params['companyId']) {
			const companyId = snapshot.params['companyId'];
			this._companies.initCurrentCompany(companyId);
			this._rAndP.setStateParent('company');
			this._rAndP.setCompanyId(companyId);
			this._rAndP.setCompanyBackLink(this._router.routerState.snapshot.url);
			this._companies.currentCompany$
				.pipe(filter((v) => v != null))
				.subscribe((v) => {
					const company = {
						isParentSelector: false,
						companySelected: {
							id: companyId,
							name: v.name,
							addressLine1: v.addressLine1,
						},
					};
					this._rAndP.setModuleCompany(company);
				});
		} else if (this.withCompany) {
			this._rAndP.setStateParent('default');
			this._rAndP.setCompanyId(0);
		} else {
			this._rAndP.setStateParent('default');
			this._rAndP.setCompanyId(0);
			this._rAndP.setModuleCompany({
				isParentSelector: true,
				childCompanyDropdown: null,
			});
		}
		this._rAndP.initRolesList();
		this._initAuditTrail();
	}

	get companyId() {
		return this._activated.snapshot.params['companyId']
			? this._activated.snapshot.params['companyId']
			: '0';
	}

	private _initAuditTrail() {
		this._rAndP.auditTrailUpdate.subscribe({
			next: (res) => {
				if (res) this.auditTrailComponent?.refresh();
			},
		});
	}
	get withCompany() {
		return !!this._rAndP.moduleCompanyId;
	}
}
