import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RolesAndPermissionService } from '@app/modules/groups-and-permissions/roles-and-permission.service';

@Component({
  selector: 'app-header-role-forms',
  templateUrl: './header-role-forms.component.html',
  styleUrls: ['./header-role-forms.component.scss']
})
export class HeaderRoleFormsComponent implements OnInit {
  //inject
  rolesAndPermission = inject(RolesAndPermissionService)
  private _cd = inject(ChangeDetectorRef)

  //props
  queryString = new FormControl('');

  constructor() { }

  ngOnInit() {
  }

  // public API
  detectChanges() {
		this._cd.detectChanges();
	}

  onQuery(query: any = ''){

  }

}
