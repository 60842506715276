import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { SmsQuestMobileUpdateModalComponent } from './components/sms-quest-mobile-update-modal/sms-quest-mobile-update-modal.component';
import { RadioInputModule } from '@app/shared/components/form-input/radio-input/radio-input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalV2Module } from '@app/shared/components/modal/modal-v2/modal-v2.module';
import { PhoneInputModule } from '@app/shared/components/form-input/phone-input/phone-input.module';
import { LocalFigmaIconImgModule } from '@app/shared/local-figma-icon-img/local-figma-icon-img.module';
import { FigmaIconImgModule } from './../shared/figma-icon-img/figma-icon-img.module';
import { OptimizeQuestModalComponent } from './optimize-quest-modal/optimize-quest-modal.component';
import { NewFeatureInfoModalComponent } from './components/new-feature-info-modal/new-feature-info-modal.component';
import { MobileLaunchModalComponent } from './components/mobile-launch-modal/mobile-launch-modal.component';
import { ImpersonateAlertBarComponent } from './components/impersonate-alert-bar/impersonate-alert-bar.component';
import { FormTooltipModule } from '@app/shared/components/form-tooltip/form-tooltip.module';
import { QuestModalComponent } from './data-modal/data-modal.component';
import { ChangeStatusComponent } from './data-modal/templates/change-status/change-status.component';
import { QuestBetaComponent } from './components/quest-beta/quest-beta.component';

@NgModule({
	declarations: [
		UnderConstructionComponent,
		SmsQuestMobileUpdateModalComponent,
		OptimizeQuestModalComponent,
		NewFeatureInfoModalComponent,
		MobileLaunchModalComponent,
		ImpersonateAlertBarComponent,
  QuestBetaComponent,
	],
	exports: [
		UnderConstructionComponent,
		SmsQuestMobileUpdateModalComponent,
		OptimizeQuestModalComponent,
		NewFeatureInfoModalComponent,
		MobileLaunchModalComponent,
		ImpersonateAlertBarComponent,
    QuestBetaComponent,
	],
	imports: [
		QuestModalComponent,
		CommonModule,
		FigmaIconImgModule,
		LocalFigmaIconImgModule,
		RadioInputModule,
		PhoneInputModule,
		ReactiveFormsModule,
		ModalV2Module,
		FormTooltipModule,
		SharedModule,
	],
	providers: [],
})
export class CoreModule {}
