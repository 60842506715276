import { FormControl } from '@angular/forms';
import { GroupsService } from './../../groups.service';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { UserService } from '../../../../core/services/user.service';
import { NotificationMessages } from '@app/shared/constants';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { GroupsAndPermissionsService } from '@app/modules/groups-and-permissions/groups-and-permissions.service';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manage-user-group',
  templateUrl: './manage-user-group.component.html',
  styleUrls: ['./manage-user-group.component.scss']
})
export class ManageUserGroupComponent implements OnInit, AfterViewInit {
  companyId: number;
  userId: number;
  groups: any[] = [];
  groupId: any | undefined;
  groupName: string;
  userIds: any[] = [];
  userGroups: any[] = [];
  isGroupLevel: boolean;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _companiesService: CompaniesService,
    private _breadcrumbsService: BreadcrumbService,
    private _groupAndPermissionService: GroupsAndPermissionsService,
    private _groupsService: GroupsService,
    private _spinner: SpinnerService,
    private _toastMessageService: ToastMessageService,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _sessionStorage: SessionStorageService,
    private _router : Router,
    private _location: Location,
  )
  {
    this.companyId = this._activatedRoute.snapshot.paramMap.get('companyId')
                      ? Number(this._activatedRoute.snapshot.paramMap.get('companyId'))
                      : Number(this._sessionStorage.getStorage('companyId'));
    this.userId = Number(this._userService.user?.id);
    if (this._activatedRoute.snapshot.paramMap.get('groupId')) {
      this.groupId = Number(this._activatedRoute.snapshot.paramMap.get('groupId'));
      this.isGroupLevel = true;
    }

  }

  ngOnInit(): void {
    this._companiesService.subCompanyId = this.companyId;
    this._groupsService.subGroupId = this.groupId;
  }

  ngAfterViewInit(): void {
    this.setupBreadCrumbs();
    if (this.isGroupLevel) {
      this.getUsersByGroup();
    } else {
      this.getGroups()
    }
  }
  setupBreadCrumbs() {
    if (!this.isGroupLevel) {
      this._companiesService
        .getCompanyForBreadcrumb(this.companyId)
        .subscribe((response: any) => {
          this._breadcrumbsService.updateBreadCrumbsText('_companyName', response.name);
          this._breadcrumbsService.updateBreadCrumbsText('_companyId', this.companyId);
          this._companiesService.changeCompanyName(response.name);
        });
    } else {
      this._groupsService
      .getGroupForBreadcrumb(this.groupId)
      .subscribe({
        next: (resp) => {
          this.groupName = resp.name;
          this._breadcrumbsService.updateBreadCrumbsText('_groupName', resp.name);
        }
      })
    }
  }
  getGroups() {
    this._spinner.start();
    this._groupAndPermissionService.getGroupsDropdown(this.companyId).subscribe({
      next: (resp) => {
        this._spinner.stop();
        this.groups = resp;
      }, error: (err) => {
        this._spinner.stop();
        this._toastMessageService.showErrorMessage(err);
      }
    });
  }

  getUsersByGroup() {
    this._spinner.start();
    this._groupAndPermissionService.getUsersByGroups(this.groupId, this.companyId).subscribe({
      next: (resp) => {
        this._spinner.stop();
        this.userGroups = resp.map(
          (resp: { id: number; name: string | number, isInGroup: boolean }) => ({
            value: resp.id,
            display: resp.name,
            selected: resp.isInGroup
          })
        );
        this.getSelectedUsers(resp);
      }, error: (err) => {
        this._spinner.stop();
        this._toastMessageService.showErrorMessage(err);
      }
    });
  }
  getSelectedUsers(data: any) {
    const sId = data.filter((x: any) => {
      return x.isInGroup === true;
    })
    this.userIds = sId.map((a: any) => {
        return a.id;
    });
  }
  saveGroups() {
    const groupData = {
      groupId: this.groupId,
      companyId: this.companyId,
      userId: this.userId,
      userIds: this.userIds
    };
    this._spinner.start();
    this._groupAndPermissionService.saveUserGroups(groupData).subscribe({
      next: () => {
        this._spinner.stop();
        this._toastMessageService.showSuccessMessage(
          NotificationMessages.GroupSuccessAdded
        )

        this._router.navigate(['../'], { relativeTo: this._activatedRoute });

      }, error: (err) => {
        this._spinner.stop();
        this._toastMessageService.showErrorMessage(err);
      }
    })
  }
  goBack() {
    this._location.back();
  }
}
