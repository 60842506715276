import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ElementRef,
	ChangeDetectorRef,
	inject,
	OnDestroy,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
	MatAutocomplete,
	MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { debounceTime, filter, map, Observable, of, startWith, Subject, takeUntil } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SelectInputService } from './service/select-input.service';

@Component({
	selector: 'select-input',
	templateUrl: './select-input.component.html',
	styleUrls: ['./select-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SelectInputComponent,
			multi: true,
		},
	],
})
export class SelectInputComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
	//injection
	private _cd = inject(ChangeDetectorRef);
	private _selectInputService = inject(SelectInputService);

	@Input() options: any[];
	
	/**
	 *@param optionKeys - Add object property name for value and label if Options are object.
	 * - example: { value: 'id', label: 'name' } or { value: 'name', label: 'name' }
	 */
	@Input() optionKeys: SelectKeys;
	@Input() inputClass: string;
	@Input() placeholder: string;
	@Input() disabled = false;
	@Input() readonly = false;
	@Input() withDelete = false;
	@Input() activeFirstOption = true;
	@Input() canCreateOption = false;
	@Input() state$: Observable<any> = of(null);
	@Input() hasClear = true;
	@Input() hasNewStatus = false;
	@Input() showSearchIcon = false;
	@Input() overrideItemsEnabled: boolean = false;
	@Output() onChange = new EventEmitter(); //use (onChange) instead of (change)
	@Output() inputChange = new EventEmitter();
	@Output() onDelete = new EventEmitter();
	@Output() onBlur = new EventEmitter();

	control = new FormControl('');
	value: any; //set value using formControl or ngModel
	isFocused = false;
	valueText = '';
	selected: any;
	private _origPlaceholder = '';
	private _$unsubscribe: Subject<void> = new Subject<void>();

	items: SelectItem[] = [];
	filteredItems: Observable<SelectItem[]>;
	cursor: 'select-text' | 'select-pointer' = 'select-pointer';

	_onChange: Function;
	_onTouched: Function;
	_input: Function;
	@ViewChild(MatAutocomplete) optionsPanel: MatAutocomplete;
	@ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
	@ViewChild('inputSelect') inputSelect: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

	ngOnInit(): void {
		this.filteredItems = this.control.valueChanges.pipe(
			startWith(''),
			debounceTime(500),
			map((value) => (value != this.value ? value : '')),
			map((value) => (typeof value === 'string' ? value : '')),
			map((value) => this._filter(value || '')),
			map((value) => value.filter(x => x.label !== '')),
		);
	}

	ngAfterViewInit(): void {
		this.optionsPanel.closed.subscribe((res) => {
			if (this.value != this.control.value && !this.isFocused)
				this.control.setValue(this.value);
			else if (!this.isFocused) this.control.setValue(this.value);
		});
		this.state$.pipe(filter((v) => v !== null)).subscribe((v) => {
			if ('placeholder' in v) {
				this.placeholder = v.placeholder;
			}
			if ('selectedOption' in v) {
				this.selected = v.selectedOption;
			}
		});
		this.optionsPanel.opened.subscribe((v) => {
			this._setPlaceHolderOnOpen();
			this.cursor = 'select-text';
		});
		this.optionsPanel.closed.subscribe((v) => {
			this.cursor = 'select-pointer';
		});


		if (this.overrideItemsEnabled) {
			this._selectInputService.overrideItems
			.pipe(takeUntil(this._$unsubscribe))
			.subscribe({
				next: (items: any) => {
					if (Array.isArray(items) && items.length) {
						this.items = items;
						this._cd.detectChanges();
					}
				}
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['placeholder'] && changes['placeholder'].firstChange)
			this._origPlaceholder = changes['placeholder'].currentValue;

		if (changes['options']) {
			if (Array.isArray && !Array.isArray(this.options)) this.options = [];
			if (this.optionKeys)
				this.items = this.options.map((o) => ({
					value: o[this.optionKeys.value],
					label: o[this.optionKeys.label]?.toString() ?? '',
				}));
			else
				this.items = this.options.map((o) => ({
					value: o,
					label: o?.toString() ?? '',
				}));
			this.control.setValue(this.value);
			// if(this.items.length && this.value) this.control.setValue(this.value);
		}
		if (changes['disabled'])
			this.setDisabledState(changes['disabled'].currentValue);
	}

	private _filter(value: string) {
		return this.items.filter((item) =>
			item.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())
		);
	}

	displayFn(value: any) {
		return this.items.find((_) => _.value === value)?.label ?? '';
	}

	writeValue(value: any) {
		if (!this.value) this.placeholder = this._origPlaceholder;
		this.value = value;
		this.selected = value;
		this.control.setValue(value);
	}

	registerOnChange(fn: Function) {
		this._onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this._onTouched = fn;
	}

	registerInput(fn: Function) {
		this._input = fn;
	}

	setValue(value: any) {
		if (!value) this.placeholder = this._origPlaceholder;
		this.value = value;
		this.selected = value;
		this.valueText = this.inputSelect.nativeElement.value;
		if (this._onChange) this._onChange(this.value);
		this.onChange.emit(this.value);
    this.viewPort.scrollToOffset(0, 'smooth');
	}

	onInput(value: string) {
		if (this._input) this._input(value);
		this.inputChange.emit(value);
	}

	onTouched() {
		if (this.canCreateOption) {
		} else {
			this.isFocused = false;
			if (this.inputSelect.nativeElement.value === '') {
				this.inputSelect.nativeElement.value = this.options.length ? this.valueText : '';
			}
			if (this.value != this.control.value && !this.optionsPanel.isOpen)
				this.control.setValue(this.value);
			setTimeout(() => {
				if (this._onTouched) this._onTouched();
			});
		}

		this.onBlur.emit();
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
		if (this.disabled) this.control.disable();
		else this.control.enable();
	}

	clear() {
		if (this.canCreateOption) {
		} else {
			this.inputSelect.nativeElement.value = '';
		}
	}

	deleteClicked(id: number) {
		//e.preventDefault();
		this.onDelete.emit(id);
		this.autoTrigger.closePanel();
	}

	clickForm() {
		this.inputSelect.nativeElement.select();
		this.autoTrigger.openPanel();
	}

	closeSelection() {
		setTimeout(() => this.autoTrigger.closePanel(), 100);
	}

	getVirtualScrollHeight(el: HTMLElement): string {
		const height = el.querySelector(
			'.cdk-virtual-scroll-content-wrapper'
		)?.clientHeight;
		return `${height ? height + 2 : 0}px`;
	}

	openedChangeEvent() {
		if (this.overrideItemsEnabled && Array.isArray(this.items) && this.items.length) {
			this.items.filter((x: any) => {
				const element: any = document.getElementById(x.value);
				if (element) element.style.display = x.isSelected ? 'none' : 'flex';
			});	

			this.items = this.items.filter((x: any) => !x.isSelected);
			this._cd.detectChanges();
		}
	}

	private _setPlaceHolderOnOpen() {
		if (this.optionsPanel.isOpen && this.value && this.optionKeys) {
			const index = this.options.findIndex(
				(v) => v[this.optionKeys.value] == this.value
			);
			if (index >= 0) {
				this.placeholder = this.options[index][this.optionKeys.label];
			}
			else this.placeholder = this._origPlaceholder;
		}
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}

interface SelectKeys {
	value: string;
	label: string;
}

interface SelectItem {
	value: any;
	label: string;
}
