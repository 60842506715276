<modal-v2 *ngIf="true" [modalWidth]="542">
	<ng-template #modalV2Header>
		<div class="new-feature-cont" style="margin-bottom: 24px">
			<div class="new-feature-info_header q-d-flex q-ai-center">
				<div
					class="new-feature-info_header_icon q-d-flex q-jc-center q-ai-center"
				>
					<app-local-figma-icon-img
						[filename]="'info-circle-blue'"
						[width]="24"
						[height]="24"
						[hasSpaceOnRight]="false"
					></app-local-figma-icon-img>
				</div>

				<div class="new-feature-info_header_title font-h3">
					{{
						joinBeta
							? 'Thanks For your Interest!'
							: 'Join the Quest Beta Program '
					}}
				</div>
			</div>

			<div class="new-feature-cont-right">
				<button
					type="button"
					class="close"
					data-bs-dismiss="modal"
					aria-label="Close"
					(click)="close()"
				>
					<span aria-hidden="true">
						<app-figma-icon-img
							[iconName]="'x'"
							[hasSpaceOnRight]="false"
						></app-figma-icon-img>
					</span>
				</button>
			</div>
		</div>
	</ng-template>

	<ng-template #modalV2Body>
		<ng-container *ngIf="!joinBeta; else joinBetaTemp">
			<div class="new-feature-info_body q-d-flex q-f-column q-ai-start">
				<div class="qb-img-cont">
					<img src="./assets/images/quest-beta/asset-28.png" alt="" />
				</div>
				<div class="qb-p-cont mb-24">
					<p>
						Join the Quest Beta Program and get exclusive access to new features
						before anyone else. You’ll also play a key part in shaping the
						future of Quest<span class="symbol symbol-tm"></span> by Sourcepass
						by sharing your feedback.
					</p>
				</div>
				<div class="qb-p-cont mb-24">
					<p class="mb-24">What You'll Get:</p>
					<div class="qb-item" *ngFor="let f of features">
						<app-figma-icon-img
							[iconName]="f.icon"
							[width]="24"
							[height]="24"
							[hasSpaceOnRight16]="true"
							[isForButton]="false"
						></app-figma-icon-img>
						<span>{{ f.description }}</span>
					</div>
				</div>
			</div>
		</ng-container>
	</ng-template>

	<ng-template #modalV2Footer>
		<div
			class="optimize-quest-modal_footer q-w-100 q-d-grid q-g-16"
			style="width: 100%"
			[style.grid-template-columns]="!joinBeta ? '1fr 1fr' : '1fr'"
		>
			<button
				type="submit"
				class="btn-2 btn-2-tertiary"
				style="width: 92px"
				*ngIf="!joinBeta"
				[disabled]="isDisabled"
				(click)="submit(false)"
			>
				Not Now
			</button>

			<button
				type="button"
				class="btn-2 btn-2-primary"
				style="width: 92px"
				(click)="joinBeta = !joinBeta; submit(true, false)"
				*ngIf="!joinBeta"
				[disabled]="isDisabled"
			>
				Join Beta
			</button>

			<button
				type="button"
				class="btn-2 btn-2-primary"
				style="width: 92px"
				*ngIf="joinBeta"
				[disabled]="isDisabled"
				(click)="submit(true)"
			>
				Submit
			</button>
		</div>
	</ng-template>
</modal-v2>

<ng-template #joinBetaTemp>
	<div class="new-feature-info_body q-d-flex q-f-column q-ai-start">
		<div class="qb-img-cont">
			<img src="./assets/images/quest-beta/Asset-3.svg" alt="" />
		</div>
		<div class="qb-p-cont mb-24">
			<p>
				Thank you for joining the Quest Beta Program! We're excited to have you
				on board. Keep an eye on your inbox for updates on new features and ways
				to share your feedback.
			</p>
		</div>
		<div class="qb-dash mb-24"></div>
		<div class="qb-p-cont mb-24">
			<p class="mb-24">
				<span style="font-weight: 600">Optional:</span> Do you have any specific
				areas of interest or features you’re excited about?
			</p>
			<div class="qb-item-cont">
				<button
					type="button"
					class="btn-2 btn-2-tertiary unhover"
					style="margin-right: 8px; margin-bottom: 16px"
					*ngFor="let int of interests"
					(click)="updateChosen(int.id)"
					[class.is-selected]="chosen.includes(int.id)"
					[disabled]="isDisabled"
				>
					{{ int.name }}
				</button>
				<button
					type="button"
					class="btn-2 btn-2-tertiary unhover"
					style="margin-right: 8px; margin-bottom: 16px"
					[class.is-selected]="showInput"
					(click)="showInput = !showInput"
					[disabled]="isDisabled"
				>
					Other
				</button>
				<input
					type="text"
					*ngIf="showInput"
					width="376px"
					class="form-control drp-shadow"
					[(ngModel)]="input"
					placeholder="Add area of interest"
					[disabled]="isDisabled"
				/>
			</div>
		</div>
	</div>
</ng-template>
