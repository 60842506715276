import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChange,
	SimpleChanges,
} from '@angular/core';
import { ReportApiService } from '@app/modules/portal-usage/pages/dashboard-report/services/report-api.service';
import { TicketService } from '@app/modules/service-and-support/ticket.service';
import { TicketContacts } from '@app/shared/components/messaging/view-ticket/ticket.interface';
import { ViewTicketService } from '@app/shared/components/messaging/view-ticket/view-ticket.service';
import { getInitials } from '@app/shared/functions/user-initials.functions';
@Component({
	selector: 'app-ticket-details',
	templateUrl: './ticket-details.component.html',
	styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent implements OnInit, OnChanges {
	@Input() data: any;
	isOpenModal: boolean = false;
	isShowOthers: boolean = false;
	contacts: TicketContacts[] = [];
	ticketId = 0;
	constructor(
		private _ticketService: TicketService,
		private _viewTicket: ViewTicketService,
		public reportApiService: ReportApiService
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['data'].isFirstChange() === false) {
			this.getContacts();
		}
	}

	addDeleteContact(response: any) {
		this.getContacts();
	}

	open() {
		this.isOpenModal = true;
		this.ticketId = this.data.ticketProperties
			? this.data.ticketProperties.id
			: 0;
	}

	getContacts() {
		this.isShowOthers = false;
		this._ticketService
			.getContacts(this.data.ticketProperties.id)
			.subscribe((v) => {
				this._viewTicket.reCheckAvatar(this._getAvatarFields(v));
				this.contacts = v.map((x) => {
					const { firstName, lastName, email, id } = x;
					let avatar: any | undefined = undefined,
						noProfileCtr = 0;
					if (!x.imagePath) {
						avatar = this._viewTicket.addAvatar({
							firstName,
							lastName,
							email,
							userId: id,
							noProfileCtr: 0,
							comingFrom: 'reply',
						});
						noProfileCtr = avatar.noProfileCtr;
					}
					return {
						...x,
						//fullName: x.fullName ? x.fullName : x.email,
						hasImage: x.imagePath ? true : false,
						isAnonymous: !x.fullName,
						noProfileCtr: noProfileCtr,
						initials: !x.imagePath
							? getInitials({ firstName, lastName, email })
							: '',
					};
				});
				console.log(this.contacts);
				this.isShowOthers = true;
			});
	}

	private _getAvatarFields(avatar: TicketContacts[]) {
		return avatar.map((v) => {
			const { firstName, lastName, email, id } = v,
				comingFrom: 'reply' | 'comment' = 'reply';
			return {
				firstName,
				lastName,
				email,
				userId: id,
				noProfileCtr: 0,
				comingFrom,
			};
		});
	}
}
