import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewCompanyComponent } from './view-company.component';
import { FigmaIconImgModule } from '@app/shared/figma-icon-img/figma-icon-img.module';
import { CompanyDropdownModule } from '../company-dropdown/company-dropdown.module';
import { ChildCompanyDropdownModule } from '../child-company-dropdown/child-company-dropdown.module';

@NgModule({
  imports: [
    CommonModule,
    FigmaIconImgModule,
    CompanyDropdownModule,
    ChildCompanyDropdownModule,
  ],
  declarations: [ViewCompanyComponent],
  exports:[
    ViewCompanyComponent
  ]
})
export class ViewCompanyModule { }
