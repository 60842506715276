import { AfterViewInit, Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from '@app/core/services/user.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { RolesAndPermissionService } from '@app/modules/groups-and-permissions';

@Component({
  selector: 'permission-header-component',
  templateUrl: './permission-header.component.html',
  styleUrls: ['./permission-header.component.scss']
})
export class PermissionHeaderComponent implements OnInit, AfterViewInit {
  // inject
  //private _companiesService = inject(CompaniesService)
  _user = inject(UserService)
  _rAndP = inject(RolesAndPermissionService)

  // props
	//queryCompanyId = new FormControl<number | null>(null);
  //companies: any[] = [];
  addUrl = "./add";
  company = new FormControl();

  constructor() { }

  ngAfterViewInit(): void {
    if(this.withCompany){
      this.company.setValue(this.companySelector)
      //this.setCompanyId(this.companySelector)
    }
  }

  ngOnInit() {
/*     this._companiesService.getCompanyDropdown().subscribe((res) => {
      this.companies = res;
    }) */
  }

  // public method
  setCompanyId(company: any){
    if(company){
      if(!company.isParentSelector){
        this._rAndP.setCompanyId(company.companySelected.id)
        this._rAndP.setModuleCompanyId(company.companySelected.id);
        this._rAndP.setModuleCompany(company)
        this.addUrl = './add/'+company.companySelected.id
      }else{
        if(this._user.isSpAdmin) {
          this._rAndP.setCompanyId(0);
          this._rAndP.setModuleCompanyId(0);
          this._rAndP.setModuleCompany(null)
          this.addUrl = './add'
        }
        else {
          this._rAndP.setCompanyId(this._user.companyId)
          //this._rAndP.setModuleCompany(company)
          this.addUrl = './add/'+this._user.companyId
        }
      }
  
      this._rAndP.initRolesList()
    }
  }

  //setters and getters
  get withCompany(){return !!this._rAndP.moduleCompanyId}
  get companySelector(){return this._rAndP.moduleCompany}
  get isShowCompanySelector(){
    return this._user.isSpAdmin 
      && this._rAndP.stateParent === 'default'
  }

}
