import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestBetaService } from './quest-beta.service';

@Component({
	selector: 'app-quest-beta',
	templateUrl: './quest-beta.component.html',
	styleUrls: ['./quest-beta.component.scss'],
})
export class QuestBetaComponent implements OnInit {
	@Input() features: any[];
	@Input() interests: any[];
	@Output() onCloseModal: EventEmitter<void> = new EventEmitter<void>();
	joinBeta: boolean = false;
	showInput: boolean = false;
	chosen: number[] = [];
	options = [
		'Artificial Intelligence',
		'Mobile App',
		'Reporting',
		'Billing & Orders',
		'Communication',
	];
	isDisabled: boolean = false;
	input: string = '';
	constructor(private questBetaService: QuestBetaService) {}

	ngOnInit(): void {}

	close() {
		if (this.joinBeta) {
			this.submit(true);
		} else {
			this.onCloseModal.emit();
		}
	}

	updateChosen(id: number) {
		if (this.chosen.includes(id)) {
			const index = this.chosen.indexOf(id);
			if (index > -1) {
				this.chosen.splice(index, 1);
			}
		} else {
			this.chosen.push(id);
		}
	}

	submit(isAccepted: boolean, isClose: boolean = true) {
		const body = {
			isAccepted: isAccepted,
			interests: this.chosen,
			other: this.input,
		};
		this.isDisabled = true;
		this.questBetaService.postBeta(body).subscribe(() => {
			this.isDisabled = false;
			if (isClose) this.onCloseModal.emit();
		});
	}
}
