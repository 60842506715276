<td *ngIf="!isLoading" class="mat-cell" colspan="100%">
	<div class="q-w-100 q-center">{{ emptyMessage }}</div>
</td>

<ng-container *ngIf="isLoading">
	<td
		*ngIf="stickyLeft"
		class="mat-cell table-loading mat-table-sticky mat-table-sticky-border-elem-left"
		[attr.colspan]="stickyLeft"
		[ngStyle]="{ zIndex: 1, left: 0 }"
	>
		<ng-container [ngTemplateOutlet]="skeletons"></ng-container>
	</td>

	<td
		class="mat-cell table-loading"
		[attr.colspan]="colCount ? colCount - stickyLeft - stickyRight : '100%'"
	>
		<ng-container [ngTemplateOutlet]="skeletons"></ng-container>
	</td>

	<td
		*ngIf="stickyRight"
		class="mat-cell table-loading mat-table-sticky mat-table-sticky-border-elem-right"
		[attr.colspan]="stickyRight"
		[ngStyle]="{ zIndex: 1, right: 0 }"
	>
		<ng-container [ngTemplateOutlet]="skeletons"></ng-container>
	</td>
</ng-container>

<ng-template #skeletons>
	<div class="q-d-flex q-f-column">
		<div *ngFor="let i of pageSize | numSequence" class="loading-item">
			<div class="skeleton q-h-100"></div>
		</div>
	</div>
</ng-template>
