import { Injectable } from '@angular/core';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { Store } from '@ngrx/store';
import { fetchCompanyUsageAction } from '../../core/store';
import * as select from '../../core/store/portal-usage.selector'
import { map, take } from 'rxjs';


@Injectable()
export class CompanyActivityGraphService {

  constructor(private _store: Store<AppStateInterface>){

  }

/*   fetchData(){
    this._store.select(select.yearAndMonth)
      .pipe(take(1))
      .subscribe(v=>{
        if(v.year && v.month) this._store.dispatch(fetchCompanyUsageAction({params:{year: v.year, month: v.month}}))
      })
  } */

  // Selector
  //topCompany$ = this._store.select(select.topCompany)
  //bottomCompany$ = this._store.select(select.bottomCompany)
  dailyActivity$ = this._store.select(select.dailyUsage)
  companyReportTop5$ = this._store.select(select.companyReport).pipe(map(c => c.slice(0, 5)));
  companyReportLeast5$ = this._store.select(select.companyReportLeast5);

}
