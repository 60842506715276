import { IInvoices } from './../invoices.interface';
import { userInvoicesUpdateAction, userInvoicesUpdateSuccessAction } from './invoices.user.actions';
import { IPageState } from '@app/shared/interfaces/page-state.interface';
import { PaginationConstants } from "@app/shared/constants";
import { createReducer, on } from "@ngrx/store";

const initialState: IInvoices = {
  page: 1,
  pageSize: PaginationConstants.pageSize,
  order: 'desc',
  column: 'invoiceDate',
  query: '',
  totalItems: 0,
  invoiceStartDate: '',
  invoiceEndDate: '',
  dueStartDate: '',
  dueEndDate: '',
  statusIds: [],
  haveBalance: false,
  isInvoiceExecute: true,
  companyIds: null,
  companyId: null
}


export const invoicesReducer =
createReducer(
  initialState,
  on(userInvoicesUpdateAction, (state, action): IInvoices =>({
    ...state,
    ...action
  })),
  on(userInvoicesUpdateSuccessAction, (state): IInvoices => ({
    ...state
  }))
)
