<div *ngIf="data" class="lists-drag-drap-container q-d-flex q-f-column q-g-24">
	<div class="list-wrapper q-d-flex q-g-24">
		<h4>{{data.available.title}}</h4>
		<h4>{{data.selected.title}}</h4>
	</div>

	<ng-content></ng-content>

	<div class="list-wrapper q-d-flex q-g-24">
		<div class="drag-drop-v2-wrapper" [style.maxHeight]="height">
			<div class="drag-drop-v2-container">
				<div class="drag-drop-v2-header font-b1">
					<checkbox-input
						(change)="toggleAllItems($event, 0)"
						[indeterminate]="checkedAvailableItems.length > 0"
						[checked]="checkedAvailableItems.length > 0 && checkedAvailableItems.length == availableItems.length"
						class="dd-item-checkbox">
						<span class="font-b1">{{data.available.header}}</span>
					</checkbox-input>

					<div *ngIf="data.available.extraInfoHeader" class="extra-info font-b1 font-b1 q-pl-32 q-ml-auto">
						{{data.available.extraInfoHeader}}
					</div>
				</div>

				<div
				cdkDropList
				#available="cdkDropList"
				cdkDropListSortingDisabled
				[cdkDropListData]="availableItems"
				[cdkDropListConnectedTo]="[selected]"
				(cdkDropListDropped)="drop($event)"
				[class.dd-drag-container]="dragContainerId == 0"
				[class.is-filtered]="isAvailableFiltered"
				(scroll)="onScroll($event, 0)"
				class="drag-drop-v2-contents scroll-v2">
					<div
					cdkDrag
					(cdkDragStarted)="dragContainerId = 0; isAvailableFiltered = false"
					*ngFor="let item of availableItems"
					(click)="onToggleItem(item, 0)"
					[class.is-multiple]="checkedAvailableItems.length > 1 || (checkedAvailableItems.length && !checkedAvailableItems.includes(item))"
					[class.is-selected]="checkedAvailableItems.includes(item)"
					[class.is-last-selected]="checkedAvailableItems.length && checkedAvailableItems[checkedAvailableItems.length-1].id == item.id"
					class="drag-drop-v2-item">
						<div class="dd-item-contents">
							<mat-icon cdkDragHandle svgIcon="dots-vertical-02" class="dd-item-handle"></mat-icon>

							<checkbox-input class="dd-item-checkbox" [checked]="checkedAvailableItems.includes(item)"></checkbox-input>

							<div class="name-wrapper q-d-flex q-ai-center q-g-16">
								<img *ngIf="item.imgPath" [src]="item.imgPath">
								<div>
									<div class="font-h4">{{item.name}}</div>
									<div clas="font-b1">{{item.description}}</div>
								</div>
							</div>

							<div *ngIf="data.available.extraInfoHeader" class="extra-info font-b1 font-b1 q-pl-32 q-ml-auto">{{item.extraInfo}}</div>

							<mat-icon *ngIf="item.iconName && !data.available.extraInfoHeader" [svgIcon]="item.iconName" class="dd-item-icon"></mat-icon>

							<div
								*ngIf="checkedAvailableItems.length"
								class="dd-selected-count font-h4">
								{{checkedAvailableItems.includes(item) ? checkedAvailableItems.length : checkedAvailableItems.length+1}}
							</div>
						</div>
						
						<div class="dd-item-multiple-shadow"></div>
					</div>

					<div *ngIf="data.available.isLoading" class="drag-drop-v2-item">
						<div class="dd-item-contents">
							<mat-icon svgIcon="dots-vertical-02" class="dd-item-handle not-allowed"></mat-icon>
							<div class="font-h4">Loading...</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="drag-drop-v2-wrapper" [style.maxHeight]="height">
			<div class="drag-drop-v2-container">
				<div class="drag-drop-v2-header font-b1">
					<checkbox-input
						(change)="toggleAllItems($event, 1)"
						[indeterminate]="checkedSelectedItems.length > 0"
						[checked]="checkedSelectedItems.length > 0 && checkedSelectedItems.length == filteredSelectedItems.length"
						class="dd-item-checkbox">
						<span class="font-b1">{{data.selected.header}}</span>
					</checkbox-input>

					<div *ngIf="data.selected.extraInfoHeader" class="extra-info font-b1 font-b1 q-pl-32 q-ml-auto">
						{{data.selected.extraInfoHeader}}
					</div>
				</div>

				<div
				cdkDropList
				#selected="cdkDropList"
				[cdkDropListData]="filteredSelectedItems"
				[cdkDropListConnectedTo]="[available]"
				(cdkDropListDropped)="drop($event)"
				[class.dd-drag-container]="dragContainerId == 1"
				[class.is-filtered]="isSelectedFiltered"
				(scroll)="onScroll($event, 1)"
				class="drag-drop-v2-contents scroll-v2">
					<div
					cdkDrag
					*ngFor="let item of filteredSelectedItems"
					(cdkDragStarted)="dragContainerId = 1; isSelectedFiltered = false"
					(click)="onToggleItem(item, 1)"
					[class.is-multiple]="checkedSelectedItems.length > 1 || (checkedSelectedItems.length && !checkedSelectedItems.includes(item))"
					[class.is-selected]="checkedSelectedItems.includes(item)"
					[class.is-last-selected]="checkedSelectedItems.length && checkedSelectedItems[checkedSelectedItems.length-1].id == item.id"
					class="drag-drop-v2-item">
						<div class="dd-item-contents">
							<mat-icon cdkDragHandle svgIcon="dots-vertical-02" class="dd-item-handle"></mat-icon>

							<checkbox-input class="dd-item-checkbox" [checked]="checkedSelectedItems.includes(item)"></checkbox-input>

							<div class="name-wrapper q-d-flex q-ai-center q-g-16">
								<img *ngIf="item.imgPath" [src]="item.imgPath">
								<div>
									<div class="font-h4">{{item.name}}</div>
									<div clas="font-b1">{{item.description}}</div>
								</div>
							</div>

							<div *ngIf="data.selected.extraInfoHeader" class="extra-info font-b1 font-b1 q-pl-32 q-ml-auto">{{item.extraInfo}}</div>

							<mat-icon *ngIf="item.iconName && !data.selected.extraInfoHeader" [svgIcon]="item.iconName" class="dd-item-icon"></mat-icon>

							<div
								*ngIf="checkedSelectedItems.length"
								class="dd-selected-count font-h4">
								{{checkedSelectedItems.includes(item) ? checkedSelectedItems.length : checkedSelectedItems.length+1}}
							</div>
						</div>

						<div class="dd-item-multiple-shadow"></div>
					</div>
					
					<div *ngIf="data.selected.isLoading" class="drag-drop-v2-item">
						<div class="dd-item-contents">
							<mat-icon svgIcon="dots-vertical-02" class="dd-item-handle not-allowed"></mat-icon>
							<div class="font-h4">Loading...</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		*ngIf="(data.selected.displaySelected && checkedSelectedItems.length) ||
			(data.available.displaySelected && checkedAvailableItems.length)"
		class="list-wrapper q-d-flex q-g-24"
	>
		<h4 *ngIf="data.available.displaySelected && checkedAvailableItems.length">
			{{data.available.selectedLabel || 'Selected'}}:
			<ng-container *ngFor="let i of checkedAvailableItems; let l = last">
				{{i.name + (l ? '' : ', ') }}
			</ng-container>
		</h4>
		
		<h4 *ngIf="data.selected.displaySelected && checkedSelectedItems.length">
			{{data.selected.selectedLabel || 'Selected'}}:
			<ng-container *ngFor="let i of checkedSelectedItems; let l = last">
				{{i.name + (l ? '' : ', ') }}
			</ng-container>
		</h4>
	</div>
</div>