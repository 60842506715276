<div class="role-header">
  <div class="role-header-title">
    <div class="role-header-title__left">
      Manage Request Form
    </div>
    <div class="role-header-title__right">
      <!-- <a href="#demo" class="role-header-title__right--filter collapsed"
        data-bs-toggle="collapse"
        aria-controls="demo"
        role="button"
        aria-expanded="false"
        #filterEl
      >
        Filter 
        <app-filter-chevron
          [isCollapsed]="filterEl.classList.contains('collapsed')"
        ></app-filter-chevron>
      </a> -->
      <!-- <a href="javascript:void(0);" class="role-header-title__right--create">
        <app-figma-icon-img
          class="q-pointer"
          [iconName]="'plus'"
          [isForButton]="true"
        ></app-figma-icon-img>
        Create
      </a> -->
      <a href="javascript:void(0);" class="role-header-title__right--close">
        Close
      </a>
    </div>
  </div>
  
  
  <div id="demo" class="collapse">
    &nbsp;
  </div>
  
  <div class="role-header-heading">
    <h2>Client Success</h2>
    <span>
      You can adjust the level of visibility for what Request Forms are available to view for this specific role
    </span>
  </div>

  <div class="role-header-search">
   <!--  <search-input
      [formControl]="queryString"
      (keyup.enter)="onQuery()"
      (input)="onQuery(500)"
      type="text"
      placeholder="Search"
    >
    </search-input> -->

    <a href="javascript:void(0);" class="q-pointer">
      <!-- Change Access for (2) Forms -->
      {{rolesAndPermission.buttonLabel$|async}}
    </a>
  </div>
</div>
