import {
	Component,
	Injector,
	OnInit,
	TemplateRef,
	ViewChild,
	inject,
} from '@angular/core';
import { DataModalService, IDataModal } from './data-modal.service';
import { Observable } from 'rxjs';
import { ViewDetailsComponent } from './templates/view-details/view-details.component';
import { PriorizeVulnerabiltyComponent } from './templates/priorize-vulnerabilty/priorize-vulnerabilty.component';
import { SharedModule } from '@app/shared/shared.module';
import { ScheduleProjectComponent } from './templates/schedule-project/schedule-project.component';
import { RequestQuoteComponent } from './templates/request-quote/request-quote.component';
import { ChangeStatusComponent } from './templates/change-status/change-status.component';
import { SatisfactionScoreComponent } from './templates/satisfaction-score/satisfaction-score.component';
import { SatSurveyComponent } from './templates/sat-survey/sat-survey.component';
import { TotalTicketsComponent } from './templates/total-tickets/total-tickets.component';
import { UserTicketComponent } from './templates/user-ticket/user-ticket.component';
import { DeviceTicketComponent } from './templates/device-ticket/device-ticket.component';
import { ServiceTimesComponent } from './templates/service-times/service-times.component';
import { OnbComponent } from './templates/onb/onb.component';
import { ServiceTicketComponent } from './templates/service-ticket/service-ticket.component';
import { SitesTicketComponent } from './templates/sites-ticket/sites-ticket.component';
import { VulnerabilitiesComponent } from './templates/vulnerabilities/vulnerabilities.component';
import { ExpandTableComponent } from './templates/expand-table/expand-table.component';
import { PlatformUsageComponent } from './templates/platform-usage/platform-usage.component';
import { AtRiskUsersComponent } from './templates/at-risk-users/at-risk-users.component';
import { AtRiskDevicesComponent } from './templates/at-risk-devices/at-risk-devices.component';
import { RecentSecurityTicketsComponent } from './templates/recent-security-tickets/recent-security-tickets.component';
import { OverridePermissionComponent } from './templates/override-permission/override-permission.component';
@Component({
	selector: 'quest-modal',
	templateUrl: './data-modal.component.html',
	styleUrls: ['./data-modal.component.scss'],
	standalone: true,
	imports: [
		ViewDetailsComponent,
		PriorizeVulnerabiltyComponent,
		ScheduleProjectComponent,
		RequestQuoteComponent,
		ChangeStatusComponent,
		SatisfactionScoreComponent,
		SatSurveyComponent,
		TotalTicketsComponent,
		OnbComponent,
		ServiceTicketComponent,
		ServiceTimesComponent,
		DeviceTicketComponent,
		DeviceTicketComponent,
		UserTicketComponent,
		SitesTicketComponent,
		VulnerabilitiesComponent,
		AtRiskUsersComponent,
		AtRiskDevicesComponent,
		RecentSecurityTicketsComponent,
		ExpandTableComponent,
		PlatformUsageComponent,
		OverridePermissionComponent,
		SharedModule,
	],
})
export class QuestModalComponent implements OnInit {
	dataModalService = inject(DataModalService);
	modalData$: Observable<IDataModal | null>;
	modalValue$: Observable<Boolean>;

	injector = inject(Injector);
	ngOnInit(): void {
		this.modalData$ = this.dataModalService.modalData$;
	}

	closeModal(flag: boolean) {
		this.dataModalService.closeModal();
		this.dataModalService.emitValue(flag);
	}
}
