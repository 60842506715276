import { createReducer, on } from '@ngrx/store';
import * as portalInterface from '../portal-usage.interface';
import * as portalAction from './portal-usage.action';

export const initial: portalInterface.PortalUsageStore = {
	monthlyReport: [],

	companyReport: [],
	companyReportFilters: getDefaultFilters({
		column: 'usersActivity',
	}),
	companyReportListDetails: {
		currentPage: 1,
		pageSize: 10,
		totalCount: 0,
	},

	companyReportTop5: [],
	companyReportTop5Filters: getDefaultFilters({
		page: 1,
		pageSize: 5,
		column: 'usersActivity',
		order: 'desc',
	}),

	companyReportLeast5: [],
	companyReportLeast5Filters: getDefaultFilters({
		page: 1,
		pageSize: 5,
		column: 'usersActivity',
		order: 'asc',
	}),

	usersActivity: [],
	userActivityFilters: getDefaultFilters({
		column: 'userActivity',
		order: 'desc',
	}),
	userActivityDetails: {
		currentPage: 1,
		pageSize: 10,
		totalCount: 0,
	},
	companyData: null,
	companyId: null,

	usersActivityGraph: [],
	usersActivityGraphFilters: getDefaultFilters({
		column: 'userActivity',
		order: 'desc',
	}),

	dailyReport: [],

	year: null,
	month: null,
	currentMonthActivity: 0,
	currentMonthUsers: 0,
	currentMonthNewUsers: 0,
	pastWeekActivity: 0,
	pastWeekUsers: 0,
	pastWeekNewUsers: 0,
	companyListReport: [],

	userActivityHistory: [],
	userActivityHistoryFilters: getDefaultFilters({
		column: 'clicks',
		order: 'desc',
	}),
	userActivityHistoryDetails: {
		currentPage: 1,
		pageSize: 10,
		totalCount: 0,
	},
	userId: null,
	userDetails: null,

	todayActivity: 0,
	todayUsers: 0,
	todayNewUsers: 0,
	loginReportSummaryList: {
		currentPage: 1,
		pageSize: 10,
		totalCount: 0,
	},
	loginReportSummary: [],
	loginReportDetailsList: {
		currentPage: 1,
		pageSize: 10,
		totalCount: 0,
	},
	loginReportDetails: [],
	usersDropdown: [],
	companyDropdown: [],
	companyIdDropdown: null,
	userQuery: '',
	DAUType: [],
	fromDate: null,
	toDate: null,
	userLoginFilters: getDefaultFilters(),
	userLoginFiltersDetails: getDefaultFilters(),

	exportFilters: getDefaultFilters({
		column: 'id',
		order: 'asc',
	}),
};

export const reducer = createReducer(
	initial,
	on(
		portalAction.updateGeneralProperties,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			...action,
		})
	),
	on(
		portalAction.fetchMonthlyUsageSuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			monthlyReport: action.monthlyReport,
			currentMonthActivity: action.currentMonthActivity,
			currentMonthUsers: action.currentMonthUsers,
			currentMonthNewUsers: action.currentMonthNewUsers,
			pastWeekActivity: action.pastWeekActivity,
			pastWeekUsers: action.pastWeekUsers,
			pastWeekNewUsers: action.pastWeekNewUsers,
			todayActivity: action.todayActivity,
			todayUsers: action.todayUsers,
			todayNewUsers: action.todayNewUsers,
		})
	),
	on(
		portalAction.fetchCompanyUsageSuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			companyReport: action.company.data,
			companyReportListDetails: {
				currentPage: action.company.currentPage,
				pageSize: action.company.pageSize,
				totalCount: action.company.totalCount,
			},
		})
	),
	on(
		portalAction.updateYearMonthAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			year: action.year,
			month: action.month,
		})
	),
	on(
		portalAction.fetchCompanyListUsageSuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			companyListReport: action.companyListReport,
		})
	),
	on(
		portalAction.fetchCompanyDataSuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			companyData: action.companyData,
		})
	),
	on(
		portalAction.updateCompanyIdAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			companyId: action.companyId,
		})
	),
	on(
		portalAction.updateYearMonthCompanyIdAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			year: action.year,
			month: action.month,
			companyId: action.companyId,
		})
	),
	on(
		portalAction.fetchUsersActivitySuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			usersActivity: action.usersActivity.data,
			userActivityDetails: {
				currentPage: action.usersActivity.currentPage,
				pageSize: action.usersActivity.pageSize,
				totalCount: action.usersActivity.totalCount,
			},
		})
	),
	on(
		portalAction.fetchUsersActivityGraphSuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			usersActivityGraph: action.usersActivity.data,
		})
	),
	on(
		portalAction.fetchUserDetailsSuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			userDetails: action.userDetails,
		})
	),
	on(
		portalAction.fetchUserActivityHistorySuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			userActivityHistory: action.userActivityHistory.data,
			userActivityHistoryDetails: {
				currentPage: action.userActivityHistory.currentPage,
				pageSize: action.userActivityHistory.pageSize,
				totalCount: action.userActivityHistory.totalCount,
			},
		})
	),
	on(
		portalAction.updateUserIdAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			userId: action.userId,
		})
	),
	on(
		portalAction.updateYearMonthUserIdAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			year: action.year,
			month: action.month,
			userId: action.userId,
		})
	),
	on(
		portalAction.fetchDailyUsageSuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			dailyReport: action.dailyUsage,
			todayActivity: action.todayActivity,
			todayUsers: action.todayUsers,
		})
	),
	on(
		portalAction.fetchLoginReportSummarySuccess,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			loginReportSummary: action.iListPage.data,
			loginReportSummaryList: {
				currentPage: action.iListPage.currentPage,
				pageSize: action.iListPage.pageSize,
				totalCount: action.iListPage.totalCount,
			},
		})
	),
	on(
		portalAction.fetchLoginReportDetailSuccess,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			loginReportDetails: action.iListPage.data,
			loginReportDetailsList: {
				currentPage: action.iListPage.currentPage,
				pageSize: action.iListPage.pageSize,
				totalCount: action.iListPage.totalCount,
			},
		})
	),
	on(
		portalAction.fetchCompanyDropdownSuccess,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			companyDropdown: action.companyDropdown,
		})
	),
	on(
		portalAction.fetchUserDropdownSuccess,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			usersDropdown: action.usersDropdown,
		})
	),
	on(
		portalAction.updateUserDropdownParams,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			companyIdDropdown: action.companyIdDropdown,
			userQuery: action.userQuery,
		})
	),
	on(
		portalAction.fetchDAUTypeDropdownSuccess,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			DAUType: action.DAUType,
		})
	),
	on(
		portalAction.updateStartEndDate,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			fromDate: action.fromDate,
			toDate: action.toDate,
		})
	),
	on(
		portalAction.updateUserLoginFilters,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			...{
				userLoginFilters: {
					...state.userLoginFilters,
					...action.userLoginFilters,
				},
			},
		})
	),
	on(
		portalAction.updateUserLoginFiltersDetails,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			...{
				userLoginFiltersDetails: {
					...state.userLoginFiltersDetails,
					...action.userLoginFiltersDetails,
				},
			},
		})
	),
	on(
		portalAction.updateLoginReportSummaryList,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			...{
				loginReportSummaryList: {
					...state.loginReportSummaryList,
					...action.loginReportSummaryList,
				},
			},
		})
	),
	on(
		portalAction.updateLoginReportDetailsList,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			...{
				loginReportDetailsList: {
					...state.loginReportDetailsList,
					...action.loginReportDetailsList,
				},
			},
		})
	),
	on(
		portalAction.fetchCompanyReportTop5SuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			companyReportTop5: action.company.data,
		})
	),
	on(
		portalAction.fetchCompanyReportLeast5SuccessAction,
		(state, action): portalInterface.PortalUsageStore => ({
			...state,
			companyReportLeast5: action.company.data,
		})
	)
);

function getDefaultFilters(
	override: any = undefined
): portalInterface.UserLoginFilters {
	let defaultFilters: portalInterface.UserLoginFilters = {
		fromDate: '',
		toDate: '',
		page: 1,
		pageSize: 10,
		order: 'desc',
		column: 'date',
		query: '',
		totalItems: 0,
	};
	if (override) {
		defaultFilters = { ...defaultFilters, ...override };
	}
	return defaultFilters;
}
