import { Component, Input } from '@angular/core';

@Component({
  selector: 'quest-profile',
  templateUrl: './quest-profile.component.html',
  styleUrls: ['./quest-profile.component.scss']
})
export class QuestProfileComponent {
  @Input() isQuestIcon: boolean = false;
  @Input() path: string = '';
}
