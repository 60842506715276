/* Angular Libraries */
import {
	Component,
	OnInit,
	ViewChild,
	ChangeDetectorRef,
	AfterViewInit,
  OnDestroy
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

/* Third Party Libraries */
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { filter, finalize, Subscription, take } from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { FinanceService } from '../../finance.service';
import { UserService } from '@services/user.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { FormUsageService } from '@app/modules/form-usage/form-usage.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { IntegrationService } from '@app/modules/integration/integration.service';
import { AdminService } from '@app/modules/admin/admin.service';

/* Components */
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { CompanyIdRouteComponent } from '@app/shared/components/route/company-id-route/company-id-route.component';
import { SearchInputComponent } from '@app/shared/components/form-input/search-input/search-input.component';

/* Interfaces */
import { FormUsage, TableSignalType } from './../../../../shared/constants/global-enum';
import { TransactionType } from '@app/shared/constants/transaction-type.enum';
import {
	PaginationConstants,
	TableMessages,
	Permission,
	NotificationMessages,
} from '@app/shared/constants';
import { TableReloadSignalService } from '@app/shared/services/table-reload-signal.service';

@Component({
	selector: 'app-invoice-detail',
	templateUrl: './invoice-detail.component.html',
	styleUrls: ['./invoice-detail.component.scss'],
})
export class InvoiceDetailComponent
	extends CompanyIdRouteComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	/* ViewChild */
	@ViewChild(PaginatorComponent) paginator: PaginatorComponent;
	@ViewChild('searchTextbox') searchTextbox: SearchInputComponent;
	@ViewChild(MatSort) sort: MatSort;

	/* Properties */
	invoiceId = 0;
	form = new FormGroup({
		id: new FormControl(0),
		invoiceNumber: new FormControl(null),
		description: new FormControl(''),
		orderId: new FormControl(0),
	});
	displayedColumns = [
		'id',
		'identifier',
		'description',
		'invoiceDate',
		'quantity',
		'unitPrice',
		'total',
	];
	dataSource: MatTableDataSource<any> | any[] = new MatTableDataSource();
	hasData: boolean = false;
	totalItems = 0;
	pageSizes = PaginationConstants.pageSizes;
	message = {
		noRecord: TableMessages.EmptyTable,
	};
	user = this._userService.user;
	companyId: number;
  isDownloadEnabled = this._userService.hasPermission([
		Permission.UserDownloadInvoice,
	]);
	isInquireEnabled = this._userService.hasPermission([
		Permission.UserViewInvoiceDetails,
	]);
	isPaymentInvoiceEnabled = this._userService.hasPermission([
		Permission.UserPaymentOfInvoice,
	]);
  searchTimeout: any;
	formUsageData: any;
	invoiceInquiryFormId: number;
	invoice: any;
	isStripeEnabled: boolean;
	isPaymentEnabled: boolean;
	urlEndpoint: string = '';
	transactionType = TransactionType;
	queryString = new FormControl('');
	agreementId: number;

  private _subscription = new Subscription();

  /* Constructor */
	constructor(
		public spinner: SpinnerService,
		public _invoiceService: FinanceService,
		private _userService: UserService,
		private location: Location,
		private _activatedRoute: ActivatedRoute,
		private _breadcrumbsService: BreadcrumbService,
		public override _route: ActivatedRoute,
		public override _companiesService: CompaniesService,
		public _utilitiesService: UtilitiesService,
		private _formUsage: FormUsageService,
		private _cd: ChangeDetectorRef,
		private _toastMessageService: ToastMessageService,
		private _integrationService: IntegrationService,
		private _router: Router,
		private _adminService: AdminService,
		private _breadcrumbService: BreadcrumbService,
		private _tableReloadSignalService: TableReloadSignalService
	) {
		super(_companiesService, _route);
		if (this.user?.roleId) {
			this.companyId =
				this.user.roleId === 1
					? Number(this._activatedRoute.snapshot.paramMap.get('companyId'))
					: this.user.companyId;
		}

		this.agreementId = Number(this._activatedRoute.snapshot.paramMap.get('agreementId'));
	}

	/* It will hidden this close button when you are in companies page. */ 
  get isCloseButtonHidden() {
		return !Number(this._activatedRoute.snapshot.paramMap.get('companyId'));
	}

  /* Methods */
	ngOnInit() {
		this._getCompanyById();
		this.getCompanyIdFromParams();

		this._route.paramMap.subscribe(params => {
			this.invoiceId = Number(params.get('id'));
			this._initInvoiceInfo();
		})

		this.form.valueChanges.subscribe((val) => {
			this.getInvoiceProductList();
		});
		this.getIntegrationSettings();
		this._getAgreementDetails();
		this._initTableReloadFromSignaIR();
		this._cd.detectChanges();
	}

	ngAfterViewInit() {
		this.paginator.size = PaginationConstants.pageSize;
		this._sortSubscription();
		this.#getFormId();
		this._cd.detectChanges();
	}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

	onSearch() {
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.getInvoiceProductList();
		}, 1000);
	}

	downloadInvoice(id: any) {
		if (id) {
			this._cd.markForCheck();
			this.spinner.start();
			this._invoiceService.downloadInvoice2(id).subscribe({
				next: (data: any) => {
					const link = document.createElement('a');
					link.href = data;
					link.setAttribute('download', `Invoice ${id}.pdf`);
					link.target = '_blank';
					document.body.appendChild(link);
					link.click();

					setTimeout(() => this.spinner.stop(), 1000);
				},
				error: (error) => {
					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
					this.spinner.stop();
				},
			});
		}
	}

	printInvoice(id: any) {
		if (id) {
			this.spinner.start();
			this._invoiceService.downloadInvoice(id).subscribe({
				next: (data: any) => {
					const url = window.URL.createObjectURL(
						new Blob([data], { type: 'application/pdf' })
					);
					window.open(url, '_blank');
					this.spinner.stop();
				},
				error: (error) => {
					this.spinner.stop();
				},
			});
		}
	}

	getInvoiceProductList(flag = 'page', pageOrSize?: number) {
		if (
			this.form.value.id !== undefined &&
			this.form.value.id !== null &&
			this.form.value.id > 0
		) {
			if (flag === 'page') {
				this.paginator.page = pageOrSize || 1;
			} else if (flag === 'size') {
				this.paginator.size = pageOrSize!;
				this.paginator.setTotalPages();
				this.paginator.setPages();
			}

			this.spinner.start();
			this._invoiceService
				.getInvoiceDetails(
					{
						page: this.paginator.page,
						pageSize: this.paginator.size,
						search: this.queryString.value,
						sort: this.sort.active ?? '',
						order: this.sort.direction,
					},
					this.form.value.id
				)
				.subscribe({
					next: (data: any) => {
						clearTimeout(this.searchTimeout);
						this.totalItems = data.totalCount;
						this.dataSource = new MatTableDataSource(data.data);
						this.hasData = !!data.data.length;
						this.spinner.stop();
					},
					error: (error) => {
						this.spinner.stop();
					},
				});
		}
	}

	onClose() {
		this.location.back();
	}

	getIntegrationSettings() {
		this._integrationService.getStripePaymentStatus().subscribe({
			next: (res: any) => {
				this.isStripeEnabled = res.isStripeEnabled as boolean;
				this.isPaymentEnabled = res.isPaymentEnabled as boolean;
			},
		});
	}

	payInvoice() {
		this._router.navigate(
			[`/${ this._invoiceService.isAdmin ? 'billing-orders' : 'billing-and-orders'}/invoices/payment-process`],
			{
				queryParams: {
					selectedId: [this.invoiceId],
					pathName: `/${
						this._invoiceService.isAdmin
							? 'billing-orders'
							: 'billing-and-orders'
					}/invoices`,
				},
			}
		);
	}

	negativeToPositiveConvertion(num: number) {
		return Math.abs(num);
	}

	getPrintInvoice(_invoice: any) {
		this.spinner.start();
		this._invoiceService
			.downloadInvoice2(_invoice.id)
			.pipe(finalize(() => setTimeout(() => this.spinner.stop(), 500)))
			.subscribe({
				next: async (data: any) => {
					this.#viewPdf(data, _invoice.id);
				},
				error: () => {
					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
			});
	}

	downloadInvoiceReceipt(id: number) {
		if (id) {
			this.spinner.start();

			this._invoiceService.downloadReceiptByIds([id]).subscribe({
				next: (result: any) => {
					if (result) {
						const link = document.createElement('a');
						link.href = result;

						if (!result.includes('Print/Zip')) {
							link.target = '_blank';
						}

						document.body.appendChild(link);
						link.click();
					}

					this.spinner.stop();
				},
				error: (error) => {
					this.spinner.stop();

					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				}
			});
		}
	}

	#viewPdf(data: any, invoiceId: number) {
		this._invoiceService.pdf$.next(data);
		this._router.navigate(['view-pdf', invoiceId], {
			queryParams: { isInvoiceType: true },
			relativeTo: this._route,
		});
	}

	#getFormId() {
		this._formUsage.getFormUsageByUsage(FormUsage.InvoiceInquiry).subscribe({
			next: (res: any) => {
				const data = {
					usageId: FormUsage.InvoiceInquiry,
					data: { invoiceId: this.invoiceId },
				};
				(this.invoiceInquiryFormId = res[0]?.srFormId),
					(this.formUsageData = window.btoa(JSON.stringify(data)));
				this._cd.markForCheck();
			},
		});
	}

  private _sortSubscription() {
    this._subscription.add(
      this.sort.sortChange.subscribe((data) => {
        this.getInvoiceProductList();
      })
    );
  }

	private _getAgreementDetails() {
		if (this.agreementId) {
			this._adminService.getAgreementDetails(this.agreementId).subscribe({
				next: (resp: any) => {
					this._breadcrumbService.updateBreadCrumbsText('_agreementName', resp.name);
				},
				error: (error) => {
					this._toastMessageService.showErrorMessage(error);
				},
			});
		}
  }

	private _getCompanyById() {
		this.spinner.start();
		this._companiesService
			.getCompanyForBreadcrumb(this.companyId)
			.pipe(take(1))
			.subscribe((response: any) => {
				this._breadcrumbsService.updateBreadCrumbsText(
					'_companyName',
					response?.name
				);
				this._breadcrumbsService.updateBreadCrumbsText(
					'_companyId',
					this.companyId
				);
				this.spinner.stop();
			});
	}

	private _initInvoiceInfo() {
		this._invoiceService
			.getInvoiceById(this.invoiceId)
			.subscribe((response: any) => {
				this.invoice = response;
				this.urlEndpoint =
					this.invoice?.transactionType === TransactionType.invoice
						? 'GetCreditAppliedById'
						: 'GetCreditAppliedInvoicesById';
				this._breadcrumbsService.updateBreadCrumbsText(
					'_invoiceName',
					response.invoiceNumber
				);
				this.form.patchValue({
					id: response.id,
					invoiceNumber: response.invoiceNumber,
					description: response.description,
					orderId: response.orderId,
				});
				this._cd.markForCheck();
			});
	}

	private _initTableReloadFromSignaIR() {
		this._tableReloadSignalService.tableReloadFromSignaIR(TableSignalType.INVOICE)
		.subscribe({ next: () => this._initInvoiceInfo() });
	}
}
