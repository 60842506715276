import { ChangeDetectorRef, Component, OnInit, ViewChild, Renderer2, Inject, ChangeDetectionStrategy } from '@angular/core';
import { finalize, forkJoin, map, Observable, switchMap, take, tap } from 'rxjs';
import { GroupsAndPermissionsService } from '../../groups-and-permissions.service';
import { IPermissionsCheck, MenuAndActionPermissionParam, MenuOverrideParam, permissionProperties } from '@app/shared/interfaces/groups.interface';
import { UserService } from '@app/core/services/user.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { NotificationService } from '@app/core/services/notification.service';
import { UserTypes, NotificationMessages, HistoryUrl} from '@app/shared/constants';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ActivatedRoute } from '@angular/router';
import { CompanyIdRouteComponent } from '@app/shared/components/route/company-id-route/company-id-route.component';
import { BreadcrumbService } from '@app/shared/navigation/breadcrumb/breadcrumb.service';
import { apiUrl, OptionsModel } from '@app/shared/functions/options';
import { AccountService } from '@app/modules/account/account.service';
import { FormControl } from '@angular/forms';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { GroupsService } from '@app/modules/groups/groups.service';
import { ContactsService } from '@app/modules/contacts/contacts.service';
import { AuditTrailComponent } from '@app/shared/components/audit-trail/audit-trail.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-groups-and-permissions-list',
  templateUrl: './groups-and-permissions-list.component.html',
  styleUrls: ['./groups-and-permissions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsAndPermissionsListComponent extends CompanyIdRouteComponent implements OnInit  {

  // enums
  spAdmin = UserTypes.SourcepassAdmin;
  clientAdmin = UserTypes.ClientAdmin;
  userType = UserTypes.User;
  apiUrl = apiUrl;
  moduleLevel = apiUrl.GLOBAL;

  menuIds = permissionProperties.menuIds;
  menuActionIds = permissionProperties.menuActionIds;

  modules: any[];
  spAdminModules: any[];
  clientAdminModules: any[];
  userModules: any[];
  flatModules: any[] = [];
  roleList: any[];

  selectedModuleId: number | null;
  selectedModule: any;
  accessibleOption = false;

  selectedMenuId = 0;
  selectedSubMenuId = 0;
  selectedThirdMenuId = 0;
  selectedFourthMenuId = 0;

  permissions: any;
  searchPermssion: string = '';

  checkedModule:IPermissionsCheck ={
    roleId: 0,
    menuIds: {},
    menuActionIds: {},
  };

  companyId:any = 0;
  groupId:any = 0;
  userId:any = 0;
  currentRole: UserTypes = 0;
  user = this._userService.user;
  menuAndActionPermissionParam: MenuAndActionPermissionParam;
  menuOverrideParam: MenuOverrideParam;
  selectedTab = new FormControl(0);
  contactRoleId = 0;
  historyUrl = HistoryUrl;
  dataOptions : OptionsModel;
  showSPPermissions = false;
  updatedMenuIds: Record<any, any> = {};
  updatedMenuActionIds: Record<any, any> = {};
  private _initTask : Observable<any>[] = [];

  @ViewChild(AuditTrailComponent) auditTrail : AuditTrailComponent;
  constructor(
    private _groupService: GroupsAndPermissionsService,
    private _userService: UserService,
    public override _companiesService: CompaniesService,
    public spinner: SpinnerService,
    private _notifier: NotificationService,
    private _cd: ChangeDetectorRef,
    private _breadcrumbsService: BreadcrumbService,
    public override _route: ActivatedRoute,
    private _accountService: AccountService,
    private _sessionStorageService: SessionStorageService,
    private _groupsService: GroupsService,
    private _contactsService: ContactsService,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
    ) {
      super(_companiesService, _route);
      if (this._userService.userRole === UserTypes.SourcepassAdmin) {
        if(this._route.snapshot.params['companyId']){
          this.companyId = Number(this._route.snapshot.paramMap.get('companyId'));
          this.moduleLevel = apiUrl.COMPANY;
          this.showSPPermissions = true;
        }
        else if(this._route.snapshot.params['userId']){
          this.userId = Number(atob((this._route.snapshot.params['userId'])));
          this.moduleLevel = apiUrl.CONTACT;
          this.showSPPermissions = true;
        }
        else if(this._route.snapshot.params['groupId']){
          this.groupId = Number(this._route.snapshot.paramMap.get('groupId'));
          this.companyId = Number(this._sessionStorageService.getStorage('companyId'));
          this.moduleLevel = apiUrl.GROUP;
          this.showSPPermissions = true;
        }
        else {
          this.currentRole = UserTypes.SourcepassAdmin;
          this.moduleLevel = apiUrl.GLOBAL;
        }
      }
      else if (this._userService.userRole === UserTypes.ClientAdmin) {
        this.companyId = this._userService.user?.companyId;
        if(this._route.snapshot.params['userId']){
          this.userId = Number(atob((this._route.snapshot.params['userId'])));
          this.moduleLevel = apiUrl.CONTACT;
        }
        else if(this._route.snapshot.params['groupId']){
          this.groupId = Number(this._route.snapshot.paramMap.get('groupId'));
          this.moduleLevel = apiUrl.GROUP;
        }
        else {
          this.moduleLevel = apiUrl.COMPANY;
        }
      }
      if(this.userId && !this.companyId){
        this._initTask.push(
          this._accountService.getUserProfile(this.userId).pipe(tap((s: any) => this.companyId = s.companyId))
        );
      }
      this.setupBreadCrumbs();
    }

  ngOnInit(): void {
    forkJoin(this._initTask).pipe(finalize(()=>{
      this._companiesService.subCompanyId = this.companyId;
      this._groupsService.subGroupId = this.groupId;
      this._contactsService.subUserId = btoa(this.userId).replace(/[^\w\s]/gi, '');

      if(this.moduleLevel !== apiUrl.GLOBAL) {
        this._getOverrideFlag();
      }
      this.getRoles();

      this.dataOptions = new OptionsModel();
      this.dataOptions.roleId  = this.currentRole;
      this.dataOptions.companyId = this.companyId;
      this.dataOptions.userId = this.userId;
      this.dataOptions.groupId = this.groupId;
    })).subscribe();
  }

  setupBreadCrumbs() {
    if ((this.moduleLevel === apiUrl.COMPANY || this.moduleLevel === apiUrl.GROUP  || this.moduleLevel === apiUrl.CONTACT) && this._userService.userRole === UserTypes.SourcepassAdmin) {
      this._companiesService
        .getCompanyForBreadcrumb(this.companyId)
        .subscribe((response: any) => {
          this._breadcrumbsService.updateBreadCrumbsText('_companyName', response?.name || '');
          this._breadcrumbsService.updateBreadCrumbsText('_companyId', this.companyId);
          this._companiesService.changeCompanyName(response?.name || '');
        });
    }
    if (this.moduleLevel === apiUrl.GROUP) {
      this._groupsService
      .getGroupForBreadcrumb(this.groupId)
      .subscribe({
        next: (resp) => {
          this._breadcrumbsService.updateBreadCrumbsText('_groupName', resp.name);
        }
      })
    }
    if (this.moduleLevel === apiUrl.CONTACT) {
      this._accountService.getUserData(this.userId)
      .subscribe({
        next: (s: any) => {
          this.contactRoleId = s.roleId;
          this._breadcrumbsService.updateBreadCrumbsText('_contactId',btoa(this.userId.toString()));
          this._breadcrumbsService.updateBreadCrumbsText('_userName', (s.firstName ? s.firstName : '') + ' ' + (s.lastName !== null ? s.lastName : ''));
        }
      });
    }
  }

  private _getOverrideFlag(): void {
    this._groupService.getOverrideFlag(
      this.companyId,
      this.userId,
      this.groupId
    ).subscribe((res: any) => {
      if(res) {
        this.accessibleOption = res.isChanged;
      }
      this._cd.detectChanges();
    });
  }

  flattenModules(){
    this.flatModules = flattenTree(this.modules);
  }

  getRoles() {
    this.spinner.start();
    this._groupService.getRoleList().pipe(take(1), map((data) => {
      this.spinner.stop();
      return data;
    }),
      tap(data => {
        this.roleList = data;
        if (this.moduleLevel === apiUrl.GLOBAL) {
          this.selectRole(this.currentRole);
        }
        else {
          this.selectRole(0);
        }

        if(this.moduleLevel === apiUrl.COMPANY){
          this.roleList.splice(0,1);
        }
        this._cd.detectChanges();
      }),

    ).subscribe();
  }

  selectTab(event: any){
    this.selectedTab.setValue(event);
    this.permissions = [];
  }

  isSpAdminTabVisible(currentRole: any, contactRole: any) {
    return (currentRole === 1 && this.moduleLevel === apiUrl.GLOBAL) || (contactRole === 1 && this.moduleLevel === apiUrl.CONTACT) || ((this.moduleLevel === apiUrl.COMPANY || this.moduleLevel === apiUrl.GROUP) && this.companyId === 2 && this._userService.user?.roleId === 1);
  }
  isClientAdminTabVisible(currentRole: any, contactRole: any) {
    return (currentRole === 2 && this.moduleLevel === apiUrl.GLOBAL) || (contactRole === 2 && this.moduleLevel === apiUrl.CONTACT) || this.moduleLevel === apiUrl.COMPANY || this.moduleLevel === apiUrl.GROUP;
  }
  isUserTabVisible(currentRole: any, contactRole: any) {
    return (currentRole === 3 && this.moduleLevel === apiUrl.GLOBAL) || this.moduleLevel !== apiUrl.GLOBAL;
  }

  resetDefaults() {
    this.userModules = [];
    this.spAdminModules = [];
    this.clientAdminModules = [];
    this.selectedModule = null;
    this.selectedModuleId = 0;
    this.selectedMenuId = 0;
    this.selectedSubMenuId = 0;
    this.selectedThirdMenuId = 0;
    this.selectedFourthMenuId = 0;
  }

  selectRole(roleId: any) {
    this.resetDefaults();
    if(roleId === '') return;
    this.updatedMenuIds = {};
    this.updatedMenuActionIds = {};
    this.selectTab(0);
    this.currentRole = this.moduleLevel === apiUrl.GLOBAL ? roleId : this.currentRole;
    this.checkedModule = {
      roleId: this.currentRole,
      menuIds: {},
      menuActionIds: {}
    }
    this.spinner.start();
    this.setMenuPermissionAccess(roleId);
  }

  addActiveClass(e: MouseEvent) {
    this.removeActiveClass();
    this._renderer.addClass((e.target as HTMLElement).closest('.form-check.highlight-hover'), 'active-selected-item');
  }

  removeActiveClass() {
    const active = this._document.querySelectorAll('.active-selected-item');

    if (active.length > 0) {
      for (const el of active) {
        this._renderer.removeClass(el, 'active-selected-item');
      }
    }
  }

  setMenuPermissionAccess(roleId: number) {
    this.dataOptions.roleId = roleId;
    this._groupService.getMenuPermissionsList(roleId, this.companyId, this.groupId, this.userId)
      .pipe(
        take(1),
        tap((data) => {
          this.modules = data;
          this.userModules = this.modules.filter(menu => menu.isAdmin === false && menu.roleId === 3);
          this.spAdminModules = this.modules.filter(menu => menu.isAdmin === true && menu.roleId === 1);
          this.clientAdminModules = this.modules.filter(menu => menu.isAdmin === true && menu.roleId === 2);

          // flatten modules
          this.flattenModules()
          this._cd.detectChanges();
        }),
        switchMap((data) => this._groupService.getMenuPermissionsListAccess(roleId, this.companyId, this.groupId, this.userId)),
        take(1),
        tap(data => {
          // update checkedmodule
          this.checkedModule = {
            roleId: this.currentRole,
            menuIds: this.fillObject(data, this.menuIds),
            menuActionIds: this.fillObject(data, this.menuActionIds),
          }

          this.auditTrailRefresh();
          this._cd.detectChanges();
          setTimeout(() => {
            this.spinner.stop();
          }, 200);
        }
        ))
      .subscribe();
  }

  isNotSelectedAll(menu: any): boolean {
    let isCheck = true;

    if (!this.checkedModule[this.menuIds][menu.id]){
      isCheck = false;
      return isCheck;
    }
    if (isCheck && Array.isArray(menu.actions) && menu.actions.length > 0) {
      for (const action of menu.actions) {
        if (!this.checkedModule[this.menuActionIds][action.id]) isCheck = false;
        if (!isCheck) return isCheck;
      }
    }
    if (isCheck && Array.isArray(menu.subMenus) && menu.subMenus.length > 0) {
      for (const child of menu.subMenus) {
        isCheck = this.isNotSelectedAll(child);
        if (!isCheck) return isCheck;
      }
    }
    return isCheck;
  }

  checkIfSelectedAll(type: string) {
    let isChecked = true;
    if (type === "sp-admin"){
      if (Array.isArray(this.spAdminModules) && this.spAdminModules.length > 0) {
        for (const module of this.spAdminModules) {
          isChecked = this.isNotSelectedAll(module);
          if (!isChecked) break;
        }
      }
    } else if (type === "client-admin"){
      if (Array.isArray(this.clientAdminModules) && this.clientAdminModules.length > 0) {
        for (const module of this.clientAdminModules) {
          isChecked = this.isNotSelectedAll(module);
          if (!isChecked) break;
        }
      }
    }

    return isChecked;
  }
  fillObject(data: any, type: permissionProperties): Record<number, boolean> {
    const obj: { [key: string]: boolean } = {};
    data[type].map((key: any) => obj[key] = data[type].includes(key) ? true : false);
    return obj;
  }

  getTotalSubMenuCounts(menu: any){
    let menuCounts = 0;
    let menuCheckCounts = 0;
    let permissionCounts = 0;
    let permissionCheckCounts = 0;
    if (Array.isArray(menu.actions)){
      permissionCounts = menu.actions.length;
      menu.actions.forEach((a:any) => {
        if (this.checkedModule[this.menuActionIds][a.id]) permissionCheckCounts++;
      });
    }
    if (Array.isArray(menu.subMenus)){
      menuCounts = menu.subMenus.length;
      menu.subMenus.forEach((m:any) => {
        if (this.checkedModule[this.menuIds][m.id]) menuCheckCounts++;
        var counter = this.getTotalSubMenuCounts(m);
        menuCounts += counter.menuCounts;
        menuCheckCounts += counter.menuCheckCounts;
        permissionCounts += counter.permissionCounts;
        permissionCheckCounts += counter.permissionCheckCounts;
      });
    }
    return { menuCounts, menuCheckCounts, permissionCounts, permissionCheckCounts };
  }

  getNotIsViewCount(_permissions:any){
    var counter = 0;
    var checkNotIsViewCount = _permissions.filter((y:any) => !y.isView);
    checkNotIsViewCount.forEach((p:any) => {
      if (this.checkedModule[this.menuActionIds][p.id]) counter++;
    });
    return counter;
  }
  checkPermission(submenu: any): boolean {
    var totalSubMenuCounts = 0;
    var totalCheckedMenu = 0;
    var totalPermissionCounts = 0;
    var totalCheckedPermission = 0;
    if (Array.isArray(submenu.actions)){
      totalPermissionCounts = submenu.actions.length;
      submenu.actions.forEach((a:any) => {
        if (a.isView){
          if (!this.checkedModule[this.menuActionIds][a.id] && this.getNotIsViewCount(submenu.actions) > 0){
            this.checkedModule[this.menuActionIds][a.id] = true;
            this.updatedMenuActionIds[a.id] = true;
          }
        }
        if (this.checkedModule[this.menuActionIds][a.id]) totalCheckedPermission++;
      });
    }
    if (Array.isArray(submenu.subMenus)){
      totalSubMenuCounts = submenu.subMenus.length;
      submenu.subMenus.forEach((m:any) => {
        if (this.checkedModule[this.menuIds][m.id]) totalCheckedMenu++;
        var counter = this.getTotalSubMenuCounts(m);
        totalSubMenuCounts += counter.menuCounts;
        totalCheckedMenu += counter.menuCheckCounts;
        totalPermissionCounts += counter.permissionCounts;
        totalCheckedPermission += counter.permissionCheckCounts;
      });
    }
    if((totalCheckedMenu + totalCheckedPermission) === 0 && (totalSubMenuCounts + totalPermissionCounts) > 0 && this.checkedModule[this.menuIds][submenu.id]){
      if (submenu.id == 72){ // this is for Dashboard only
        if (Array.isArray(submenu.actions) && this.checkedModule[this.menuIds][submenu.id] == true){
          submenu.actions.forEach((a:any) => {
            if (a.id == 282 && a.isAllowed == false){ // only for View Dashboard permission
              this.checkedModule[this.menuActionIds][a.id] = true;
              this.updatedMenuActionIds[a.id] = true;
            }
          })
        }
      }
      else{
        this.checkedModule[this.menuIds][submenu.id] = false;
        this.updatedMenuIds[submenu.id] = false;
        if (Array.isArray(submenu.actions)){
          submenu.actions.forEach((a:any) => {
            if (this.checkedModule[this.menuActionIds][a.id] !== false){
              this.checkedModule[this.menuActionIds][a.id] = false;
              this.updatedMenuActionIds[a.id] = false;
            }
          })
        }
      }
    }
    return (totalCheckedMenu + totalCheckedPermission) < (totalSubMenuCounts + totalPermissionCounts);
  }

  changePermission(subMenu: any){
    this.selectedModuleId = subMenu.id;
    this.selectedModule = subMenu;
    this.permissions = subMenu.actions;
  }

  toggleMenu(menu: any, subMenu?: any, thirdMenu?: any, fourthMenu?: any) {
    if (menu && !subMenu && !thirdMenu && !fourthMenu){
      this.selectedMenuId = this.selectedMenuId === menu.id ? 0 : menu.id;
      this.selectedSubMenuId = 0;
      this.selectedThirdMenuId = 0;
      this.selectedFourthMenuId = 0;
      this.changePermission(menu);
    }
    else if (menu && subMenu && !thirdMenu && !fourthMenu) {
      this.selectedMenuId = this.selectedMenuId === menu.id ? menu.id : 0;
      this.selectedSubMenuId = this.selectedSubMenuId === subMenu.id ? 0 : subMenu.id;
      this.selectedThirdMenuId = 0;
      this.selectedFourthMenuId = 0;
      this.changePermission(subMenu);
    }
    else if (menu && subMenu && thirdMenu && !fourthMenu) {
      this.selectedMenuId = this.selectedMenuId === menu.id ? menu.id : 0;
      this.selectedSubMenuId = this.selectedSubMenuId === subMenu.id ? subMenu.id : 0;
      this.selectedThirdMenuId = this.selectedThirdMenuId === thirdMenu.id ? 0 : thirdMenu.id;
      this.selectedFourthMenuId = 0;
      this.changePermission(thirdMenu);
    }
    else if (menu && subMenu && thirdMenu && fourthMenu) {
      this.selectedMenuId = this.selectedMenuId === menu.id ? menu.id : 0;
      this.selectedSubMenuId = this.selectedSubMenuId === subMenu.id ? subMenu.id : 0;
      this.selectedThirdMenuId = this.selectedThirdMenuId === thirdMenu.id ? thirdMenu.id : 0;
      this.selectedFourthMenuId = this.selectedFourthMenuId === fourthMenu.id ? 0 : fourthMenu.id;
      this.changePermission(fourthMenu);
    }
  }

  isPermissionDisabled(permission: any):boolean {
    let isDisabled = false;
    if (permission.isView === true && permission.id != 282){
      isDisabled = this.getNotIsViewCount(this.permissions) > 0;
    }
    else if (permission.isView == true && permission.id == 282) { // only for View Dashboard - Disabled Always
      isDisabled = true;
    }
    else {
      var isViewPermission = this.permissions.filter((y:any) => y.isView);
      isDisabled = (isViewPermission.length > 0 && this.checkedModule[this.menuActionIds][isViewPermission[0].id] === false);
    }
    return isDisabled;
  }

  updateSubMenus(id: number, isAllowed: boolean){
    this.flatModules.forEach(menu=>{
      if (menu.id === id) this.updatePermissions(menu, isAllowed);
      if (menu.id === id && this.checkedModule[this.menuIds][menu.id] !== isAllowed){
        this.updatedMenuIds[menu.id] = isAllowed;
        this.checkedModule[this.menuIds][menu.id] = isAllowed;
      }
      if ((menu.subMenus && menu.subMenus.length > 0 && menu.id === id)){
        menu.subMenus.forEach((subMenu: any)=>{
          this.updateSubMenus(subMenu.id, isAllowed);
        })
      }
    })
  }

  updatePermissions(subModule: any, isAllowed: boolean){
    subModule.actions.forEach((permission: any)=>{
      if (this.checkedModule[this.menuActionIds][permission.id] !== isAllowed) {
        this.checkedModule[this.menuActionIds][permission.id] = isAllowed;
        this.updatedMenuActionIds[permission.id] = isAllowed;
      }
    })
  }
  selectAllModule(event: any, type: any) {
    if (type === 'sp-admin') {
      if (Array.isArray(this.spAdminModules)){
        if (event.target.checked === true) {
          if (Array.isArray(this.spAdminModules)){
          this.spAdminModules.forEach(module => {
            this.checkedModule[this.menuIds][module.id] = true;
            this.setModule(this.menuIds, module);
          })
          }
        } else {
          this.spAdminModules.forEach(module => {
            this.checkedModule[this.menuIds][module.id] = false;
            this.setModule(this.menuIds, module);
          })
        }
      }
    } else if (type === 'client-admin') {
      if (Array.isArray(this.clientAdminModules)){
        if (event.target.checked === true) {
          this.clientAdminModules.forEach(module => {
            this.checkedModule[this.menuIds][module.id] = true;
            this.setModule(this.menuIds, module);
          })
        } else {
          this.clientAdminModules.forEach(module => {
            this.checkedModule[this.menuIds][module.id] = false;
            this.setModule(this.menuIds, module);
          })
        }
      }
    }
  }
  setModule(type: permissionProperties,module: any, submenu?: any, thirdmenu?: any, fourthMenu?: any, fifthMenu?: any){
    if (module && !submenu && !thirdmenu && !fourthMenu && !fifthMenu) {
      let isAllowed = this.checkedModule[type][module.id];
      this.updatedMenuIds[module.id] = isAllowed;
      this.updateSubMenus(module.id, isAllowed)
      this.updatePermissions(module, isAllowed)
    }
    else if (module && submenu && !thirdmenu && !fourthMenu && !fifthMenu) {
      let isAllowed = this.checkedModule[type][submenu.id];
      this.updatedMenuIds[submenu.id] = isAllowed;
      this.checkedModule[type][submenu.id] = isAllowed;
      if (submenu.subMenus && submenu.subMenus.length > 0) {
        this.updateSubMenus(submenu.id, isAllowed)
      }
      else{
        this.updatePermissions(submenu, isAllowed)
      }
      if (isAllowed && this.checkedModule[this.menuIds][module.id] !== true){
        this.checkedModule[this.menuIds][module.id] = isAllowed;
        this.updatedMenuIds[module.id] = isAllowed;
      }
    }
    else if (module && submenu && thirdmenu && !fourthMenu && !fifthMenu) {
      let isAllowed = this.checkedModule[type][thirdmenu.id];
      this.updatedMenuIds[thirdmenu.id] = isAllowed;
      this.checkedModule[type][thirdmenu.id] = isAllowed;
      if (thirdmenu.subMenus && thirdmenu.subMenus.length > 0) {
        this.updateSubMenus(thirdmenu.id, isAllowed)
      }
      else{
        this.updatePermissions(thirdmenu, isAllowed)
      }
      if (isAllowed && this.checkedModule[this.menuIds][submenu.id] !== true){
        this.checkedModule[this.menuIds][submenu.id] = isAllowed;
        this.updatedMenuIds[submenu.id] = isAllowed;
      }
      if (isAllowed && this.checkedModule[this.menuIds][module.id] !== true){
        this.checkedModule[this.menuIds][module.id] = isAllowed;
        this.updatedMenuIds[module.id] = isAllowed;
      }
    }
    else if (module && submenu && thirdmenu && fourthMenu && !fifthMenu){
      let isAllowed = this.checkedModule[type][fourthMenu.id];
      this.updatedMenuIds[fourthMenu.id] = isAllowed;
      this.checkedModule[type][fourthMenu.id] = isAllowed;
      if (fourthMenu.subMenus && fourthMenu.subMenus.length > 0) {
        this.updateSubMenus(fourthMenu.id, isAllowed)
      }
      else{
        this.updatePermissions(fourthMenu, isAllowed)
      }
      if (isAllowed && this.checkedModule[this.menuIds][thirdmenu.id] !== true){
        this.checkedModule[this.menuIds][thirdmenu.id] = isAllowed;
        this.updatedMenuIds[thirdmenu.id] = isAllowed;
      }
      if (isAllowed && this.checkedModule[this.menuIds][submenu.id] !== true){
        this.checkedModule[this.menuIds][submenu.id] = isAllowed;
        this.updatedMenuIds[submenu.id] = isAllowed;
      }
      if (isAllowed && this.checkedModule[this.menuIds][module.id] !== true){
        this.checkedModule[this.menuIds][module.id] = isAllowed;
        this.updatedMenuIds[module.id] = isAllowed;
      }
    }
    else if (module && submenu && thirdmenu && fourthMenu && fifthMenu){
      let isAllowed = this.checkedModule[type][fifthMenu.id];
      this.updatedMenuIds[fifthMenu.id] = isAllowed;
      this.checkedModule[type][fifthMenu.id] = isAllowed;
      this.updatePermissions(fifthMenu, isAllowed)
      if (isAllowed && this.checkedModule[this.menuIds][fourthMenu.id] !== true){
        this.checkedModule[this.menuIds][fourthMenu.id] = isAllowed;
        this.updatedMenuIds[fourthMenu.id] = isAllowed;
      }
      if (isAllowed && this.checkedModule[this.menuIds][thirdmenu.id] !== true){
        this.checkedModule[this.menuIds][thirdmenu.id] = isAllowed;
        this.updatedMenuIds[thirdmenu.id] = isAllowed;
      }
      if (isAllowed && this.checkedModule[this.menuIds][submenu.id] !== true){
        this.checkedModule[this.menuIds][submenu.id] = isAllowed;
        this.updatedMenuIds[submenu.id] = isAllowed;
      }
      if (isAllowed && this.checkedModule[this.menuIds][module.id] !== true){
        this.checkedModule[this.menuIds][module.id] = isAllowed;
        this.updatedMenuIds[module.id] = isAllowed;
      }
    }

  }

  setPermission(permission: any){
    permission.isAllowed = !permission.isAllowed;
    let isAllowed = this.checkedModule[this.menuActionIds][permission.id];

    this.updatedMenuActionIds[permission.id] = isAllowed;
    if(isAllowed){
      if (this.checkedModule[this.menuIds][this.selectedMenuId] !== isAllowed && this.selectedMenuId > 0) {
        this.checkedModule[this.menuIds][this.selectedMenuId] = isAllowed;
        this.updatedMenuIds[this.selectedMenuId] = isAllowed;
      }
      if (this.checkedModule[this.menuIds][this.selectedSubMenuId] !== isAllowed && this.selectedSubMenuId > 0) {
        this.checkedModule[this.menuIds][this.selectedSubMenuId] = isAllowed;
        this.updatedMenuIds[this.selectedSubMenuId] = isAllowed;
      }
      if (this.checkedModule[this.menuIds][this.selectedThirdMenuId] !== isAllowed && this.selectedThirdMenuId > 0) {
        this.checkedModule[this.menuIds][this.selectedThirdMenuId] = isAllowed;
        this.updatedMenuIds[this.selectedThirdMenuId] = isAllowed;
      }
      if (this.checkedModule[this.menuIds][this.selectedFourthMenuId] !== isAllowed && this.selectedFourthMenuId > 0) {
        this.checkedModule[this.menuIds][this.selectedFourthMenuId] = isAllowed;
        this.updatedMenuIds[this.selectedFourthMenuId] = isAllowed;
      }
      if (this.checkedModule[this.menuIds][this.selectedModule.id] !== isAllowed && this.selectedModule.id > 0) {
        this.checkedModule[this.menuIds][this.selectedModule.id] = isAllowed;
        this.updatedMenuIds[this.selectedModule.id] = isAllowed;
      }
    }
  }

  updateMenuAndActionIds(){
    this.menuAndActionPermissionParam = {
      roleId: this.moduleLevel == apiUrl.GLOBAL ? this.currentRole : 0,
      companyId: this.companyId,
      groupId: this.groupId,
      userId: this.userId,
      menuValues: Object.entries(this.updatedMenuIds).map(([m, a]) => ({ menuId: Number(m), isAllowed: a })),
      menuActionValues: Object.entries(this.updatedMenuActionIds).map(([m, a]) => ({ menuActionId: Number(m), isAllowed: a }))
    };
    this.spinner.start();
    this._groupService.setMenuAndActionPermission(this.menuAndActionPermissionParam).subscribe({
      next: () => {
        this.resetUpdates();
      },
      error: (error) => {
        this._notifier.notify(NotificationMessages.error(error), {
          duration: 5,
          panelClass: 'error',
        });
      },
      complete: () => {
        this.spinner.stop();
        this.auditTrailRefresh();
      },
    });
  }

  resetUpdates(){
    this.updatedMenuIds = {};
    this.updatedMenuActionIds = {};
    this.setMenuPermissionAccess(this.currentRole);
  }

  isSaveButtonEnable(){
    let menuIdCount = Object.entries(this.updatedMenuIds).map(([m, a]) => ({ menuId: Number(m), isAllowed: a })).length;
    let menuActionIdCount = Object.entries(this.updatedMenuActionIds).map(([m, a]) => ({ menuActionId: Number(m), isAllowed: a })).length;
    return menuIdCount > 0 || menuActionIdCount > 0;
  }

  fillArray(type: permissionProperties){
    let temp = []
    for(let key in this.checkedModule[type]){
      if(this.checkedModule[type][key]=== true){
        temp.push(key)
      }
    }
    return temp;
  }

  onOverrideFlag(): void {
    this.spinner.start();
    const isChanged = this.accessibleOption;
    this.menuOverrideParam = {
      companyId: this.companyId,
      groupId: this.groupId,
      userId: this.userId,
      isChanged: isChanged
    };
    this._groupService.setOverrideFlag(this.menuOverrideParam).subscribe({
      error: (error) => {
        this.accessibleOption = !this.accessibleOption;
        this._notifier.notify(NotificationMessages.error(error), {
          duration: 5,
          panelClass: 'error',
        });
      },
      complete: () => {

        this.spinner.stop();
        if (this.moduleLevel === apiUrl.GLOBAL) {
          this.selectRole(this.currentRole);
        }
        else {
          this.selectRole(0);
        }
        this.auditTrailRefresh();
        this._cd.detectChanges();
      },
    });
  }

  auditTrailRefresh(){
    if(this.auditTrail)
      this.auditTrail.refresh();
  }
}

function flattenTree(tree: any[]): any[] {
  const flattened: any[] = [];
  function flatten(node: any) {
    flattened.push(node);
    if (node.subMenus) {
      for (const child of node.subMenus) {
        flatten(child);
      }
    }
  }
  for (const node of tree) {
    flatten(node);
  }
  return flattened;
}
