<a
	class="btn-2-nav sidebar-btn"
	(click)="clearGlobalSearch()"
	routerLinkActive="active-2"
	[routerLink]="[sideBarData.url]"
	#navLink
	[activityTracker]="sideBarData.id"
>
	<quest-icon [name]="sideBarData.icon"></quest-icon>
	<div>{{ sideBarData.name }}</div>
</a>
