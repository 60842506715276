import { Injectable } from '@angular/core';
import {
	CompanyReportUsage,
	DailyReportUsageFormat,
	MonthlyReportUsageFormat,
	UserDetails,
	UsersActivity,
	LoginReportSummary,
	LoginReportDetails,
	DAUTypeDropdown,
	iListPage,
} from './portal-usage.interface';
import { Observable, delay, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PortalUsageTesterService {
	isTest = false;

	constructor() {}

	getMonthlyUsage(id: number): Observable<MonthlyReportUsageFormat> {
		return of({
			portalUsage: [
				{
					year: 2020,
					monthName: 'January',
					uniqueUsers: 20,
					usersActivity: 200,
					newUsers: 0,
					fromDate: '2023-01-01',
					toDate: '2023-01-31',
				},
				{
					year: 2020,
					monthName: 'February',
					uniqueUsers: 30,
					usersActivity: 250,
					newUsers: 0,
					fromDate: '2023-02-01',
					toDate: '2023-02-28',
				},
				{
					year: 2020,
					monthName: 'March',
					uniqueUsers: 15,
					usersActivity: 80,
					newUsers: 0,
					fromDate: '2023-03-01',
					toDate: '2023-03-31',
				},
			],
			currentMonthActivity: 20000,
			currentMonthUsers: 840,
			pastWeekActivity: 2000,
			pastWeekUsers: 319,
			todayActivity: 300,
			todayUsers: 140,
			todayNewUsers: 0,
			pastWeekNewUsers: 0,
			currentMonthNewUsers: 0,
		}).pipe(delay(2000));
	}

	getCompanyUsage(id: number): Observable<iListPage> {
		return of({
			currentPage: 1,
			pageSize: 10,
			totalCount: 10,
			data: [
				{
					companyId: 2,
					companyName: 'Company 2',
					uniqueUsers: 200,
					usersActivity: 3000,
					fromDate: '2023-01-01',
					toDate: '2023-01-31',
				},
				{
					companyId: 3,
					companyName: 'Company 3',
					uniqueUsers: 250,
					usersActivity: 5000,
					fromDate: '2023-02-01',
					toDate: '2023-02-28',
				},
				{
					companyId: 4,
					companyName: 'Company 4',
					uniqueUsers: 150,
					usersActivity: 3000,
					fromDate: '2023-03-01',
					toDate: '2023-03-31',
				},
			],
		});
	}

	getCompanyUsageTop5(): Observable<iListPage> {
		return of({
			currentPage: 1,
			pageSize: 10,
			totalCount: 10,
			data: [
				{
					companyId: 2,
					companyName: 'Company 2',
					uniqueUsers: 200,
					usersActivity: 3000,
					fromDate: '2023-01-01',
					toDate: '2023-01-31',
				},
				{
					companyId: 3,
					companyName: 'Company 3',
					uniqueUsers: 250,
					usersActivity: 5000,
					fromDate: '2023-02-01',
					toDate: '2023-02-28',
				},
				{
					companyId: 4,
					companyName: 'Company 4',
					uniqueUsers: 150,
					usersActivity: 3000,
					fromDate: '2023-03-01',
					toDate: '2023-03-31',
				},
			],
		});
	}

	getCompanyUsageLeast5(): Observable<iListPage> {
		return of({
			currentPage: 1,
			pageSize: 10,
			totalCount: 10,
			data: [
				{
					companyId: 2,
					companyName: 'Company 2',
					uniqueUsers: 200,
					usersActivity: 3000,
					fromDate: '2023-01-01',
					toDate: '2023-01-31',
				},
				{
					companyId: 3,
					companyName: 'Company 3',
					uniqueUsers: 250,
					usersActivity: 5000,
					fromDate: '2023-02-01',
					toDate: '2023-02-28',
				},
				{
					companyId: 4,
					companyName: 'Company 4',
					uniqueUsers: 150,
					usersActivity: 3000,
					fromDate: '2023-03-01',
					toDate: '2023-03-31',
				},
			],
		});
	}

	getUsersUsage(): Observable<iListPage> {
		return of({
			currentPage: 1,
			pageSize: 10,
			totalCount: 10,
			data: [
				{
					userId: 1,
					name: 'User 1',
					userActivity: 1000,
					fromDate: '2023-01-01',
					toDate: '2023-01-31',
				},
				{
					userId: 2,
					name: 'User 2',
					userActivity: 2000,
					fromDate: '2023-02-01',
					toDate: '2023-02-28',
				},
				{
					userId: 3,
					name: 'User 3',
					userActivity: 3000,
					fromDate: '2023-03-01',
					toDate: '2023-03-31',
				},
			],
		});
	}

	getUsersActivityGraph(): Observable<iListPage> {
		return of({
			currentPage: 1,
			pageSize: 10,
			totalCount: 10,
			data: [
				{
					userId: 1,
					name: 'User 1',
					userActivity: 1000,
					fromDate: '2023-01-01',
					toDate: '2023-01-31',
				},
				{
					userId: 2,
					name: 'User 2',
					userActivity: 2000,
					fromDate: '2023-02-01',
					toDate: '2023-02-28',
				},
				{
					userId: 3,
					name: 'User 3',
					userActivity: 3000,
					fromDate: '2023-03-01',
					toDate: '2023-03-31',
				},
			],
		});
	}

	getUserDetails(userId: number): Observable<UserDetails> {
		return of({
			id: 3,
			firstName: 'Linda',
			lastName: 'Brotherton',
			companyId: 2,
			companyName: 'Sourcepass Inc.',
		});
	}

	getUserActivityHistory(): Observable<iListPage> {
		return of({
			currentPage: 1,
			pageSize: 10,
			totalCount: 10,
			data: [
				{
					userId: 1,
					menuName: 'Menu 1',
					clicks: 100,
				},
				{
					userId: 1,
					menuName: 'Menu 2',
					clicks: 200,
				},
				{
					userId: 1,
					menuName: 'Menu 3',
					clicks: 300,
				},
			],
		});
	}

	getDailyUsage(): Observable<DailyReportUsageFormat> {
		return of({
			dailyUsage: [
				{
					companyId: 2,
					companyName: 'Company 2',
					uniqueUsers: 200,
					usersActivity: 3000,
					fromDate: '2023-01-01',
					toDate: '2023-01-31',
					newUsers: 0,
				},
				{
					companyId: 3,
					companyName: 'Company 3',
					uniqueUsers: 250,
					usersActivity: 5000,
					fromDate: '2023-02-01',
					toDate: '2023-02-28',
					newUsers: 0,
				},
				{
					companyId: 4,
					companyName: 'Company 4',
					uniqueUsers: 150,
					usersActivity: 3000,
					fromDate: '2023-03-01',
					toDate: '2023-03-31',
					newUsers: 0,
				},
			],
			todayActivity: 300,
			todayUsers: 140,
		});
	}

	getUserLoginReportSummary(): Observable<iListPage> {
		return of({
			data: [
				{
					id: 1,
					date: new Date().toUTCString(),
					companyName: 'Dummy company',
					userName: 'Dummy Tester',
					description: 'Description',
					details: 'Details',
					count: 10,
				},
			],
			currentPage: 1,
			pageSize: 10,
			totalCount: 1,
		}).pipe(delay(1000));
	}

	getUserLoginReportDetail(): Observable<iListPage> {
		return of({
			data: [
				{
					id: 1,
					date: new Date().toUTCString(),
					companyName: 'Dummy company',
					userName: 'Dummy Tester',
					description: 'Description',
					details: 'Details',
				},
			],
			currentPage: 1,
			pageSize: 10,
			totalCount: 1,
		}).pipe(delay(1000));
	}

	getDAUTypeDropdown(): Observable<DAUTypeDropdown[]> {
		return of([
			{ id: 1, description: 'Type 1' },
			{ id: 2, description: 'Type 2' },
		]);
	}
}
