<div class="card-2 q-mb-24">
	<!-- CARD HEADER-->

	<div class="card-header-2">
		<h3 class="q-mr-auto">Knowledge Base</h3>

		<search-input
			[formControl]="search"
			size="small"
			class="font-btn"
			[placeholder]="searchPlaceHolder"
			#searchTextbox
		></search-input>

		<app-tertiary-button [isFilterToggle]="true"></app-tertiary-button>

		<ng-container
			*ngIf="
				isManageEnabled &&
				((dataOptions && dataOptions.levelId) === 1 || isCustom)
			"
		>
			<a
				href="javascript:void(0)"
				(click)="setIsCustom()"
				class="btn-2 btn-2-primary imp-disabled-op"
				[routerLink]="['./add']"
			>
				Create
			</a>
		</ng-container>

		<div *ngIf="!isMainPage" class="actions-wrapper q-jc-start q-pt-16 q-w-100">
			<app-tertiary-button
				[isTab]="true"
				[btnText]="'Global'"
				[isActiveTabOrButton]="kbService.tab == 0"
				(click)="kbService.tab = 0"
				[iconName]="'globe-04'"
			></app-tertiary-button>

			<app-tertiary-button
				[isTab]="true"
				[btnText]="'Company'"
				[isActiveTabOrButton]="kbService.tab == 1"
				(click)="kbService.tab = 1"
				[iconName]="'building-06'"
			></app-tertiary-button>
		</div>
	</div>

	<div id="demo" class="collapse">
		<div class="table-filter-2">
			<div class="filter-item">
				<label>Due Date Start</label>
				<date-input
					[formControl]="queryStartDate"
					max="{{ queryEndDate.value | date : 'yyyy-MM-dd' }}"
					inputClass="form-control"
				></date-input>
			</div>

			<div class="filter-item">
				<label>Due Date End</label>
				<date-input
					[formControl]="queryEndDate"
					min="{{ queryStartDate.value | date : 'yyyy-MM-dd' }}"
					inputClass="form-control"
				></date-input>
			</div>

			<div class="filter-item">
				<label for="" class="form-label">Category</label>
				<multi-select-input-2
					[options]="dropdownOption"
					[optionKeys]="{ value: 'id', label: 'name' }"
					[autoClosePerSelect]="true"
					[(ngModel)]="queryCategory"
					(itemToggle)="categoryChange()"
					[allowSelectAll]="false"
					placeholder="- Select Category -"
					[resetVal]="resetVal"
					[hideIcon]="true"
				>
				</multi-select-input-2>
			</div>

			<div class="filter-item filter-actions">
				<app-clear-filter></app-clear-filter>
			</div>
		</div>
	</div>

	<div
		class="imp-parent"
		*ngIf="dataOptions && dataOptions.hasOverrideFlag && !isCustom"
	>
		<p class="bold">{{ dataOptions.overrideSettings.message }}</p>
		<switch-input
			class="imp-disabled-op"
			[(ngModel)]="visibilityOption"
			(ngModelChange)="updateOverrideFlag()"
			[disabled]="!isAccessEnabled"
		>
			{{ dataOptions.overrideSettings.checkbox }}
		</switch-input>
	</div>

	<app-new-table-shared
		loadingText="Fetching Topics..."
		[searchFilters]="searchFilters"
		[pageOptions]="kbOptions"
		[onClick]="updateAccessVisibility.bind(this)"
		[deleteClick]="onDelete.bind(this)"
	></app-new-table-shared>
</div>

<app-audit-trail
	*ngIf="isViewEnabled"
	[url]="historyUrl.KNOWLEDGE_BASE"
	history_category="permission"
	[data_options]="dataOptions"
	[isCustom]="isCustom"
></app-audit-trail>
