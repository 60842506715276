import {
	OnInit,
	AfterViewInit,
	OnDestroy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
	tap,
	debounceTime,
	map,
	Observable,
	startWith,
	BehaviorSubject,
	Subscription,
	distinctUntilChanged,
	fromEvent,
} from 'rxjs';

@Component({
	selector: 'multi-select-input',
	templateUrl: './multi-select-input.component.html',
	styleUrls: ['./multi-select-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MultiSelectInputComponent,
			multi: true,
		},
	],
})
export class MultiSelectInputComponent
	implements OnChanges, OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
	@ViewChild(MatAutocompleteTrigger)
	private _autocomplete: MatAutocompleteTrigger;
	@ViewChild('scroll') private _scroll: ElementRef;
	@ViewChild('multiSelect') private _multiSelect: ElementRef;
	@ViewChild('multiSelectOptions') private _multiSelectOptions: ElementRef;
	@Input() customClass: string = '';
	@Input() customClassSelect: string = '';
	@Input() options: any[];
	@Input() optionKeys: { value: string; label: string };
	@Input() placeholder = '';
	@Input() allowSelectAll = true;
	@Input() autoClosePerSelect = false;
	@Input() disabled = false;
	@Input() disabledSearch = false;
	@Input() maxSelectionCount?: number;
	@Input() selectedOptons: number[] = [];
	@Input() resetVal = false;
	@Input() isShowSearchIcon: boolean = false;
	@Output() itemToggle = new EventEmitter<object>(); // Use this when allowSelectAll = false
	@Output() queryChanges = new EventEmitter<string>();
	@Output() onChange: EventEmitter<{ id: number; name: string }[]> =
		new EventEmitter<{ id: number; name: string }[]>(); // Use this when allowSelectAll = true

	searchControl = new FormControl();
	query: string;
	items: MultiSelectItem[] = [];
	filteredItems: Observable<MultiSelectItem[]>;
	filtered: MultiSelectItem[] = [];
	selectedItems: MultiSelectItem[] = [];
	isAllSelected = false;
	selectedPanelOpen = false;
	value: any[] | null;

	optionWidth = 0;

	private _isFirstLoad = true;
	private _onChange: Function;
	private _onTouched: Function;
	private _subscription = new Subscription();
	private _allowSelect$ = new BehaviorSubject<boolean>(false);

	constructor(private _cd: ChangeDetectorRef) {
		this.filteredItems = this.searchControl.valueChanges.pipe(
			startWith<string>(''),
			debounceTime(500),
			map((value) => (typeof value === 'string' ? value : '')),
			map((filter) => {
				if ((filter && filter != this.query) || this.query === undefined) {
					this.query = filter;
					this.queryChanges.emit(filter);
				}
				return this.filter(filter);
			})
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['options'] && changes['options'].currentValue != null) {
			this.items = this.options.map((o) => {
				if (this.optionKeys)
					return new MultiSelectItem(
						o[this.optionKeys.value],
						o[this.optionKeys.label] ?? ''
					);
				else if (typeof o === 'string' || typeof o === 'number' || !o)
					return new MultiSelectItem(o, o?.toString() ?? '');
				else
					return new MultiSelectItem(
						o.value ?? o,
						o.display ?? '',
						o?.selected ?? false
					);
			});
			this.searchControl.setValue(this.searchControl.value ?? '');

			if (this.value) this.writeValue(this.value);
		}

		if (changes['disabledSearch']) {
			if (this.disabledSearch) this.searchControl.disable();
			else this.searchControl.enable();
		}

		if (this.resetVal) {
			this.filtered.forEach((i) => {
				i.selected = false;
				const x = this.selectedItems.findIndex((i) => i.value === i.value);
				this.selectedItems.splice(x, 1);
			});
			this.isAllSelected = false;
		}
	}

	ngOnInit() {
		this._allowSelect$.next(this.allowSelectAll);
	}

	ngAfterViewInit() {
		this._subscription.add(
			fromEvent(window, 'resize')
				.pipe(
					tap(() => {
						this.closePanel();
					}),
					debounceTime(100),
					distinctUntilChanged(),
					tap(() => {
						this.optionWidth = (
							this._multiSelect.nativeElement as HTMLElement
						).offsetWidth;
						this._cd.detectChanges();
					})
				)
				.subscribe()
		);

		this._subscription.add(
			fromEvent(this._multiSelect.nativeElement, 'click')
				.pipe(
					tap(() => {
						this.optionWidth = (
							this._multiSelect.nativeElement as HTMLElement
						).offsetWidth;
						this._cd.detectChanges();
					})
				)
				.subscribe()
		);
	}

	ngAfterViewChecked() {
		const el = this._multiSelect.nativeElement as HTMLElement;

		if (el.offsetWidth !== 0 && this._isFirstLoad) {
			this._isFirstLoad = false;
			setTimeout(() => {
				this.optionWidth = el.offsetWidth;
				this._cd.detectChanges();
			}, 500);
		}
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	filter = (filter: string): Array<MultiSelectItem> => {
		const filterValue = filter.toLowerCase();
		this.filtered = this.items.filter((option) =>
			option.display.toString().toLowerCase().includes(filterValue)
		);
		//.slice(0, 500); //Limit displayed options to max 500 to remove lagging
		this.isAllSelected =
			this.filtered.findIndex((i) => i.selected != true) === -1 &&
			this.filtered.length > 0;

		this.allowSelectAll = this._allowSelect$.getValue();
		if (this.allowSelectAll && this.filtered.length === 0) {
			this.allowSelectAll = false;
		}

		return this.filtered;
	};

	optionClicked = (
		event: Event,
		item: MultiSelectItem,
		isChip?: boolean
	): void => {
		event.stopPropagation();
		if (!isChip) {
			this.toggleSelection(item);
			if (this.autoClosePerSelect) {
				this._reSetMenuPosition();
			}
		}
		if (isChip) {
			this.openPanel();
		}
		if (this.autoClosePerSelect && this.selectedPanelOpen)
			this.closeSelectedPanel();
		this.searchControl.setValue('');
	};

	setValues() {
		const keys = this.selectedItems.map((selectedItem) => {
			return { id: selectedItem.value, name: selectedItem.display };
		});
		this.onChange.emit(keys);
		this.autoAdjutOverlayWidth();
	}

	/* This function will automatically adjust the width of the overlay pane based on the size of the input form dropdown. */ 
	autoAdjutOverlayWidth() {
		setTimeout(() => {
			if (this._multiSelect.nativeElement) {
				const autocompleteId = this._multiSelect.nativeElement.querySelector('input').getAttribute('aria-owns');
				const overlayPane = document.querySelector(`.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane:has(#${autocompleteId})`) as HTMLElement;
		
				if (overlayPane) {
					overlayPane.style.width = `${this._multiSelect.nativeElement.clientWidth}px`;
					this._cd.detectChanges();
				}
			}
		}, 0);
	}

	getVirtualScrollHeight(el: HTMLElement): string {
		const height = el.querySelector(
			'.cdk-virtual-scroll-content-wrapper'
		)?.clientHeight;
		return `${height ? height + 2 : 0}px`;
	}

	toggleSelection(item: MultiSelectItem) {
		item.selected = !item.selected;
		if (item.selected === true) {
			if (
				this.maxSelectionCount &&
				this.selectedItems.length >= this.maxSelectionCount
			) {
				item.selected = !item.selected;
				return;
			}
			this.selectedItems.push(item);
			this._reSetMenuPosition();
		} else {
			const i = this.selectedItems.findIndex((i) => i.value === item.value);
			this.selectedItems.splice(i, 1);
			if (this.selectedItems.length == 0) this.selectedPanelOpen = false;
			this.items.filter((i: MultiSelectItem) => {
				if (i.value === item.value) {
					item.selected = false;
				}
			});
			this._reSetMenuPosition();
		}

		if (this.filtered.findIndex((i) => i.selected == false) === -1)
			this.isAllSelected = true;
		else this.isAllSelected = false;

		this.value = this.selectedItems.map((i) => i.value);
		if (this._onChange) this._onChange(this.value);
		if (this.autoClosePerSelect && this.searchControl.value)
			this.searchControl.setValue('');
		this._emitEvent(item);
	}

	selectAll(event?: Event) {
		event?.stopPropagation();

		if (this.searchControl.value == '') this.filtered = this.items;

		if (this.isAllSelected) {
			this.filtered.forEach((i) => {
				i.selected = false;
			});
			this.selectedItems = this.selectedItems.filter(s => s.selected);
			this.isAllSelected = false;
		} else {
			if (!this.filtered.length) this.filtered = this.items;

			this.filtered.forEach((i) => (i.selected = true));
			this.selectedItems = [
				...new Set([...this.selectedItems, ...this.filtered]),
			];
			this.isAllSelected = true;

			if (this.autoClosePerSelect) this.closePanel();
		}
		this.value = this.selectedItems.map((i) => i.value);

		if (this._onChange) this._onChange(this.value);

		this.setValues();
		this._reSetMenuPosition();
		this._cd.detectChanges();
	}

	closeSelectedPanel() {
		this.selectedPanelOpen = false;
	}

	openSelectedPanel() {
		this.selectedPanelOpen = true;
	}

	openPanel() {
		this._autocomplete.openPanel();
	}

	closePanel() {
		this._autocomplete.closePanel();
		this.closeSelectedPanel();
	}

	onTouched() {
		setTimeout(() => {
			if (this._onTouched) this._onTouched();
		});
	}

	writeValue(values: any[] | null) {
		this.value = values;
		this.selectedItems.forEach((item) => (item.selected = false));
		this.selectedItems = [];
		values?.forEach((value) => {
			const v = this.items.find((o) => o.value == value);
			if (v) {
				(v.selected = true), this.selectedItems.push(v);
			}
		});
	}

	registerOnChange(fn: Function) {
		this._onChange = fn;
	}

	registerOnTouched(fn: Function) {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	private _emitEvent(item: MultiSelectItem) {
		if (item.selected) {
			this.itemToggle.emit({
				event: 'select',
				item,
			});
		} else {
			this.itemToggle.emit({
				event: 'deselect',
				item,
			});
		}
		this.setValues();
	}

	private _reSetMenuPosition() {
		setTimeout(() => {
			this._autocomplete.updatePosition();
		}, 50);
	}
}

export class MultiSelectItem {
	constructor(
		public value: any,
		public display: string,
		public selected?: boolean
	) {
		if (selected === undefined) this.selected = false;
	}
}
