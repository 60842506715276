import { AppStateInterface } from '@app/core/store/app-state.interface';
import { User } from '@app/shared/interfaces/user.interface';
import { createSelector } from '@ngrx/store';
import { IUser } from './user.interface';

export const userSelector = (state: AppStateInterface): IUser => state.user;

export const userDataSelector = createSelector(
	userSelector,
	(state: IUser) => state.userData!
);

export const userFirstLoginSelector = createSelector(
	userSelector,
	(state: IUser) => state.userFirstLogin!
);

export const jwtDecodeSelector = createSelector(
	userSelector,
	(state: IUser) => state.jwt
);

export const userUrlSelector = createSelector(
	userSelector,
	(state: IUser) => state.userUrl
);

export const adminUrlSelector = createSelector(
	userSelector,
	(state: IUser) => state.adminUrl
);

export const userIdSelector = createSelector(
	userDataSelector,
	(state: User) => state.id
);

export const firstNameSelector = createSelector(
	userDataSelector,
	(state: User) => state.firstName
);

export const lastName = createSelector(
	userDataSelector,
	(state: User) => state.lastName
);

export const fullNameSelector = createSelector(
	userDataSelector,
	(state: User) => state.fullName
);

export const companyNameSelector = createSelector(
	userDataSelector,
	(state: User) => state.companyName
);

export const companyIdSelector = createSelector(
	userDataSelector,
	(state: User) => state.companyId
);

export const titleSelector = createSelector(
	userDataSelector,
	(state: User) => state.title
);

export const imagePathSelector = createSelector(
	userDataSelector,
	(state: User) => state.imagePath
);

export const roleIdSelector = createSelector(
	userDataSelector,
	(state: User) => state.roleId
);

export const systemRoleIdSelector = createSelector(
	userDataSelector,
	(state: User) => state.systemRoleId
);

export const roleSelector = createSelector(
	userDataSelector,
	(state: User) => state.role
);

export const impersonatingBySelector = createSelector(
	userDataSelector,
	(state: User) => state.impersonatingBy
);

export const permissionsSelector = createSelector(
	userDataSelector,
	(state: User) => state.permissions
);

export const menuIdsSelector = createSelector(
	userDataSelector,
	(state: User) => state.menuIds
);

export const isFirstLoginSelector = createSelector(
	userDataSelector,
	(state: User) => state.isFirstLogin
);

export const groupIdSelector = createSelector(
	userDataSelector,
	(state: User) => state.groupId
);

export const isKB4ManagerSelector = createSelector(
	userDataSelector,
	(state: User) => state.isKB4Manager
);

export const isPendingLoginSelector = createSelector(
	userDataSelector,
	(state: User) => state.isPendingLogin
);

export const companiesSelector = createSelector(
	userDataSelector,
	(state: User) => state.companies
);

export const isProductDevelopmentSelector = createSelector(
	userDataSelector,
	(state: User) => state.isProductDevelopment
);
