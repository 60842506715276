/* Angular Libraries */
import {
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewChild,
} from '@angular/core';

/* Third Party Libraries */
import { finalize } from 'rxjs';

/* Services */
import { IntegrationService } from '@app/modules/integration/integration.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@services/user.service';
import { FinanceService } from '../../finance.service';
import { CheckboxService } from '@app/shared/directives/multiple-selection/checkbox.service';
import { InvoiceService } from './store/invoice.service';

/* Components */
import { AuditTrailComponent } from '@app/shared/components/audit-trail/audit-trail.component'; 

/* Interfaces */
import { InvoiceWidgets } from './../../../../shared/interfaces/finance.interface';
import { MenuPermissionConstant, Permission } from '@app/shared/constants';
import { HistoryUrl } from './../../../../shared/constants/global-enum';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { userInvoicesUpdateAction } from './store/user/invoices.user.actions';
import { NewTableSharedService } from '@app/shared/new-table-shared/new-table-shared.service';
import { InvoiceTableComponent } from './invoice-table/invoice-table.component';
import { CreditsTableComponent } from '../credits-table/credits-table.component';
import { PaymentsTableComponent } from '../payments-table/payments-table.component';
import { AccountActivityComponent } from '../account-activity/account-activity.component';

@Component({
	selector: 'app-invoices',
	templateUrl: './invoices.component.html',
	styleUrls: ['./invoices.component.scss']
})

export class InvoicesComponent implements OnInit {
	@ViewChild(AuditTrailComponent) auditTrailComponent: AuditTrailComponent;
	@ViewChild(InvoiceTableComponent) invoiceTableComponent: InvoiceTableComponent;
	@ViewChild(CreditsTableComponent) creditsTableComponent: CreditsTableComponent;
	@ViewChild(PaymentsTableComponent) paymentsTableComponent: PaymentsTableComponent;
	@ViewChild(AccountActivityComponent) accountActivityComponent: AccountActivityComponent;

	isPaymentInvoiceEnabled: boolean;
	widgetData: InvoiceWidgets = {
		outstandingBalance: 0,
		billingContacts: [],
		unappliedPayments: 0,
		unappliedCredits: 0,
	};
	historyUrl = HistoryUrl;
	isStripeEnabled: boolean;
	isPaymentEnabled: boolean;
	isManagePaymentMethodEnabled: boolean;
	isUserManagePaymentMethodEnabled = this._userService.hasPermission([
		Permission.UserManagePaymentMethod,
	]) || (MenuPermissionConstant.QuoteAndOrders.subMenus!['invoices'] && this._userService.isSpAdmin);
	isShowInvoice: boolean = true;
	hasChildren: boolean = false;
	tabIndex: number = 0;
	loadedTab: number[] = [];
	totalSelectedAmount: number = 0;

	/* Constructor */
	constructor(
		public _spinner: SpinnerService,
		public _financeService: FinanceService,
		private _userService: UserService,
		private _cd: ChangeDetectorRef,
		private _checkboxService: CheckboxService,
		private _integrationService: IntegrationService,
		private _invoiceService: InvoiceService,
		private _store: Store<AppStateInterface>,
		private _newTableSharedService: NewTableSharedService
	) {}

	/* Methods */
	ngOnInit() {
		// Hide company-view if user in not admin and company dont have children
		if(!this._financeService.isSetFirstCompanyId) 
			this._financeService.setFirstCompanyId()
		if (!this._financeService.isAdmin) {
			this._spinner.start();
			this._financeService
				.getInvoiceChildCompanies()
				.pipe(
					finalize(() => {
						this._spinner.stop();
						this._cd.detectChanges();
					})
				)
				.subscribe({
					next: (res) => {
						if (res.length) this.hasChildren = true;
						else {
							localStorage.setItem(
								'companyUser',
								JSON.stringify({
									companyId: this._userService.user?.companyId,
									companyName: null,
								})
							);
							this.onUpdatedCompanyId();
						}
					},
					error: (error) => {
						this.hasChildren = true;
					},
				});
		} else this.hasChildren = true;

		this.onChangeTab(0);
	}

	getIntegrationSettings() {
		this._spinner.start();
		this._integrationService.getStripePaymentStatus()
		.pipe(
			finalize(() => {
				this._spinner.stop();
				this._cd.detectChanges();
			})
		)
		.subscribe({
			next: (res: any) => {
				this.isStripeEnabled = res.isStripeEnabled as boolean;
				this.isPaymentEnabled = res.isPaymentEnabled as boolean;
				this.isManagePaymentMethodEnabled = res.isManagePaymentMethodEnabled as boolean;
			},
		});
	}

	onUpdatedCompanyId() {
		this.isShowInvoice = false;
		this.loadedTab = [this.tabIndex];

		setTimeout(() => {
			if (this._financeService.selectedCompanyId) {

				// Reset filter when changing company
				if(this._financeService.isCompanyIdChanged())
					this._store.dispatch(userInvoicesUpdateAction({
						query: '',
						invoiceStartDate: '',
						invoiceEndDate: '',
						dueStartDate: '',
						dueEndDate: '',
						statusIds: [],
					}));

				this.isShowInvoice = true;
				this.isPaymentInvoiceEnabled = this._userService.hasPermission([Permission.UserPaymentOfInvoice]) || this._financeService.isAdmin;
				this._checkboxService.clearSelectedIds();
				this.getIntegrationSettings();
				this.getWidgets();
				this.fetchAllTable();
			} else {
				this.isShowInvoice = false;
			}
		}, 100);
	}

	ngOnDestroy(): void {
		this._spinner.reset();
	}

	getWidgets() {
		this._spinner.start();

		this._financeService.getWidgetDetails()
		.pipe(
			finalize(() => {
				this._spinner.stop();
				this._cd.detectChanges();
			})
		)
		.subscribe({
			next: (res) => {
				this.widgetData = {
					outstandingBalance: res.outstandingBalance,
					billingContacts: res.billingContacts,
					unappliedCredits: res.unappliedCredits,
					unappliedPayments: res.unappliedPayments,
				};

				this._cd.detectChanges();
			},
		});
	}

	updateAuditTrail() {
		this.auditTrailComponent.refresh();
	}

	onChangeTab(tabNum: number) {
		this._newTableSharedService.setTabIndex(tabNum);
		if(!this.loadedTab.includes(tabNum)) {
			this.loadedTab.push(tabNum);
			this._cd.detectChanges();
		}
	}

	fetchAllTable() {
		setTimeout(() => {
			this.invoiceTableComponent?.fetchNewData();
			this.creditsTableComponent?.fetchNewData();
			this.paymentsTableComponent?.fetchNewData();
			this.accountActivityComponent?.fetchNewData();
			this._cd.detectChanges();
		}, 0);
	}

	selectedAmountUpdate(amount: number) {
		this.totalSelectedAmount = amount;
	}
}
