/* Angular Libraries */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/* Third Party Libraries */
import { SortDirection } from '@angular/material/sort';
import { Store, select } from '@ngrx/store';
import {
	Subscription,
	finalize,
	skip,
	take,
	filter,
} from 'rxjs';

/* Services */
import { SpinnerService } from '@app/core/services/spinner.service';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { ToastMessageService } from '@app/shared/services/toast-message.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { UserService } from '@services/user.service';
import { FinanceService } from '../../../finance.service';
import { InvoiceService } from '../store/invoice.service';

/* Functions */
import { isValueEmpty } from '@app/shared/utilities/helper'; 

/* Components */
import { ClearFilterComponent } from '@app/shared/components/clear-filter/clear-filter.component';
import { NewTableSharedComponent } from '@app/shared/new-table-shared/new-table-shared.component';

/* Interfaces */
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { IInvoices } from '@app/modules/finance/pages/invoices/store/invoices.interface';
import {
	Invoice,
	InvoiceParameters,
} from '@app/shared/interfaces/finance.interface';
import { QuickFilter } from '../../../../../shared/interfaces/order.interface';
import { IQuickFilter } from '@app/shared/new-table-shared/new-table-shared.interface';
import {
	ApplicationType,
	FilterUrl,
	InvoiceStatus,
	NotificationMessages,
	Permission,
	TableSignalType,
	Themes,
} from '@app/shared/constants';

/* Ngrx */ 
import { userInvoicesUpdateAction } from '../store/user/invoices.user.actions';
import { userInvoicesSelector } from '../store/user/invoices.user.selector';
import { TableReloadSignalService } from '@app/shared/services/table-reload-signal.service';

@Component({
	selector: 'app-invoice-table',
	templateUrl: './invoice-table.component.html',
	styleUrls: ['./invoice-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceTableComponent implements OnInit {
	/* ViewChild */
	@ViewChild(NewTableSharedComponent) newTable: NewTableSharedComponent;
	@ViewChild(ClearFilterComponent) clearFilter: ClearFilterComponent;

	@Input() isStripeEnabled: boolean;
	@Input() isPaymentEnabled: boolean;
	@Input() isManagePaymentMethodEnabled: boolean;

	@Output() onSelectedAmount: EventEmitter<number> = new EventEmitter<number>();

	/* Properties */
	form = new FormGroup({
		statusId: new FormControl(),
		isAutopay: new FormControl(this._financeService.autopay),
	});
	dateFilterLabel = { start: 'Start Date:', end: 'End Date:' };
	dateFilterLabelDue = { start: 'Due Date Start:', end: 'Due Date End:' };
	filterUrl: string = 'sort15';
	themeSubs: Subscription;
	totalItems = 0;
	invoiceStatus: any[] = [];
	searchTimeout: any;
	isDownloadEnabled: boolean;
	isPaymentInvoiceEnabled: boolean;
	queryStartDate = new FormControl('');
	queryEndDate = new FormControl('');
	queryStartDateDue = new FormControl('');
	queryEndDateDue = new FormControl('');
	order: SortDirection = 'desc';
	queryString = new FormControl('');
	invoiceStatementType = new FormControl(true);
	selectedIds: any[] = [];
	selectedItem: Invoice[] = [];
	haveBalance = false;
	userId: number;
	invoices: any[] = [];
	checkBoxItems: number[] = [];
	autopayOption: any = [];
	invoicesData: IInvoices;
	resetVal = false;
	#subscription = new Subscription();
	#ignoreOnce = true;
	#triggerAPI = true;
	qFilterUrl = FilterUrl;
	isFilterHaveBeenCalled: boolean = false;

	_startDate: string = '';
	_endDate: string = '';
	_startDateDue: string = '';
	_endDateDue: string = '';
	_generateStartDate = '';
	_generateEndDate = '';

	/* New Codes */
	searchFilters: any;
	quickFilter: IQuickFilter;
	selectedSortColumn: string = 'invoiceDate';
	isCheckedAll: boolean = false;

	/* Constructor */
	constructor(
		public spinner: SpinnerService,
		public _financeService: FinanceService,
		private _userService: UserService,
		private _themeService: ThemeService,
		private _cd: ChangeDetectorRef,
		private _toastMessageService: ToastMessageService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _localStorage: LocalStorageService,
		public _utilitiesService: UtilitiesService,
		public store: Store<AppStateInterface>,
		private _invoiceService: InvoiceService,
		private _route: ActivatedRoute,
		private _tableReloadSignalService: TableReloadSignalService
	) {
		this.userId = Number(this._userService.user?.id);
	}

	/* Getters/Setters */
	get isAdmin() {
		return this._financeService.isAdmin;
	}

	get applicationType() {
		return ApplicationType;
	}

	get proceedToPayEnabled() {
		return this.selectedIds.length && this.selectedIds.every(x => x.isSettled && x.status === InvoiceStatus.OUTSTANDING);
	}

	get isFilterOn(): boolean {
		const hasStatus = !isValueEmpty(this.form.controls.statusId.value);
		const hasAutopay = !isValueEmpty(this.form.controls.isAutopay.value);
		const hasInvoiceStartDate = !isValueEmpty(this.queryStartDate.value) && ![null, 'Invalid date'].includes(this.queryStartDate.value);
		const hasInvoiceEndDate = !isValueEmpty(this.queryEndDate.value) && ![null, 'Invalid date'].includes(this.queryEndDate.value)
		const hasDueStartDate = !isValueEmpty(this.queryStartDateDue.value) && ![null, 'Invalid date'].includes(this.queryStartDateDue.value);
		const hasDueEndDate = !isValueEmpty(this.queryEndDateDue.value) && ![null, 'Invalid date'].includes(this.queryEndDateDue.value);

		return (
			hasInvoiceStartDate ||
			hasInvoiceEndDate ||
			hasDueStartDate ||
			hasDueEndDate ||
			hasStatus ||
			hasAutopay
		);
	}

	export(exportType: ApplicationType) {
		const options: IInvoices = {
			page: this.newTable.paginator.page,
			pageSize: this.newTable.itemsPerPage.pageSize,
			order: this.order,
			column: this.invoicesData?.column,
			isAutopay: this.invoicesData.isAutopay,
			statusIds:
				this._financeService.statusId?.length !== 0
					? this._financeService.statusId
					: [],
			invoiceStartDate: this.#getWithValue(
				this.queryStartDate.value,
				this.invoicesData.invoiceStartDate
			),
			invoiceEndDate: this.#getWithValue(
				this.queryEndDate.value,
				this.invoicesData.invoiceEndDate
			),
			dueStartDate: this.#getWithValue(
				this.queryStartDateDue.value,
				this.invoicesData.dueStartDate
			),
			dueEndDate: this.#getWithValue(
				this.queryEndDateDue.value,
				this.invoicesData.dueEndDate
			),

			query: this.queryString.value!.trim(),
		};
		const params: InvoiceParameters = {
			page: options.page,
			pageSize: options.pageSize,
			sort: options.column,
			order: options.order,
			query: options.query,
			statusIds: options.statusIds?.filter((x) => x !== undefined && x > 0),
			startDate: options.invoiceStartDate,
			endDate: options.invoiceEndDate,
			dueStartDate: options.dueStartDate,
			dueEndDate: options.dueEndDate,
			isAutopay: options.isAutopay,
			companyIds: this._userService.user?.companyId ?? 0,
			haveBalance: this.haveBalance,
			companyId: this._financeService.companyIdType,
		};

		if (this.totalItems === 0) {
			this._toastMessageService.showErrorMessage(
				NotificationMessages.NoRecordFound
			);
		} else {
			this.spinner.start();

			this._financeService.export(params, exportType).subscribe({
				next: (resp) => {
					const link = document.createElement('a');
					link.href = resp;
					link.setAttribute('download', 'Invoices');
					document.body.appendChild(link);
					link.click();
				},
				error: () => {
					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
				complete: () => {
					this._toastMessageService.showSuccessMessage(
						NotificationMessages.Export
					);
				},
			});
		}
	}

	ngOnInit() {
		setTimeout(() => {
			this._onInit();
			this._afterViewInit();
			this.searchFilters = this._getFilters();
			this._getInvoiceQuickFilters();
			this._invoiceService.invoiceData$.subscribe(res => this.invoices = res);
		}, 0);

		this.themeSubs = this._themeService.themeType.pipe().subscribe((theme) => {
			if (theme === Themes.light) {
				this.filterUrl = 'sort15';
			} else if (theme === Themes.dark) {
				this.filterUrl = 'sort';
			}
		});

		this._initTableReloadFromSignaIR();
	}

	detectChanges() {
		this._cd.detectChanges();
	}

	setGenerateStartDate(date: string) {
		this._generateStartDate = date;
	}

	setGenerateEndDate(date: string) {
		this._generateEndDate = date;
	}

	onGenerateStatement() {
		if (!this._generateStartDate || !this._generateEndDate) {
			this._toastMessageService.showErrorMessage(
				'Review Error', 'Complete the required fields'
			);
		} else {
			const generateDate = {
				startDate: this._generateStartDate,
				endDate: this._generateEndDate,
			};
			this._localStorage.setStorageObject('generateDate', generateDate);
			this._router.navigate(['./invoice-statement'], {
				relativeTo: this._activatedRoute,
				queryParams: {
					isOpenOnly: this.invoiceStatementType.value,
					haveBalance: this.haveBalance,
				},
			});
		}
	}

	setStartDate(startDate: string) {
		this._startDate = startDate;
		this.onDateSearch();
	}

	setEndDate(endDate: string) {
		this._endDate = endDate;
		this.onDateSearch();
	}

	setStartDateDue(startDate: string) {
		this._startDateDue = startDate;
		this.onDateSearch();
	}

	setEndDateDue(endDate: string) {
		this._endDateDue = endDate;
		this.onDateSearch();
	}

	setQuickFilter(event: QuickFilter[]) {
		this.isFilterHaveBeenCalled = true;
		const row = event.filter((x) => x.selected);

		if (Array.isArray(row) && row.length) {
			this.form.get('statusId')?.setValue(row[0].id);
		} else {
			this.form.get('statusId')?.setValue(null);
		}

		this.searchFilters = this._getFilters();

		// this.getInvoices();
	}

	queryChange(delayTime: number = 0) {
		clearTimeout(this.searchTimeout);
		this.searchTimeout = setTimeout(() => {
			this.isFilterHaveBeenCalled = true;
			this.newTable.paginator.changePageWithoutEmit(1);
			this.updateState({ query: this.queryString.value!.trim() });
			this.fetchNewData();
			this._cd.detectChanges();
		}, delayTime);
	}

	isSelected(item: number): boolean {
		return this.isShowHighlightedRow(item);
	}

	onRangeSelected(event: any, option: any) {
		if (event.target.checked) {
			this.selectedIds.push(option);

		} else {
			if (Array.isArray(this.selectedIds) && this.selectedIds.length) {
				const index = this.selectedIds.findIndex(x => option?.id === x.id);
				this.selectedIds.splice(index, 1);
			}
		}

		this.updatedSelectedInvoice();
	}

	updatedSelectedInvoice() {
		this.isCheckedAll = !!this.invoices.length && this.invoices.map(x => x.id).every(x => this.selectedIds.map(x => x.id).includes(x));
		this._selectedAmtUpdate();
	}

	// for this => this.queryStartDate.value! || this.queryStartDate.value === null ? this.queryStartDate.value : this.invoicesData.invoiceStartDate || '',
	#getWithValue(args1: any, args2: any) {
		if (args1) return args1;
		else if (args2) return args2;
		else return '';
	}

	updateState(options: Partial<IInvoices>) {
		if (!('isInvoiceExecute' in options)) options.isInvoiceExecute = true;
		this.store.dispatch(userInvoicesUpdateAction({ ...options }));
	}

	#eventListeners() {
		this.#subscription.add(
			this.store.pipe(select(userInvoicesSelector), skip(1)).subscribe((v) => {
				// at the moment, only called every getInvoice() call
				this.invoicesData = v;
				if (this.#triggerAPI === true && v.isInvoiceExecute === true) {
					// this.getInvoices();
				}
			})
		);

		this.#subscription.add(
			this.spinner.spinner.subscribe((v) => {
				if (v) {
					this.queryString.disable();
				} else {
					this.queryString.enable();
				}
			})
		);
	}

	//invoicesData: any;
	private _loadPrevTable() {
		this.store.pipe(select(userInvoicesSelector), take(1)).subscribe((data) => {
			this.invoicesData = data;
			this._cd.detectChanges();
			this.order = this.invoicesData.order!;
			this.haveBalance = this.invoicesData.haveBalance!;
			this.queryString.setValue(this.invoicesData.query!, { emitEvent: false });
			this._financeService.statusId = this.invoicesData.statusIds!;
			this.form
				.get('statusId')
				?.setValue(this.invoicesData.statusIds![0], { emitEvent: false });
			this.invoicesData.isAutopay
				? this.form
						.get('isAutopay')
						?.setValue(this.invoicesData.isAutopay!, { emitEvent: false })
				: '';
			this.newTable.paginator.changePageWithoutEmit(this.invoicesData.page!);
			this.newTable.itemsPerPage.pageSize = this.invoicesData.pageSize!;
			this.queryStartDate.setValue(this.invoicesData.invoiceStartDate!, {
				emitEvent: false,
			});
			this.queryEndDate.setValue(this.invoicesData.invoiceEndDate!, {
				emitEvent: false,
			});
			this.queryStartDateDue.setValue(this.invoicesData.dueStartDate!, {
				emitEvent: false,
			});
			this.queryEndDateDue.setValue(this.invoicesData.dueEndDate!, {
				emitEvent: false,
			});
			this._getInvoiceQuickFilters() ;
			this._cd.detectChanges();
			this.#ignoreOnce = false;
		});
	}

	onDateSearch() {
		let startDateFilter = new Date(this._startDate);
		let endDateFilter = new Date(this._endDate);

		if (startDateFilter > endDateFilter && endDateFilter.getFullYear() > 1900) {
			this._endDate = this._startDate;
			endDateFilter = new Date(this._endDate);
		}

		if (
			(startDateFilter.getFullYear() > 1900 && !this._endDate) || // start + no end
			(!this._startDate && !this._endDate) || // no start + no end
			(endDateFilter.getFullYear() > 1900 && !this._startDate) || // no start + end
			(startDateFilter.getFullYear() > 1900 &&
				endDateFilter.getFullYear() > 1900) // start + end > 1900
		) {
			this.searchFilters = this._getFilters();
		}
	}

	getPrintInvoice(_invoice: any, _type: 'download' | 'viewPdf' = 'download') {
		this.spinner.start();
		this._financeService
			.downloadInvoice2(_invoice.id)
			.pipe(finalize(() => setTimeout(() => this.spinner.stop(), 500)))
			.subscribe({
				next: async (data: any) => {
					if (_type === 'download') this.#downloadPdf(data, _invoice.id);
					else this.#viewPdf(data, _invoice.id);
				},
				error: () => {
					this._toastMessageService.showErrorMessage(
						NotificationMessages.FailedToGenerateFile
					);
				},
			});
	}

	downloadInvoiceReceipt() {
		if (!this.selectedIds) { return; }

		const invoiceIds = this.selectedIds.map(x => x.id);
		this.spinner.start();

		this._financeService.downloadReceiptByIds(invoiceIds).subscribe({
			next: (result: any) => {
				if (result) {
					const link = document.createElement('a');
					link.href = result;

					if (!result.includes('Print/Zip')) {
						link.target = '_blank';
					}

					document.body.appendChild(link);
					link.click();
				}

				this.spinner.stop();
			},
			error: (error) => {
				this.spinner.stop();
				
				this._toastMessageService.showErrorMessage(
					NotificationMessages.FailedToGenerateFile
				);
			}
		});
	}

	#downloadPdf(data: any, invoiceId: number) {
		const link = document.createElement('a');
		link.href = data;
		link.setAttribute('download', `Invoice ${invoiceId}.pdf`);
		link.target = '_blank';
		document.body.appendChild(link);
		link.click();
	}

	#viewPdf(data: any, invoiceId: number) {
		this._financeService.pdf$.next(data);
		this._router.navigate(['view-pdf', invoiceId], {
			queryParams: { isInvoiceType: true },
			relativeTo: this._route,
		});
	}

	checkedAllInvoice(event: any) {
		if (event.target.checked) {
			this.invoices.forEach(x => this.selectedIds.push(x));

		} else {
			this.selectedIds = this.selectedIds.filter(x => !this.invoices.some(removeItem => removeItem.id === x.id));
		}

		this._selectedAmtUpdate();
	}

	#getIsAutopayDropdown() {
		this.#subscription.add(
			this._financeService
				.getAutopayDropdown()
				.subscribe((v) => (this.autopayOption = v))
		);
	}

	getStatusIds() {
		return [this.form.get('statusId')?.value];
	}

	payInvoice() {
		if (!this.proceedToPayEnabled) { return; }

		this._router.navigate(
			[
				`/${
					this.isAdmin ? 'billing-orders' : 'billing-and-orders'
				}/invoices/payment-process`,
			],
			{ queryParams: { selectedId: this.selectedIds.map((x: any) => x.id) } }
		);
	}

	negativeToPositiveConvertion(num: number) {
		return Math.abs(num);
	}

	isShowHighlightedRow(id: number) {
		return this.selectedIds.some((x: any) => x.id === id);
	}

	fetchNewData() {
		const filters: any = this._getFilters();
		this.searchFilters = filters;
		this._cd.detectChanges();
	}

	onEmitTotalItems(totalItems: number) {
		this.totalItems = totalItems;
		this.updatedSelectedInvoice();
	}

	private _onInit() {
		this.isPaymentInvoiceEnabled =
			this._userService.hasPermission([Permission.UserPaymentOfInvoice]) ||
			this.isAdmin;
		this.isDownloadEnabled =
			this._userService.hasPermission([Permission.UserDownloadInvoice]) ||
			this.isAdmin;

		this.#subscription.add(
			this.form.valueChanges.subscribe((val) => {
				this.isFilterHaveBeenCalled = true;
				if (val.statusId !== this._financeService.statusId) {
					if (Array.isArray(val.statusId)) {
						this._financeService.statusId = val.statusId;
						this.updateState({ statusIds: val.statusId });
					} else {
						const statusIdVal = [Number(val.statusId)];
						this._financeService.statusId = statusIdVal;
						this.updateState({ statusIds: statusIdVal });
					}
				}

				if (val.isAutopay !== this._financeService.autopay) {
					this._financeService.autopay = val.isAutopay;
					this.updateState({ isAutopay: val.isAutopay });
				}

				this.searchFilters = this._getFilters();
				this._getInvoiceQuickFilters();
			})
		);

		this._invoiceService.clearSelectedIds();
	}

	private _afterViewInit() {
		this._getDropdowns();
		this.#getIsAutopayDropdown();

		setTimeout(() => {
			this._loadPrevTable();
		});
		this.#subscription.add(
			this.queryStartDate.valueChanges.subscribe((date) => {
				this.isFilterHaveBeenCalled = true;
				this.updateState({ invoiceStartDate: date || '' });
				this.fetchNewData();
			})
		);

		this.#subscription.add(
			this.queryEndDate.valueChanges.subscribe((date) => {
				this.isFilterHaveBeenCalled = true;
				this.updateState({ invoiceEndDate: date || '' });
				this.fetchNewData();
			})
		);

		this.#subscription.add(
			this.queryStartDateDue.valueChanges.subscribe((date) => {
				this.isFilterHaveBeenCalled = true;
				this.updateState({ dueStartDate: date || '' });
				this.fetchNewData();
			})
		);

		this.#subscription.add(
			this.queryEndDateDue.valueChanges.subscribe((date) => {
				this.isFilterHaveBeenCalled = true;
				this.updateState({ dueEndDate: date || '' });
				this.fetchNewData();
			})
		);

		this.#subscription.add(
			this.clearFilter.clearAll.subscribe(() => {
				const setEmpty = (field: any) => {
					if (field instanceof FormControl) {
						if (field.value instanceof Array) {
							field.setValue([]);
						} else {
							field.setValue(null);
						}
					}
				};

				this.#triggerAPI = false;
				setEmpty(this.queryStartDate); // calls api
				setEmpty(this.queryEndDate); // calls api
				setEmpty(this.queryStartDateDue); // calls api
				setEmpty(this.queryEndDateDue);
				setEmpty(this.form.get('statusId'));
				setEmpty(this.form.get('companyIds'));
				setEmpty(this.form.get('isAutopay'));
				// this.getInvoices();
				this.resetVal = true; // trigger the reset
				setTimeout(() => {
					this.resetVal = false; // set back to false after you trigger reset
					this.#triggerAPI = true;
				}, 500);
			})
		);

		this.#eventListeners();
	}

	private _getDropdowns() {
		return new Promise((resolve) => {
			this._financeService.getInvoiceStatusDropdown().subscribe((res: any) => {
				this.invoiceStatus = res.map(
					(resp: { id: number; name: string | number }) => ({
						value: resp.id,
						display: resp.name,
						selected: false,
					})
				);
				this._cd.detectChanges();
				resolve(res);
			});
		});
	}

	private _clearAllSelectedInvoices() {
		this.selectedIds = [];
		this._invoiceService.clearSelectedIds();
	}

	private _getFilters(): IInvoices {
		return {
			page: this.newTable.paginator.page,
			pageSize: this.newTable.itemsPerPage.pageSize,
			order: this.order,
			column: this.invoicesData?.column ? this.invoicesData?.column : this.selectedSortColumn,
			statusIds: this._financeService.statusId?.length !== 0 ? this._financeService.statusId : [],
			companyIds: this._userService.user?.companyId ?? 0,
			invoiceStartDate: this.#getWithValue(
				this.queryStartDate.value,
				this.invoicesData?.invoiceStartDate
			),
			invoiceEndDate: this.#getWithValue(
				this.queryEndDate.value,
				this.invoicesData?.invoiceEndDate
			),
			dueStartDate: this.#getWithValue(
				this.queryStartDateDue.value,
				this.invoicesData?.dueStartDate
			),
			dueEndDate: this.#getWithValue(
				this.queryEndDateDue.value,
				this.invoicesData?.dueEndDate
			),
			query: this.queryString.value!.trim(),
			haveBalance: this.haveBalance,
			companyId: this._financeService.companyIdType,
			isAutopay: this.form.controls.isAutopay.value,
		};
	}

	private _getInvoiceQuickFilters() {
		this.quickFilter = {
			statusIds: this.getStatusIds(),
			onSelectIds: this.setQuickFilter.bind(this),
			url: this.qFilterUrl.INVOICE,
			selectedType: 'single'
		};
	}

	private _initTableReloadFromSignaIR() {
		this._tableReloadSignalService.tableReloadFromSignaIR(TableSignalType.INVOICE)
		.subscribe({ 
			next: () => {
				this.fetchNewData();
				this._getDropdowns();
				this.newTable.onFetchQuickFilter();
			} 
		});
	}

	private _selectedAmtUpdate() {
		this.onSelectedAmount.emit(this._invoiceService.totalAmount(this.selectedIds));
	}

	ngOnDestroy(): void {
		if (this.themeSubs) {
			this.themeSubs.unsubscribe();
		}

		this.#subscription.unsubscribe();
		this.spinner.reset();
	}
}

export interface checkbox {
	id: number;
	checked: boolean;
}
