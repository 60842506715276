<div class="role-header">
	<div class="role-header__left">
		<h3>{{ rolesAndPermission.modeText$ | async }} Role</h3>
		<span class="font-b1"
			>Roles serve as a framework to group permissions based on activity.
		</span>
	</div>
	<div class="role-header__right">
		<app-parent-child-input-selector
			[formControl]="company"
			*ngIf="withCompany && isSpAdmin && !isOverride"
			url="ParentChildCompany/GetCurrentChildren/2"
			(onChangeSelector)="setCompanyId($event)"
		></app-parent-child-input-selector>

		<!--     <button class="btn-2 btn-2-primary"
      *ngIf="isOverride"
    >
      Reset to Default
    </button> -->

		<app-secondary-button
			[isBackButton]="true"
			[link]="backLink"
			*ngIf="isShowButton"
		></app-secondary-button>
	</div>
</div>
