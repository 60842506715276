import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImpersonateAlertBarService } from '@app/core/components/impersonate-alert-bar/impersonate-alert-bar.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { jwtUpdateAction } from '@app/core/store/jwt/jwt.action';
import { IJWT } from '@app/core/store/jwt/jwt.interface';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ImpersonateModalComponent } from '@app/shared/components/modal/impersonate-modal/impersonate-modal.component';
import { ImpersonateModalService } from '@app/shared/components/modal/impersonate-modal/impersonate-modal.service';
import { BaseUserComponent } from '@app/shared/components/user/base-user.component';
import { NotificationMessages } from '@app/shared/constants';
import { RoleMenuInterface } from '@app/shared/interfaces/role-menu.interface';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { select, Store } from '@ngrx/store';
import { Observable, switchMap, take } from 'rxjs';
import { SideBarService } from '../../side-bar/side-bar.service';
import { topBarUpdateAction } from '../store/top-bar.action';
import { impersonateSelector } from '../store/top-bar.selector';
import { TopBarService } from '../top-bar.service';
import { NewTableSharedService } from '@app/shared/new-table-shared/new-table-shared.service';
import { NavigationService } from '@app/shared/services/navigation.service';

@Component({
  selector: 'sp-impersonate-menu',
  templateUrl: './impersonate-menu.component.html',
  styleUrls: ['./impersonate-menu.component.scss']
})
export class ImpersonateMenuComponent extends BaseUserComponent implements OnInit {
  impersonateOptions$: Observable<{isOpen: boolean}>;
  data: any;
  constructor(
    private store: Store<AppStateInterface>,
    private topBarService: TopBarService,
    userService: UserService,
    private _companiesService: CompaniesService,
    private _notifier: NotificationService,
    private spinner: SpinnerService,
    private impersonateModalService: ImpersonateModalService,
    private router: Router,
    private sidebarService: SideBarService,
    private localStorageService: LocalStorageService,
    private _impersonateAlertBarService: ImpersonateAlertBarService,
    private _navService: NavigationService,
    private _newTableSharedService: NewTableSharedService
    ) {
    super(userService);
    this._companiesService.getCompanyDropdown().subscribe((res: any) => {
      if (res.length > 0) {
        this.companies = res;
        if(this.data) {
          this.selectedCompanyId = this.data;
          this.companyChange(this.selectedCompanyId);
        }
      }
    });
   }

  ngOnInit(): void {
    this.impersonateOptions$ = this.store.pipe(select(impersonateSelector));
  }

  onImpersonateClick(){
    this.topBarService.onImpersonateClick();
  }

  companies: any[] = [];
  users: any[] = [];
  companyLogoSrc: string = '';
  selectedCompanyId: number = 0;
  selectedUserId: number = 0;



  ngAfterViewInit() {
    this._setIdentity();
  }



  companyChange(id: number) {
    if(this.data && id != this.data) return;
    // this.selectedCompanyId = Number(event.target.value);
    // this.selectedCompanyId = id;
    this.selectedUserId = 0;
    if (this.selectedCompanyId) {
      this._companiesService
        .getUsersDropdownByCompanyId(this.selectedCompanyId)
        .subscribe((res: any) => {
          if (res.length > 0) {
            this.users = res.filter(
              (user: { id: number }) => user.id !== this.userId
            );
          } else {
            this.users = [];
          }
        });
      this._companiesService
        .getCompanyLogo(this.selectedCompanyId)
        .subscribe((res: any) => {
          this.companyLogoSrc = res.logo;
        });
    } else {
      this.users = [];
      this.companyLogoSrc = '';
    }
  }

  userChange(id: number) {
    // this.selectedUserId = Number(event.target.value);
    this.selectedUserId = id;
  }

  onLogin() {
    let  data = {
      companyId: this.selectedCompanyId,
      userId: this.selectedUserId,
      userName: this.users.find((obj) => obj.id === this.selectedUserId).name,
      companyName: this.companies.find(
        (obj) => obj.id === this.selectedCompanyId
      ).name,
    }
    this.openImpersonateUserModal(data)
  }


  openImpersonateUserModal(data: {companyId: number, userId: number, userName: string, companyName: string}) {
      // do impersonate
      this.spinner.start();
      this.impersonateModalService
        .startImpersonate(data.userId)
        .pipe(
          take(1),
          switchMap((jwt: IJWT)=>{
            this.store.dispatch(jwtUpdateAction({accessToken: jwt.accessToken, refreshToken: this.localStorageService.getStorageObject('refreshToken')}))
            // console.log('switchUser')
            return this._userService.refreshUser()
          }),
          switchMap((_res)=>this.sidebarService.getSideBarContents()),
          )
        .subscribe({
          next: (res: RoleMenuInterface[]) => {
            this.sidebarService.getContents(res);
            this.spinner.stop();
            this.store.dispatch(topBarUpdateAction({impersonate:{isOpen: false}}))
            this._newTableSharedService.clearTables();
            this._navService.smartNavigate();
          },
          error: (error) => {
            this.spinner.stop();
            if (error.error?.Message) {
              this._notifier.notify(
                NotificationMessages.error(error.error?.Message),
                {
                  duration: 7,
                  panelClass: 'error',
                }, NotificationMessages.Try
              );
            } else {
              this._notifier.notify(NotificationMessages.UnkownError, {
                duration: 5,
                panelClass: 'error',
              }, NotificationMessages.Try);
            }
          },
          complete: () => {
            this._impersonateAlertBarService.init();
          }
        });
  }
}
