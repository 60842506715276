<ng-container *ngIf="modalData$ | async as data">
	<div
		class="modal fade error-module-modal"
		id="ErrorModuleModal"
		tabindex="-1"
		aria-labelledby="ErrorModuleModalLabel"
		aria-hidden="true"
	>
		<div
			class="modal-dialog modal-dialog-centered"
			role="document"
			[class]="
				data.type +
				' ' +
				(data.templateData && data.templateData['dashboardClass']
					? data.templateData['dashboardClass']
					: '')
			"
		>
			<div class="modal-content" [class]="data.type">
				<div class="modal-header" [class]="data.type">
					<h5
						class="modal-title"
						id="ErrorModuleModalCenterTitle"
						[class.no-capital]="
							data.templateData && data.templateData['isTitleNotCapital']
						"
					>
						<ng-container [ngSwitch]="data.type">
							<ng-container *ngSwitchCase="'danger'">
								<app-local-figma-icon-img
									[filename]="'alert-triangle-red'"
									[width]="24"
									[height]="24"
									[classList]="'img-flex'"
								></app-local-figma-icon-img>
							</ng-container>
							<ng-container *ngSwitchCase="'success'">
								<app-local-figma-icon-img
									[filename]="'check-circle-green'"
									[width]="24"
									[height]="24"
									[classList]="'img-flex'"
								></app-local-figma-icon-img>
							</ng-container>
							<ng-container *ngSwitchCase="'info'">
								<app-local-figma-icon-img
									[filename]="'info-circle-blue'"
									[width]="24"
									[height]="24"
									[classList]="'img-flex'"
								></app-local-figma-icon-img>
							</ng-container>
							<ng-container *ngSwitchCase="'warn'">
								<app-local-figma-icon-img
									[filename]="'annotation-dots-yellow'"
									[width]="24"
									[height]="24"
									[classList]="'img-flex'"
								></app-local-figma-icon-img>
							</ng-container>
						</ng-container>
						{{ data.title }}
					</h5>
					<button
						*ngIf="!data.hasNoClose"
						type="button"
						class="close"
						data-bs-dismiss="modal"
						aria-label="Close"
						(click)="closeModal(false)"
					>
						<span aria-hidden="true">
							<app-figma-icon-img
								[iconName]="'x'"
								[hasSpaceOnRight]="false"
							></app-figma-icon-img>
						</span>
					</button>
				</div>
				<ng-container
					[ngTemplateOutlet]="data.template ? customTemplate : defaultTemplate"
					[ngTemplateOutletContext]="{ $implicit: data }"
				></ng-container>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #defaultTemplate let-data>
	<div class="modal-body">
		<div class="ErrorModule-content">
			<p>{{ data.message }}</p>
			<p *ngIf="data.hasTicket">
				Your Ticket #: {{ data.ticketNumber }} Date:
				{{ data.ticketTime | date : 'MM/d/yy h:mm a' }}
			</p>
		</div>
	</div>
	<div
		class="modal-footer q-g-16"
		[class.flex-start]="!data.options && data.altMessage"
	>
		<p *ngIf="!data.options && data.altMessage">{{ data.altMessage }}</p>
		<app-tertiary-button
			*ngIf="data.options && data.options.noText"
			[btnText]="data.options.noText"
			(click)="closeModal(false)"
			class="q-m-0"
		>
		</app-tertiary-button>

		<button
			class="imp-disabled-op"
			*ngIf="data.options && data.options.yesText"
			type="button"
			[class.btn-2-primary]="
				((data.type == 'success' ||
					data.type == 'info' ||
					data.type == 'warn') &&
					!data.options.yesType) ||
				data.options.yesType == 'success'
			"
			[class.btn-2-destructive]="data.type == 'danger' && !data.options.yesType"
			class="btn-2"
			(click)="closeModal(true)"
		>
			{{ data.options.yesText }}
		</button>
	</div>
</ng-template>

<ng-template #customTemplate let-data>
	<ng-container *ngIf="data.template === 'prioritizeVulnerability'">
		<app-priorize-vulnerabilty [data]="data"></app-priorize-vulnerabilty>
	</ng-container>

	<ng-container *ngIf="data.template === 'viewDetails'">
		<app-view-details [data]="data"></app-view-details>
	</ng-container>

	<ng-container *ngIf="data.template === 'scheduleProject'">
		<app-schedule-project [data]="data"></app-schedule-project>
	</ng-container>

	<ng-container *ngIf="data.template === 'requestQuote'">
		<app-request-quote [data]="data"></app-request-quote>
	</ng-container>

	<ng-container *ngIf="data.template === 'changeStatus'">
		<app-change-status [data]="data"></app-change-status>
	</ng-container>

	<ng-container *ngIf="data.template === 'satisfactionScore'">
		<app-satisfaction-score [data]="data"></app-satisfaction-score>
	</ng-container>

	<ng-container *ngIf="data.template === 'satSurvey'">
		<app-sat-survey [data]="data"></app-sat-survey>
	</ng-container>

	<ng-container *ngIf="data.template === 'totalTickets'">
		<app-total-tickets [data]="data"></app-total-tickets>
	</ng-container>

	<ng-container *ngIf="data.template === 'onb'">
		<app-onb [data]="data"></app-onb>
	</ng-container>

	<ng-container *ngIf="data.template === 'serviceTicket'">
		<app-service-ticket [data]="data"></app-service-ticket>
	</ng-container>

	<ng-container *ngIf="data.template === 'serviceTimes'">
		<app-service-times [data]="data"></app-service-times>
	</ng-container>

	<ng-container *ngIf="data.template === 'deviceTicket'">
		<app-device-ticket [data]="data"></app-device-ticket>
	</ng-container>

	<ng-container *ngIf="data.template === 'userTicket'">
		<app-user-ticket [data]="data"></app-user-ticket>
	</ng-container>

	<ng-container *ngIf="data.template === 'siteTicket'">
		<app-sites-ticket [data]="data"></app-sites-ticket>
	</ng-container>

	<ng-container *ngIf="data.template === 'expandTable'">
		<app-expand-table [data]="data"></app-expand-table>
	</ng-container>

	<ng-container *ngIf="data.template === 'platformUsage'">
		<app-platform-usage [data]="data"></app-platform-usage>
	</ng-container>

	<ng-container *ngIf="data.template === 'vulnerabilities'">
		<app-vulnerabilities [data]="data"></app-vulnerabilities>
	</ng-container>

	<ng-container *ngIf="data.template === 'atRiskUsers'">
		<app-at-risk-users [data]="data"></app-at-risk-users>
	</ng-container>

	<ng-container *ngIf="data.template === 'atRiskDevices'">
		<app-at-risk-devices [data]="data"></app-at-risk-devices>
	</ng-container>

	<ng-container *ngIf="data.template === 'recentSecurityTickets'">
		<app-recent-security-tickets [data]="data"></app-recent-security-tickets>
	</ng-container>

	<ng-container *ngIf="data.template === 'overridePermission'">
		<app-override-permission [data]="data"></app-override-permission>
	</ng-container>
</ng-template>
