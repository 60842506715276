<div class="override-container">
	<div class="override-container-header">
		<img src="./assets/icons/info-hexagon.svg" alt="" />
		<h4>Cascade Changes - Override users</h4>
	</div>

	<div class="override-container-message">
		<p>
			Before you continue, there are current users that have an
			<span>Override Role</span>
			based on the default role. Determine which of the following changes you
			wish to push onto those users:
		</p>
	</div>

	<div class="override-container-options">
		<h3>Permission Changes</h3>
		<div class="override-button-container">
			<app-tertiary-button [btnText]="'Skip All'" (click)="skipAll()">
			</app-tertiary-button>
			<app-tertiary-button [btnText]="'Push All'" (click)="pushAll()">
			</app-tertiary-button>
		</div>
	</div>

	<div class="override-container-choices">
		<div class="option-container" *ngFor="let m of menu; let first = first">
			<div class="choices-title-item">
				<div class="choices-title">
					<app-figma-icon-img
						[iconName]="m.icon!"
						[hasSpaceOnRight]="false"
					></app-figma-icon-img>
					<h4>{{ m.menuGroupName }}</h4>
				</div>
				<div class="choices-label" *ngIf="first">
					<h6>Skip</h6>
					<h6>Push</h6>
				</div>
			</div>

			<div class="choices-title-item" *ngFor="let menuActions of m.menuActions">
				<div class="choices-title">
					<label class=""
						>{{
							(menuActions.isEnableAccess ? 'Enabled ' : 'Disabled ') +
								menuActions.menuName
						}}
						- {{ menuActions.name }}</label
					>
				</div>

				<div class="choices-label">
					<radio-input
						[name]="menuActions.id + ''"
						[value]="false"
						[removeGap]="true"
						[_radioValue]="menuActions.isPush"
						(valueChange)="getRadioValue($event)"
					>
					</radio-input>
					<radio-input
						[name]="menuActions.id + ''"
						[value]="true"
						[removeGap]="true"
						[_radioValue]="menuActions.isPush"
						(valueChange)="getRadioValue($event)"
					></radio-input>
				</div>
			</div>

			<!-- <div class="choices-title-item">
				<div class="choices-title">
					<label class="">Enabled Dashboard - View Projects Tab</label>
				</div>

				<div class="choices-label">
					<radio-input [name]="'projectsTab'" [value]="true" [removeGap]="true">
					</radio-input>
					<radio-input
						[name]="'projectsTab'"
						[value]="false"
						[removeGap]="true"
					></radio-input>
				</div>
			</div>

			<div class="choices-title-item">
				<div class="choices-title">
					<label class="">Enabled Dashboard - View Security Tab</label>
				</div>

				<div class="choices-label">
					<radio-input [name]="'securityTab'" [value]="true" [removeGap]="true">
					</radio-input>
					<radio-input
						[name]="'securityTab'"
						[value]="false"
						[removeGap]="true"
					></radio-input>
				</div>
			</div> -->
		</div>

		<!-- <div class="option-container">
			<div class="choices-title-item">
				<div class="choices-title">
					<img src="./assets/icons/building-07.svg" alt="" />
					<h4>Service & Support</h4>
				</div>
			</div>

			<div class="choices-title-item">
				<div class="choices-title">
					<label class="">Enabled Request Forms - Copy</label>
				</div>

				<div class="choices-label">
					<radio-input [name]="'reqCopy'" [value]="true" [removeGap]="true">
					</radio-input>
					<radio-input
						[name]="'reqCopy'"
						[value]="false"
						[removeGap]="true"
					></radio-input>
				</div>
			</div>

			<div class="choices-title-item">
				<div class="choices-title">
					<label class="">Disabled Request Forms - Delete</label>
				</div>

				<div class="choices-label">
					<radio-input
						[name]="'reqDelete'"
						[value]="true"
						[removeGap]="true"
						[_radioValue]="true"
					>
					</radio-input>
					<radio-input
						[name]="'reqDelete'"
						[value]="false"
						[removeGap]="true"
					></radio-input>
				</div>
			</div>

			<div class="choices-title-item">
				<div class="choices-title">
					<label class="">Disabled Knowledge Base - Access</label>
				</div>

				<div class="choices-label">
					<radio-input [name]="'kbAccess'" [value]="true" [removeGap]="true">
					</radio-input>
					<radio-input
						[name]="'kbAccess'"
						[value]="false"
						[removeGap]="true"
					></radio-input>
				</div>
			</div>
		</div>

		<div class="option-container">
			<div class="choices-title-item">
				<div class="choices-title">
					<img src="./assets/icons/coins-stacked-03.svg" alt="" />
					<h4>Billings & Orders</h4>
				</div>
			</div>

			<div class="choices-title-item">
				<div class="choices-title">
					<label class="">Disabled Billing & Orders - All Access</label>
				</div>

				<div class="choices-label">
					<radio-input [name]="'billAccess'" [value]="true" [removeGap]="true">
					</radio-input>
					<radio-input
						[name]="'billAccess'"
						[value]="false"
						[removeGap]="true"
					></radio-input>
				</div>
			</div>
		</div> -->
	</div>

	<div class="override-container-message m-32">
		<p>These permission changes will take immediate effect.</p>
	</div>

	<div class="override-container-button">
		<app-tertiary-button [btnText]="'Back'" (click)="closeModal()">
		</app-tertiary-button>

		<button class="btn-2 btn-2-primary" type="button" (click)="submit()">
			Confirm
		</button>
	</div>
</div>
