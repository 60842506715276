import { DataModalService } from '@app/core/data-modal/data-modal.service';
import { Component, OnInit, inject } from '@angular/core';
import { RolesAndPermissionService } from '@app/modules/groups-and-permissions/roles-and-permission.service';
import { select, Store } from '@ngrx/store';
import {
	isProductDevelopmentSelector,
	userSelector,
} from '@app/core/store/user/user.selector';

@Component({
	selector: 'role-button-component',
	templateUrl: './role-buttons.component.html',
	styleUrls: ['./role-buttons.component.scss'],
})
export class RoleButtonsComponent implements OnInit {
	// inject
	rolesAndPermission = inject(RolesAndPermissionService);
	dataModalService = inject(DataModalService);
	store = inject(Store);
	isProductDevelopmentRole: boolean = false;
	hasOverrideUsers: boolean = false;
	// prop
	private _button: 'default' | 'override' = 'default';

	constructor() {}

	ngOnInit() {
		this.store
			.pipe(select(isProductDevelopmentSelector))
			.subscribe((data) => (this.isProductDevelopmentRole = data));
		this.rolesAndPermission.roleHeader$.subscribe((data) => {
			if (data) this.hasOverrideUsers = data!.hasOverrideUsers;
		});

		if (this.rolesAndPermission.modeState === 'override')
			this._button = 'override';
	}

	saveChanges() {
		this.rolesAndPermission._updateEditedPages();
		if (
			this.rolesAndPermission.modeState !== 'override' &&
			this.isProductDevelopmentRole &&
			this.hasOverrideUsers &&
			this.rolesAndPermission.mapGroupToOverrideModal().length > 0
		) {
			const data = {
				title: '',
				message: '',
				hasTicket: false,
				hasNoClose: true,
				messageSubText: '',
				type: 'overridePermission' as const,
				options: {
					yesText: 'Submit',
					noText: 'Back',
				},
				template: 'overridePermission',
				templateData: {},
			};
			this.dataModalService.showModal(data).subscribe();
		} else this.rolesAndPermission.saveChanges(); // current error, inserting pages not updating
	}

	saveForLater() {
		this.rolesAndPermission.saveChanges(true); // current error, inserting pages not updating
	}

	get isDefault() {
		return this._button === 'default';
	}
	get isOverride() {
		return this._button === 'override';
	}
	get isViewPage() {
		return this.rolesAndPermission.modeState === 'view';
	}
}
