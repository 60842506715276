<input
	type="text"
	[class]="inputClass"
	class="font-b1"
	[placeholder]="placeholder || ''"
	[formControl]="control"
	[matAutocomplete]="auto"
	(focus)="isFocused = true"
	(blur)="onTouched()"
	#inputSelect
	(input)="onInput(inputSelect.value); virtualScroll.scrollToIndex(0)"
	spellcheck="false"
	[readonly]="readonly"
	(click)="clear()"
	[ngClass]="cursor"
	[class.select-input-search-icon]="showSearchIcon"
/>
<mat-autocomplete
	[autoActiveFirstOption]="activeFirstOption"
	#auto="matAutocomplete"
	[displayWith]="canCreateOption ? null : displayFn.bind(this)"
	(optionSelected)="setValue($event.option.value)"
	class="virtual-scroll select-input-options"
	(closed)="virtualScroll.scrollToIndex(0);"
	(opened)="openedChangeEvent()"
>
	<mat-option
		[value]="inputSelect.value"
		*ngIf="canCreateOption && inputSelect.value"
	>
		{{ inputSelect.value }}
	</mat-option>
		<cdk-virtual-scroll-viewport
      #virtualScroll
			style="max-height: 200px;"
      [ngStyle]="{'height.px':
				40 * (((filteredItems | async)?.length ?? 0) +
				(hasClear ? 1 : 0) +
				(hasNewStatus ? 1 : 0))
			}"
			itemSize="40"
			minBufferPx="200"
			maxBufferPx="400"
			class="q-scroll"
			qScroll
		>
			<ng-container
				*cdkVirtualFor="let item of filteredItems | async; let first = first; let last = last"
			>
				<mat-option [value]="''" *ngIf="hasClear && first">
					<span class="font-b1 font-h-normal">Clear Select</span>
				</mat-option>
				<mat-option
					[value]="item.value"
					[id]="item.value"
					[ngClass]="{ 'selected-option': this.selected === item.value }"
				>
					<app-figma-icon-img
						*ngIf="withDelete"
						[iconName]="'trash-01'"
						[isForButton]="true"
						(click)="deleteClicked(item.value)"
						onclick="event.stopPropagation()"
						[height]="15"
						[width]="15"
					></app-figma-icon-img>
					<span class="font-b1 font-h-normal ellipsis">{{ item.label }}</span>
				</mat-option>
				<mat-option
					[value]="0"
					*ngIf="hasNewStatus && last"
					class="new-status"
				>
					<app-figma-icon-img
						[iconName]="'plus'"
						[hasSpaceOnRight]="false"
            [isForButton]="true"
					></app-figma-icon-img>
					Create New Status
				</mat-option>
			</ng-container>
		</cdk-virtual-scroll-viewport>
</mat-autocomplete>

<div *ngIf="showSearchIcon" class="search-icon-container"></div>
