<div class="roles-and-permission card-2 q-mb-24">
	<div class="roles-and-permission__header">
		<permission-header-component></permission-header-component>
	</div>
	<div class="roles-and-permission__content">
		<roles-and-modules-component></roles-and-modules-component>
	</div>
</div>

<app-audit-trail
	[id]="companyId"
	[url]="historyUrl.ROLES_AND_PERMISSIONS_V2_ROLE"
	[isCustomizeContent]="true"
>
	<ng-template #customizeContent let-option let-i="index">
		<strong> {{ option.firstName }} {{ option.lastName }} </strong>

		<ng-container [ngSwitch]="option.fieldNameInUI">
			<ng-container *ngSwitchCase="'Added'">
				Turned {{ option.newValue === 'Yes' ? 'ON' : 'OFF' }} Admin role for
				<strong>{{ option.name }}</strong>
			</ng-container>

			<ng-container *ngSwitchCase="'Deleted'">
				Deleted <strong>{{ option.name }}</strong>
			</ng-container>

			<ng-container *ngSwitchDefault>
				changed the field
				<strong>{{ option.name || option.fieldNameInUI }}</strong> to
				{{ option.newValue }}.
			</ng-container>
		</ng-container>
	</ng-template>
</app-audit-trail>
