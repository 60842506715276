<div class="card-2 q-mb-24">
	<div class="card-header-2">
		<h3 class="q-mr-auto">Orders</h3>

		<app-parent-child-input-selector
			*ngIf="childCompanies.length"
			url="ParentChildCompany/GetCurrentChildren?access=4"
			(onChangeSelector)="childCompanyChanged($event)"
			[formControl]="childCompanyControl"
		></app-parent-child-input-selector>

		<input
			type="text"
			class="font-btn"
			placeholder="Search"
			[formControl]="search"
			#searchTextbox
		/>

		<app-tertiary-button
			[isFilterOn]="isFilterOn"
			[isFilterToggle]="true"
		></app-tertiary-button>

		<export-btn (onExport)="export($event)"></export-btn>
	</div>

	<div id="demo" class="collapse">
		<div [formGroup]="form" class="table-filter-2 tf-col-4">
			<div class="filter-item">
				<label>Start Date</label>
				<date-input
					formControlName="queryStartDate"
					max="{{ form.controls.queryEndDate.value | date : 'yyyy-MM-dd' }}"
					[isString]="true">
				</date-input>
			</div>

			<div class="filter-item">
				<label>End Date</label>
				<date-input
					formControlName="queryEndDate"
					min="{{ form.controls.queryStartDate.value | date : 'yyyy-MM-dd' }}"
					[isString]="true">
				</date-input>
			</div>

			<div class="filter-item">
				<label>Status</label>
				<multi-select-input-2
					[options]="orderStatus"
					formControlName="statusIds"
					[autoClosePerSelect]="true"
					placeholder="- Select Status -"
					[resetVal]="resetVal"
					[allowSelectAll]="false"
					[hideIcon]="true"
				>
				</multi-select-input-2>
			</div>

			<div class="filter-item filter-actions">
				<app-clear-filter></app-clear-filter>
			</div>
		</div>
	</div>

	<app-new-table-shared
    loadingText="Fetching Orders..."
		[searchFilters]="searchFilters"
		[quickFilter]="quickFilter"
		(emitTotalItems)="onEmitTotalItems($event)"
	></app-new-table-shared>
</div>
