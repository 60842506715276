import { Location } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { ApplicationsService } from '@app/modules/applications/applications.service';
import { KnowledgeBaseService } from '@app/modules/knowledge-base/knowledge-base.service';
import { SupportService } from '@app/modules/service-and-support/support.service';
import { ItemPerPageComponent } from '@app/shared/components/paginator/item-per-page/item-per-page.component';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import {
	HistoryUrl,
	NotificationMessages,
	PaginationConstants,
	TableMessages,
} from '@app/shared/constants';
import { NameId } from '@app/shared/interfaces/support.interface';
import {
	Observable,
	Subscription,
	debounceTime,
	finalize,
	merge,
	retry,
	tap,
} from 'rxjs';
import {
	RoleHeader,
	UserRoleForms,
} from '../../roles-and-permission.interface';
import { RolesAndPermissionService } from '../../roles-and-permission.service';

@Component({
	selector: 'rp-manage-forms-access',
	templateUrl: './manage-forms-access.component.html',
	styleUrls: ['./manage-forms-access.component.scss'],
})
export class RPManageFormsAccess implements AfterViewInit {
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(PaginatorComponent) paginator: PaginatorComponent;
	@ViewChild(ItemPerPageComponent) itemsPerPage: ItemPerPageComponent;

	id: number;
	companyId: number | undefined;
	userId?: number;
	role: RoleHeader;
	type: RoleFormTypeData;
	createEnabled: boolean;

	searchControl = new FormControl('');
	searchSub: Subscription;
	filterControl = new FormGroup({
		categoryId: new FormControl<number[]>([]),
	});
	dataSource: MatTableDataSource<UserRoleForms> = new MatTableDataSource();
	displayedColumns = ['check', 'isAccess', 'title'];
	totalItems = 0;
	pageSizes = PaginationConstants.abovePageSizes;
	noRecordMessage = TableMessages.EmptyTable;
	selectedItems: UserRoleForms[] = [];
	isLoading = false;
	categories: NameId[] = [];
	// historyUrl = HistoryUrl.AGREEMENT_TYPE; // to change
	historyUrl = HistoryUrl;
	overrideSwitch$: Observable<any>;
	overrideSwitch: boolean = true;

	constructor(
		private _userService: UserService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _location: Location,
		private _spinner: SpinnerService,
		private _rolesPermissionService: RolesAndPermissionService,
		private _supportService: SupportService,
		private _kbService: KnowledgeBaseService,
		private _appsService: ApplicationsService,
		private _notifier: NotificationService,
		private _cd: ChangeDetectorRef
	) {
		this.id = parseInt(this._route.snapshot.params['id']);
		this.userId = parseInt(this._route.snapshot.params['userId']) || undefined;
		this.companyId =
			parseInt(this._route.snapshot.params['companyId']) || undefined;
		this.type = this.getType(this._route.snapshot.routeConfig?.path);

		if (this.type.type != RoleFormType.ticketBoards)
			this.displayedColumns.push('categories');

		if (this.id) {
			if (history.state.name) {
				this.role = history.state;
				this._rolesPermissionService.setBreadcrumb(this.role!.name);
			} else this.getRole();
		} else this.notFound();
	}

	ngAfterViewInit(): void {
		merge(
			this.searchControl.valueChanges,
			this.filterControl.valueChanges,
			this.sort.sortChange
		)
			.pipe(
				tap(() => {
					if (this.searchSub && !this.searchSub.closed)
						this.searchSub.unsubscribe();
				}),
				debounceTime(800),
				tap((v) => this.getList())
			)
			.subscribe();
		if (this.userId) this.getOverrideSwitch();
		this.getList();
		this.getCategories();
		this.detectChanges();
	}

	detectChanges() {
		this._cd.detectChanges();
	}

	private getOverrideSwitch() {
		const options: any = {
			roleId: this.id,
			userId: this.userId,
		};

		if (this._rolesPermissionService.modeState === 'company-override')
			this.overrideSwitch$ =
				this._rolesPermissionService[this.type.overrideCompanyGet](options);
		else
			this.overrideSwitch$ =
				this._rolesPermissionService[this.type.overrideGet](options);

		this.overrideSwitch$.subscribe((v) => {
			v ? (this.overrideSwitch = true) : (this.overrideSwitch = false);
		});
	}

	getSwitch() {
		return this.overrideSwitch ? 'switch-on' : 'switch-off';
	}
	switchOverride() {
		this.overrideSwitch = !this.overrideSwitch;
		const options = {
			roleId: this.id,
			userId: this.userId!,
			isOverride: this.overrideSwitch,
		};
		if (this._rolesPermissionService.modeState === 'company-override') {
			this._rolesPermissionService[this.type.overrideCompanyUpdate](
				options
			).subscribe((v) => {
				// do nothing? haha
			});
		} else {
			this._rolesPermissionService[this.type.overrideUpdate](options).subscribe(
				(v) => {
					// do nothing? haha
				}
			);
		}
	}

	getList() {
		const options: any = {
			search: this.searchControl.value?.trim(),
			page: this.paginator.page,
			pageSize: this.itemsPerPage.pageSize,
			column: this.sort.active,
			order: this.sort.direction,
			roleId: this.id,
			userId: this.userId,
			...this.filterControl.value,
		};

		this._spinner.start();
		this.searchSub = this._rolesPermissionService[this.type.listMethod](options)
			.pipe(
				finalize(() => {
					this.selectedItems = [];
					this._spinner.stop();
				})
			)
			.subscribe({
				next: (res) => {
					this.totalItems = res.totalCount;
					this.dataSource = new MatTableDataSource(res.data);
				},
				error: (error) => {
					this.totalItems = 0;
					this.dataSource = new MatTableDataSource();
					this._notifier.notifyError(
						'Retrieval Failed',
						NotificationMessages.RefreshTry
					);
				},
			});
	}

	getRole() {
		this._rolesPermissionService
			.getRoleData(this.id)
			.pipe(retry(1))
			.subscribe({
				next: (res) => {
					(this.role = res),
						this._rolesPermissionService.setBreadcrumb(this.role.name);
				},
				error: (error) => this.notFound(),
			});
	}

	toggleAllItems(event: any) {
		this.selectedItems = event.target.checked ? [...this.dataSource.data] : [];
	}

	toggleItem(row: UserRoleForms) {
		const i = this.selectedItems.indexOf(row);
		if (i > -1) this.selectedItems.splice(i, 1);
		else this.selectedItems.push(row);
	}

	toggleAccess(row?: UserRoleForms) {
		if (this.userId) {
			if (!this.overrideSwitch) return;
		}

		const items = row ? [row] : this.selectedItems;
		const itemIds = items.map((i) => i.id);

		this._spinner.start();
		this._rolesPermissionService[this.type.updateMethod](
			this.id,
			itemIds,
			this.userId!
		)
			.pipe(finalize(() => this._spinner.stop()))
			.subscribe({
				next: (res) => {
					this.selectedItems = [];
					items.forEach((item) => (item.isAccess = !item.isAccess));
				},
				error: (error) => {
					this._notifier.notifyError(
						'Retrieval Failed',
						NotificationMessages.Try
					);
				},
			});
	}

	getCategories() {
		if (this.type.type === RoleFormType.requestForms) {
			this._supportService.categoriesDropdown().subscribe((res) => {
				this.categories = res.data;
			});
		} else if (this.type.type === RoleFormType.knowledgeBase) {
			this._kbService.getKBCategoryDropdown().subscribe((res: any) => {
				this.categories = res.data;
			});
		} else if (this.type.type === RoleFormType.applications) {
			this._appsService.getCategoryDropdownList().subscribe((res: any) => {
				this.categories = res;
			});
		}
	}

	notFound() {
		this._notifier.notifyError('Role not found');
		this.back();
	}

	back() {
		if (history.state.navigationId !== 1) this._location.back();
		else
			this._router.navigateByUrl('/roles-and-permissions', {
				replaceUrl: true,
			});
	}

	updateSize() {
		this.paginator.size = this.itemsPerPage.pageSize;
		this.paginator.setTotalPages();
		this.paginator.setPages();
		if (
			this.paginator.totalItems <
			this.paginator.size * (this.paginator.page - 1) + 1
		)
			this.paginator.changePageWithoutEmit(1);
		this.getList();
	}

	get roleFormType() {
		return RoleFormType;
	}

	get isCompanyOverride() {
		return this._rolesPermissionService.modeState === 'company-override';
	}

	getType(path: string | undefined): RoleFormTypeData {
		let typePath = path ? path.split('/')[0] : '',
			type: RoleFormType,
			header: string,
			placeholder: string,
			listMethod: RoleFormTypeData['listMethod'],
			updateMethod: RoleFormTypeData['updateMethod'],
			createLink: string,
			overrideGet: any,
			overrideUpdate: any,
			auditTrailUrl: string,
			overrideCompanyGet: any,
			overrideCompanyUpdate: any;
		//auditTrailUrlCompanyOverride: any;

		switch (typePath) {
			case 'ticket-boards':
				(type = RoleFormType.ticketBoards),
					(header = 'Ticket Boards Access'),
					(placeholder = 'Boards'),
					(listMethod = this.userId
						? 'getUserTicketBoards'
						: 'getRoleTicketBoards');
				updateMethod = this.userId
					? 'updateUserTicketBoards'
					: 'updateRoleTicketBoards';
				createLink = '';
				overrideGet = 'getBoardUserAccessOverride';
				overrideUpdate = 'setBoardUserAccessOverride';
				auditTrailUrl = this.userId
					? this.historyUrl.ROLES_AND_PERMISSIONS_V2_USER_TICKET_BOARD_ACCESS
					: this.historyUrl.ROLES_AND_PERMISSIONS_V2_ROLE_TICKET_BOARD_ACCESS;
				//auditTrailUrlCompanyOverride = this.historyUrl.ROLE_TICKET_BOARD_ACCESS;
				overrideCompanyGet = 'getBoardCompanyAccessOverride';
				overrideCompanyUpdate = 'setBoardCompanyOverride';
				break;
			case 'knowledge-base':
				type = RoleFormType.knowledgeBase;
				(header = 'Knowledge Base Access'),
					(placeholder = 'Topics'),
					(listMethod = this.userId ? 'getUserKBTopics' : 'getRoleKBTopics');
				updateMethod = this.userId
					? 'updateUserKBTopics'
					: 'updateRoleKBTopics';
				createLink = '/knowledge-base/topics/add';
				overrideGet = 'getKBTopicUserAccessOverride';
				overrideUpdate = 'setKBTopicUserAccessOverride';
				auditTrailUrl = this.userId
					? this.historyUrl.ROLES_AND_PERMISSIONS_V2_USER_KBT_ACCESS
					: this.historyUrl.ROLES_AND_PERMISSIONS_V2_ROLE_KBT_ACCESS;
				//auditTrailUrlCompanyOverride = this.historyUrl.ROLE_KB_TOPIC_ACCESS;
				overrideCompanyGet = 'getKBTopicCompanyAccessOverride';
				overrideCompanyUpdate = 'setKbTopicCompanyOverride';
				break;
			case 'applications':
				type = RoleFormType.applications;
				(header = 'Applications Access'),
					(placeholder = 'Applications'),
					(listMethod = this.userId
						? 'getUserApplications'
						: 'getRoleApplications');
				updateMethod = this.userId
					? 'updateUserApplications'
					: 'updateRoleApplications';
				createLink = '/application/applications/add';
				overrideGet = 'getApplicationUserAccessOverride';
				overrideUpdate = 'setApplicationUserAccessOverride';
				auditTrailUrl = this.userId
					? this.historyUrl.ROLES_AND_PERMISSIONS_V2_USER_APPLICATIONS
					: this.historyUrl.ROLES_AND_PERMISSIONS_V2_ROLE_APPLICATIONS;
				//auditTrailUrlCompanyOverride = this.historyUrl.ROLES_AND_PERMISSIONS_V2_COMPANY_APPLICATIONS_ACCESS;
				overrideCompanyGet = 'getApplicationCompanyAccessOverride';
				overrideCompanyUpdate = 'setApplicationCompanyOverride';
				break;
			default:
				type = RoleFormType.requestForms;
				(header = 'Request Forms Access'),
					(placeholder = 'Forms'),
					(listMethod = this.userId ? 'getUserForms' : 'getRoleForms');
				updateMethod = this.userId ? 'updateUserForms' : 'updateRoleForms';
				createLink = '/service-and-support/request-forms/add';
				overrideGet = 'getFormnUserAccessOverride';
				overrideUpdate = 'setFormnUserAccessOverride';
				auditTrailUrl = this.userId
					? this.historyUrl.ROLES_AND_PERMISSIONS_V2_USER_FORMS
					: this.historyUrl.ROLES_AND_PERMISSIONS_V2_ROLE_FORMS;
				//auditTrailUrlCompanyOverride = this.historyUrl.ROLES_AND_PERMISSIONS_V2_COMPANY_FORMS;
				overrideCompanyGet = 'getFormnCompanyAccessOverride';
				overrideCompanyUpdate = 'setFormCompanyOverride';
				break;
		}
		//console.log('method', listMethod, updateMethod);
		return {
			type,
			header,
			placeholder,
			listMethod,
			updateMethod,
			createLink,
			overrideGet,
			overrideUpdate,
			auditTrailUrl,
			overrideCompanyGet,
			overrideCompanyUpdate /* , auditTrailUrlCompanyOverride */,
		};
	}
}

interface RoleFormTypeData {
	type: RoleFormType;
	header: string;
	placeholder: string;
	listMethod:
		| 'getRoleForms'
		| 'getUserForms'
		| 'getRoleTicketBoards'
		| 'getUserTicketBoards'
		| 'getRoleKBTopics'
		| 'getUserKBTopics'
		| 'getRoleApplications'
		| 'getUserApplications';
	updateMethod:
		| 'updateRoleForms'
		| 'updateUserForms'
		| 'updateRoleTicketBoards'
		| 'updateUserTicketBoards'
		| 'updateRoleKBTopics'
		| 'updateUserKBTopics'
		| 'updateRoleApplications'
		| 'updateUserApplications';
	createLink: string;
	overrideGet:
		| 'getApplicationUserAccessOverride'
		| 'getKBTopicUserAccessOverride'
		| 'getFormnUserAccessOverride'
		| 'getBoardUserAccessOverride';
	overrideUpdate:
		| 'setApplicationUserAccessOverride'
		| 'setKBTopicUserAccessOverride'
		| 'setFormnUserAccessOverride'
		| 'setBoardUserAccessOverride';
	overrideCompanyGet:
		| 'getApplicationCompanyAccessOverride'
		| 'getKBTopicCompanyAccessOverride'
		| 'getFormnCompanyAccessOverride'
		| 'getBoardCompanyAccessOverride';
	overrideCompanyUpdate:
		| 'setApplicationCompanyOverride'
		| 'setKbTopicCompanyOverride'
		| 'setFormCompanyOverride'
		| 'setBoardCompanyOverride';
	auditTrailUrl: string;
	//auditTrailUrlCompanyOverride: string;
}

enum RoleFormType {
	requestForms,
	ticketBoards,
	knowledgeBase,
	applications,
}
