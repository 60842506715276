/***
 *
 * The structure of the data are based on the route.
 * All you need to do is to import the parent data in id.
 * Though sometimes you need to also add the data to its child route.
 *
 */

interface MenuPermission {
	1?: number | null; // SP Admin
	2?: number | null; // Client Admin
	3?: number | null; // User
	subMenus?: SubMenu | null;
}

interface SubMenu {
	[key: string]: MenuPermission;
}

export class MenuPermissionConstant {
	/**
	 *
	 * @param value this param is the object key(s) you're going to access(Example below).
	 * ex. Dashboard
	 * ex. Companies.subMenus.application.subMenus.categories
	 *
	 * @returns return will be array of number
	 */
	static get(value: string): number[] {
		const splitValue = value.split('.');
		let newVal: MenuPermission | null = null;

		const data = Object.entries(this).find((x) => x[0] === splitValue[0]);

		if (data) {
			const mainValue = data[1];
			if (splitValue.length > 1) {
				// skip this if only getting parent module
				splitValue.shift();

				const result = this.getPropertyValue(mainValue, splitValue);
				newVal = result as MenuPermission;
			} else {
				newVal = mainValue;
			}
		}

		const retVal = newVal ? Object.values(newVal).filter(Number) : [];

		return retVal;
	}

	static getPropertyValue(object: SubMenu, dataToRetrieve: string[]) {
		return dataToRetrieve.reduce(
			(o: { [x: string]: any }, k: string | number) => {
				return o && o[k]; // get inner property if `o` is defined else get `o` and return
			},
			object
		); // set initial value as object
	}

	public static readonly Dashboard: MenuPermission = {
		// Dashboard Module
		3: 72,
	};

	public static readonly ServiceAndSupport: MenuPermission = {
		// Service and Support Module
		1: 11,
		2: 122,
		3: 60,
		subMenus: {
			newTicket: {
				3: 203,
			},
			serviceRequest: {
				3: 61,
			},
			supportRequest: {
				3: 62,
			},
			tickets: {
				3: 63,
			},
			categories: {
				1: 12,
				2: 154,
			},
			requestForms: {
				1: 16,
				2: 75,
			},
			formButtonDefaults: {
				1: 76,
			},
			ticketBoards: {
				1: 74,
			},
			knowledgeBase: {
				3: 57,
			},
		},
	};

	public static readonly CardsAndWidgets: MenuPermission = {
		1: 176,
		subMenus: {
			dashboardCards: {
				1: 177,
			},
			widgets: {
				1: 178,
			},
		},
	};

	public static readonly Application: MenuPermission = {
		1: 2,
		2: 123,
		subMenus: {
			categories: {
				1: 3,
				2: 153,
			},
			applications: {
				1: 4,
				2: 52,
			},
			applicationCatalog: {
				1: 229,
			},
		},
	};

	public static readonly Applications: MenuPermission = {
		3: 58,
	};

	public static readonly KnowledgeBase: MenuPermission = {
		1: 5,
		2: 48,
		subMenus: {
			categories: {
				1: 6,
				2: 49,
			},
			topics: {
				1: 7,
				2: 50,
			},
			chatbot: {
				1: 8,
			},
		},
	};

	public static readonly QuoteCategories: MenuPermission = {
		1: 78,
	};

	public static readonly AgreementTypes: MenuPermission = {
		1: 73,
	};

	public static readonly NotificationCenter: MenuPermission = {
		1: 246,
	};

	public static readonly Admin: MenuPermission = {
		2: 47,
	};

	public static readonly Companies: MenuPermission = {
		1: 23,
		subMenus: {
			contactSettings: {
				1: 85,
				subMenus: {
					profile: {
						1: 24,
					},
					branding: {
						1: 25,
					},
					domainSID: {
						1: 221,
					},
					contacts: {
						1: 26,
						subMenus: {
							contactSetting: {
								1: 232,
								subMenus: {
									profile: {
										1: 233,
									},
									groupsAndPermissions: {
										1: 234,
									},
								},
							},
							requestForms: {
								1: 235,
							},
							boards: {
								1: 236,
							},
							applications: {
								1: 237,
							},
							topics: {
								1: 238,
							},
						},
					},
					orgChart: {
						1: 28,
					},
					companyAgreements: {
						1: 29,
					},
					groupsAndPermissions: {
						1: 86,
					},
					notifications: {
						1: 247,
					},
					parentChildRelationships: {
						1: 245,
					},
					knowBe4: {
						1: 250,
					},
				},
			},
			serviceAndSupport: {
				1: 87,
				subMenus: {
					supportCategories: {
						1: 88,
					},
					requestForms: {
						1: 90,
					},
					ticketBoards: {
						1: 91,
					},
				},
			},
			application: {
				1: 92,
				subMenus: {
					categories: {
						1: 93,
					},
					applications: {
						1: 94,
					},
				},
			},
			knowledgeBase: {
				1: 95,
				subMenus: {
					categories: {
						1: 96,
					},
					topics: {
						1: 98,
					},
				},
			},
			agreementTypes: {
				1: 100,
			},
			boards: {
				1: 167,
			},
		},
	};
	public static readonly Groups: MenuPermission = {
		1: 185,
		2: 186,
		subMenus: {
			assign3: {
				1: 187,
				2: 191,
			},
			application: {
				1: 188,
				2: 192,
			},
			kbTopics: {
				1: 189,
				2: 193,
			},
			ticketBoards: {
				1: 190,
				2: 194,
			},
		},
	};
	public static readonly Company: MenuPermission = {
		3: 55,
		subMenus: {
			contacts: {
				subMenus: {
					directory: {
						3: 56,
					},
					orgChart: {
						3: 171,
					},
				},
			},
			knowledgeBase: {
				3: 57,
			},
			assets: {
				3: 206,
			},
			applications: {
				3: 58,
			},
		},
	};

	public static readonly Contacts: MenuPermission = {
		1: 103,
		2: 135,
		subMenus: {
			contactSetting: {
				1: 104,
				2: 126,
				subMenus: {
					profile: {
						1: 105,
						2: 127,
					},
					groupsAndPermissions: {
						1: 107,
						2: 130,
					},
				},
			},
			requestForms: {
				1: 109,
				2: 131,
			},
			boards: {
				1: 110,
				2: 180,
			},
			applications: {
				1: 113,
				2: 132,
			},
			topics: {
				1: 115,
				2: 134,
			},
		},
	};

	public static readonly GroupsAndPermissions: MenuPermission = {
		1: 9,
		2: 53,
	};

	public static readonly RolesAndPermissions: MenuPermission = {
		// 1: 201,
		// 2: 200,
		1: 9,
		2: 53,
	};

	public static readonly Message: MenuPermission = {
		3: 59,
	};

	public static readonly Messages: MenuPermission = {
		1: 10,
		2: 228,
	};

	public static readonly GlobalSettings: MenuPermission = {
		1: 172,
		subMenus: {
			applications: {
				1: 173,
			},
			topics: {
				1: 174,
			},
			forms: {
				1: 175,
			},
		},
	};

	public static readonly Reports: MenuPermission = {
		1: 30,
		2: 46,
		subMenus: {
			portalUsage: {
				1: 252,
			},
			finance: {
				1: 253,
			},
			userLogin: {
				1: 254,
			},
			emailNotification: {
				1: 255,
			},
			netsuiteCredits: {
				1: 256,
			},
			auditTrail: {
				1: 257,
			},
			autopay: {
				1: 258,
			},
			userPermissions: {
				1: 357,
			},
		},
	};

	public static readonly QuoteAndOrders: MenuPermission = {
		3: 64,
		subMenus: {
			quotes: {
				3: 66,
			},
			orders: {
				3: 67,
			},
			invoices: {
				3: 69,
			},
		},
	};

	public static readonly BillingOrders: MenuPermission = {
		1: 243,
		subMenus: {
			invoices: {
				1: 244,
			},
		},
	};

	public static readonly UserSettings: MenuPermission = {
		3: 165,
		subMenus: {
			profile: {
				3: 166,
			},
		},
	};

	public static readonly ChatSupport: MenuPermission = {
		3: 164,
	};

	public static readonly ContactSupport: MenuPermission = {
		1: 197,
		2: 198,
		3: 163,
	};

	public static readonly Invoices: MenuPermission = {
		3: 69,
	};

	public static readonly Agreements: MenuPermission = {
		2: 54,
	};

	public static readonly OrderStatus: MenuPermission = {
		1: 181,
	};

	public static readonly FeedbackSuggestion: MenuPermission = {
		1: 195,
	};

	public static readonly TicketStatus: MenuPermission = {
		1: 204,
	};

	public static readonly QuoteStatus: MenuPermission = {
		1: 207,
	};

	public static readonly TermsOfUse: MenuPermission = {
		1: 241,
	};

	public static readonly Integration: MenuPermission = {
		1: 222,
		subMenus: {
			rewst: {
				1: 223,
			},
			stripePayment: {
				1: 224,
			},
			connectWise: {
				1: 338,
			},
		},
	};

	public static readonly ApplicationCatalog: MenuPermission = {
		1: 208,
	};

	public static readonly InvoiceStatus: MenuPermission = {
		1: 208,
	};

	public static readonly DashboardSetup: MenuPermission = {
		1: 179,
	};

	public static readonly Inventory: MenuPermission = {
		3: 205,
		subMenus: {
			assets: {
				3: 206,
			},
		},
	};

	public static readonly System: MenuPermission = {
		1: 336,
		2: 347,
		subMenus: {
			versionUpdates: {
				1: 337,
			},
			siteMaintenance: {
				1: 351,
			},
			featureCampaigns: {
				1: 354,
			},
		},
	};

	public static readonly EmailTemplate: MenuPermission = {
		1: 355,
	};

	public static readonly TemplateStyles: MenuPermission = {
		1: 358,
		subMenus: {
			fonts: {
				1: 361,
			},
			buttons: {
				1: 362,
			},
			margins: {
				1: 363,
			},
			variables: {
				1: 364,
			},
		},
	};

	public static readonly Communications: MenuPermission = {
		3: 365
	};
}
