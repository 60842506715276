import { createAction, props } from '@ngrx/store';
import * as i from '../portal-usage.interface';
import { IDropdown } from '@app/shared/constants';

export class ActionTypes {
	LOAD_MONTHLY_REPORT_PAGE = '[Portal Usage] Load Monthly Report Page';

	UPDATE_YEAR_MONTH = '[Portal Usage] Update Year Month';
	UPDATE_COMPANY_ID = '[Portal Usage] Update Company Id';
	UPDATE_YEAR_MONTH_COMPANYID = '[Portal Usage] Update Year Month Company Id';
	UPDATE_USER_ID = '[Portal Usage] Update User Id';
	UPDATE_YEAR_MONTH_USERID = '[Portal Usage] Update Year Month User Id';
	UPDATE_USER_DROPDOWN_PARAMS = '[Portal Usage] Update User Dropdown Params';
	UPDATE_START_END_DATE = '[Portal Usage] Update Start End Date';
	UPDATE_PORTAL_FILTERS = '[Portal Usage] Update Portal Filters';
	UPDATE_PORTAL_FILTERS_DETAILS =
		'[Portal Usage] Update Portal Filters Details';
	UPDATE_LOGIN_REPORT_SUMMARY_LIST =
		'[Portal Usage] Update Login Report Summary List';
	UPDATE_LOGIN_REPORT_DETAILS_LIST =
		'[Portal Usage] Update Login Report Details List';
	// since its too much boilerplate
	UPDATE_GENERAL_PROPERTIES = '[Portal Usage] Update General Properties';

	FETCH_MONTHLY_REPORT = '[Portal Usage] Fetch Monthly Report';
	FETCH_MONTHLY_REPORT_SUCCESS = '[Portal Usage] Fetch Monthly Report Success';
	FETCH_COMPANY_REPORT = '[Portal Usage] Fetch Company Report'; // for table list
	FETCH_COMPANY_REPORT_SUCCESS = '[Portal Usage] Fetch Company Report Success';
	FETCH_COMPANY_REPORT_TOP_5 = '[Portal Usage] Fetch Company Report Top 5';
	FETCH_COMPANY_REPORT_TOP_5_SUCCESS =
		'[Portal Usage] Fetch Company Report Top 5 Success';
	FETCH_COMPANY_REPORT_LEAST_5 = '[Portal Usage] Fetch Company Report Least 5';
	FETCH_COMPANY_REPORT_LEAST_5_SUCCESS =
		'[Portal Usage] Fetch Company Report Least 5 Success';

	FETCH_USERS_ACTIVITY = '[Portal Usage] Fetch Users Activity';
	FETCH_USERS_ACTIVITY_SUCCESS = '[Portal Usage] Fetch Users Activity Success';
	FETCH_USERS_ACTIVITY_GRAPH = '[Portal Usage] Fetch Users Activity Graph';
	FETCH_USERS_ACTIVITY_GRAPH_SUCCESS =
		'[Portal Usage] Fetch Users Activity Graph Success';

	FETCH_COMPANY_LIST_REPORT = '[Portal Usage] Fetch Company List Report';
	FETCH_COMPANY_LIST_REPORT_SUCCESS =
		'[Portal Usage] Fetch Company List Report Success';
	FETCH_COMPANY_DATA = '[Portal Usage] Fetch Company Data';
	FETCH_COMPANY_DATA_SUCCESS = '[Portal Usage] Fetch Company Data Success';
	FETCH_USER_DETAILS = '[Portal Usage] Fetch User Details';
	FETCH_USER_DETAILS_SUCCESS = '[Portal Usage] Fetch User Details Success';
	FETCH_USER_ACTIVITY_HISTORY = '[Portal Usage] Fetch User Activity History';
	FETCH_USER_ACTIVITY_HISTORY_SUCCESS =
		'[Portal Usage] Fetch User Activity History Success';
	FETCH_DAILY_REPORT = '[Portal Usage] Fetch Daily Report';
	FETCH_DAILY_REPORT_SUCCESS = '[Portal Usage] Fetch Daily Report Success';
	FETCH_LOGIN_REPORT_SUMMARY = '[Portal Usage] Fetch Login Report Summary';
	FETCH_LOGIN_REPORT_SUMMARY_SUCCESS =
		'[Portal Usage] Fetch Login Report Summary Success';
	FETCH_LOGIN_REPORT_DETAIL = '[Portal Usage] Fetch Login Report Detail';
	FETCH_LOGIN_REPORT_DETAIL_SUCCESS =
		'[Portal Usage] Fetch Login Report Detail Success';
	FETCH_COMPANY_DROPDOWN = '[Portal Usage] Fetch Company Dropdown';
	FETCH_COMPANY_DROPDOWN_SUCCESS =
		'[Portal Usage] Fetch Company Dropdown Success';
	FETCH_USER_DROPDOWN = '[Portal Usage] Fetch User Dropdown';
	FETCH_USER_DROPDOWN_SUCCESS = '[Portal Usage] Fetch User Dropdown Success';
	FETCH_DAU_TYPE_DROPDOWN = '[Portal Usage] Fetch DAU Type Dropdown';
	FETCH_DAU_TYPE_DROPDOWN_SUCCESS =
		'[Portal Usage] Fetch DAU Type Dropdown Success';
}

const action = new ActionTypes();

/* export const loadMonthlyUsageAction = createAction(
  action.LOAD_MONTHLY_REPORT_PAGE
) */

export const fetchMonthlyUsageAction = createAction(
	action.FETCH_MONTHLY_REPORT,
	props<{ id: number }>()
);

export const fetchMonthlyUsageSuccessAction = createAction(
	action.FETCH_MONTHLY_REPORT_SUCCESS,
	props<{
		monthlyReport: i.MonthlyReportUsage[];
		currentMonthActivity: number;
		currentMonthUsers: number;
		pastWeekActivity: number;
		pastWeekUsers: number;
		todayActivity: number;
		todayUsers: number;
		todayNewUsers: number;
		pastWeekNewUsers: number;
		currentMonthNewUsers: number;
	}>()
);

export const fetchCompanyUsageAction = createAction(
	action.FETCH_COMPANY_REPORT,
	props<{ id: number }>()
);
export const fetchCompanyUsageSuccessAction = createAction(
	action.FETCH_COMPANY_REPORT_SUCCESS,
	props<{ company: i.iListPage }>()
);

export const fetchCompanyReportTop5Action = createAction(
	action.FETCH_COMPANY_REPORT_TOP_5
);
export const fetchCompanyReportTop5SuccessAction = createAction(
	action.FETCH_COMPANY_REPORT_TOP_5_SUCCESS,
	props<{ company: i.iListPage }>()
);

export const fetchCompanyReportLeast5Action = createAction(
	action.FETCH_COMPANY_REPORT_LEAST_5
);
export const fetchCompanyReportLeast5SuccessAction = createAction(
	action.FETCH_COMPANY_REPORT_LEAST_5_SUCCESS,
	props<{ company: i.iListPage }>()
);

export const updateYearMonthAction = createAction(
	action.UPDATE_YEAR_MONTH,
	props<{ year: number; month: number }>()
);

export const fetchCompanyListUsageAction = createAction(
	action.FETCH_COMPANY_LIST_REPORT
);

export const fetchCompanyListUsageSuccessAction = createAction(
	action.FETCH_COMPANY_LIST_REPORT_SUCCESS,
	props<{ companyListReport: i.CompanyListReportUsage[] }>()
);

export const updateCompanyIdAction = createAction(
	action.UPDATE_COMPANY_ID,
	props<{ companyId: number }>()
);

export const fetchCompanyDataAction = createAction(
	action.FETCH_COMPANY_DATA,
	props<{ companyId: number }>()
);

export const fetchCompanyDataSuccessAction = createAction(
	action.FETCH_COMPANY_DATA_SUCCESS,
	props<{ companyData: i.CompanyData }>()
);

export const updateYearMonthCompanyIdAction = createAction(
	action.UPDATE_YEAR_MONTH_COMPANYID,
	props<{ year: number; month: number; companyId: number }>()
);

export const fetchUsersActivityAction = createAction(
	action.FETCH_USERS_ACTIVITY
);
export const fetchUsersActivitySuccessAction = createAction(
	action.FETCH_USERS_ACTIVITY_SUCCESS,
	props<{ usersActivity: i.iListPage }>()
);
export const fetchUsersActivityGraphAction = createAction(
	action.FETCH_USERS_ACTIVITY_GRAPH
);
export const fetchUsersActivityGraphSuccessAction = createAction(
	action.FETCH_USERS_ACTIVITY_GRAPH_SUCCESS,
	props<{ usersActivity: i.iListPage }>()
);

export const fetchUserDetailsAction = createAction(
	action.FETCH_USER_DETAILS,
	props<{ userId: number }>()
);

export const fetchUserDetailsSuccessAction = createAction(
	action.FETCH_USER_DETAILS_SUCCESS,
	props<{ userDetails: i.UserDetails }>()
);

export const fetchUserActivityHistoryAction = createAction(
	action.FETCH_USER_ACTIVITY_HISTORY
);

export const fetchUserActivityHistorySuccessAction = createAction(
	action.FETCH_USER_ACTIVITY_HISTORY_SUCCESS,
	props<{ userActivityHistory: i.iListPage }>()
);

export const updateUserIdAction = createAction(
	action.UPDATE_USER_ID,
	props<{ userId: number }>()
);

export const updateYearMonthUserIdAction = createAction(
	action.UPDATE_YEAR_MONTH_USERID,
	props<{ year: number; month: number; userId: number }>()
);

export const fetchDailyUsageAction = createAction(action.FETCH_DAILY_REPORT);

export const fetchDailyUsageSuccessAction = createAction(
	action.FETCH_DAILY_REPORT_SUCCESS,
	props<{
		dailyUsage: i.CompanyReportUsage[];
		todayActivity: number;
		todayUsers: number;
	}>()
);

export const fetchLoginReportSummary = createAction(
	action.FETCH_LOGIN_REPORT_SUMMARY
);

export const fetchLoginReportSummarySuccess = createAction(
	action.FETCH_LOGIN_REPORT_SUMMARY_SUCCESS,
	props<{
		iListPage: i.iListPage;
	}>()
);

export const fetchLoginReportDetail = createAction(
	action.FETCH_LOGIN_REPORT_DETAIL
);

export const fetchLoginReportDetailSuccess = createAction(
	action.FETCH_LOGIN_REPORT_DETAIL_SUCCESS,
	props<{
		iListPage: i.iListPage;
	}>()
);

export const fetchCompanyDropdown = createAction(action.FETCH_COMPANY_DROPDOWN);

export const fetchCompanyDropdownSuccess = createAction(
	action.FETCH_COMPANY_DROPDOWN_SUCCESS,
	props<{
		companyDropdown: IDropdown[];
	}>()
);

export const fetchUserDropdown = createAction(
	action.FETCH_USER_DROPDOWN,
	props<{ companyId: number; query: string }>()
);

export const fetchUserDropdownSuccess = createAction(
	action.FETCH_USER_DROPDOWN_SUCCESS,
	props<{
		usersDropdown: IDropdown[];
	}>()
);

export const updateUserDropdownParams = createAction(
	action.UPDATE_USER_DROPDOWN_PARAMS,
	props<{
		companyIdDropdown: number;
		userQuery: string;
	}>()
);

export const fetchDAUTypeDropdown = createAction(
	action.FETCH_DAU_TYPE_DROPDOWN
);
export const fetchDAUTypeDropdownSuccess = createAction(
	action.FETCH_DAU_TYPE_DROPDOWN_SUCCESS,
	props<{
		DAUType: i.DAUTypeDropdown[];
	}>()
);

export const updateStartEndDate = createAction(
	action.UPDATE_START_END_DATE,
	props<{
		fromDate: string | null;
		toDate: string | null;
	}>()
);

export const updateUserLoginFilters = createAction(
	action.UPDATE_PORTAL_FILTERS,
	props<{ userLoginFilters: Partial<i.UserLoginFilters> }>()
);
export const updateUserLoginFiltersDetails = createAction(
	action.UPDATE_PORTAL_FILTERS_DETAILS,
	props<{ userLoginFiltersDetails: Partial<i.UserLoginFilters> }>()
);

// these two should be removed?
export const updateLoginReportSummaryList = createAction(
	action.UPDATE_LOGIN_REPORT_SUMMARY_LIST,
	props<{ loginReportSummaryList: Partial<i.iListPage> }>()
);
export const updateLoginReportDetailsList = createAction(
	action.UPDATE_LOGIN_REPORT_DETAILS_LIST,
	props<{ loginReportDetailsList: Partial<i.iListPage> }>()
);

export const updateGeneralProperties = createAction(
	action.UPDATE_GENERAL_PROPERTIES,
	props<Partial<i.PortalUsageStore>>()
);
