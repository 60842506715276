<div class="card-2 q-mb-24">
	<div class="card-header-2 q-ai-center">
			<h3 class="q-mr-auto">Manage Users</h3>

			<app-parent-child-input-selector
				*ngIf="(isSPAdmin || isParentCompany) && companyUrl"
				[url]="companyUrl"
				[placeholder]="isSPAdmin ? 'Select Company' : 'Select Child Company'"
				[parentPlaceholder]="isSPAdmin ? 'View all Companies' : 'View as Company'"
				[formControl]="companyControl"
			></app-parent-child-input-selector>

			<!-- <button
			*ngIf="isAddUserEnabled"
			[disabled]="!companyId"
			class="btn-2 btn-2-primary imp-disabled-op"
			routerLink="/companies/contact-setting/directory/{{companyId}}/add">
				Add New Users
			</button> -->

			<div class="resp-item dropright">
				<button
				class="btn-2 btn-2-primary imp-disabled-op resp-item"
				id="export"
				data-bs-toggle="dropdown"
				aria-expanded="false"
				#exportEl>
					Export As
					<mat-icon [svgIcon]="exportEl.classList.contains('show') ? 'chevron-up' : 'chevron-down'"></mat-icon>
				</button>
				<ul class="dropdown-menu dropdown-menu-right q-shadow-1" aria-labelledby="export">
					<li><a class="dropdown-item" (click)="export(applicationType.CSV)">CSV file</a></li>
					<li><a class="dropdown-item" (click)="export(applicationType.EXCEL)">Excel file</a></li>
				</ul>
			</div>

      <app-secondary-button
        [isBackButton]="true"
        [link]="'/roles-and-permissions'"
        [removeMsAuto]="true"
				class="for-main"
      ></app-secondary-button>
	</div>

	<div class="role-card card-2 font-b3">
		<h3>{{role ? role.name : 'Role'}}</h3>
		{{role ? role.description : ''}}
	</div>

	<div class="actions-wrapper q-ai-center q-g-24">
		<tip name="manageRolesUsers.drag_drop_tip" message="You can also drag & drop users between lists!"></tip>

		<div class="actions-wrapper">
			<button
			class="btn-2 btn-2-success imp-disabled-op"
			(click)="list.addSelectedItems()"
			[disabled]="!selectedAvailableCount || isSaving">
				Add ({{selectedAvailableCount || '#'}}) Selected Users
			</button>

			<button
			class="btn-2 btn-2-destructive imp-disabled-op"
			(click)="list.removeSelectedItems()"
			[disabled]="!selectedRoleUsersCount || isSaving">
				Remove ({{selectedRoleUsersCount || '#'}}) Selected Users
			</button>
		</div>
	</div>

	<lists-drag-drop-input
		#list
		[formControl]="listControl"
		[data]="listData"
		(checkedSelectedChange)="selectedRoleUsersCount = $event.length"
		(checkedAvailableChange)="selectedAvailableCount = $event.length"
		(onItemsAdded)="onUsersAdded($event)"
		(onItemsRemoved)="onUsersRemoved($event)"
		(scrolledMax)="onScroll()"
		(scrolledMaxSelected)="onScrollAccess()">
			<div class="list-wrapper q-d-flex q-g-24">
				<search-input
					[formControl]="searchControl"
					[withDropdown]="true"
					placeholder="Search Users by Name, Email, {{!companyId ? 'Company, ' : ''}}Department"
					maxlength="100"
					[autoFocus]="false"
					class="q-mr-auto"
				>
					<div [formGroup]="filter" class="q-d-flex q-g-16 q-f-wrap">
						<div class="search-tag font-b2-5" [class.active-tag]="filter.controls.isBillingContact.value">
							Bill To CONTACT
							<input type="checkbox" formControlName="isBillingContact">
						</div>

						<div class="search-tag font-b2-5" [class.active-tag]="filter.controls.isDefaultContact.value">
							IMPORTANT CC
							<input type="checkbox" formControlName="isDefaultContact">
						</div>
					</div>
				</search-input>

				<search-input
					[formControl]="searchControl2"
					[withDropdown]="true"
					placeholder="Search Users by Name, Email, {{!companyId ? 'Company, ' : ''}}Department"
					maxlength="100"
					[autoFocus]="false"
					class="q-mr-auto"
				>
					<div [formGroup]="filter2" class="q-d-flex q-g-16 q-f-wrap">
						<div class="search-tag font-b2-5" [class.active-tag]="filter2.controls.isBillingContact.value">
							Bill To CONTACT
							<input type="checkbox" formControlName="isBillingContact">
						</div>

						<div class="search-tag font-b2-5" [class.active-tag]="filter2.controls.isDefaultContact.value">
							IMPORTANT CC
							<input type="checkbox" formControlName="isDefaultContact">
						</div>
					</div>
				</search-input>
			</div>
	</lists-drag-drop-input>

	<div class="actions-wrapper">
    <app-tertiary-button
			[btnText]="'Save for Later'"
			(click)="save(false)"
      [disabled]="isSaving || isLoading"
      [isProcessing]="isSaving"
		>
		</app-tertiary-button>

		<button class="btn-2 btn-2-primary imp-disabled-op" [disabled]="isSaving || isLoading" (click)="save()">Save & Finish</button>
	</div>
</div>

<app-audit-trail
  [id]="id"
  [url]="historyUrl.ROLES_AND_PERMISSIONS_V2_MANAGE_USER">
</app-audit-trail>