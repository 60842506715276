import { Component, OnInit, inject } from '@angular/core';
import { ModulesPermission2 } from '@app/modules/groups-and-permissions';
import { RolesAndPermissionService } from '@app/modules/groups-and-permissions/roles-and-permission.service';

@Component({
	selector: 'module-list-component',
	templateUrl: './module-list.component.html',
	styleUrls: ['./module-list.component.scss'],
})
export class ModuleListComponent implements OnInit {
	//inject
	rolesAndPermission = inject(RolesAndPermissionService);

	//props
	activeIndex = 0;
	constructor() {}

	ngOnInit() {}

	selectModule(module: ModulesPermission2, index: number) {
		this.activeIndex = index;
		this.rolesAndPermission.setStateMenuIndex(index);
		this.rolesAndPermission.setStateIsEditPageLoading(true);
		this.rolesAndPermission.setModule(module);
	}
}
