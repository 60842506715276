import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';

import { BehaviorSubject, combineLatest, Subscription, take } from 'rxjs';

import {
	ModulePages2,
	ModulesPermission2,
} from '@app/modules/groups-and-permissions';

import { RolesAndPermissionService } from '@app/modules/groups-and-permissions/roles-and-permission.service';
import { select, Store } from '@ngrx/store';
import { isProductDevelopmentSelector } from '@app/core/store/user/user.selector';

@Component({
	selector: 'module-permission-component',
	templateUrl: './module-permission.component.html',
	styleUrls: ['./module-permission.component.scss'],
})
export class ModulePermissionComponent implements OnInit, OnDestroy {
	//inject
	rolesAndPermission = inject(RolesAndPermissionService);

	//props
	prop = '';
	switch: boolean = false;
	activeModule$ = new BehaviorSubject<ModulesPermission2 | null>(null);
	pages$ = new BehaviorSubject<ModulePages2[] | null>(null);
	userId: number;
	companyId: number;
	isCascade = true;
	store = inject(Store);
	showOrangeDot: boolean = false;
	private _route = inject(ActivatedRoute);
	private _router = inject(Router);
	private subs = new Subscription();

	// getters
	get isViewMode() {
		return this.rolesAndPermission.modeState === 'view';
	}
	get isEditMode() {
		return this.rolesAndPermission.modeState === 'edit';
	}
	get isViewClass() {
		return this.isViewMode ? 'no-cursor' : '';
	}

	get isAllowCascade() {
		if (
			(this.rolesAndPermission.modeState === 'company-override' ||
				this.rolesAndPermission.modeState === 'override') &&
			this.isCascade
		) {
			return true;
		} else if (
			this.rolesAndPermission.modeState === 'add' ||
			this.rolesAndPermission.modeState === 'edit'
		) {
			return true;
		} else {
			return false;
		}
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit() {
		combineLatest([this.store.pipe(select(isProductDevelopmentSelector))])
			.pipe(take(1))
			.subscribe(([data1]) => {
				const isProductDevelopmentRole = data1;
				this.showOrangeDot =
					this.rolesAndPermission.modeState === 'override' &&
					isProductDevelopmentRole;
			});
		this.rolesAndPermission.roleHeader$.subscribe(console.log);
		const snapshot = this._route.snapshot;
		this.userId = Number(snapshot.params['userId']);
		this.companyId = Number(snapshot.params['companyId']);
		this.subs.add(
			this.rolesAndPermission.activeModule$.subscribe((v) =>
				this.activeModule$.next(v)
			)
		);
		this.subs.add(
			this.rolesAndPermission.pages$.subscribe((v) => {
				//console.log('pages', v);
				let pages: any = v;
				if (this.rolesAndPermission.withCompany)
					pages = v?.map((x) => {
						x.companyId = this.rolesAndPermission.moduleCompanyId!;
						return x;
					});
				this.pages$.next(pages);
				this._initAllAccess(pages);
			})
		);

		this.subs.add(
			this.rolesAndPermission.isCascade$.subscribe((v) => (this.isCascade = v))
		);
	}

	//public method
	toggleAccess(page: ModulePages2) {
		if (this.isViewMode || !this.isAllowCascade) return;
		this.rolesAndPermission.updateCurrentPage(page);
	}

	toggleCheckbox(page: ModulePages2, actionId: number) {
		if (this.isViewMode || !this.isAllowCascade) return;
		this.rolesAndPermission.updateCurrentPageCheckbox(page, actionId);
	}

	switchOnOff() {
		if (this.isViewMode || !this.isAllowCascade) return;

		let enableDisable = this.switch;
		if (enableDisable) {
			this._setAllAccess(false);
		} else {
			this._setAllAccess(true);
		}

		this.switch = !enableDisable;
	}

	getManageAccessUrl(url: string, id: number = 0) {
		let finalUrl = '';
		if (this.rolesAndPermission.modeState === 'company-override') {
			const type = url.split('/')[3];
			finalUrl =
				this._router.routerState.snapshot.url
					.replace('edit', 'manage')
					.replace('add', `manage/${this.companyId}`) + `/${type}`;
		} else {
			finalUrl = `/roles-and-permissions${url}`;
			id ? (finalUrl += `/${id}`) : '';
		}

		return finalUrl;
	}

	//private method
	private _initAllAccess(pages: ModulePages2[] | null) {
		// if all are active / checked, switch "All Access" to enabled
		let enableDisable = true;
		if (pages) {
			pages.map((v) => {
				if (v.isEnableAccess) {
					v.menuActions.map((x) => {
						if (!x.isEnableAccess) {
							enableDisable = false;
						}
					});
				} else {
					enableDisable = false;
				}
			});

			this.switch = enableDisable;
		}
	}

	private _setAllAccess(access: boolean) {
		this.rolesAndPermission.updateAllAccess(access);
	}
}
