import { IPageState } from './page-state.interface';
export interface GenericDropdown {
	id: number;
	name: string;
}

export interface GenericDropdownWithEmail extends GenericDropdown {
	email: string;
}

export interface IFigmaIcon {
	name: string;
	iconUrl: string;
}

export interface IGenericInterface extends IPageState {
	userRole?: number;
	companyId?: number | null;
	isFilterCompany?: boolean;
	territoryId?: number;
	filterByCompanyId?: number;
}
