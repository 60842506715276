<button
	*ngIf="!isFilterToggle"
	type="button"
	class="btn-2-tertiary"
	[ngClass]="{
		'btn-2-tab': isTab,
		'btn-2': !isTab,
		'imp-disabled-op': !isTab,
		isActive: isActiveTabOrButton,
		'is-selected': isSelected
	}"
	[disabled]="disabled"
>
	<mat-icon
		*ngIf="!isProcessing && iconName && isIconLeft"
		[svgIcon]="iconName"
	></mat-icon>

	<span *ngIf="!isProcessing">
		{{ btnText }}
	</span>

	<mat-icon
		*ngIf="!isProcessing && iconName && !isIconLeft"
		[svgIcon]="iconName"
	></mat-icon>

	<span *ngIf="isProcessing">...Processing</span>
</button>

<a
	*ngIf="isFilterToggle"
	href="#demo"
	class="btn-2 btn-2-tertiary collapsed"
	data-bs-toggle="collapse"
	aria-controls="demo"
	role="button"
	aria-expanded="false"
	#filterEl
	(click)="onUpdateChevronIcon()"
>
	<span *ngIf="isFilterOn" class="filter-indicator"></span>

	{{ btnText ? btnText : 'Filter' }}
	<mat-icon
		[svgIcon]="
			filterEl.classList.contains('collapsed') ? 'chevron-down' : 'chevron-up'
		"
		[ngStyle]="{
			'margin-bottom': marginBottom(filterEl.classList.contains('collapsed'))
		}"
	></mat-icon>
</a>
