<div class="company-view">

	<div class="company-view__right">
		<div class="company-view__right--label-group">
			<div
				class="company-view__right--label-company cursor-pointer position-relative"
				[class.selected-menu]="isViewCompany"
				(click)="onClickMenu(true)"
			>
				<app-figma-icon-img
					[iconName]="'eye'"
					[hasSpaceOnRight]="false"
					[isForButton]="true"
				></app-figma-icon-img>

				<span> View as Company </span>
			</div>

      <div
        (click)="clickCompanyDropdown()"
        class="company-view__right--select cursor-pointer position-relative"
        [class.selected-menu]="!isViewCompany">
        <span>{{ companyName ? companyName : isAdmin ? 'Search Companies' : 'Select Child Companies' }}</span>
      </div>
    </div>

    <div id="company-menu-form">
      <div class="form-relative">
        <div class="search-icon-pos">
          <app-figma-icon-img
            [iconName]="'search-sm'"
            [isForButton]="false"
            [hasSpaceOnRight]="false"
          ></app-figma-icon-img>
        </div>

        <ng-container *ngIf="isAdmin; else ChildCompany">
          <company-dropdown [hasClear]="false" [placeholder]="'Search Companies'" (onBlurForm)="onBlur()"></company-dropdown>
        </ng-container>

        <ng-template #ChildCompany>
          <app-child-company-dropdown (onBlurForm)="onBlur()"></app-child-company-dropdown>
        </ng-template>
      </div>
    </div>

  </div>
</div>
