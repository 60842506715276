<div class="module-list">
	<a
		class="btn-2-nav sidebar-btn q-pointer"
		*ngFor="let module of rolesAndPermission.modules2$ | async; let i = index"
		[ngClass]="{
			'active-2': activeIndex === i
		}"
		(click)="selectModule(module, i)"
	>
		<app-figma-icon-img
			[iconName]="module.icon!"
			[hasSpaceOnRight]="false"
		></app-figma-icon-img>
		<!-- [iconName]="module.icon" -->

		{{ module.name }}
	</a>
</div>
