<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header pb-0">
        <div class="d-lg-flex">
          <h5 class="mb-0">Manage User: {{groupName}}</h5>
        </div>

        <app-secondary-button
          [isBackButton]="true"
          (click)="goBack()"
        ></app-secondary-button>
      </div>
      <div class="manage-group">
        <div class="editapplication-wrap portal-form">
          <div class="row">
            <div class="col-lg-12">
              <div class="cus-field cat-select">
                <multi-select-input
                  class="form-control imp-disabled-bg"
                  [options]="userGroups"
                  [(ngModel)]="userIds"
                  [placeholder]="'Select Users'"
                  [allowSelectAll]="userGroups.length !== 0 ? true : false"
                  [disabled]="!groupId"
                  [ngClass]="[!groupId ? 'disabled' : '']"
                >
                </multi-select-input>
              </div>

            </div>
          </div>
          <div>
            <button (click)="saveGroups()" class="btn-2 btn-2-primary d-inline imp-disabled-op imp-disabled-op"
            >Save</button>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
