import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss'],
  standalone: true,
  imports: [MatIconModule, CommonModule],
})
export class TipComponent implements OnInit {
  @Input() name = 'tip'; // Use unique name for localstorage
  @Input() label = 'Tip';
  @Input() message: string;
  showTip = false;

  ngOnInit() {
    this.showTip = !localStorage.getItem(this.name);
  }

  closeTip() {
		localStorage.setItem(this.name, '1');
		this.showTip = false;
	}
}
