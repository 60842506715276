import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class QuestBetaService {
	constructor(private _http: HttpClient) {}

	private _initBetaApi = new BehaviorSubject<boolean>(false);
	initBetaApi = this._initBetaApi.asObservable();

	public isInitializeBetaApi(isInitialize: boolean) {
		this._initBetaApi.next(isInitialize);
	}

	getBeta() {
		return this._http.get<any[]>(`${environment.apiBaseUrl}Beta`);
	}

	postBeta(body: any) {
		return this._http.post<any[]>(`${environment.apiBaseUrl}Beta`, body);
	}
}
