export const alphabet = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
];

export const PaginationConstants = {
	short: [10],
	medium: [10, 20],
	pageSizes: [10, 20, 50],
	abovePageSizes: [10, 20, 50, 150],
	longPageSizes: [10, 20, 50, 150, 200],
	pageSize: 10,
};

// Client admin
import { MenuInterface } from '../interfaces/menu.interface';
// To delete
export const CLIENTADMIN_MENU: MenuInterface[] = [
	{
		name: 'Company',
		link: '',
		icon: '',
		children: [
			{ name: 'Contacts', link: '/company/contacts' },
			{ name: 'Knowledge Base', link: '/company/kb' },
			{ name: 'Applications', link: '/company/applications' },
		],
	},
	{
		name: 'Message',
		link: '/client-message',
		icon: '',
		children: [],
	},
	{
		name: 'Support',
		link: '',
		icon: '',
		children: [
			{ name: 'Service Request', link: '/support/service-request' },
			{ name: 'Support Request', link: '/support/support-request' },
		],
	},
	{ name: 'Ticket', link: '/ticket', icon: '', children: [] },
	{
		name: 'Quotes and Orders',
		icon: '',
		link: '',
		children: [
			{ name: 'Quotes', link: '/quotes-and-orders/quotes' },
			{ name: 'Orders', link: '/quotes-and-orders/orders' },
		],
	},
	{
		name: 'Finance',
		link: '',
		icon: '',
		children: [{ name: 'Invoice', link: '/finance/invoices' }],
	},
	{ name: 'Report', link: '/report', icon: '', children: [] },
	{
		name: 'Admin',
		link: '',
		icon: '',
		children: [
			{ name: 'Manage Categories', link: '/admin/categories' },
			{ name: 'Manage Topics', link: '' },
			{ name: 'Organization', link: '' },
			{ name: 'Manage Application', link: '/application/applications-list' },
			{ name: 'Roles and Permissions', link: '/roles-and-permissions' },
			{ name: 'Agreements', link: '' },
		],
	},
];

// Dashboard
import { DashboardCategoryDropdown } from '../interfaces/dashboard.interface';
import { NewTicket } from './global-class';

export const CONTENT_TYPE_ID_DATA = [
	// map this to the proper service/ api endpoint / constant later
	{ id: 1, label: 'Widget' },
	{ id: 2, label: 'Internal Link' },
	{ id: 3, label: 'External Link' },
];
export const CONTENT_ID_DATA = [
	// map this to the proper service / api call later
	{ id: 1, name: 'Widget 1' },
	{ id: 2, name: 'Widget 2' },
	{ id: 3, name: 'Widget 3' },
	{ id: 0, name: 'Widget 0 ID' },
];

export const INTERNAL_LINK_DATA = [
	// map this somewhere
	{ value: NewTicket.url, label: 'Create Ticket' },
	{ value: '/internal/link2', label: 'View Report' },
	{ value: '/internal/link3', label: 'Knowledge Base List' },
	{ value: '/internal/link4', label: 'Application List' },
];

export const ROLE_ID_DATA: Array<{ id: number; name: string }> = [
	{ id: 3, name: 'User' },
	{ id: 2, name: 'Client Admin' },
	{ id: 1, name: 'Sourcepass Admin' },
];

export const CATEGORY_ID_DATA: DashboardCategoryDropdown[] = [
	{ id: 1, name: 'Ticket' },
	{ id: 2, name: 'External' },
	{ id: 3, name: 'Security' },
];

// Dashboard 2
import { AcronisBackupAllocationComponent } from '@app/modules/dashboard/pages/dashboard/widgets/acronis-backup-allocation/acronis-backup-allocation.component';
import { AcronisBackupStatusComponent } from '@app/modules/dashboard/pages/dashboard/widgets/acronis-backup-status/acronis-backup-status.component';
import { AcronisCloudBackupComponent } from '@app/modules/dashboard/pages/dashboard/widgets/acronis-cloud-backup/acronis-cloud-backup.component';
import { ApplicationWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/application-widget/application-widget.component';
import { AssetInformationWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/asset-information-widget/asset-information-widget.component';
import { CreateTicketComponent } from '@app/modules/dashboard/pages/dashboard/widgets/create-ticket/create-ticket.component';
import { CurrentOpenOrderComponent } from '@app/modules/dashboard/pages/dashboard/widgets/current-open-order/current-open-order.component';
import { FeedbackSuggestionComponent } from '@app/modules/dashboard/pages/dashboard/widgets/feedback-suggestion/feedback-suggestion.component';
import { GraphApiWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/graph-api-widget/graph-api-widget.component';
import { KbWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/kb-widget/kb-widget.component';
import { Kb4trainingWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/kb4training-widget/kb4training-widget.component';
import { MailboxUsageComponent } from '@app/modules/dashboard/pages/dashboard/widgets/mailbox-usage/mailbox-usage.component';
import { OfflineSiteWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/offline-site-widget/offline-site-widget.component';
import { OnSiteTicketComponent } from '@app/modules/dashboard/pages/dashboard/widgets/on-site-ticket/on-site-ticket.component';
import { OneDriveUsageStatComponent } from '@app/modules/dashboard/pages/dashboard/widgets/one-drive-usage/one-drive-usage-stat.component';
import { QuotesWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/quotes-widget/quotes-widget.component';
import { ServiceHealthMicrosoftWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/service-health-microsoft-widget/service-health-microsoft-widget.component';
import { MsTeamsUsageComponent } from '@app/modules/dashboard/pages/dashboard/widgets/teams-usage/ms-teams-usage.component';
import { TicketsWidgetComponent } from '@app/modules/dashboard/pages/dashboard/widgets/tickets-widget/tickets-widget.component';
import { DynamicComponentList } from '../interfaces/dashboard.interface';

export const dynamicComponentList: DynamicComponentList[] = [
	{
		componentId: 33, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 33,
		component: ApplicationWidgetComponent,
	},
	{
		componentId: 35, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 35,
		component: TicketsWidgetComponent,
	},
	{
		componentId: 31, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 31,
		component: CreateTicketComponent,
	},
	{
		componentId: 36, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 36,
		component: FeedbackSuggestionComponent,
	},
	/*   {
    componentId: 11, // note: id and widgetCardListId must be the same atm
    widgetCardListId: 11,
    component: CurrentOpenOrderComponent,
  }, */
	{
		componentId: 32, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 32,
		component: KbWidgetComponent,
	},
	{
		componentId: 34, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 34,
		component: OnSiteTicketComponent,
	},
	/*   {
      componentId:40, // note: id and widgetCardListId must be the same atm
      widgetCardListId:40,
      component:TicketsWidgetComponent,
  },
  {
      componentId:47, // note: id and widgetCardListId must be the same atm
      widgetCardListId:47,
      component:FeedbackSuggestionComponent,
  }, */
	{
		componentId: 37, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 37,
		component: CurrentOpenOrderComponent,
	},
	/*   {
      componentId: 46, // note: id and widgetCardListId must be the same atm
      widgetCardListId: 46,
      component: OnSiteTicketComponent,
  }, */
	{
		componentId: 41, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 41,
		component: TicketsWidgetComponent,
	},
	/*   {
      componentId: 50, // note: id and widgetCardListId must be the same atm
      widgetCardListId: 50,
      component: FeedbackSuggestionComponent,
  }, */
	{
		componentId: 30, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 30,
		component: Kb4trainingWidgetComponent,
	},
	{
		componentId: 49, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 49,
		component: GraphApiWidgetComponent,
	},

	{
		componentId: 48, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 48,
		component: AssetInformationWidgetComponent,
	},

	{
		componentId: 38, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 38,
		component: ServiceHealthMicrosoftWidgetComponent,
	},
	{
		componentId: 17, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 17,
		component: OfflineSiteWidgetComponent,
	},
	{
		componentId: 52, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 52,
		component: MsTeamsUsageComponent,
	},
	{
		componentId: 54, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 54,
		component: OneDriveUsageStatComponent,
	},
	{
		componentId: 53, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 53,
		component: MailboxUsageComponent,
	},
	{
		componentId: 55, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 55,
		component: AcronisBackupStatusComponent,
	},
	{
		componentId: 56, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 56,
		component: AcronisBackupAllocationComponent,
	},
	{
		componentId: 57, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 57,
		component: AcronisCloudBackupComponent,
	},
	{
		componentId: 60, // note: id and widgetCardListId must be the same atm
		widgetCardListId: 60,
		component: QuotesWidgetComponent,
	},
];

// why is there an interface here
export interface IDropdown {
	name: string;
	id: number;
	groupId?: number[];
	checked?: boolean;
	email?: string;
}

import { FontInterface } from '../interfaces/fonts.interface';

export const FONT_STYLE: FontInterface[] = [
	{ value: '"Arial"', name: 'Arial' },
	{ value: '"Arial Black"', name: 'Arial Black' },
	{ value: '"Brush Script MT"', name: 'Brush Script MT' },
	{ value: '"Comic Sans MS"', name: 'Comic Sans MS' },
	{ value: '"Courier New Courier, monospace"', name: 'Courier New Courier' },
	{ value: '"Georgia"', name: 'Georgia' },
	{ value: '"Helvetica Neue, Helvetica,Arial, sans-serif"', name: 'Helvetica' },
	{ value: '"Impact"', name: 'Impact' },
	{ value: '"Lucida Sans Unicode"', name: 'Lucida Sans Unicode' },
	{ value: '"Segoe UI"', name: 'Segoe UI' },
	{ value: '"Tahoma"', name: 'Tahoma' },
	{ value: '"Times New Roman"', name: 'Times New Roman' },
	{ value: '"Trebuchet MS"', name: 'Trebuchet MS' },
	{ value: '"Verdana, Geneva, sans-serif"', name: 'Verdana' },
	{ value: '"Metropolis"', name: 'Metropolis' },
];

// notification
const suffix = '.';

export class NotifOperation {
	public static readonly ADDED = 'added';
	public static readonly UPDATED = 'updated';
	public static readonly DELETED = 'deleted';
	public static readonly ARCHIVED = 'archived';
	public static readonly REMOVED = 'removed';
	public static readonly COPIED = 'copied';
	public static readonly ENABLED = 'enabled';
	public static readonly DISABLED = 'disabled';
	public static readonly MARKREAD = 'mark as read';
	public static readonly SUBMIT = 'submit';
	public static readonly SUBMITTED = 'submitted';
	public static readonly LIKED = 'liked';
	public static readonly DISLIKED = 'disliked';
	public static readonly SENT = 'sent';
	public static readonly SAVED = 'saved';
	public static readonly UNSAVED = 'unsaved';
	public static readonly GENERATED = 'generated';
	public static readonly READ = 'read';
	public static readonly OVERRIDE = 'override';
	public static readonly RELEASED = 'released';
	public static readonly MARK_AS_READ = 'mark as read';
	public static readonly MARK_AS_UNREAD = 'mark as unread';
	public static readonly DUPLICATED = 'duplicated';
}

export class NotifType {
	public static readonly SUCCESS = 'successfully';
}

export enum Modules {
	GROUP = 'Group',
	USER_PERMISSION = 'User permission',
	USER_THEME = 'User Theme',
	TOKEN = 'Token',
	TOPIC = 'Topic',
	KNOWLEDGE_BASE = 'Knowledge base',
	BOT = 'Bot',
	ORDERINQUIRY = 'Order inquiry',
	REPORT = 'Report',
	REQUEST = 'Request',
	MESSAGE = 'Message(s)',
	TWO_FACTOR_AUTH = '2Fa',
	COMPANY = 'Company',
	COMPANY_BRANDING = 'Company Branding',
	CATEGORY = 'Category',
	APPLICATION = 'Application',
	AGREEMENT_TYPE = 'Agreement Type',
	BOARD = 'Board',
	FORM_USAGE = 'Form Usage',
	FORM_USAGE_COMPANY = 'Form Usage for Company',
	EDIT_DIRECTORY = 'User contact',
	FIRST_LOGIN = 'User',
	PROFILE = 'Profile',
	QUICK_ACCESS = 'Quick Access',
	FILE = 'File',
	REQUEST_FORM = 'Request Form',
	FEEDBACK_SUGGESTION = 'Feedback Suggestion',
	DASHBOARD_CARD = 'Dashboard Card',
	DASHBOARD_TAB = 'Dashboard Tab',
	DASHBOARD_VERSION = 'Dashboard Version',
	SERVICE_AND_REQUEST = 'Service and Request',
	ORDER_STATUS = 'Order Status',
	TICKET = 'Ticket',
	MANAGE_INVOICE_STATUS = 'Manage Invoice Status',
	MANAGE_QUOTE_STATUS = 'Manage Quote Status',
	ROLE_INFO = 'Roles and Groups',
	BASIC_INFO = 'Basic Info',
	CONTACT_INFO = 'Contact Info',
	REWST = 'Rewst',
	MY_TICKET_ACCESS = 'My Ticket Access',
	EMAIL = 'Email',
	MESSAGES = 'Messages',
}

export class NotificationMessages {
	// error message
	static message(message: string, withSuffix = true): string {
		return `${message}${withSuffix ? suffix : ''}`;
	}

	// success message
	static success(
		notifOp: NotifOperation | string,
		module: Modules | string = ''
	): string {
		let message = `${NotifType.SUCCESS} ${notifOp}.`; // Sample Output: "successfully added."

		if (module) {
			// if you pass a module then it will add a module name to the sentence
			message = `${module} ${message}`; // Sample Output: "Category successfully added."
		}

		return message;
	}

	// error message
	static error(message: string): string {
		return `Error: ${message}. Please try again.`;
	}

	// failed to message
	static failedTo(message: string): string {
		return `${this.FailedTo} ${message}. Please try again.`;
	}

	// invalid message
	static invalid(message: string): string {
		return `${message} is invalid. Please try again.`;
	}

	// disabled account message
	static disabledAccountFor(time: string): string {
		return `Your account has been disabled for ${time}. Please try again later.`;
	}

	// theme mode message
	static themeMode(mode: string): string {
		const newMode = `${mode.charAt(0).toUpperCase()}${mode.slice(1)}`;

		return `${newMode} mode ${NotifOperation.ENABLED}.`;
	}

	public static readonly Downloading = 'Generating PDF file please wait.';

	public static readonly FailedTo = 'Failed to';
	public static readonly NoRecordFound = 'No Record Found';
	public static readonly FailedToGenerateFile = 'Export Failed.';
	public static readonly FailedToFetchForm =
		'Failed to fetch form. Please try again.';
	public static readonly FailedToFetchForms =
		'Failed to fetch forms. Please try again.';
	public static readonly FailedToFetchAssets =
		'Failed to fetch assets. Please try again.';
	public static readonly FailedToResendToken = 'Failed to resend token.';
	public static readonly SomethingWentWrong = 'Something went wrong.';
	public static readonly UnkownError = 'An unknown error occured.';
	public static readonly ErrorOccured = 'An error has occured.';
	public static readonly LoggedSuccessfully = 'Logged in successfully.';
	public static readonly LoginFailed =
		'Login failed. Please try again or try a different option.';
	public static readonly MicrosoftSignIn = 'Trying to sign you in...';
	public static readonly InactiveAccount = 'Account Not Present.';
	public static readonly ReplySent = 'Your reply has been sent.';
	public static readonly CloseTicket = 'Ticket has been marked as closed.';
	public static readonly MessageSending = 'Your message is sending...';
	public static readonly CopiedClipboard = 'Copied to clipboard.';
	public static readonly CannotConnectToServer =
		'Could not connect to the server. Please check your internet connection.';
	public static readonly NoChangesToApply = 'No changes to apply.';
	public static readonly AlreadyExist = 'Data already exist.';
	public static readonly SwitchToAdmin = 'Switching to Admin Mode...';
	public static readonly SwitchToUser = 'Switching to User Mode...';
	public static readonly NoNotifications = 'No notifications.';
	// Order Status Module Messages
	public static readonly OrderStatusUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.ORDER_STATUS
	);

	// Category Module Messages
	public static readonly CategorySuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.CATEGORY
	);
	public static readonly CategorySuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.CATEGORY
	);
	public static readonly CategorySuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.CATEGORY
	);

	public static readonly FormUsageCompanySuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.FORM_USAGE_COMPANY
	);

	public static readonly RewstSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.REWST
	);

	// Application Module Messages
	public static readonly ApplicationSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.APPLICATION
	);
	public static readonly ApplicationSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.APPLICATION
	);
	public static readonly ApplicationSuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.APPLICATION
	);

	// Dasboard Card Module Messages
	public static readonly DashboardCardSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.DASHBOARD_CARD
	);
	public static readonly DashboardCardSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.DASHBOARD_CARD
	);
	public static readonly DashboardCardSuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.DASHBOARD_CARD
	);

	// Dasboard Tab Module Messages
	public static readonly DashboardTabSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.DASHBOARD_TAB
	);
	public static readonly DashboardTabSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.DASHBOARD_TAB
	);
	public static readonly DashboardTabSuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.DASHBOARD_TAB
	);
	public static readonly DashboardTabSuccessUpdatedOrder = this.success(
		NotifOperation.SAVED,
		Modules.DASHBOARD_TAB
	);

	public static readonly DashboardChangeVersion = this.success(
		NotifOperation.UPDATED,
		Modules.DASHBOARD_VERSION
	);

	// Dasboard Version Module Messages
	public static readonly DashboardVersionSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.DASHBOARD_VERSION
	);
	public static readonly DashboardVersionSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.DASHBOARD_VERSION
	);
	public static readonly DashboardVersionSuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.DASHBOARD_VERSION
	);

	// Company Branding Module Messages
	public static readonly CompanyBrandingSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.COMPANY_BRANDING
	);
	public static readonly CompanyBrandingSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.COMPANY_BRANDING
	);

	// User Theme Module Messages
	public static readonly UserThemeSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.USER_THEME
	);
	public static readonly UserThemeSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.USER_THEME
	);

	// 2FA Module Messages
	public static readonly TwoFactorAuthSuccessEnabled = this.success(
		NotifOperation.ENABLED,
		Modules.TWO_FACTOR_AUTH
	);
	public static readonly TwoFactorAuthSuccessDisabled = this.success(
		NotifOperation.DISABLED,
		Modules.TWO_FACTOR_AUTH
	);

	// Message Module Messages
	public static readonly MessageSuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.MESSAGE
	);
	public static readonly MessageSuccessMarkRead = this.success(
		NotifOperation.MARKREAD,
		Modules.MESSAGE
	);
	public static readonly MessageCannotDelete =
		'Some messages with ongoing publish period cannot be deleted.';

	// Request Module Messages
	public static readonly RequestSuccessSubmitted = this.success(
		NotifOperation.SUBMITTED,
		Modules.REQUEST
	);
	public static readonly RequestFailedSubmit = this.failedTo('submit request');

	// Report Module Messages
	public static readonly ReportSuccessSubmitted = this.success(
		NotifOperation.SUBMITTED,
		Modules.REPORT
	);
	public static readonly ReportFailedSubmit = this.failedTo('submit report');

	// Order Inquiry Module Messages
	public static readonly OrderInquirySuccessSubmitted = this.success(
		NotifOperation.SUBMITTED,
		Modules.ORDERINQUIRY
	);
	public static readonly OrderInquiryFailedSubmit = this.failedTo(
		'submit order inquiry'
	);

	// Bot Module Messages
	public static readonly BotSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.BOT
	);
	public static readonly BotSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.BOT
	);

	// KnowledgeBase Module Messages
	public static readonly KnowledgeBaseSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.KNOWLEDGE_BASE
	);
	public static readonly KnowledgeBaseSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.KNOWLEDGE_BASE
	);
	public static readonly KnowledgeBaseSuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.TOPIC
	);

	// Token Module Messages
	public static readonly TokenSuccessSent = this.success(
		NotifOperation.SENT,
		Modules.TOKEN
	);

	// User permission Messages
	public static readonly UserPermissionSuccessSaved = this.success(
		NotifOperation.SAVED,
		Modules.USER_PERMISSION
	);
	public static readonly UserPermissionNoChanges = 'No Changes were made.';

	// Group Module Messages
	public static readonly GroupSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.GROUP
	);
	public static readonly GroupSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.GROUP
	);
	public static readonly GroupSuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.GROUP
	);
	public static readonly GroupUserSuccessRemoved = 'User successfully removed.';

	//Service And Support
	public static readonly NoCommonBoard =
		'No common board found for selected companies.';
	public static readonly FailedToPublish =
		'Failed to publish custom form. Make sure to save your questions first.';
	public static readonly FailedToDeleteCompany =
		'Failed to delete company.\nMake sure to have more than 1 company selected in Ticket Setup before deleting this company.';

	// Feedback Module Messages
	public static readonly FeedbackSuccessAdded = this.success(
		NotifOperation.ADDED,
		Modules.FEEDBACK_SUGGESTION
	);
	public static readonly FeedbackSuccessRead = this.success(
		NotifOperation.READ,
		Modules.FEEDBACK_SUGGESTION
	);
	public static readonly FeedbackSuccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.FEEDBACK_SUGGESTION
	);
	public static readonly FeedbackSuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.FEEDBACK_SUGGESTION
	);
	public static readonly FeedbackSuccessArchived = this.success(
		NotifOperation.ARCHIVED,
		Modules.FEEDBACK_SUGGESTION
	);
	public static readonly FeedbackFailedToDeleted = this.failedTo(
		`${NotifOperation.DELETED} ${Modules.FEEDBACK_SUGGESTION}`
	);

	// Ticket Messages
	public static readonly TicketAdded = this.success(
		NotifOperation.ADDED,
		Modules.TICKET
	);

	// Setup Module
	static readonly AgreementTypeUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.AGREEMENT_TYPE
	);
	static readonly AgreementTypeDeleted = this.success(
		NotifOperation.DELETED,
		Modules.AGREEMENT_TYPE
	);
	static readonly BoardUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.BOARD
	);
	static readonly MyTicketAccessUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.MY_TICKET_ACCESS
	);
	static readonly BoardSuccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.COMPANY
	);
	static readonly FormUsageUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.FORM_USAGE
	);

	// Edit Directory
	static readonly EditDirectoryUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.EDIT_DIRECTORY
	);

	// User Detail
	static readonly FirstLoginUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.FIRST_LOGIN
	);

	// Profile
	static readonly ProfileUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.PROFILE
	);

	// Download
	static readonly FileGenerated = this.success(
		NotifOperation.GENERATED,
		Modules.FILE
	);

	// Client Admin - Application Quick Access
	static readonly QuickAccessSaved = this.success(
		NotifOperation.SAVED,
		Modules.QUICK_ACCESS
	);

	static readonly QuickAccessDeleted = this.success(
		NotifOperation.DELETED,
		Modules.QUICK_ACCESS
	);

	static readonly ServiceAndRequestUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.SERVICE_AND_REQUEST
	);

	static readonly ServiceAndRequestDeleted = this.success(
		NotifOperation.DELETED,
		Modules.SERVICE_AND_REQUEST
	);

	static readonly RequestFormDeleted = this.success(
		NotifOperation.DELETED,
		Modules.REQUEST_FORM
	);

	static readonly RequestFormUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.REQUEST_FORM
	);

	static readonly ManageInvoiceStatusUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.MANAGE_INVOICE_STATUS
	);

	static readonly ManageQuoteStatusUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.MANAGE_QUOTE_STATUS
	);

	static readonly RoleInfoUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.ROLE_INFO
	);

	static readonly BasicInfoUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.BASIC_INFO
	);

	static readonly ContactInfoUpdated = this.success(
		NotifOperation.UPDATED,
		Modules.CONTACT_INFO
	);

	public static readonly Export = 'File Exported';
	public static readonly Review = 'Review Error';
	public static readonly Required = 'Complete the required fields';
	public static readonly Try = 'Please try again later';
	public static readonly RefreshTry = 'Refresh the page and try again';
	public static readonly UpdateFail = 'Update Failed';
	public static readonly Unknown = 'Unknown Error Occurred';
	public static readonly Retrieval = 'Retrieval Failed';

	static save(message: string): string {
		return `${message} Saved`;
	}

	static update(message: string): string {
		return `${message} Updated`;
	}

	static delete(message: string): string {
		return `${message} Deleted`;
	}

	static submit(message: string): string {
		return `${message} Submitted`;
	}

	static send(message: string): string {
		return `${message} Sent`;
	}

	static publish(message: string): string {
		return `${message} Published`;
	}

	static unpublish(message: string): string {
		return `${message} Unpublished`;
	}

	static complete(message: string): string {
		return `${message} Complete`;
	}

	static unable(message: string): string {
		return `Unable To ${message}`;
	}

	static request(message: string): string {
		return `Request ${message}`;
	}
}

/// permission constants
import { MatSort, SortDirection } from '@angular/material/sort';
import { ApiRoutes } from './routes.constants';
import { UserTypes } from './global-enum';

export enum PermissionType {
	Application = 1,
	KbTopics = 2,
	Message = 3,
}

export enum RoleMenuIds {
	Company = 55,
}
export enum Menus {
	// Temporary: This is just to comply with the visibility of UI based on menuId
	UserViewMailbox = 59,
	UserViewDashboad = 72,
	ClientAdminViewDashboard = 341,
}
export enum Permission {
	///////////// User Start /////////////
	// Administrative
	// Dashboard //
	ViewDashboard = 282, // View Dashboard // TODO: Implement
	DashboardManageCards = 3270,
	DashboardServiceReport = 281, // View Service Tab
	DashboardProjectReport = 419, // View Project Tab // TODO: Implement

	// Mailbox //

	// Service & Support
	// Tickets
	UserTicketEdit = 73, // Access My Tickets
	UserViewAllTickets = 116, // Access Company Tickets
	AISummarization = 3271,

	// Company
	// Directory
	UserViewBasicInfo = 971, // View Basic Info
	UserViewContactInfo = 972, // View Contact Info
	UserViewRolesAndAccess = 973, // View Roles and Access
	UserViewOrganizationChart = 974, // View Organization Chart
	AddUserUser = 43, // Add New User

	// Applications
	UserAddEditApplication = 109, // Manage Application
	UserAddEditApplicationCategory = 111, // Manage Application Category

	// Billing & Orders
	// Quotes
	ShowQuoteLinkUser = 74, // Show External Quote Link

	// Orders

	// Invoices
	UserViewInvoiceDetails = 77, // View Invoice Details
	UserDownloadInvoice = 78, // Download Invoices
	UserManagePaymentMethod = 3222, // Manage Payment Methods
	UserPaymentOfInvoice = 170, // Pay Invoices

	// Communications
	UserViewNotification = 3272,
	UserManageApproval = 3273,

	// Others
	UserCWPodClientVerification = 189,
	UserCWPodManageClientAdminAndInvoicePermission = 190,
	///////////// User End /////////////

	///////////// Client Admin Start /////////////
	// Administrative
	// Impersonation
	CompanyAdminImpersonation = 3268,

	// Dashobard Insight //TODO: Implement
	// View Service Tab
	// View Project Tab

	// Categories
	CompanyAdminServiceAndSupportCategoryView = 237,
	CompanyAdminServiceAndSupportCategoryAddEdit = 107, // Service & Support Manage
	CompanyAdminKbCategoryView = 225,
	CompanyAdminKbCategoryAddEdit = 30, // Knowledge Base Manage
	CompanyAdminApplicationCategoryView = 236,
	CompanyAdminApplicationCategoryAddEdit = 105, // Application Manage

	// Service & Support
	// Request Forms
	CompanyAdminRequestForm = 80, // View
	CompanyAdminRequestFormAddEdit = 45, // Manage

	// Ticket Boards //TODO: Implement
	// View All Boards
	// Manage Access to Ticket

	// Knowledge Base
	CompanyAdminKbTopicView = 226, // View
	CompanyAdminKbTopicAddEdit = 33, // Manage

	// New Ticket Paths // TODO: Implement
	CompanyAdminNewTicketAccess = 3254, // Access
	CompanyAdminNewTicketHardware = 3255, // Hardware
	CompanyAdminNewTicketSoftware = 3256, // Software
	CompanyAdminNewTicketAdministrative = 3257, // Administrative
	CompanyAdminNewTicketSecurity = 3258, // Security
	CompanyAdminNewTicketOther = 3259, // Other

	// Audit Trail
	CompanyAdminAuditTrailCompany = 86, // Company
	CompanyAdminAuditTrailApplication = 88, // Application
	CompanyAdminAuditTrailKnowledgeBase = 90, // Knowledge Base
	CompanyAdminAuditTrailGroup = 91, // Group
	CompanyAdminAuditTrailServiceAndSupport = 92, // Service & Support
	CompanyAdminAuditTrailMessage = 200, // Message
	CompanyAdminAuditTrailContacts = 3262, // Contacts

	// Directory
	// Directory Settings
	CompanyAdminViewUserProfile = 3223,
	CompanyAdminContactAddEdit = 3260, // Update Profile Info
	CompanyAdminContactViewAccess = 3261, // View Access
	CompanyAdminContactManageAccess = 163, // Manage Access

	// Billing & Orders
	// N/A

	// Messages
	//Admin Level
	CompanyAdminMessageView = 244, // View
	CompanyAdminMessageAddEdit = 198, // Manage

	// Applications
	// Users Applications
	CompanyAdminApplicationView = 227, // View
	CompanyAdminApplicationAddEdit = 37, // Manage

	// Others
	CompanyAdminReportsFinance = 210,
	///////////// Client Admin End /////////////

	///////////// Sourcepass Admin Start /////////////
	// Administrative
	// Impersonation
	SpAdminImpersonation = 3267,

	// Dashboard Cards // TODO: Implement
	SpAdminDashboardCardView = 254, // View
	SpAdminDashboardCardManage = 138, // Edit

	// Categories
	SpAdminServiceAndSupportCategoryView = 223,
	SpAdminServiceAndSupportCategoryAddEdit = 18, // Edit Service & Support
	SpAdminKbCategoryView = 220,
	SpAdminKbCategoryAddEdit = 9, // Edit Knowledge Base
	SpAdminApplicationCategoryView = 218,
	SpAdminApplicationCategoryAddEdit = 1, // Edit Applications
	SpAdminQuoteCategoryView = 233,
	SpAdminQuoteCategoryEdit = 64, // Edit Quotes

	// Manage Status Names
	SpAdminManageOrderStatusView = 255,
	SpAdminManageOrderStatusEdit = 143,
	SpAdminManageQuoteStatusView = 257,
	SpAdminManageQuoteStatusEdit = 260,
	SpAdminManageInvoiceStatusView = 258,
	SpAdminManageInvoiceStatusEdit = 261,
	SpAdminManageTicketBoardStatusView = 256,
	SpAdminManageTicketBoardStatusEdit = 259,

	// Service & Support
	// Request Forms
	SpAdminServiceAndSupportFormView = 224, // View
	SpAdminServiceAndSupportFormAddEdit = 21, // Manage
	SpAdminServiceAndSupportAccessQuestionLibrary = 274, // Access Question Library

	// Button Defaults
	SpAdminFormUsageView = 61, // View
	SpAdminFormUsageEdit = 62, // Edit

	// Ticket Boards
	SpAdminBoardView = 251, // View All Boards
	SpAdminBoardEdit = 137, // Manage Access to Ticket Boards

	// Knowledge Base
	SpAdminKbTopicView = 221, // View
	SpAdminKbTopicAddEdit = 12, // Manage
	SpAdminChatbotUpdate = 51, // Manage Chat Bot

	// Companies
	// Company Profile
	SpAdminCompanyProfileUpdate = 27, // Update Profile Info //TODO: Implement
	SpAdminBrandingUpdate = 48, // Update Branding

	// Organization Hierarchy
	SpAdminManageRolesAndPermissionsForUsers = 246, // Manage Roles & Permissions for Users
	SpAdminUpdateOrganizationChart = 3264, // Update Oranization Chart
	SpAdminDirectoryViewUserProfiles = 228, // View User Profiles
	SpAdminContactAddEdit = 41, // Edit User Profiles

	// Agreements //TODO: Implement
	SpAdminCompanyAgreementView = 3266, //3265,
	MP_SpAdminAgreementTypeEdit = 154, //73,
	SpAdminAgreementTypeEdit = 60, // Manage

	// Directory
	// Directory Settings
	SpAdminUpdateUserProfileInfo = 113, // Update Profile Info
	SpAdminManageAccess = 246,
	// View Roles & Permissions //TODO: Implement
	// 246 // Manage Role Access

	// Billing & Orders / Invoice Portal
	// Quotes
	// Orders
	// Invoices

	// Messages
	// Global Communication
	SpAdminMessageView = 222, // View
	SpAdminMessageAddEdit = 15, // Manage
	SpAdminEmailTemplateView = 999, // View (to change / not real value)
	SpAdminEmailTemplateAddEdit = 9999, // Manage (to change / not real value)

	// Applications
	// Users Applications
	SpAdminApplicationView = 219, // View
	SpAdminApplicationAddEdit = 4, // Manage

	// Application Catalog
	SpAdminApplicationCatalogView = 245, // View
	SpAdminApplicationCatalogAddEdit = 201, // Manage

	// Reports
	// Quest Usage
	SpAdminReportsPortalUsage = 207, // Portal Usage Report
	SpAdminReportsUserLogin = 262, // User Login Report
	SpAdminReportsEmailNotification = 264, // Email Notification Activity

	// Financial Information
	SpAdminReportsFinance = 209, // Finance Report
	SpAdminReportsNetsuiteCreditMemo = 265, // NetSuite Credits
	SpAdminReportsCWAutoPay = 268, // Autopay Report

	// Notification Center
	SpAdminNotificationAccessTerritoryEdit = 278,
	SpAdminNotificationAccessTerritoryView = 279,
	SpAdminNotificationAccessCompanyEdit = 280,
	///////////// Sourcepass Admin End /////////////

	// Tracking **Do not use** For Tracking Only
	SubmitOrderInquiry = 293,
	EditProfile = 3269,
}

// export interface IPermissionData {
//   userIds: [];
//   hasApplicationPermission: boolean;
// }

export interface IPermissionData {
	userIds: number[];
	hasApplicationPermission: boolean;
	companyId: number;
	applicationId: number;
}

export interface IPermission {
	id: number;
	permissionModule: number;
	companyIds: number[];
}

export interface IPermissionUser {
	applicationId: number;
	userIds: number[];
	hasUserLevelPermission: boolean;
}

export interface companyListProps {
	search: string;
	page: number;
	pageSize: number;
	column: string;
	order: string;
	sort?: SortDirection;
	index?: number;
}

export interface KBPermission {
	kbTopicId: number;
	companyIds: number[];
}
export interface IPermissionUserKb {
	kbTopicId: number;
	userIds: number[];
}

export interface MessagePermission {
	messageId: number;
	companyIds: number[];
}

export interface IPermissionUserMessage {
	messageId: number;
	userIds: number[];
}

export const SPADMIN_MENU: MenuInterface[] = [
	{
		name: 'Application',
		link: '',
		icon: '',
		children: [
			{ name: 'Manage Categories', link: '/application/categories' },
			{ name: 'Manage Applications', link: '/application/applications' },
		],
	},
	{
		name: 'Knowledge Base',
		link: '',
		icon: '',
		children: [
			{ name: 'Manage Categories', link: './knowledge-base/categories' },
			{ name: 'Manage Topics', link: './knowledge-base/topics' },
			{ name: 'Chat Bot', link: './knowledge-base/bot' },
		],
	},
	{
		name: 'Roles and Permissions',
		link: '/roles-and-permissions',
		icon: '',
		children: [],
	},
	{
		name: 'Message',
		link: '/message',
		icon: '',
		children: [],
	},
	{
		name: 'Service and Support',
		icon: '',
		link: '',
		children: [
			{ name: 'Categories', link: '/service-and-support/categories' },
			{ name: 'Request Forms', link: '/service-and-support/request-forms' },
		],
	},
	{
		name: 'Manage Agreement Types',
		link: '/message',
		icon: '',
		children: [],
	},
	{
		name: 'Impersonate',
		link: '',
		icon: '',
		children: [
			{ name: 'Logged in as admin', link: '' },
			{ name: 'Logged in as user', link: '' },
			{ name: 'Log in as other User', link: '' },
		],
	},
	{
		name: 'Companies',
		link: './companies',
		icon: '',
		children: [],
	},
	{
		name: 'Reporting',
		link: './reporting',
		icon: '',
		children: [],
	},
];

export const UntokenizedRoutes: string[] = [
	ApiRoutes.AUTH_SEND_TOKEN,
	ApiRoutes.AUTH_RESEND_TOKEN,
	ApiRoutes.AUTH_EMAIL_TOKEN_LOGIN,
	ApiRoutes.AUTH_MICROSOFT_LOGIN,
	ApiRoutes.AUTH_CONTINUE_LOGIN,
	ApiRoutes.AUTH_REFRESHTOKEN,
	'azurekeyvault/getgoogleandmicrosoftclientid',
];

/* Add here for those api endpoints that are supported with 1.1 api version. */
export const HasXApiVersionHeader: string[] = [
	ApiRoutes.TICKETS_ADD_OR_REMOVE_CONTACTS,
	ApiRoutes.NOTIFICATION_SETTING,
	ApiRoutes.ASSETS,
];

//import { MenuInterface } from "../interfaces/menu.interface";

export const USER_MENU: MenuInterface[] = [
	{
		name: 'Company',
		link: '',
		icon: '',
		children: [
			{ name: 'Contacts', link: '/company/contacts' },
			{ name: 'Knowledge Base', link: '/company/kb' },
			{ name: 'Applications', link: '/company/applications' },
		],
	},
	{
		name: 'Message',
		link: '/client-message',
		icon: '',
		children: [],
	},
	{
		name: 'Support',
		link: '',
		icon: '',
		children: [
			{ name: 'Service Request', link: '/support/service-request' },
			{ name: 'Support Request', link: '/support/support-request' },
		],
	},
	{ name: 'Ticket', link: '/ticket', icon: '', children: [] },
	{
		name: 'Quotes and Orders',
		icon: '',
		link: '',
		children: [
			{ name: 'Quotes', link: '/quotes-and-orders/quotes' },
			{ name: 'Orders', link: '/quotes-and-orders/orders' },
		],
	},
	{
		name: 'Finance',
		link: '',
		icon: '',
		children: [{ name: 'Invoices', link: '/finance/invoices' }],
	},
];

export const UserTypeArray = [
	{ name: 'Sourcepass Admin', id: UserTypes.SourcepassAdmin },
	{ name: 'Client Admin', id: UserTypes.ClientAdmin },
	{ name: 'User', id: UserTypes.User },
];

export const InvoiceTransactionType: ValueLabel[] = [
	{
		value: 'CustCred',
		label: 'Credit Memo',
	},
	{
		value: 'CustPymt',
		label: 'Payment',
	},
];

interface ValueLabel {
	value: string;
	label: string;
}
