import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numSequence',
	standalone: true,
})
export class NumSequencePipe implements PipeTransform {
	transform(number: number) {
		return Array(number);
	}
}
