import { Injectable } from '@angular/core';
import { ModulesPermission, RoleList, RoleList2 } from './roles-and-permission.interface';

@Injectable()
export class RolesAndPermissionDummyService {

  roles:RoleList2[] = getRoleModule();
  modules:ModulesPermission[] = getModuleList();

  constructor(){
    console.log('module dummy:', getModuleList())
  }

}


function getRoleModule(): RoleList2[]{
  return [
    {
      roleId:1,
      roleName: 'Client User**',
      userCount: 1214,
      isAdmin: true,
      isBaseRole: true,
      
      roleDescription: `
        This is the base level of access for Quest to allow users to submit IT support requests, utilize the company Directory, and access the app they need.
      `,
      isDeletable: true,
      isEditable: true,
      isOverride: false,
    },
    {
      roleId:2,
      roleName: 'Client Admin**',
      userCount: 207,
      isAdmin: true,
      isBaseRole: true,
      
      roleDescription: `
        This is the administrator level of access for Quest partners that allows them to manage their business IT needs internally.
      `,
      isDeletable: true,
      isEditable: true,
      isOverride: false,

    },
    {
      roleId:3,
      roleName: 'Billing & Orders Admin**',
      userCount: 60,
      isAdmin: true,
      isBaseRole: true,
      
      roleDescription: ``,
      isDeletable: true,
      isEditable: true,
      isOverride: false,

    },
    {
      roleId:4,
      roleName: 'Sourcepass Developers**',
      userCount: 24,
      isAdmin: true,
      isBaseRole: true,
      
      roleDescription: ``,
      isDeletable: true,
      isEditable: true,
      isOverride: false,
    },
    {
      roleId:5,
      roleName: 'Service & Support Admin',
      userCount: 60,
      isAdmin: true,
      isBaseRole: true,
      roleDescription: ``,
      isDeletable: true,
      isEditable: true,
      isOverride: false,
    },
    {
      roleId:6,
      roleName: 'Client Success Team',
      userCount: 45,
      isAdmin: true,
      isBaseRole: true,
      
      roleDescription: ``,
      isDeletable: true,
      isEditable: true,
      isOverride: false,
    },
    {
      roleId:7,
      roleName: 'Sourcepass Admin',
      userCount: 8,
      isAdmin: true,
      isBaseRole: true,
      roleDescription: ``,
      isDeletable: true,
      isEditable: true,
      isOverride: false,
    },
    {
      roleId:8,
      roleName: 'UX Designers',
      userCount: 0,
      isAdmin: true,
      isBaseRole: true,
      roleDescription: ``,
      isDeletable: true,
      isEditable: true,
      isOverride: false,
    },
  ]
}

function getModuleList():ModulesPermission[]{
  return [
    {
      id: 1, 
      name: 'Administrative', 
      icon: 'briefcase-01',
      isAdmin: true,
      isAllAccess: true,
      pages: [{
        id: 1,
        name: 'Dashboard',
        description: 'Allows access to view and customize dashboard tabs and cards',
        isEnableAccess: true, 
        actions:[]
      }]
    },
    {
      id: 2, 
      name: 'Service & Support', 
      icon: 'building-07',
      isAdmin: true,
      isAllAccess: true,
      pages: [{
        id: 1,
        name: 'Tickets',
        description: 'Allows users to request IT support tickets and monitor their status.',
        isEnableAccess: true,
        actions:[
          {
            id: 1,
            isEnable: true,
            name: 'accessTicket',
            displayName: 'Access My Tickets'
          },
          {
            id: 2,
            isEnable: false,
            name: 'accessCompanyTickets',
            displayName: 'Access Company Tickets'
          },
        ]
      }]
    },
    {
      id: 3, 
      name: 'Company', 
      icon: 'building-06',
      isAdmin: true,
      isAllAccess: true,
      pages: [
        {
          id: 1, 
          name: 'Directory',
          description: 'Update their profile info',
          isEnableAccess: true,
          actions: [
            {
              id: 1,
              isEnable: true,
              name: 'viewBasicInfo',
              displayName: 'View Basic Info',
            },
            {
              id: 2,
              isEnable: true,
              name: 'viewContactInfo',
              displayName: 'View Contact Info',
            },
            {
              id: 3,
              isEnable: true,
              name: 'viewRole&Access',
              displayName: 'View Roles & Access',
            },
            {
              id: 4,
              isEnable: true,
              name: 'viewOrganizationChart',
              displayName: 'View Organization Chart',
            },
          ]
        },
        {
          id: 2, 
          name: 'Assets',
          description: `Access to view company's assetsFilterLayout, like computers, workstations, etc.`,
          isEnableAccess: true,
          actions: []
        },
        {
          id: 3, 
          name: 'Applications',
          description: 'Access to adjust permissions around client applications',
          isEnableAccess: true,
          actions: [
            {
              id: 1,
              isEnable: true,
              name: 'manageApplication',
              displayName: 'Manage',
            },
            {
              id: 2,
              isEnable: true,
              name: 'manageApplication',
              displayName: 'Manage',
            }
          ]
        },
      ]
    },
    {
      id: 4, 
      name: 'Billing & Orders', 
      icon: 'coins-stacked-03',
      isAdmin: true,
      isAllAccess: true,
      pages: [
        {
          id: 1, 
          name: 'Quotes',
          description: 'Allows access to view & approve quotes for projects',
          isEnableAccess: false,
          actions: [
            {
              id: 1,
              isEnable: false,
              name: 'viewQuotes',
              displayName: 'View Quotes',
            },
            {
              id: 2,
              isEnable: false,
              name: 'showExternal',
              displayName: 'Show External Quote Link',
            },
          ]
        },
        {
          id: 2, 
          name: 'Orders',
          description: 'Allows access to view and approve orders for procurement',
          isEnableAccess: false,
          actions: [
            {
              id: 1,
              isEnable: false,
              name: 'viewCompanyOrders',
              displayName: 'View Company Orders',
            },
          ]
        },
        {
          id: 3, 
          name: 'Invoices',
          description: 'Allows access to view and pay invoices',
          isEnableAccess: false,
          actions: [
            {
              id: 1,
              isEnable: false,
              name: 'viewAllInvoices',
              displayName: 'View All Invoices',
            },
            {
              id: 2,
              isEnable: false,
              name: 'viewInvoiceDetails',
              displayName: 'View Invoice Details',
            },
            {
              id: 3,
              isEnable: false,
              name: 'downloadInvoices',
              displayName: 'Download Invoices',
            },
            {
              id: 4,
              isEnable: false,
              name: 'managePaymentMethods',
              displayName: 'Manage Payment Methods',
            },
            {
              id: 5,
              isEnable: false,
              name: 'payInvoices',
              displayName: 'Pay Invoices (Checking this enables "Bill To Contact" title',
            },
          ]
        },
      ]
    },
  ]
}