<div class="roles-and-permission q-mb-24">
  <div 
    [ngClass]="{'create-new-radius': !isShowPermissions}"
    class="roles-and-permission__header"
  >
    <role-header-component></role-header-component>
  </div>
  <div class="roles-and-permission__content">
    <role-body-component></role-body-component>
  </div>
  <div 
    *ngIf="isShowPermissions"
    class="roles-and-permission__footer"
  >
    <role-button-component></role-button-component>
  </div>
</div>

<app-audit-trail
  *ngIf="historyType.id && (historyType.isEditType || historyType.isOverride)"
  [id]="historyType.id"
  [url]="historyType.urlPath"
  [userId]="historyType.userId"
  [isCustomizeContent]="true">

  <ng-template #customizeContent let-option let-i="index">
    <strong>
      {{ option.firstName }} {{ option.lastName }}
    </strong>

    changed the field
    <strong>{{ option.name || option.fieldNameInUI }}</strong> to {{ option.newValue }}.
    
    <!-- <ng-container [ngSwitch]="option.fieldNameInUI">
      <ng-container *ngSwitchCase="'Added'">
        Turned {{ option.newValue === 'Yes' ? 'ON' : 'OFF' }} Admin role for <strong>{{ option.name }}</strong>
      </ng-container>

      <ng-container *ngSwitchCase="'Deleted'">
        Deleted <strong>{{ option.name }}</strong>
      </ng-container>
    
      <ng-container *ngSwitchDefault>
        changed the field
        <strong>{{ option.name || option.fieldNameInUI }}</strong>
        {{ option.oldValue != '' && option.oldValue != undefined && option.newValue != 'Empty' && option.fieldNameInUI != 'Role Name' ? 'from' : ''}}
        {{ option.newValue != 'Empty' && option.fieldNameInUI != 'Role Name' ? option.oldValue : '' }} to {{ option.newValue }}.
      </ng-container>
      
      </ng-container> -->
  </ng-template>
</app-audit-trail>