import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { delay, finalize, Subject, takeUntil } from 'rxjs';

import { SpinnerService } from '@app/core/services/spinner.service';

import { NotificationSettings } from '@app/shared/constants';
import { NotificationSettingsList, SubSettings } from './notification-settings.interface';
import { NotificationSettingsService } from './notification-settings.service';

@Component({
	selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
  @Input() userId: number;
	@Input() isCurrentUser: boolean;
	@Output() onReloadAuditTrail: EventEmitter<void> = new EventEmitter<void>();

	notificationSettings: NotificationSettingsList[] = [];
	modifiedSettingsArr: SubSettings[] = [];
  isSelectAll: boolean = false;

	private _$unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		public spinner: SpinnerService,
		private _cd: ChangeDetectorRef,
		private _notificationsSettingService: NotificationSettingsService
	) {}

	get notificationSetting(): typeof NotificationSettings {
		return NotificationSettings;
	}

	get defaultSettings() {
		const defaultSettings = this.notificationSettings.map(x => {
			return x.subSettings.map(d => {
				let obj: any = {};

				if (d.hasEmail) {
					obj.isSendEmail = d.isSendEmail;
				}

				if (d.hasNotification) {
					obj.isSendNotification = d.isSendNotification;
				}

				if (d.hasPushNotification) {
					obj.isSendPushNotification = d.isSendPushNotification; 
				}

				return Object.values(obj);
			})
		});

		return defaultSettings.every(x => x.every(d => d.every(s => s)));
  }

	ngOnInit() {
		this._initData();
	}

	updateNotificationSetting() {
		let tempArr: any = [];
		this.notificationSettings.forEach(x => Array.prototype.push.apply(tempArr, x.subSettings));
		this.modifiedSettingsArr = tempArr;
		this._cd.detectChanges();
	}

	onClickSave() {
		this.spinner.start();
		this.updateNotificationSetting();

		const payload = {
			notificationSettingValues: this.modifiedSettingsArr.map(x => {
				let obj: any = {};

				obj.notificationSettingId = x.id;
				x.hasEmail ? obj.isSendEmail = x.isSendEmail : obj.isSendEmail = false;
				x.hasNotification ? obj.isSendNotification = x.isSendNotification : obj.isSendNotification = false;
				x.hasPushNotification ? obj.isSendPushNotification = x.isSendPushNotification : obj.isSendPushNotification = false;
				return obj;
			})
		};

		this._notificationsSettingService
		.updateNotificationSettings(payload, this.userId)
		.pipe(
			delay(500),
			finalize(() => this.spinner.reset())
		)
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: (result) => {
				this.modifiedSettingsArr = [];
        this._initData();
				this._reloadAuditTrail();
			}
		});
	}

	onChangeToggleButton() {
    this.notificationSettings.forEach(x => {
			x.subSettings.forEach(d => {
				d.isSendEmail = this.isSelectAll ? d.hasEmail : false;
				d.isSendPushNotification = this.isSelectAll ? d.hasPushNotification : false;
				d.isSendNotification = this.isSelectAll ? d.hasNotification : false;
			});
		});
  }

	private _initData() {
    this._notificationsSettingService.getNotificationSettings(this.userId)
		.pipe(takeUntil(this._$unsubscribe))
		.subscribe({
			next: (resp: NotificationSettingsList[]) => {
				if (Array.isArray(resp) && resp.length) {
					this.notificationSettings = resp.sort((a, b) => a.name == 'Quest' ? 0 : b.name.localeCompare(a.name));
				}
				
        setTimeout(() => this.isSelectAll = this.defaultSettings, 0);
				this._cd.detectChanges();
			},
		});
  }

	private _reloadAuditTrail() {
		this.onReloadAuditTrail.emit();
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}

