import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataModalService } from '@app/core/data-modal/data-modal.service';
import {
	RoleList2,
	RolesAndPermissionService,
} from '@app/modules/groups-and-permissions';
import { SearchInputComponent } from '@app/shared/components/form-input/search-input/search-input.component';
import { Observable, map, startWith, switchMap } from 'rxjs';

@Component({
	selector: 'roles-and-modules-component',
	templateUrl: './roles-and-modules.component.html',
	styleUrls: ['./roles-and-modules.component.scss'],
})
export class RolesAndModulesComponent implements OnInit {
	@ViewChild('searchTextbox') searchTextbox: SearchInputComponent;
	// inject
	private _rAndP = inject(RolesAndPermissionService);
	private _dataModalService = inject(DataModalService);

	// props
	roles$: Observable<RoleList2[]>; //= getRoleModule();
	filteredRoles$: Observable<RoleList2[]>;
	role = new FormControl('');
	ngOnInit() {
		this.roles$ = this._rAndP.roles$;

		this.filteredRoles$ = this.role.valueChanges.pipe(
			startWith(''),
			switchMap((value) => {
				if (value) {
					return this.roles$.pipe(
						map((roles) =>
							roles.filter((role) =>
								role.roleName.toLowerCase().includes(value.toLowerCase())
							)
						)
					);
				} else {
					return this.roles$;
				}
			})
		);
	}

	deleteRole(id: number, roleName: string) {
		const data = this._dataModalService.getDeleteModel2(
			'Roles & Permission',
			roleName
		);
		this._dataModalService.showModal(data).subscribe({
			next: (result) => {
				if (result) {
					this._rAndP.deleteRole(id);
				}
			},
		});
	}

	getIsShowView(isBaseRole: boolean) {
		return this._rAndP.stateParent === 'company' && isBaseRole;
	}
}
