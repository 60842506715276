import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TicketDetailsComponent } from './ticket-details.component';
import { AddManageUsersModalComponent } from '@app/shared/components/messaging/view-ticket/add-manage-users-modal/add-manage-users-modal.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
	declarations: [TicketDetailsComponent],
	imports: [CommonModule, AddManageUsersModalComponent, SharedModule],
	exports: [TicketDetailsComponent],
})
export class TicketDetailsModule {}
