<div class="company-view">
  <div class="company-view__left">
    <div class="company-view__description">
      <h3 class="font-h-normal">Invoices</h3>
      <h5 class="mt-3 font-b2-5" *ngIf="isAdmin">ACCESS FOR Finance Department</h5>
    </div>
  </div>

  <ng-container *ngIf="isAdmin; else ChildCompany">
    <app-parent-child-input-selector
      url="companies/GetDropdown"
      (onChangeSelector)="childCompanyChanged()"
      [formControl]="childCompanyControl">
    </app-parent-child-input-selector>
  </ng-container>

  <ng-template #ChildCompany>
    <app-parent-child-input-selector
      url="ParentChildCompany/GetCurrentChildren"
      (onChangeSelector)="childCompanyChanged()"
      [formControl]="childCompanyControl">
    </app-parent-child-input-selector>
  </ng-template>
</div>
