import { ReportApiService } from '@app/modules/portal-usage/pages/dashboard-report/services/report-api.service';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { TicketService } from '@app/modules/service-and-support/ticket.service';
import { BaseUserComponent } from '@app/shared/components/user/base-user.component';
import { User } from '@app/shared/interfaces/user.interface';
import { DateService } from '@app/shared/services/date.service';
import { UtilitiesService } from '@app/shared/services/utilities.service';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import { IAttachments, INotes, ITicketContent } from '../ticket.interface';
import { MAIN_COLOR, colors } from './colors.constant';
import { getInitials } from '@app/shared/functions/user-initials.functions';
import { ViewTicketService } from '../view-ticket.service';

@Component({
	selector: 'view-ticket-comment',
	templateUrl: './comment.component.html',
	styleUrls: ['./comment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent
	extends BaseUserComponent
	implements OnChanges, AfterViewInit
{
	@Input() ticket: ITicketContent | null;
	@Input() comments: INotes[];
	@Input() attachments: IAttachments[];

	@ViewChild('ticketContainer') ticketContainer!: ElementRef;

	responses: (INotes | IAttachments)[] = [];
	userIdTable: { id: number; color: string }[] = [];
	userTable: User[] = [];
	apibaseUrl: string = environment.apiBaseUrl;

	mainColor = MAIN_COLOR;
	colors = JSON.parse(JSON.stringify(colors));
	userid: number;

	showScrollTop: boolean = false;
	showScrollDown: boolean = false;
	constructor(
		public _utilitiesService: UtilitiesService,
		private _ticketService: TicketService,
		public override _userService: UserService,
		private renderer: Renderer2,
		public dateService: DateService,
		private _cd: ChangeDetectorRef,
		private _viewTicket: ViewTicketService,
		public reportApiService: ReportApiService
	) {
		super(_userService);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['ticket'] && !changes['ticket'].firstChange) {
			this.ticket = changes['ticket'].currentValue;
		}
		if (changes['attachments'] && !changes['attachments'].firstChange) {
			this.attachments = changes['attachments'].currentValue;
			this.responses = [];
		}
		if (changes['comments'] && !changes['comments'].firstChange) {
			this.comments = changes['comments'].currentValue;
			this.responses = [];
		}

		this._setIdentity();
		this.generateUserId();
		this.mapCommentsEmail();
		this.mapResponseData();
		this.userid = this.userId;
		this._cd.detectChanges();
		// setTimeout(() => {
		// 	this.checkShowScrollTop();
		// }, 3000);

		// this.getImages();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.checkShowScrollTop();
		}, 3000);
	}

	mapCommentsEmail() {
		this.comments.forEach((comment) => {
			comment.note = this.formatEmails(comment.note);
		});
	}

	formatEmails(inputHtml: string) {
		// Define the target text we're looking for in the string
		const targetText =
			'Include the following accounts and emails on this request:';

		// Check if the input string contains the target text
		if (inputHtml.includes(targetText)) {
			// Find the position of the target text in the string
			const startIndex = inputHtml.indexOf(targetText);

			// Extract the part of the string that contains the emails (after the target text)
			let emailSection = inputHtml.slice(startIndex);

			// Extract the actual email part which comes after the first <br> tag (which follows the targetText)
			const emailStartIndex = emailSection.indexOf('<br>') + 4; // The <br> tag is 4 characters long
			let emails = emailSection.slice(emailStartIndex);

			// Split the emails by commas and add <br> between them
			const emailArray = emails.split(',');
			const formattedEmails = emailArray.join('<br>');

			inputHtml =
      inputHtml.slice(0, inputHtml.indexOf(targetText)) +
				"Include the following accounts and emails on this request:</strong><br>" + formattedEmails;
		}

		return inputHtml;
	}

	checkShowScrollTop() {
		if (this.ticketContainer) {
			const isAtTop = this.ticketContainer.nativeElement.scrollTop === 0;

			// Show the bottom button only if the scroll is at the top
			this.showScrollTop =
				isAtTop &&
				this.ticketContainer.nativeElement.scrollHeight >
					this.ticketContainer.nativeElement.clientHeight;
			this._cd.detectChanges();
		}
	}

	onScroll(evt: any) {
		const div = evt.target;
		const isAtTop = div.scrollTop === 0;
		const isAtBottom = div.scrollTop + div.clientHeight >= div.scrollHeight;

		// Show the bottom button only if the scroll is at the top
		this.showScrollTop = isAtTop && div.scrollHeight > div.clientHeight;
		// Show the top button only if the scroll is at the bottom
		this.showScrollDown = isAtBottom;
	}

	scrollDown() {
		if (this.ticketContainer) {
			this.ticketContainer.nativeElement.scrollTop =
				this.ticketContainer.nativeElement.scrollHeight;
		}
	}

	scrollUp() {
		if (this.ticketContainer) {
			this.ticketContainer.nativeElement.scrollTop = 0;
		}
	}

	downloadFile(id: number) {
		this._ticketService.getImage(id).subscribe((url) => {
			this.renderer.setAttribute(
				window.open(url, '_blank'),
				'noopener',
				'true'
			);
		});
	}

	displayFile(id: number) {
		this._ticketService.getImage(id).subscribe((url) => {
			return url;
		});
		this._cd.detectChanges();
	}

	generateUserId() {
		this.attachments = this.attachments.map((attachment, index) => {
			return {
				...attachment,
				type: 'attachment',
				userId: Number(
					this._utilitiesService.parseTitle(this.attachments[index].title)
				),
			};
		});

		this.userIdTable = [];
		this.attachments.filter((attachment) => {
			let i = this.userIdTable.findIndex(
				(u) => u.id === Number(attachment.userId)
			);
			if (i <= -1 && attachment.userId) {
				this.userIdTable.push({
					id: Number(attachment.userId),
					color:
						Number(attachment.userId) === this.userId
							? this.mainColor
							: this.colors[this.userIdTable.length % 8],
				});
			}
			return null;
		});
		this.comments.filter((attachment) => {
			let i = this.userIdTable.findIndex(
				(u) => u.id === Number(attachment.userId)
			);
			if (i <= -1 && attachment.userId) {
				this.userIdTable.push({
					id: Number(attachment.userId),
					color:
						Number(attachment.userId) === this.userId
							? this.mainColor
							: this.colors[this.userIdTable.length % 8],
				});
			}
			return null;
		});
	}

	formatDate(d: string): string {
		return DateTime.fromISO(d).toFormat('DDDD');
	}

	formatTime(d: string): string {
		return DateTime.fromISO(d).toFormat('t');
	}

	mapResponseData() {
		if (!this.comments.length) {
			this.responses = [];
			return;
		}

		this.responses = this.responses
			.concat(
				this.comments.map((comment) => {
					let { name } = comment,
						firstName = '',
						lastName = '',
						email = '',
						names: string[] = [],
						userId = comment.userId,
						noProfileCtr = 0,
						avatar: any;

					if (!comment.imagePath) {
						try {
							names = name!.split(' ');
							(firstName = names[0]), (lastName = names[1]);
						} catch (e) {
							firstName = name!.substring(0, 1);
							lastName = name!.substring(1, 1);
						}
						avatar = this._viewTicket.addAvatar({
							firstName,
							lastName,
							email,
							userId,
							noProfileCtr,
							comingFrom: 'comment',
						});
					}
					return {
						...comment,
						type: 'comment',
						color: this._utilitiesService.mapUserColor(
							comment.userId,
							this.userIdTable
						),
						profile: '',
						isLoading: comment.isLoading ? comment.isLoading : false,
						initials: !comment.imagePath
							? getInitials({ firstName, lastName, email })
							: '',
						noProfileCtr: !comment.imagePath ? avatar.noProfileCtr : '',
					};
				}),
				this.attachments.map((attachment, index) => {
					let { name } = attachment,
						firstName = '',
						lastName = '',
						email = '',
						names: string[] = [],
						userId = attachment.userId,
						noProfileCtr = 0,
						avatar: any,
						isImagePath = !!attachment.imagePath;

					if (!isImagePath) {
						try {
							names = name!.split(' ');
							(firstName = names[0]), (lastName = names[1]);
						} catch (e) {
							firstName = name!.substring(0, 1);
							lastName = name!.substring(1, 1);
						}
						avatar = this._viewTicket.addAvatar({
							firstName,
							lastName,
							email,
							userId,
							noProfileCtr,
							comingFrom: 'comment',
						});
					}
					return {
						...attachment,
						type: 'attachment',
						name: this._utilitiesService.parseName(attachment.title),
						color: this._utilitiesService.mapUserColor(
							attachment.userId,
							this.userIdTable
						),
						profile: '',
						isLoading: attachment.isLoading ? attachment.isLoading : false,
						initials: !isImagePath
							? getInitials({ firstName, lastName, email })
							: '',
						noProfileCtr: !isImagePath ? avatar.noProfileCtr : '',
					};
				})
			)
			.sort(
				(a, b) =>
					new Date(
						(a.lastUpdated ? a.lastUpdated : a.startDate) as any
					).getTime() -
					new Date(
						(b.lastUpdated ? b.lastUpdated : b.startDate) as any
					).getTime()
			);
		this.responses.forEach((r) => {
			if (r.type === 'attachment') {
				this._ticketService.getImage(r.id).subscribe((url) => {
					r.url = url;
					this._cd.detectChanges();
				});
			}
		});
	}
}
