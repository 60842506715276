/* Angular Libraries */
import {
	Component,
	EventEmitter,
	inject,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

/* Services */
import { UserService } from '@app/core/services/user.service';

/* Functions */
import { ChildCompanyDropdownComponent } from '@app/shared/components/child-company-dropdown/child-company-dropdown.component';
import { CompanyDropdownComponent } from '@app/shared/components/company-dropdown/company-dropdown.component';
import { InputSelectType } from '@app/shared/components/parent-child-input-selector/parent-child-input-selector.component';

/* Interfaces */
import { UserTypes } from '@app/shared/constants/global-enum';

@Component({
	selector: 'company-view',
	templateUrl: './company-view.component.html',
	styleUrls: ['./company-view.component.scss'],
})

export class CompanyViewComponent implements OnInit {
  /* ViewChild */
	@ViewChild(CompanyDropdownComponent) companyDropdown: CompanyDropdownComponent;
	@ViewChild(ChildCompanyDropdownComponent) childCompanyDropdown: ChildCompanyDropdownComponent;

  /* Input/Output */
	@Output() onUpdatedCompanyId: EventEmitter<void> = new EventEmitter<void>();

  /* Properties */
	isAdmin: boolean;
	storageName: string = '';
	dataStorage: any = { companyId: null, companyName: null };

	private _userService = inject(UserService);

	childCompanyControl: FormControl = new FormControl<InputSelectType>({
		isParentSelector: true,
		companySelected: null
	});

	ngOnInit(): void {
		this.isAdmin = this._userService.userRole === UserTypes.SourcepassAdmin;
		this.storageName = this.isAdmin ? 'companyAdmin' : 'companyUser';

		this.updateCompanyForm();
	}

	childCompanyChanged() {
		if (this.childCompanyControl.value === null) { 
			localStorage.setItem(this.storageName, JSON.stringify({ companyId: this._userService.user?.companyId, companyName: null }));
			this.onUpdatedCompanyId.emit();
			return;
		}

		if (this.childCompanyControl.value.isParentSelector) {
			localStorage.setItem(this.storageName, JSON.stringify({ companyId: this._userService.user?.companyId, companyName: null }));

		} else {
			localStorage.setItem(this.storageName, JSON.stringify({ 
				companyId: this.childCompanyControl.value.companySelected.id, 
				companyName: this.childCompanyControl.value.companySelected.name 
			}));
		}

		this.onUpdatedCompanyId.emit();
	}

	updateCompanyForm() {
		this.dataStorage = JSON.parse(localStorage.getItem(this.storageName) as any);

		if (!this.dataStorage?.companyId || this.dataStorage?.companyId === null || (Number(this.dataStorage?.companyId) === Number(this._userService.user?.companyId))) {
			this.childCompanyControl.reset();

		} else {
			this.childCompanyControl.setValue({
				isParentSelector: false,
				companySelected: {
					id: this.dataStorage?.companyId,
					name: this.dataStorage?.companyName
				}
			});
		}

		this.childCompanyChanged();
	}
}
