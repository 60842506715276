import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GenericTable, RolesAndPermissionService, UserRoleForms, UserRoleForms2 } from '@app/modules/groups-and-permissions';
import { ItemPerPageComponent } from '@app/shared/components/paginator/item-per-page/item-per-page.component';
import { PaginatorComponent } from '@app/shared/components/paginator/paginator.component';
import { PaginationConstants, TableMessages } from '@app/shared/constants';
import { BehaviorSubject, filter, map, Observable, of, Subscription, take, tap } from 'rxjs';

@Component({
  selector: 'app-table-role-forms',
  templateUrl: './table-role-forms.component.html',
  styleUrls: ['./table-role-forms.component.scss']
})
export class TableRoleFormsComponent implements OnInit, OnDestroy, AfterViewInit {
  // inject
  rolesAndPermission = inject(RolesAndPermissionService)

  // Viewchild
  @ViewChild(MatSort) sort: MatSort;
	@ViewChild(PaginatorComponent) paginator: PaginatorComponent;
	@ViewChild(ItemPerPageComponent) itemsPerPage: ItemPerPageComponent;


  // props
  displayedColumns = ['check', 'isAccess', 'title', 'categories'];
  dataSource = new MatTableDataSource<UserRoleForms>();
  order: SortDirection;
  message = {
		noRecord: TableMessages.EmptyTable,
	};
  private _table$ = new BehaviorSubject<GenericTable | null>(null)//:Observable<GenericTable|null> = of(null);
  data = new BehaviorSubject<GenericTable|null>(null)
  hasData = false;
  pageSizes = PaginationConstants.pageSizes;
  subs = new Subscription()
  isCheckedAll = false;
  countCheckedItems = 0;
  private _buttonLabel = {
    default: 'Change Access',
    withValue: 'Change Access for (%d) Forms'
  }


  // init
  constructor() { }
  ngOnInit() {
    this._eventListener();
  }
  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe((v) => {
      this.rolesAndPermission.setQueryParams({
        column: v.active,
        order: v.direction
      })
			this.getRoleForms();
		});

    this.formType = this.rolesAndPermission.formType;


    console.log('sort', this.sort)
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


  // public method
  sortData(event:any){
  }

  getRoleForms(){
    if(this.rolesAndPermission.formType === 'role'){
      this.rolesAndPermission.initRoleForms()
    }else{
      this.rolesAndPermission.initUserForms()
    }
  }

  updatePage(page:number=1){
    this.rolesAndPermission.setQueryParams({page})
    this.getRoleForms()
  }
  updateSize(pageSize:number){
    this.rolesAndPermission.setQueryParams({pageSize})
    this.getRoleForms()
  }

  checkRow(row: UserRoleForms2){
    let table = this._table$.value;
    let data = (table?.data as UserRoleForms2[]).map(v=>{
      if(v.id == row.id){
        return {...v, ...{isCheck: !row.isCheck}}
      }else return v
    })
    table!.data = data;
    this._table$.next(table);
  }

  checkAll(){
    this.isCheckedAll = !this.isCheckedAll;
    let table = this._table$.value;
    let data = (table?.data as UserRoleForms2[]).map(v=>{
      return {...v, ...{isCheck: this.isCheckedAll}}
    })
    table!.data = data;
    this._table$.next(table);
  }

  toggleAccess(form:any){
    //console.log('form', form);
    //this.rolesAndPermission.addUpdateUserRoleForm(form);
  }

  // private method
  private _eventListener(){
    console.log('formType', this.formType)
    console.log('formType2', this.rolesAndPermission.formType)
    if(this.formType==='user'){
      this.subs.add(
        this.rolesAndPermission.userForms$
          .pipe(
            filter(v=>v!=null),
            map(v=>{
              let data = v?.data.map(x=>({...x, ...{isCheck: false}}))
              return {...v, ...{data:data}} as GenericTable
            })
          )
          .subscribe(v=>{
            this._table$.next(v)
          })
      )
    }else{
      this.subs.add(
        this.rolesAndPermission.roleForms$
          .pipe(
            tap(v=>console.log('roleforms tap', v)),
            filter(v=>v!=null),
            map(v=>{
              let data = v?.data.map(x=>({...x, ...{isCheck: false}}))
              return {...v, ...{data:data}} as GenericTable
            })
          )
          .subscribe(v=>{
            console.log('pasok sa roleForms$', v)
            this._table$.next(v)
          })
      )
    }

    this.subs.add(
      this.table$
        .pipe(
          filter(v=>v!=null),
          tap(v=>{
            this.countCheckedItems = 0;
            let checkAll:boolean[] = [];
            v?.data.map(x=>{
              checkAll.push((x as UserRoleForms2).isCheck!)
              //if((x as UserRoleForms2).isCheck) this.countCheckedItems++;
            })
            checkAll = checkAll.filter(x=>{
              if(x) this.countCheckedItems++; return !x;
            })
            if(checkAll.length > 0) this.isCheckedAll = false
            else this.isCheckedAll = true;
            this.setButtonLabel();
          })
        )
        .subscribe(v=>{
          if(v!.data.length > 0) this.hasData = true
          else this.hasData = false

          this.dataSource = new MatTableDataSource(v!.data)
        })
    )
  }

  // setters and getters
  get table$(){
    return this._table$.asObservable()
  }

  setButtonLabel(){
    let label = '';
    if(this.countCheckedItems > 0)
      label = this._buttonLabel.withValue.replace('%d', (this.countCheckedItems as unknown as string));
    else
      label = this._buttonLabel.default;

    this.rolesAndPermission.buttonLabel$.next(label)
  }

  get formType(){return this.rolesAndPermission.formType;}
  set formType(type: 'user' | 'role'){this.rolesAndPermission.formType=type}

}
