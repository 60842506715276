import { ManageUserGroupComponent } from '../groups/pages/manage-user-group/manage-user-group.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuPermissionGuard } from '@app/core/guards/menu-permission.guard';
import { UserTypeGuard } from '@app/core/guards/user-type.guard';
import { UserTypes } from '@app/shared/constants';
import { AddUpdateRoleComponent, RolesAndPermissionsComponent, UserRoleFormsComponent } from './new-pages';
import { RPManageUsers } from './new-pages/manage-users/manage-users.component';
import { RPManageFormsAccess } from './new-pages/manage-forms-access/manage-forms-access.component';



const routes: Routes = [
  {
    path: '',
    // component: GroupsAndPermissionsListComponent,
    component: RolesAndPermissionsComponent,
    data: {
      title: 'Groups and Permissions',
      userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
    },
    // canActivate: [UserTypeGuard, MenuPermissionGuard],
    children: [
      {
        path: 'manage-user-group',
        component: ManageUserGroupComponent,
        data: {
          title: 'Manage User Group',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard, MenuPermissionGuard]
      },
    ]
  },
  {
    path: 'add', 
    component: AddUpdateRoleComponent,
    data: {
      title: 'Create New Role',
      userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
    },
    //canActivate: [UserTypeGuard, MenuPermissionGuard]
  },
  {
    path: 'add/:companyId', 
    component: AddUpdateRoleComponent,
    data: {
      title: 'Manage User Group',
      userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
    },
    //canActivate: [UserTypeGuard, MenuPermissionGuard]
  },
  {
    path: 'edit', 
    redirectTo: '',
  },
  {
    path: 'edit/:id', 
    component: AddUpdateRoleComponent,
    data: {
      title: '_rpRoleName',
      userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
    },
    //canActivate: [UserTypeGuard, MenuPermissionGuard]
  },
  {
    path: 'override', 
    redirectTo: '',
  },
  {
    path: 'override/:roleId', 
    redirectTo: '',
  },
  {
    path:'override',
    data: {
      title: '_rpRoleName',
      userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
    },
    canActivate: [UserTypeGuard],
    children: [
      {
        path:':roleId/:userId',
        component: AddUpdateRoleComponent, 
        data: {
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      }
    ]
  },

  // {
  //   path: 'override/:roleId/:userId', 
  //   component: AddUpdateRoleComponent, 
  //   data: {
  //     title: 'Override Role',
  //     userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
  //   },
  //   //canActivate: [UserTypeGuard, MenuPermissionGuard]
  // },
  {
    path: 'user-forms', 
    redirectTo: '',
  },
  {
    path: 'user-forms/:id', 
    component: UserRoleFormsComponent,
    data: {
      title: 'Manage User Group',
      userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
    },
    //canActivate: [UserTypeGuard, MenuPermissionGuard]
  },
  {
    path: 'role-forms', 
    redirectTo: '',
  },
  {
    path: 'role-forms/:id', 
    component: UserRoleFormsComponent,
    data: {
      title: 'Manage User Group',
      userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
    },
    //canActivate: [UserTypeGuard, MenuPermissionGuard]
  },
  {
    path: 'manage/:id',
    data: {
      title: '_rpRoleName',
      userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
    },
    canActivate: [UserTypeGuard],
    children: [
      {
        path: 'users',
        component: RPManageUsers,
        data: {
          title: 'Users',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      },
      {
        path: 'request-forms',
        component: RPManageFormsAccess,
        data: {
          title: 'Request Forms',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      },
      {
        path: 'request-forms/:userId',
        component: RPManageFormsAccess,
        data: {
          title: 'Override Request Forms',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      },
      {
        path: 'ticket-boards',
        component: RPManageFormsAccess,
        data: {
          title: 'Ticket Boards',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      },
      {
        path: 'ticket-boards/:userId',
        component: RPManageFormsAccess,
        data: {
          title: 'Override Ticket Boards',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      },
      {
        path: 'knowledge-base',
        component: RPManageFormsAccess,
        data: {
          title: 'Knowledge Base',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      },
      {
        path: 'knowledge-base/:userId',
        component: RPManageFormsAccess,
        data: {
          title: 'Override Knowledge Base',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      },
      {
        path: 'applications',
        component: RPManageFormsAccess,
        data: {
          title: 'Applications',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      },
      {
        path: 'applications/:userId',
        component: RPManageFormsAccess,
        data: {
          title: 'Override Applications',
          userTypes: [UserTypes.ClientAdmin,UserTypes.SourcepassAdmin],
        },
        canActivate: [UserTypeGuard]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupsAndpermissionsRoutingModule { 
}
