import { Injectable } from '@angular/core';
import { SelectItem } from '@app/shared/interfaces/support.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SelectInputService {
  private _overrideItems = new BehaviorSubject<SelectItem[]>([]);
  public overrideItems = this._overrideItems.asObservable();

  public setOverrideOptions(items: any) {
    this._overrideItems.next(items);
  }
}
