import {
	Component,
	OnInit,
	ChangeDetectorRef,
	Inject,
	ViewChild,
	OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import {
	map,
	Observable,
	shareReplay,
	take,
	tap,
	catchError,
	switchMap,
	startWith,
	Subject,
	takeUntil,
} from 'rxjs';
import { select, Store } from '@ngrx/store';

import { setAppTheme } from '@app/shared/utilities/theme';

import { ImageService } from '@app/shared/services/image.service';
import { AccountService } from '@app/modules/account/account.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SpinnerService } from '@app/core/services/spinner.service';
import { UserService } from '@app/core/services/user.service';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { UserNotificationService } from '@app/shared/services/user-notification.service';
import { DashboardDrawerService } from '@app/shared/services/dashboard-drawer-service';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { GlobalSearchService } from '@app/modules/global-search/global-search.service';
import { TopBarService } from './top-bar.service';
import { SideBarService } from '../side-bar/side-bar.service';
import { IdleService } from '@app/core/services/idle.service';
import { FigmaDemoService } from '@app/figma-demo/figma-demo.service';

import {
	UserTypes,
	NotificationMessages,
	UserMessageCategoryEnum,
	Permission,
	Menus,
} from '@app/shared/constants';

import { ITopBar } from './top-bar.interface';
import { AppStateInterface } from '@app/core/store/app-state.interface';
import { User } from '@app/shared/interfaces/user.interface';
import { IFigmaIcon } from '@app/shared/interfaces/generic.interface';
import { Notification, NotificationCategory } from '@app/modules/communications/interfaces/notifications.interface';

import { ImpersonateAlertBarService } from '@app/core/components/impersonate-alert-bar/impersonate-alert-bar.service';

import { impersonateSelector } from './store/top-bar.selector';
import { userDataSelector } from '@app/core/store/user/user.selector';
import { topBarUpdateAction } from './store/top-bar.action';

import { GlobalSearchComponent } from './global-search/global-search.component';

@Component({
	selector: 'nav-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {
	@ViewChild(GlobalSearchComponent)
	globalSearchComponent: GlobalSearchComponent;
	impersonateOptions$: Observable<{ isOpen: boolean }>;
	isLogoHasLoaded: boolean = false;
	notificationCount = 0;
	isImpersonateLogoutOpen: boolean = false;
	colorScheme = 'light';

	logo$: Observable<string>;
	user$: Observable<User | null>;

	search = '';
	bellNotifications: null | Notification[];
	isMailEnabled = this.userService.hasMenu([Menus.UserViewMailbox]);

	isImpersonateEnabled: boolean;
	
	private _$unsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		public userService: UserService,
		public idleService: IdleService,
		private _notifier: NotificationService,
		private spinner: SpinnerService,
		private router: Router,
		private _cd: ChangeDetectorRef,
		private store: Store<AppStateInterface>,
		private _companiesService: CompaniesService,
		private _themeService: ThemeService,
		private _accountService: AccountService,
		private _userNotification: UserNotificationService,
		private _imageService: ImageService,
		private _globalSearch: GlobalSearchService,
		private localStorageService: LocalStorageService,
		private topBarService: TopBarService,
		private _impersonateAlertBarService: ImpersonateAlertBarService
	) {
		this.user$ = this.store.pipe(select(userDataSelector));
	}

	public get UserTypes() {
		return UserTypes;
	}

	get img() {
		let profileImg: any = this._accountService.getProfileImg();

		if (!profileImg || profileImg?.includes('profilePic.png')) {
			const figma: IFigmaIcon[] = JSON.parse(
				localStorage.getItem('newFigma') || ''
			);
			profileImg = figma.filter((e) => e.name === 'user-circle')[0].iconUrl;
		}

		return profileImg;
	}

	get isUserRole() {
		return this.userService.userRole === UserTypes.User;
	}

	get isOpenSearchForm() {
		return this.globalSearchComponent?.isSearchOpened;
	}

	ngOnInit() {
		// init store
		this.impersonateOptions$ = this.store.pipe(select(impersonateSelector));
		this.initTopBarStore();
		// this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		// Moved Branding changes to side bar

		this._getUserProfile();

		if (!this.userService.user?.impersonatingBy) {
			this._userNotification.notificationCount$.subscribe({
				next: (c) => {
					this.notificationCount = c;
					this._cd.detectChanges();
				},
			});
		}

		this.userService.userChange$.subscribe((res) => {
			this.isImpersonateEnabled = this.userService.hasPermission(
				Permission.SpAdminImpersonation
			);
			this.isMailEnabled = this.userService.hasMenu([Menus.UserViewMailbox]);
		});

		this._onImpersonateEvent();
	}

	onImpersonateClick() {
		this.topBarService.onImpersonateClick();
	}

	initTopBarStore() {
		let topBar = this.localStorageService.getStorageObject('topBar') as ITopBar;
		if (!topBar) {
			this.store.dispatch(topBarUpdateAction({}));
		}
	}

	clearAllNotification(e: any) {
		e.stopPropagation();
		const tempNotifs = this.bellNotifications;
		this.bellNotifications = [];

		this._userNotification.markReadNotifications().subscribe({
			error: (err) => {
				this.bellNotifications = tempNotifs;
				this._notifier.notifyError(
					'Clear all failed',
					NotificationMessages.Try
				);
			},
		});
	}

	searchGlobal() {
		if (this.userService.userRole === UserTypes.User) {
			this.spinner.start();
			this._globalSearch.getGlobalList(this.search).subscribe((resp: any) => {
				let hasData = false;

				for (const table of resp.tables) {
					if (table.data.length > 0) {
						hasData = true;
						break;
					}
				}

				if (hasData) {
					this.router.navigate(['global-search'], {
						queryParams: { search: this.search },
					});
				} else {
					this.spinner.stop();
					this._notifier.notify(
						'No result was found from the keyword you search',
						{ duration: 5, panelClass: 'success' }
					);
				}
			});
		}
	}

	getCompanyData() {
		return this._companiesService.getCompanyBranding(
			this.userService.user!.companyId
		);
	}

	themeToggled(colorScheme: string) {
		this._themeService.themeType.pipe(take(1)).subscribe((theme) => {
			if (colorScheme == theme) {
				return;
			} else {
				this.colorScheme = colorScheme;
				this.isLogoHasLoaded = false;
				localStorage.setItem(
					'elevate.prefers-color-scheme-' + this.userService.user!.id,
					colorScheme
				);
				setAppTheme(
					colorScheme,
					this._companiesService.branding.lightThemeColor,
					this._companiesService.branding.darkThemeColor
				);
				this._themeService.changeThemeType(colorScheme);

				this._notifier.notify(NotificationMessages.themeMode(colorScheme), {
					duration: 3,
					panelClass: 'success',
				});
			}
		});
	}

	loadingLogo() {
		this.isLogoHasLoaded = true;
	}

	switchMode() {
		this.userService.switchUserRole();
		(document as any).getElementById('sidenav-main').scrollTop = 0;
	}

	checkImpersonateLogout() {
		this.isImpersonateLogoutOpen = !this.isImpersonateLogoutOpen;
	}

	private _getUserProfile() {
		this._accountService
			.getUserData(this.userService.user!.id)
			.pipe(
				catchError((e) => {
					this._accountService.setProfileImg(
						this._imageService.getDefaultProfilePic()
					);
					this._cd.markForCheck();
					throw e;
				})
			)
			.subscribe((resp: any) => {
				let profilePicture = resp.imagePath;

				if (this._isNullorEmpty(profilePicture)) {
					profilePicture = this._imageService.getDefaultProfilePic();
				}

				this._accountService.setProfileImg(profilePicture);
				this._cd.markForCheck();
			});
	}

	getThemeClasses(value: string): string {
		return `theme-icon ${this.colorScheme === value ? 'enabled' : ''}`;
	}

	private _isNullorEmpty(str: string) {
		return str === null || str === 'null' || str.trim() === '';
	}

	getBellNotifications() {
		if(this.notificationCount && !this.bellNotifications?.length)
			this.bellNotifications = null;
		this._userNotification.getNotifications(true).subscribe({
			next: (res) => this.bellNotifications = res,
			error: (err) => this.bellNotifications = []
		});
	}

	openNotification(n: Notification, index: number) {
		if (n.categoryId == NotificationCategory.Tickets) {
			if(!this.userService.isUser) window.open('/service-and-support/tickets/view/' + n.refId, '_blank');
			else this.router.navigateByUrl('/service-and-support/tickets/view/' + n.refId);
		}
		else if (n.categoryId == NotificationCategory.Approvals) {
			if(!this.userService.isUser) window.open('/u/communications/approvals', '_blank');
			else this.router.navigateByUrl('/u/communications/approvals');
		}
		else if (n.categoryId == NotificationCategory.Invoices) {
			if(this.userService.isSpAdmin) this.router.navigateByUrl('/billing-orders/invoices/invoice-detail/' + n.refId);
			else if(this.userService.isClientAdmin) window.open('/billing-and-orders/invoices/invoice-detail/' + n.refId, '_blank');
			else this.router.navigateByUrl('/billing-and-orders/invoices/invoice-detail/' + n.refId);
		}
		else if (n.categoryId == NotificationCategory.Orders) {
			if(!this.userService.isUser) window.open('/billing-and-orders/orders/' + n.refId, '_blank');
			else this.router.navigateByUrl('/billing-and-orders/orders/' + n.refId);
		}
		else if (
			n.categoryId == NotificationCategory.Quest &&
			n.title.includes('Quest')
		) {
			const p = n.title.includes('Desktop') ? 1 : n.title.includes('Mobile') ? 2 : 0,
				v = n.message.split('v. ')[1]?.split(' ')[0];
			this.router.navigateByUrl(`/release-notes?p=${p}&v=${v}`);
		}
		
		const tempNotif = this.bellNotifications!.splice(index, 1);
		const ids = n.ids?.length ? n.ids : [n.id];
		this._userNotification.markReadNotifications(ids).subscribe({
			error: (err) => this.bellNotifications!.unshift(...tempNotif)
		});
	}

	private _onImpersonateEvent() {
		this._impersonateAlertBarService.topBarCwUser
		.pipe(
			startWith(''),
			takeUntil(this._$unsubscribe)
		)
		.subscribe({
			next: (isImpersonating) => {
				if (isImpersonating) {
					console.log('_onImpersonateEvent')
					this._getUserProfile();
				}
			}
		});
	}

	get permission() {
		return Permission;
	}

	ngOnDestroy(): void {
		this._$unsubscribe.next();
		this._$unsubscribe.complete();
	}
}
