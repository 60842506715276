import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	inject,
	Output,
} from '@angular/core';

import { ApplicationType } from '@app/shared/constants';

@Component({
	selector: 'export-btn',
	templateUrl: './export.component.html',
	styleUrls: ['./export.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportBtnComponent {
	@Output() onExport = new EventEmitter<ApplicationType>();

	type = ApplicationType;

	private _cd = inject(ChangeDetectorRef);

	export(type: ApplicationType) {
		this.onExport.emit(type);
	}

	onClose() {
		setTimeout(() => {
			this._cd.detectChanges();
		});
	}
}
