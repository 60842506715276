import { Directive, ElementRef, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent, tooltipData } from './tooltip/tooltip.component';
import { Subscription } from 'rxjs';
import { validationMessages } from './tooltip.constant';

export interface ErrorTooltipData {
  error: FormError;
  message: string;
}

type FormError = keyof typeof validationMessages;

@Directive({
  selector: '[errorTooltip]'
})
export class ErrorDirective implements OnInit, OnChanges, OnDestroy{
  /**
   * @example
   *  <input
        errorTooltip
        etMessage="Please enter a value"
        [etShow]="form.get('title')!.invalid && form.get('title')!.touched">
   *
      <input
        errorTooltip
        [etControl]="form.get('title')!"
        [etIsTouched]="form.get('title')!.touched">
   *
      <input
        errorTooltip
        [etControl]="form.get('title')!"
        [etIsTouched]="form.get('title')!.touched"
        [etMessage]="[{error: 'required', message: 'Please enter a value'}, {error: 'minlength', message: 'Must be greater than 5'}]">
   */

  /**Tooltip message.
   * - ETMessage array is required if there are multiple errors.
   * - If not set default error message will be displayed.
   * @example 'This field is required' or
   * [{error: 'required', message: 'This field is required'}, {error: 'email', message: 'Invalid email'}]*/
  @Input() etMessage?: string | ErrorTooltipData[];
  /**Field FormControl. Not required if etShow is set.*/
  @Input() etControl?: AbstractControl;
  /**Boolean to trigger tooltip if value is invalid and touched. Optional if etControl is set.*/
  @Input() etIsTouched?: boolean;
  /**Boolean to toggle the tooltip. Not required if etControl is set.*/
  @Input() etShow?: boolean;
  @Input() etPosition?: string;
  @Input() fieldName = 'This field';
  @Input() scrollEvent = false; // Add cdkScrollable directive to parent instead
  @Input() isForErrorContentOnly: boolean = false;
  @Input() isHideErrorTooltipMessage: boolean = false;

  private _overlayRef: OverlayRef;
  private _tooltips: {[key: string]: ComponentPortal<TooltipComponent>} = {};
  private _tooltip: ComponentPortal<TooltipComponent> | null;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _elRef: ElementRef,
    private _overlay: Overlay,
    private _injector: Injector
  ) { }

  ngOnInit(): void {
    const noWidth = this._elRef.nativeElement.localName === 'select-input';
    if(noWidth) this._elRef = new ElementRef(this._elRef.nativeElement.firstChild);

    this._initOverlay();

    if(this.etControl)
      this._subscriptions.push(
        this.etControl.valueChanges.subscribe(s => this._check())
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['etMessage']) this._initTooltip();
    if(changes['etShow']) this._check();
    if(changes['etIsTouched']) setTimeout(() => this._check(), 200);
    if(changes['scrollEvent']){
      try{this._overlayRef?.detach()} catch(e){} // this will conflict with Quest-6429 but use this instead due to initTooltip
      setTimeout(()=>{try{this._overlayRef?.attach(this._initTooltip())}catch(e){}}, 1000)
    }
  }

  private _initOverlay() {
    const positionStrategy = this._overlay.position()
    .flexibleConnectedTo(this._elRef)
    .withPositions([{
      originY: this.etPosition ? 'bottom' : 'top',
      originX: 'center',
      overlayX: 'center',
      overlayY: this.etPosition ? 'top' : 'bottom'
    }])
    .withFlexibleDimensions(false)
    .withPush(false);

    this._overlayRef = this._overlay.create({
      positionStrategy: positionStrategy,
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
      panelClass: 'error-tooltip',
    });
  }

  private _initTooltip() {
    this._tooltips = {};
    this._overlayRef?.detach();
    this._elRef.nativeElement.classList.remove('is-invalid');
    this._check();
  }

  private _check() {
    const el = this._elRef.nativeElement as HTMLElement;

    if(!this._overlayRef) return;

    if(this.etShow || (this.etControl?.invalid && this.etControl.touched)) { //(this.etControl.dirty || this.etControl.touched)
      // if (this.etControl!.errors! === undefined || this.etControl!.errors! === null) { // etControl is optional and if the Formcontrol is invalid then there should be errors value
      if (this.etControl && (this.etControl.errors === undefined || this.etControl.errors === null)) {
        return;
      }

      const error = this.etControl ? Object.keys(this.etControl!.errors!)[0] as FormError : 'required';

      if(this._tooltip && this._tooltip === this._tooltips[error]) return;
      else if(this._tooltips[error]) this._tooltip = this._tooltips[error];
      else {
        const message = typeof(this.etMessage) === 'string' ? this.etMessage : this.etMessage?.find(m => m.error === error)?.message;
        this.addTooltip(error, message, this.etControl?.errors![error]);
        this._tooltip = this._tooltips[error];
      }

      this._overlayRef?.detach(),
      this._overlayRef?.attach(this._tooltip);

      this.#toggleIsInvalidClass(el, 'add');

      if (this.isForErrorContentOnly) {
        const errorMessage = typeof(this.etMessage) === 'string' ? this.etMessage : this.etMessage?.find(m => m.error === error)?.message;
        this.setErrorContent(errorMessage);
      }
    }
    else if(this._overlayRef.hasAttached() || !this.etShow) {
      this._overlayRef.detach();
      this._tooltip = null;
      this.#toggleIsInvalidClass(el, 'remove');

      if (this.isForErrorContentOnly) {
        this.setErrorContent(null);
      }
    }
  }

  #toggleIsInvalidClass(el: HTMLElement, flag: string) {
    if (this.#isInputOrTextarea(el)) {
      if (flag === 'add') {
        el.classList.add('is-invalid');
      } else {
        el.classList.remove('is-invalid');
      }
    } else {
      for (const txtbox of el.querySelectorAll('input')) {
        if (flag === 'add') {
          txtbox.classList.add('is-invalid');
        } else {
          txtbox.classList.remove('is-invalid');
        }
      }
    }
  }

  #isInputOrTextarea(el: HTMLElement) {
    return el.tagName.toLowerCase() === 'input' || el.tagName.toLowerCase() === 'textarea';
  }

  addTooltip(error: FormError, message: string = '', errorData: any) {
    const defaultMessage = validationMessages[error];
    const data = Injector.create({
      parent: this._injector,
      providers: [{
          provide: tooltipData,
          useValue: { message: message || this.#getValidationMessage(defaultMessage, errorData,error), type: 'error', position: this.etPosition ? this.etPosition : 'top', isHide: this.isHideErrorTooltipMessage || this.isForErrorContentOnly }
        }]
    });

    this._tooltips[error] = new ComponentPortal(TooltipComponent, null, data);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._overlayRef?.dispose();
  }

  #getValidationMessage(defaultMessage: Function | string, errorData: any, error: FormError): any {
    let message;

    if (typeof(defaultMessage) === 'string') {
      message = defaultMessage;
    } else if (typeof(defaultMessage) === 'function') {
      message = defaultMessage(error === 'required' ? this.fieldName : errorData);
    }
    return message;
  }

  setErrorContent(message: string | null | undefined) {
    this._elRef.nativeElement.innerHTML = ![undefined, null, ''].includes(message) ? message : '';
  }
}
