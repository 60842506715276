import { SocialUser } from '@abacritt/angularx-social-login';
import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AccountService } from '@app/modules/account/account.service';
import { twoFAResponse } from '@app/modules/account/pages/user/authentication/two-factor-auth/two-factor-auth.interface';
import { CompaniesService } from '@app/modules/companies/companies.service';
import { GroupsAndPermissionsService } from '@app/modules/groups-and-permissions/groups-and-permissions.service';
import {
	ApiRoutes,
	Menus,
	Permission,
	UserTypeNames,
	UserTypes,
} from '@app/shared/constants';
import { configUpdateAction } from '@app/shared/navigation/configuration/store/configuration.action';
import { SideBarService } from '@app/shared/navigation/side-bar/side-bar.service';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { NavigationService } from '@app/shared/services/navigation.service';
import { SessionStorageService } from '@app/shared/services/session-storage.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { setAppTheme } from '@app/shared/utilities/theme';
import { MsalService } from '@azure/msal-angular';
import { User, UserActivity } from '@interfaces/user.interface';
import {
	DefaultProjectorFn,
	MemoizedSelector,
	Store,
	select,
} from '@ngrx/store';
import { environment } from 'environments/environment';
import {
	BehaviorSubject,
	Observable,
	catchError,
	filter,
	map,
	merge,
	of,
	switchMap,
	take,
	tap,
	throwError,
} from 'rxjs';
import { SmsQuestMobileUpdateModalService } from '../components/sms-quest-mobile-update-modal/sms-quest-mobile-update-modal.service';
import { AppStateInterface } from '../store/app-state.interface';
import { jwtRemoveAction, jwtUpdateAction } from '../store/jwt/jwt.action';
import { IJWT } from '../store/jwt/jwt.interface';
import {
	adminUrlUpdateAction,
	jwtDecodeUpdateAction,
	userFirstLoginAction,
	userUpdateAction,
	userUrlUpdateAction,
} from '../store/user/user.action';
import {
	adminUrlSelector,
	jwtDecodeSelector,
	userUrlSelector,
} from '../store/user/user.selector';
import { ColorService } from './../../shared/services/color.service';
import { JwtService } from './jwt.service';
import { SpinnerService } from './spinner.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private _user: User | null;
	private _isAuthenticated = new BehaviorSubject<boolean>(false);
	private _userChange = new BehaviorSubject<User | null>(null);
	isAuthenticated$ = this._isAuthenticated.asObservable();
	userChange$ = this._userChange.asObservable();
	sourcepassLogo: string = 'header-logo';
	#state: any = {
		isCWPod: false,
	};
	decodedJwt: any;

	constructor(
		private msalService: MsalService,
		private _http: HttpClient,
		private _router: Router,
		private _companiesService: CompaniesService,
		private _themeService: ThemeService,
		private _sessionStorageService: SessionStorageService,
		private _spinner: SpinnerService,
		private _colorService: ColorService,
		private localStorageService: LocalStorageService,
		private store: Store<AppStateInterface>,
		private _account: AccountService,
		private sidebarService: SideBarService,
		private jwtService: JwtService,
		private _groupsPermission: GroupsAndPermissionsService,
		private navigationService: NavigationService,
		private _smsQuestMobileUpdateModalService: SmsQuestMobileUpdateModalService
	) {}

	get user(): Readonly<User | null> {
		return this._user;
	}

	get userRole() {
		return this._user?.roleId!;
	}

	get userId() {
		return this._user?.id!;
	}

	get companyId() {
		return this._user?.companyId!;
	}

	get isAuthenticated() {
		return this._isAuthenticated.value;
	}

	get isSpAdmin() {
		return this.userRole == UserTypes.SourcepassAdmin;
	}

	get isClientAdmin() {
		return this.userRole == UserTypes.ClientAdmin;
	}

	get isUser() {
		return this.userRole == UserTypes.User;
	}

	get isKB4Manager() {
		return this._user?.isKB4Manager;
	}

	get sessionId() {
		return this.decodedJwt['sessionId'];
	}

	get timezone() {
		return this._user?.timezoneValue;
	}

	set isConnectWise(flag: boolean) {
		this.#state.isCWPod = flag;
		this._companiesService.isConnectWise = flag;
		this._groupsPermission.isConnectWise = flag;
	}

	get isConnectWise() {
		return this.#state.isCWPod;
	}

	get isProdDev() {
		return this._user?.isProductDevelopment;
	}

	ssoLogout() {
		this.msalService.logout();
	}

	isLoggedIn(): boolean {
		return this.msalService.instance.getActiveAccount() != null;
	}

	continuePendingLogin(companyId: number) {
		return this._http
			.post<IJWT>(
				`${environment.apiBaseUrl}${ApiRoutes.AUTH_CONTINUE_PENDING_LOGIN}/${companyId}`,
				null
			)
			.pipe(
				switchMap((jwt: IJWT) => {
					this.store.dispatch(jwtUpdateAction(jwt));
					return this.refreshUser();
				})
			);
	}

	getUserCompanies() {
		return this._http.get<any>(environment.apiBaseUrl + 'Users/GetCompanies');
	}

	getMsgMode(): string {
		if (this.isSpAdmin || this.isClientAdmin) {
			return 'Back to User Mode';
		} else {
			return 'Switch to Admin Mode';
		}
	}

	switchUserRole(redirect = true) {
		return new Promise<boolean>((resolve) => {
			this._spinner.start();

			this.switchRole()
				.pipe(
					take(1),
					switchMap((jwt: IJWT) => {
						this.store.dispatch(
							jwtUpdateAction({
								accessToken: jwt.accessToken,
								refreshToken:
									this.localStorageService.getStorageObject('refreshToken'),
							})
						);
						return this.refreshUser();
					}),
					switchMap((_res) => this.sidebarService.getSideBarContents()),
					tap({
						next: (data) => {
							this._spinner.stop();
							this.sidebarService.getContents(data);
							if (redirect) this.redirectToDefault();
							resolve(true);
						},
						error: () => {
							this._spinner.stop();
							resolve(false);
						},
					})
				)
				.subscribe(() => {});
		});
	}

	redirectToDefault(): void {
		let selector: MemoizedSelector<
			AppStateInterface,
			string,
			DefaultProjectorFn<string>
		>;
		switch (this._user!.roleId) {
			case 1:
			case 2:
				selector = adminUrlSelector;
				break;
			case 3:
			default:
				selector = userUrlSelector;
		}
		const invalidUrls = ['verify-email'];
		this.store
			.pipe(
				select(selector),
				take(1),
				switchMap((url) => {
					if (!url || url === '/' || checkStringInArray(invalidUrls, url)) {
						return this.navigationService.smartUrl();
					}
					return of(url);
				})
			)
			.subscribe((url) => {
				if (url !== '/page-forbidden') {
					if (selector === userUrlSelector) {
						this.store.dispatch(userUrlUpdateAction({ url: url }));
					} else if (selector === adminUrlSelector) {
						this.store.dispatch(adminUrlUpdateAction({ url: url }));
					}
				}
				this._router.navigate([url]);
			});
		// let url = localStorage.getItem('userUrl');
		// localStorage.setItem('prevUrl', this._router.url);
		// url = (this.getMsgMode() == 'Switch to Admin Mode')
		// ? './dashboard'
		// : './service-and-support/categories';
		// this._router.navigate([url]);
	}

	setRoleId(id: UserTypes) {
		this._user!.roleId = id;
	}

	setRole(name: UserTypeNames) {
		this._user!.role = name;
	}

	getNewAccessToken(isNewSession: boolean = false): Observable<IJWT> {
		const isSessionExpired = this._istokenExpired(
			JSON.stringify(sessionStorage.getItem('accessToken'))
		);
		const accessToken = !isSessionExpired
			? sessionStorage.getItem('accessToken')
			: this.localStorageService.getStorageObject('accessToken');
		const refreshToken =
			this.localStorageService.getStorageObject('refreshToken');

		let newHeaders: HttpHeaders = new HttpHeaders();
		newHeaders = newHeaders.set('Authorization', 'Bearer ' + accessToken);

		return this._http.post<IJWT>(
			environment.apiBaseUrl + ApiRoutes.AUTH_REFRESHTOKEN,
			{
				accessToken: accessToken,
				refreshToken: refreshToken,
				isNewSession: isNewSession,
			},
			{ headers: newHeaders }
		);
	}

	private _istokenExpired(token: string) {
		const expiry = JSON.parse(atob(token.split('.')[1])).exp;
		return Math.floor(new Date().getTime() / 1000) >= expiry;
	}

	signInWithEmail(email: string): Observable<{ id: string } | User> {
		const body = { email };
		return this._http.post<{ id: string } | User>(
			`${environment.apiBaseUrl}${ApiRoutes.AUTH_SEND_TOKEN}`,
			body
		);
	}
	validateJWTMicrosoft(idToken: string) {
		const body = { token: idToken },
			endpoint = ApiRoutes.AUTH_MICROSOFT_LOGIN;
		return this._http
			.post<IJWT>(`${environment.apiBaseUrl}${endpoint}`, body)
			.pipe(
				switchMap(this.processJWT),
				switchMap(this.processFirstLogin),
				map(this.authSuccessHandler)
			);
	}

	validateJWT(user: SocialUser) {
		const body = { token: user.idToken },
			endpoint =
				user.provider === 'GOOGLE'
					? ApiRoutes.AUTH_GOOGLE_LOGIN
					: ApiRoutes.AUTH_MICROSOFT_LOGIN;
		return this._http
			.post<IJWT>(`${environment.apiBaseUrl}${endpoint}`, body)
			.pipe(
				switchMap(this.processJWT),
				switchMap(this.processFirstLogin),
				map(this.authSuccessHandler)
			);
	}

	processJWT = (jwt: IJWT) => {
		// set jwt in localstorage
		this.store.dispatch(jwtUpdateAction(jwt));
		const decodedToken = this.jwtService.decodeJWT(jwt.accessToken!);
		this.decodedJwt = decodedToken;
		this.store.dispatch(jwtDecodeUpdateAction(decodedToken));

		if (this.decodedJwt.isAgree === 'False') {
			return this.firstLoginInfo();
		} else {
			return of(null);
		}
	};

	processFirstLogin = (res: any) => {
		if (res && res.hasOwnProperty('directExt')) {
			this.store.dispatch(userFirstLoginAction(res));
			localStorage.setItem('first-login', '1');
			this._router.navigate(['user-details'], { replaceUrl: true });
			return of(null);
		} else {
			// if null from processJWT
			return this.refreshUser();
		}
	};

	authSuccessHandler = (res: null | User) => {
		if (!res) {
			return;
		}
		this._user = res;
		// // check 2fa

		if (this._user.isPendingLogin === false) {
			this.authorize();
			this.loadCompanyBranding(res.companyId);
		} else if (this._user.isPendingLogin === true) {
			// do nothing
		} else if (this._user.twoFactorEnabled === true) {
			// do nothing
		} else {
			this.authorize();
		}
		return res;
	};

	validateCode(id: string, token: string) {
		const body = { id, token };
		return this._http
			.post<IJWT>(
				`${environment.apiBaseUrl}${ApiRoutes.AUTH_EMAIL_TOKEN_LOGIN}`,
				body
			)
			.pipe(
				switchMap(this.processJWT),
				switchMap(this.processFirstLogin),
				map(this.authSuccessHandler)
			);
	}

	authorize() {
		this.identifyPx();
		this._isAuthenticated.next(true);
	}

	resendCode(loginTokenId: string) {
		const body = { loginTokenId };
		return this._http.post<{ id: string }>(
			`${environment.apiBaseUrl}${ApiRoutes.AUTH_RESEND_TOKEN}`,
			body
		);
	}

	refreshUser() {
		return this._http
			.get<User>(environment.apiBaseUrl + 'Users/isAuthenticated')
			.pipe(
				tap((res) => {
					// update user
					this.store.dispatch(userUpdateAction(res));
					const token =
						sessionStorage.getItem('accessToken') ||
						this.localStorageService.getStorageObject('accessToken');
					if (token) {
						const decodedToken = this.jwtService.decodeJWT(token);
						this.decodedJwt = decodedToken;
						this.store.dispatch(jwtDecodeUpdateAction(decodedToken));
					}
					this._user = res;
					//console.log('user', res)
					this._userChange.next(this.user!);

					if (this.user?.impersonatingBy === null) {
						this._smsQuestMobileUpdateModalService.startEmitMobileOptInNotification(
							true
						);
					} else {
						this._smsQuestMobileUpdateModalService.isShowQuestMobileUpdate$ =
							of(false);
					}
				})
			);
	}

	decodeJWT() {}

	userSuccessHandler(res: User) {
		this._user = res;
		if (this._user.isPendingLogin === false) {
			this.loadCompanyBranding(res.companyId);
		}

		// check if user mode or sp admin mode
		this._isAuthenticated.next(true);
		return res;
	}

	reconnect() {
		return this.refreshUser().pipe(
			map((res) => {
				this._user = res;
				if (this._user.isPendingLogin === false) {
					this.loadCompanyBranding(res.companyId);
				}

				// check if user mode or sp admin mode
				this._isAuthenticated.next(true);
				return res;
			}),
			catchError((error: HttpErrorResponse) => {
				this._user = null;
				this._isAuthenticated.next(false);
				this.store.dispatch(jwtRemoveAction());
				return throwError(() => error);
			})
		);
	}

	firstLoginInfo() {
		return this._http.get(`${environment.apiBaseUrl}Users/FirstLoginInfo`);
	}

	async loadCompanyBranding(companyId: number) {
		await this._account.initBranding(this.userId);
		let user = this._companiesService.getCompanyBranding(companyId);
		let company = this._account.profileBranding$;
		let mergeTheme = merge(user, company);
		let darkModeColor = '#00baf2';
		let logoColorList: string[] = [];
		let isDarkMode: boolean = false;
		let isDefault: boolean = false;
		let lightColor = '#ffffff';

		//result.subscribe((f) => {
		mergeTheme
			.pipe(
				filter((v) => v != null),
				catchError((e) => [])
			)
			.subscribe((f) => {
				let dominant = this._colorService.dominantColor;
				const f_logoColorList = Array.isArray(f?.logoColorList)
					? f?.logoColorList!
					: [];

				if ('userId' in f! && f.userId != 0) {
					// user branding
					isDarkMode = f.isDarkMode;
					isDefault = f.isDefault;
					darkModeColor = f.darkThemeColor || '#00baf2';
					lightColor = f.lightThemeColor || '#ffffff';

					let theme = window.matchMedia('(prefers-color-scheme: dark)').matches
						? 'dark'
						: 'light';
					setAppTheme(
						theme,
						darkModeColor, //this._companiesService.branding.lightThemeColor,
						lightColor //this._companiesService.branding.darkThemeColor
					);

					this._themeService.changeThemeType(theme);
				}

				logoColorList = [
					...new Set([
						...logoColorList,
						...f_logoColorList,
						...dominant,
						...[lightColor, darkModeColor],
					]),
				];

				this.store.dispatch(
					configUpdateAction({
						darkModeColor,
						isDarkMode,
						isDefault,
					})
				);

				this._colorService.setDominantColor(logoColorList || []);

				this._companiesService.setCompanyBranding(lightColor, darkModeColor);
			});
	}

	impersonate(userId: number) {
		return this._http
			.post<User>(
				environment.apiBaseUrl + `users/StartImpersonate/${userId}`,
				{}
			)
			.pipe(
				map((res) => {
					this._user = res;
					return res;
				})
			);
	}

	endImpersonate() {
		return this._http.post<IJWT>(
			environment.apiBaseUrl + ApiRoutes.AUTH_END_IMPERSONATE,
			{}
		);
	}

	logout() {
		this.store
			.pipe(select(jwtDecodeSelector), take(1))
			.subscribe((decodedJwt) => {
				if (decodedJwt && decodedJwt['isOffice365'] === 'True') {
					this._user = null;
					this._sessionStorageService.clearStorage();
					this.msalService.logout();
					this.store.dispatch(jwtRemoveAction());
					this._isAuthenticated.next(false);
					this.localStorageService.removeStorageObject('userUrl');
					this.localStorageService.removeStorageObject('accessToken');
					this.localStorageService.removeStorageObject('refreshToken');
					this.localStorageService.removeStorageObject('adminUrl');
					this.localStorageService.removeStorageObject('topBar');
					this.localStorageService.removeStorageObject('idleExpTime');
				} else {
					this._user = null;
					this._sessionStorageService.clearStorage();
					this.localStorageService.clearStorage();
					this.store.dispatch(jwtRemoveAction());
					this._isAuthenticated.next(false);
					this._router.navigate(['/'], {
						// queryParams: { logout: true },
					});

					this.resetPx();

					setTimeout(() => {
						window.location.reload();
					}, 1000);
				}
				// logout all tabs
				localStorage.setItem('logout-event', Math.random().toString());
			});
	}

	logoutNoApi() {
		this._user = null;
		this._sessionStorageService.clearStorage();
		this.localStorageService.clearStorage();
		this.store.dispatch(jwtRemoveAction());
		this._isAuthenticated.next(false);
		this._router.navigate(['/'], { replaceUrl: true });
		window.location.reload();
	}

	idleLogout() {
		this.store.dispatch(jwtRemoveAction());
		localStorage.removeItem('prevUrl');

		this._isAuthenticated.next(false);
		this._router.navigate(['/'], { queryParams: { rdr: this._router.url } });
	}

	userList() {
		return this._http.get(environment.apiBaseUrl + 'users');
	}

	userById(id: number) {
		return this._http.get<User>(environment.apiBaseUrl + 'users/' + id);
	}

	hasPermission(permission: Permission | Permission[]) {
		let permissions =
				typeof permission === 'object' ? permission : [permission],
			valid = false;

		permissions.forEach((p) => {
			if (this._user?.permissions.includes(p)) valid = true;
		});
		return valid;
	}
	hasMenu(menu: Menus | Menus[]) {
		let menus = typeof menu === 'object' ? menu : [menu],
			valid = false;

		menus.forEach((p) => {
			if (this._user?.menuIds.includes(p)) valid = true;
		});
		return valid;
	}
	twoFactorAuthenticationEnable(): Observable<twoFAResponse> {
		return this._http.get<twoFAResponse>(
			`${environment.apiBaseUrl}Users/TwoFactorLogin`
		);
	}

	switchRole() {
		return this._http.post<IJWT>(
			`${environment.apiBaseUrl}${ApiRoutes.AUTH_SWITCHROLE}`,
			null
		);
	}

	request2FACode(): Observable<any> {
		return this._http.post<twoFAResponse>(
			`${environment.apiBaseUrl}UserTwoFactorCodes/RequestCode`,
			null
		);
	}

	authenticateUserInPod(
		companyName: string,
		userName: string,
		memberHash: string,
		screen: string,
		memberContext: string
	) {
		const body = { companyName, userName, memberHash, screen, memberContext };
		return (
			this._http
				//.post<User>(
				.post<IJWT>(
					//`${environment.apiBaseUrl}CW_Pods/AuthenticateUserInPod`,
					`${environment.apiBaseUrl}${ApiRoutes.AUTH_CONNECTWISE_LOGIN}`,
					body
				)
				.pipe(
					switchMap((jwt: IJWT) => {
						this.store.dispatch(jwtUpdateAction(jwt));
						return this.refreshUser();
					}),
					map((res) => {
						//this._user = res;
						if (res.isPendingLogin === false) {
							this.loadCompanyBranding(res.companyId);

							// localStorage.setItem('elevate.isAuthenticated', '1');
							this._isAuthenticated.next(true);
						}
						return res;
					}),
					tap({
						next: (res) => {
							// this._user = res;
							this._isAuthenticated.next(true);
							// localStorage.setItem('elevate.isAuthenticated', '1');
							this.loadCompanyBranding(res.companyId);
						},
						error: () => {
							this._user = null;
							this._isAuthenticated.next(false);
							this.store.dispatch(jwtRemoveAction());
						},
					})
				)
		);
	}
	getConnectWiseUser(id: number) {
		return this._http.get<User>(`${environment.apiBaseUrl}CW_Users/${id}`);
	}
	getPhoneVerifyStatus() {
		return this._http.get(`${environment.apiBaseUrl}Users/IsPhoneVerified`);
	}
	continueLogin(data: any) {
		data.user.lastName = data.firstLogin.lastName;

		return this._http
			.post<IJWT>(
				`${environment.apiBaseUrl}${ApiRoutes.AUTH_CONTINUE_LOGIN}`,
				data
			)
			.pipe(
				switchMap(this.processJWT),
				switchMap(this.processFirstLogin),
				map(this.authSuccessHandler)
			);
	}

	saveUserActivity(activity: UserActivity) {
		const body: UserActivity = { ...activity, ...{ userId: this.userId } };
		return this._http.post(
			`${environment.apiBaseUrl}PortalUsage/AddUserActivity`,
			body
		);
	}

	/* For tracking User/Account and can be sent to Gainsight PX */
	identifyPx() {
		const win = window as any;
		console.log(this._user?.signupDate);
		win.aptrinsic(
			'identify',
			/* User Fields */
			{
				id: this._user?.id,
				email: this._user?.email,
				firstName: this._user?.firstName,
				lastName: this._user?.lastName,
				signUpDate: new Date(this._user?.signupDate!),
				role: `Role: ${this._user?.systemRole} Group: ${this._user?.groups}`,
			},
			{
				/* Account Fields */
				id: this._user?.companyId,
				name: this._user?.companyName,
			}
		);
	}

	/* For resets the tracked session and it will split the tracked session into two separate sessions */
	resetPx() {
		const win = window as any;
		win.aptrinsic('reset');
	}

	getEventMultipleTabInBrowser() {
		sessionStorage.setItem('isOpenSingleTab', 'true');
		localStorage['openpages'] = Date.now();

		window.addEventListener(
			'storage',
			(e) => {
				if (e.key === 'openpages') {
					localStorage['page_available'] = Date.now();
				}
				if (e.key === 'page_available') {
					// console.log("One more page already open");
					sessionStorage.setItem('isOpenSingleTab', 'false');
				} else {
					// console.log("First tab is open");
					sessionStorage.setItem('isOpenSingleTab', 'true');
				}
			},
			false
		);

		window.addEventListener('beforeunload', (event) =>
			sessionStorage.setItem('refreshFlag', 'true')
		);
		window.addEventListener('load', () => {
			const refreshFlag = sessionStorage.getItem('refreshFlag');

			if (refreshFlag === 'true') {
				// console.log('Page was refreshing', sessionStorage.getItem("isOpenSingleTab"));
				sessionStorage.setItem('refreshFlag', 'false');
			} else {
				// console.log('Page was loaded normally ', sessionStorage.getItem("isOpenSingleTab"));

				setTimeout(() => {
					if (sessionStorage.getItem('isOpenSingleTab') === 'false') {
						this.getNewAccessToken(true)
							.pipe(take(1))
							.subscribe((jwt) => {
								this.store.dispatch(
									jwtUpdateAction({
										accessToken: jwt.accessToken,
										refreshToken: jwt.refreshToken,
									})
								);
								this.localStorageService.setStorageObject(
									'accessToken',
									jwt.accessToken ? jwt.accessToken : ''
								);
								this.localStorageService.setStorageObject(
									'refreshToken',
									jwt.refreshToken ? jwt.refreshToken : ''
								);
								sessionStorage.setItem(
									'accessToken',
									jwt.accessToken ? jwt.accessToken : ''
								);
							});
					}
				}, 1000);
			}
		});
	}
}

function checkStringInArray(array: string[], targetString: string): boolean {
	for (const str of array) {
		if (targetString.includes(str)) {
			return true;
		}
	}
	return false;
}
