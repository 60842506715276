<div class="actions-wrapper">
	<!-- <button class="btn-2 btn-2-link">
    Cancel
  </button> -->
	<!--   <button class="btn-2 btn-2-tertiary"
    (click)="saveForLater()"
  >
    Save for Later
  </button> -->
	<div
		[class.disabled-button-tooltip]="
			rolesAndPermission.isDisabledPermissionButton
		"
	>
		<div
			*ngIf="rolesAndPermission.isDisabledPermissionButton"
			class="tooltip-message-wrapper"
			[tooltipContentV2]="{
				message:
					'This is global role contact administrator to change permission.'
			}"
		></div>

		<button
			class="btn-2 btn-2-primary"
			(click)="saveChanges()"
			*ngIf="isDefault"
			[disabled]="
				(rolesAndPermission.isEditPageLoading$ | async) ||
				isViewPage ||
				rolesAndPermission.isDisabledPermissionButton
			"
		>
			Save & Add Users
			<mat-icon svgIcon="chevron-right"></mat-icon>
		</button>

		<button
			class="btn-2 btn-2-primary"
			*ngIf="isOverride"
			(click)="saveChanges()"
			[disabled]="
				(rolesAndPermission.isEditPageLoading$ | async) ||
				rolesAndPermission.isDisabledPermissionButton
			"
		>
			Save Changes
			<mat-icon svgIcon="chevron-right"></mat-icon>
		</button>
	</div>
</div>
