import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Observable, Subscription } from 'rxjs';
import { CheckboxInputModule } from '../checkbox-input/checkbox-input.module';

@Component({
	selector: 'lists-drag-drop-input',
	templateUrl: './lists-drag-drop-input.component.html',
	styleUrls: ['./lists-drag-drop-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, FormsModule, DragDropModule, MatIconModule, CheckboxInputModule],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ListsDragDropInputComponent,
			multi: true,
		},
	],
})
export class ListsDragDropInputComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
	@Input() data: DragDropListData;
	@Input() filterValue: string;
	@Input() height: string;

	@Output() checkedSelectedChange = new EventEmitter<number[]>();
	@Output() checkedAvailableChange = new EventEmitter<number[]>();
	@Output() onItemsRemoved = new EventEmitter<number[]>();
	@Output() onItemsAdded = new EventEmitter<number[]>();
	@Output() scrolledMax = new EventEmitter<boolean>();
	@Output() scrolledMaxSelected = new EventEmitter<boolean>();
	
	selectedItems: DragDropListItem[] = [];
	availableItems: DragDropListItem[] = [];

	filteredSelectedItems: DragDropListItem[] = [];
	filteredAvailableItems: DragDropListItem[] = [];

	checkedSelectedItems: DragDropListItem[] = [];
	checkedAvailableItems: DragDropListItem[] = [];
	
	isSelectedFiltered = false;
	isAvailableFiltered = false;

	dragContainerId: null | DragDropListType;
	value: number[] = [];
	subscriptions: Subscription[] = [];
	
	constructor(private _cd: ChangeDetectorRef) {} // Don't Implement Virtual Scroll
	
	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes['data']) { // Resets the list
			this.#setSelectedItems(this.data.selected);
			this.#setAvailableItems(this.data.available);
		}
		if(
			changes['filterValue'] &&
			(!changes['filterValue'].firstChange ||
			changes['filterValue'].currentValue)
		) {
			this.#filterItems(this.filterValue, DragDropListType.selected);
			this.#filterItems(this.filterValue, DragDropListType.available);
		}
	}

	_onChange: any = () => {};
	_onTouched: any = () => {};

	registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(v: number[]) {
    this.value = v;
  }

	onModelChange(v: number[]) {
    this.value = v;
    this._onChange(v);
  }

	#emitChange() {
		const childIds = this.selectedItems.map(c => c.id);
		this.onModelChange(childIds);
	}

	#filterItems(search: string, type: DragDropListType) {
		if(type === DragDropListType.selected && this.data.selected.allowFilter === false) return;
		if(type === DragDropListType.available && this.data.available.allowFilter !== true) return;

		const list = type == DragDropListType.selected ? this.selectedItems : this.availableItems;
		const checked = type == DragDropListType.selected ? this.checkedSelectedItems : this.checkedAvailableItems;
		const filtered = search ? [...new Set([
				...(checked),
				...(list).filter(c => this.advanceFilter(c, search))]
			)] : [...list];

		if(type == DragDropListType.selected) { 
			this.filteredSelectedItems = filtered;
			this.isSelectedFiltered = !!search && checked.length < this.filteredSelectedItems.length;
		}
		else {
			this.filteredAvailableItems = filtered;
			this.isAvailableFiltered = !!search && checked.length < this.filteredAvailableItems.length;
		}
	}

	#setSelectedItems(data: DragDropListTab) {
		this.checkedSelectedItems = [];
		this.isSelectedFiltered = false;
		this.subscriptions.push(
			data.items.subscribe(items => {
				const filtered = items.filter(i => 
					this.checkedSelectedItems.findIndex(cs => cs.id == i.id) == -1
				);
				this.selectedItems = [...this.checkedSelectedItems, ...filtered];
				this.filteredSelectedItems = [...this.selectedItems];
				this.writeValue(this.selectedItems.map(c => c.id));
				this.isSelectedFiltered = (!!this.filterValue || !!data.isFiltered) &&
					this.checkedSelectedItems.length < this.selectedItems.length;
				if(this.selectedItems.length < 20) this.scrolledMaxSelected.emit(true);
				this._cd.markForCheck();
			})
		);
	}

	#setAvailableItems(data: DragDropListTab) {
		this.checkedAvailableItems = [];
		this.isAvailableFiltered = false;
		this.subscriptions.push(
			data.items.subscribe(items => {
				const filtered = items.filter(c => 
					this.selectedItems.findIndex(cc => cc.id == c.id) == -1 &&
					this.checkedAvailableItems.findIndex(sc => sc.id == c.id) == -1
				);
				this.availableItems = [...this.checkedAvailableItems, ...filtered];
				this.filteredAvailableItems = [...this.availableItems];
				this.isAvailableFiltered = (!!this.filterValue || !!data.isFiltered) &&
					this.checkedAvailableItems.length < this.availableItems.length;
				if(this.availableItems.length < 20) this.scrolledMax.emit(true);
				this._cd.markForCheck();
			})
		);
	}
		
	drop(event: CdkDragDrop <DragDropListItem[]>) {
    if (event.previousContainer === event.container) {
			this.#moveSelectedItems(event);
    } else {
			if(this.dragContainerId == 0) this.addSelectedItems(event);
			else this.removeSelectedItems(event);
		}
		this.dragContainerId = null;
	}

	#moveSelectedItems(event: CdkDragDrop <DragDropListItem[]>) {
		const sc = [...(this.dragContainerId === DragDropListType.available ? this.checkedAvailableItems : this.checkedSelectedItems)];

		if(sc.indexOf(event.container.data[event.previousIndex]) == -1) {
			sc.unshift(event.container.data[event.previousIndex]);
		}

		sc.forEach(c => {
			const pi = event.container.data.indexOf(c);
			moveItemInArray(event.container.data, pi, event.currentIndex);
		});

		if(this.dragContainerId === DragDropListType.selected && this.data.selected.allowSort !== false) {
			this.selectedItems = [...new Set([...event.container.data, ...this.selectedItems])];
			this.#emitChange() // Sort Items
		}
	}

	addSelectedItems(event?: CdkDragDrop <DragDropListItem[]>) {
		const targetIndex = event?.currentIndex ?? 0;
		this.checkedSelectedItems = this.checkedSelectedItems.concat(this.checkedAvailableItems);

		if(event && this.checkedAvailableItems.indexOf(event.previousContainer.data[event.previousIndex]) == -1) {
			this.checkedAvailableItems.unshift(event.previousContainer.data[event.previousIndex]);
		}

		this.onItemsAdded.emit(this.checkedAvailableItems.map(i => i.id));
		this.checkedAvailableItems.forEach((c, ti) => {
			const ci = this.availableItems.indexOf(c);
			transferArrayItem(this.availableItems, this.filteredSelectedItems, ci, ti+targetIndex);
		});
		this.selectedItems = [...new Set([...this.filteredSelectedItems, ...this.selectedItems])];
		this.checkedAvailableItems = [];
		this.isSelectedFiltered = false;
		this.checkedAvailableChange.emit([]);
		this.checkedSelectedChange.emit(this.checkedSelectedItems.map(i => i.id));
		this.#emitChange(); // Add Items
		if(this.availableItems.length < 20) this.scrolledMax.emit(true);
	}

	removeSelectedItems(event?: CdkDragDrop <DragDropListItem[]>) {
		const targetIndex = event?.currentIndex ?? 0;
		this.checkedAvailableItems = this.checkedAvailableItems.concat(this.checkedSelectedItems);

		if(event && this.checkedSelectedItems.indexOf(event.previousContainer.data[event.previousIndex]) == -1) {
			this.checkedSelectedItems.unshift(event.previousContainer.data[event.previousIndex]);
		}

		this.onItemsRemoved.emit(this.checkedSelectedItems.map(i => i.id));
		this.checkedSelectedItems.forEach((c, ti) => {
			const ci = this.filteredSelectedItems.indexOf(c);
			transferArrayItem(this.filteredSelectedItems, this.availableItems, ci, ti+targetIndex);
			this.selectedItems.splice(this.selectedItems.indexOf(c), 1);
		});
		this.checkedSelectedItems = [];
		this.isAvailableFiltered = false;
		this.checkedSelectedChange.emit([]);
		this.checkedAvailableChange.emit(this.checkedAvailableItems.map(i => i.id));
		this.#emitChange(); // Remove Items
		if(this.filteredSelectedItems.length < 20) this.scrolledMaxSelected.emit(true);
	}

	onToggleItem(item: DragDropListItem, type: DragDropListType) {
		const checked = type == DragDropListType.selected ? this.checkedSelectedItems : this.checkedAvailableItems;
		const emit = type == DragDropListType.selected ? this.checkedSelectedChange : this.checkedAvailableChange;
		const i = checked.indexOf(item);
		if(i > -1) {
			checked.splice(i, 1);
			if(this.filterValue) this.#filterItems(this.filterValue, type);
		}
		else checked.push(item);
		emit.emit(checked.map(i => i.id));
		if(type == DragDropListType.selected) this.isSelectedFiltered = false;
		else this.isAvailableFiltered = false;
	}

	toggleAllItems(event: any, type: DragDropListType) {
		if(type === DragDropListType.available) {
			this.checkedAvailableItems = event.target.checked ? [...this.availableItems] : [];
			this.checkedAvailableChange.emit(this.checkedAvailableItems.map(i => i.id));
			this.isAvailableFiltered = false;
		}
		else {
			this.checkedSelectedItems = event.target.checked ? [...this.filteredSelectedItems] : [];
			this.checkedSelectedChange.emit(this.checkedSelectedItems.map(i => i.id));
			this.isSelectedFiltered = false;
		}
		if(!event.target.checked && this.filterValue)
			this.#filterItems(this.filterValue, type);
	}

	onScroll(event: any, type: DragDropListType) {
		const h: number = event.target.offsetHeight,
		sh: number = event.target.scrollHeight,
		st: number = event.target.scrollTop;

		if((sh - h) <= st) {
			const output = type == DragDropListType.selected ? this.scrolledMaxSelected : this.scrolledMax;
			output.emit(true);
		}
	}

	advanceFilter(item: DragDropListItem, search: string): boolean {
		if(item.name.toLowerCase().includes(search)) return true;
		else if(!item.filterData) return false;

		const searchArray = search.split(':::'); // The first item is required in name.
		let found = false;

		searchArray.forEach((s, i) => {
			if(i === 0) return;
			if(
				item.name.toLowerCase().includes(searchArray[0]) &&
				item.filterData!.toLowerCase().includes(s)
			) found = true;
		});

		return found;
	}

	#unsubscribe() {
		this.subscriptions.forEach(sub => {
			if(sub && !sub.closed) sub.unsubscribe();
		});
	}

	ngOnDestroy(): void {
		this.#unsubscribe();
	}
}

export interface DragDropListData {
	available: DragDropListTab,
	selected: DragDropListTab,
}

export interface DragDropListTab {
	title: string;
	header: string;
	items: Observable<DragDropListItem[]>;
	extraInfoHeader?: string;
	isLoading?: boolean;
	isFiltered?: boolean;
	displaySelected?: boolean;
	selectedLabel?: string;
	allowSort?: boolean;
	allowFilter?: boolean;
	// allowSelect?: boolean;
	// allowSelectAll?: boolean;
}

export interface DragDropListItem {
	id: number;
	name: string;
	description?: string;
	extraInfo?: string;
	imgPath?: string;
	iconName?: string;
	filterData?: string;
}

enum DragDropListType {
	available,
	selected,
}
