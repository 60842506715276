import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { QuestIconComponent } from './quest-icon.component';

@NgModule({
	declarations: [QuestIconComponent],
	imports: [CommonModule, MatIconModule],
	exports: [QuestIconComponent],
})
export class QuestIconModule {}
