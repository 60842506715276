<nav class="v2-topbar">
	<div
		class="v2-topbar_container q-d-flex q-jc-between q-ai-center q-as-stretch"
	>
		<div class="v2-topbar_bar q-d-flex q-w-100 q-f-column q-ai-start q-g-8">
			<div class="v2-topbar_breadcrumbs q-d-flex q-ai-center q-g-16">
				<div
					class="v2-topbar_breadcrumbs-container q-d-flex q-jc-between q-ai-center q-as-stretch"
				>
					<div class="q-d-flex q-pr-16 q-ai-center q-g-8">
						<nav nav-breadcrumb aria-label="breadcrumb"></nav>
					</div>
				</div>
			</div>
		</div>

		<div class="v2-topbar_search-and-toolbar q-d-flex q-ai-center q-g-16">
			<div
				class="v2-topbar_search"
				[style.display]="isOpenSearchForm ? 'block' : ''"
			>
				<div class="v2-topbar_search-form">
					<global-search *ngIf="isUserRole"></global-search>
				</div>
			</div>

			<div class="v2-topbar_toolbar q-d-flex q-ai-center q-g-16">
				<ul
					class="v2-topbar_navbar-nav q-d-flex q-ai-center q-g-16 q-mb-0 q-pl-0"
				>
					<li
						class="dropdown q-d-flex q-p-4 q-ai-center q-g-10"
						*ngIf="
							isImpersonateEnabled && userService.user?.impersonatingBy === null
						"
					>
						<div class="user-popup-link">
							<a (click)="onImpersonateClick()">
								<quest-icon name="user-right-01"></quest-icon>
							</a>
						</div>
					</li>

					<li
						class="v2-topbar_search-icon q-d-flex q-p-4 q-ai-center q-g-10"
						*ngIf="!isOpenSearchForm"
					>
						<div class="position-relative">
							<a
								href="javascript:void(0);"
								class="nav-link text-white p-0"
								(click)="globalSearchComponent.isSearchOpened = true"
							>
								<quest-icon name="search-sm"></quest-icon>
							</a>
						</div>
					</li>

					<li class="dropdown q-d-flex q-p-4 q-ai-center q-g-10">
						<a
							href="javascript:void(0);"
							(click)="getBellNotifications()"
							class="nav-link text-white p-0"
							id="dropdownMenuButton"
							data-bs-toggle="dropdown"
							aria-expanded="true"
						>
							<quest-icon name="bell-03"></quest-icon>
							<span *ngIf="notificationCount" class="notif-dot"></span>
						</a>

						<div
							class="dropdown-menu notifications-2 q-shadow-1"
							aria-labelledby="dropdownMenuButton"
							data-bs-popper="static"
						>
							<div class="notifications-2-header">
								<h3 class="q-mr-auto">Notifications</h3>

								<button
									class="btn-2 btn-2-link"
									(click)="clearAllNotification($event)"
									[disabled]="!bellNotifications || !bellNotifications.length"
								>
									Clear All
								</button>
							</div>

							<div class="notifications-2-body scroll-v2">
								<ng-container *ngIf="bellNotifications; else loading">
									<div
										*ngFor="let n of bellNotifications; let i = index"
										class="notifications-2-item"
										[class.highlight]="n.categoryId == 9"
										(click)="openNotification(n, i)"
									>
										<p class="font-b1 font-w-500">{{ n.title }}</p>
										<span class="font-b3 font-w-400">{{ n.message }}</span>
									</div>

									<div
										*ngIf="!bellNotifications.length"
										class="notifications-2-item empty"
									>
										<p class="font-b1 font-w-500">No notifications</p>
									</div>
								</ng-container>

								<ng-template #loading>
									<div class="notifications-2-item empty">
										<div class="skeleton q-w-100"></div>
									</div>
								</ng-template>
							</div>
						</div>
					</li>

					<li class="q-d-flex q-p-4 q-ai-center q-g-10">
						<a
							href="javascript:void(0);"
							class="nav-link text-white p-0"
							spConfigurator
						>
							<quest-icon name="settings-02"></quest-icon>
						</a>
					</li>

					<li class="q-d-flex q-p-4 q-ai-center q-g-10">
						<div [matMenuTriggerFor]="options">
							<img
								image-error-detection
								[component]="'top-bar.component.html'"
								*ngIf="img"
								[src]="img"
								class="sp-img-fluid rounded-circle q-pointer"
								alt="icon"
							/>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>

<sp-impersonate-menu
	*ngIf="(impersonateOptions$ | async)?.isOpen"
></sp-impersonate-menu>

<mat-menu #options="matMenu" xPosition="before" class="q-menu-panel">
	<button
		class="q-d-flex q-ai-center font-b3"
		mat-menu-item
		[routerLink]="['/my-account/profile']"
		activityTracker
		[activityAction]="permission.EditProfile"
	>
		<mat-icon svgIcon="user-01"></mat-icon>
		View Profile
	</button>

	<button
		class="destructive q-d-flex q-ai-center font-b3"
		mat-menu-item
		(click)="userService.logout(); idleService.stopTracking()"
	>
		<mat-icon svgIcon="log-out-03"></mat-icon>
		Log Out
	</button>
</mat-menu>
