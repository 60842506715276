import { formatDate } from '@angular/common';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FormValidators {
	static dropdownRequired(control: AbstractControl) {
		const val = control.value;

		if (val === 0) {
			return { required: true };
		}

		return null;
	}

	static timeRequired(timeControl: AbstractControl): ValidatorFn {
		return (dateControl: AbstractControl): ValidationErrors | null => {
			if (
				this._isEmpty(timeControl.value) &&
				!this._isEmpty(dateControl.value)
			) {
				timeControl.setErrors({ timeRequired: true });
			}

			return null;
		};
	}

	static dateRequired(dateControl: AbstractControl): ValidatorFn {
		return (timeControl: AbstractControl) => {
			if (
				this._isEmpty(dateControl.value) &&
				!this._isEmpty(timeControl.value)
			) {
				dateControl.setErrors({ dateRequired: true });
			}

			return null;
		};
	}

	static greaterThan(date1Control: AbstractControl): ValidatorFn {
		return (date2Control: AbstractControl) => {
			const date1 = Date.parse(date1Control.value);
			const date2 = Date.parse(date2Control.value);

			if (
				!this._isEmpty(date1Control.value) &&
				!this._isEmpty(date2Control.value) &&
				date1 >= date2
			) {
				return { greaterThanDate: true };
			}

			date1Control.setErrors(null);
			return null;
		};
	}

	static lessThan(date1Control: AbstractControl): ValidatorFn {
		return (date2Control: AbstractControl) => {
			const date1 = Date.parse(date1Control.value);
			const date2 = Date.parse(date2Control.value);

			if (
				!this._isEmpty(date1Control.value) &&
				!this._isEmpty(date2Control.value) &&
				date2 >= date1
			) {
				return { lessThanDate: true };
			}

			date1Control.setErrors(null);
			return null;
		};
	}

	/**### Validate that control value is greater than the given sibling control value*/
	static greaterThanSibling(
		controlName: string | AbstractControl,
		allowEqual = false
	): ValidatorFn {
		return (control: AbstractControl) => {
			const sibling =
				controlName instanceof AbstractControl
					? controlName
					: control.parent?.get(controlName);

			if (!control.value || !sibling?.value) return null;

			if (!allowEqual && control.value <= sibling.value)
				return { greaterThan: true };
			if (control.value < sibling.value) return { greaterThan: true };

			return null;
		};
	}

	static validUrl(): ValidatorFn {
		return (control: AbstractControl) => {
			if (!control.value) return null;
			return this.url(control);
		};
	}

	/**### Validate that the url is valid and secure*/
	static url(control: AbstractControl): ValidationErrors | null {
		const URL_REGEXP =
			/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
		const url = control.value;

		if (!url) return null;
		if (!URL_REGEXP.test(url)) return { url: true };

		return null;
	}

	/**### Validate that the url is valid and secured is not required*/
	static urlLight(control: AbstractControl): ValidationErrors | null {
		const URL_REGEXP =
			/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
		const url = control.value;

		if (!url) return null;
		if (!URL_REGEXP.test(url)) return { url: true };

		return null;
	}

	/**
	 *### Validate that the file extension is allowed
	 *@param `types` File extension array. Ex. ['png', 'jpeg'];
	 */
	static fileTypes(types: string[]): ValidatorFn {
		return function (control: AbstractControl) {
			const files: FileList | File[] =
				control.value instanceof File ? [control.value] : control.value;

			if (!files || !files.length) return null;

			for (let i = 0; i < files.length; i++) {
				const file = files[i];

				const nameArray = file.name.split('.');
				const extension = nameArray[nameArray.length - 1].toLowerCase();

				if (!types.includes(extension)) {
					return { fileTypes: { fileTypes: types.join(', ') } };
				}
			}

			return null;
		};
	}

	/**### Validate that the image file is in landscape orientation */
	static async landscape(
		control: AbstractControl
	): Promise<ValidationErrors | null> {
		return new Promise((resolve) => {
			const files: FileList | File[] =
				control.value instanceof File ? [control.value] : control.value;

			if (!files || !files.length) resolve(null);

			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				var validCount = 0;

				const fileReader = new FileReader();
				fileReader.readAsDataURL(file);
				fileReader.onerror = () => resolve(null);
				fileReader.onload = (event: any) => {
					const image = new Image();
					image.src = event.target.result;
					image.onerror = () => resolve(null);
					image.onload = () => {
						validCount++;
						if (image.height > image.width) resolve({ landscape: true });
						if (validCount == files.length) resolve(null);
					};
				};
			}
		});
	}

	/**### Validate that the Datetime is not a past Datetime*/
	static past(control: AbstractControl): ValidationErrors | null {
		const date: Date = control.value,
			currentDate = new Date();

		if (!date) return null;
		if (
			formatDate(date, 'yyyy-MM-ddTHH:mm', 'en-US') <
			formatDate(currentDate, 'yyyy-MM-ddTHH:mm', 'en-US')
		)
			return { past: true };
		return null;
	}

	/**### Validate that the Datetime is not a future Datetime*/
	static future(control: AbstractControl): ValidationErrors | null {
		const date: Date = control.value,
			currentDate = new Date();

		if (!date) return null;
		if (
			formatDate(date, 'yyyy-MM-ddTHH:mm', 'en-US') >
			formatDate(currentDate, 'yyyy-MM-ddTHH:mm', 'en-US')
		)
			return { future: true };
		return null;
	}

	/**### Validate that the Date is not a past Date*/
	static pastDate(control: AbstractControl): ValidationErrors | null {
		const date = control.value,
			currentDate = new Date();

		if (!date) return null;
		if (
			formatDate(date, 'yyyy-MM-dd', 'en-US') <
			formatDate(currentDate, 'yyyy-MM-dd', 'en-US')
		)
			return { past: true };
		return null;
	}

	/**### Validate that the Date is not a future Date*/
	static futureDate(control: AbstractControl): ValidationErrors | null {
		const date = control.value,
			currentDate = new Date();

		if (!date) return null;
		if (
			formatDate(date, 'yyyy-MM-dd', 'en-US') >
			formatDate(currentDate, 'yyyy-MM-dd', 'en-US')
		)
			return { future: true };
		return null;
	}

	/**### Validate that the Date is not less than the minimum date*/
	static minDate(minDate: Date | string): ValidatorFn {
		return function (control: AbstractControl) {
			const date = control.value;
			if (!date) return null;
			if (
				formatDate(date, 'yyyy-MM-dd', 'en-US') <
				formatDate(minDate, 'yyyy-MM-dd', 'en-US')
			)
				return {
					minDate: { minDate: formatDate(minDate, 'MM/dd/yyyy', 'en-US') },
				};
			return null;
		};
	}

	/**### Validate that the Date is not greater than the maximum date*/
	static maxDate(maxDate: Date | string): ValidatorFn {
		return function (control: AbstractControl) {
			const date = control.value;
			if (!date) return null;
			if (
				formatDate(date, 'yyyy-MM-dd', 'en-US') >
				formatDate(maxDate, 'yyyy-MM-dd', 'en-US')
			)
				return {
					maxDate: { maxDate: formatDate(maxDate, 'MM/dd/yyyy', 'en-US') },
				};
			return null;
		};
	}

	/**### Validate that string is a valid time*/
	static timeParse({ value }: AbstractControl): ValidationErrors | null {
		if (!value || value instanceof Date) return null;

		const time = (value as string).trim().toLocaleLowerCase();
		if (!time) return null;

		const split = time.split(' '),
			period = split[1],
			hour = parseInt(time.split(':')[0]),
			min = parseInt(time.split(':')[1]);

		if (
			split.length > 2 ||
			!period ||
			(period != 'am' && period != 'pm') ||
			!hour ||
			hour > 12 ||
			min == undefined ||
			min < 0 ||
			min >= 60
		)
			return { timeParse: true };
		return null;
	}

	/**### Only Allows Alphabets and Numbers characters with Space*/
	static alphaNumeric(control: AbstractControl): ValidationErrors | null {
		const val = control.value,
			URL_REGEXP = /^[A-Za-z0-9 ]+$/;

		if (!val) return null;
		if (!URL_REGEXP.test(val)) return { alphaNumeric: true };

		return null;
	}

	/**### Only Allows Alphabets and Numbers characters with Space*/
	static domain(control: AbstractControl): ValidationErrors | null {
		const val = control.value,
			URL_REGEXP = /^[A-Za-z0-9\.]+$/;

		if (!val) return null;
		if (!URL_REGEXP.test(val)) return { domain: true };

		return null;
	}

	/* Not allowed these special characters except for punctuation marks. */
	static noSpecialCharacters(
		control: AbstractControl
	): ValidationErrors | null {
		const val = control.value,
			URL_REGEXP = /^[A-Za-z0-9 .,!?:;'\"()\-]+$/;

		if (!val) return null;
		if (!URL_REGEXP.test(val)) return { domain: true };

		return null;
	}

	/* Not allowed these special characters except for punctuation marks and these symbols @ / &  % \ $ ^ | + */
	static noSpecialCharactersExceptSomeSymbols(
		control: AbstractControl
	): ValidationErrors | null {
		const val = control.value,
			URL_REGEXP = /^[A-Za-z0-9 .,!?:;@/&%*#—$^|+/\\/'\"()\-\n]+$/;

		if (!val) return null;
		if (!URL_REGEXP.test(val)) return { domain: true };

		return null;
	}

	/**### Validate that the value is a whole number*/
	static wholeNumber(control: AbstractControl): ValidationErrors | null {
		const val = control.value;

		if (Number.isInteger(val)) {
			return null; // Return null if it's a whole number
		} else {
			return { wholeNumber: true }; // Return an error object if it's not a whole number
		}

		return null;
	}

	private static _isEmpty(str: string) {
		return str === '';
	}
}
