<div class="role-forms-table">
  <div class="table-container">
    <app-column-slider
      [(displayedColumns)]="displayedColumns"
    ></app-column-slider>
    
    <div class="table-wrapper scroll-v2">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="title"
        [matSortDirection]="order"
        matSortDisableClear="true"
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="check" sticky>
          <th mat-header-cell *matHeaderCellDef class="font-b1">
            <app-local-figma-icon-img
              [filename]="
                isCheckedAll
                  ? 'checkbox-active'
                  : 'checkbox-in-active'
              "
              [width]="20"
              [height]="20"
              [hasSpaceOnRight]="false"
              (click)="checkAll()"
            ></app-local-figma-icon-img>
          </th>
          <td mat-cell *matCellDef="let row">
            <app-local-figma-icon-img
              [filename]="row.isCheck?'checkbox-active':'checkbox-in-active'"
              [width]="20"
              [height]="20"
              [hasSpaceOnRight]="false"
              (click)="checkRow(row)"
            ></app-local-figma-icon-img>
          </td>
        </ng-container>
        <ng-container matColumnDef="isAccess" sticky>
          <th mat-header-cell *matHeaderCellDef class="font-b1">Access</th>
          <td mat-cell *matCellDef="let row">
            <div (click)="toggleAccess(row)">
              <app-svg
                *ngIf="row.isAccess"
                name="unlock"
              ></app-svg>
              <app-svg
                *ngIf="!row.isAccess"
                name="lock"
              ></app-svg>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="title" sticky>
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="font-b1">Title</th>
          <td mat-cell *matCellDef="let row">
            {{row.title}}
          </td>
        </ng-container>
        <ng-container matColumnDef="categories" sticky>
          <th mat-header-cell *matHeaderCellDef class="font-b1">Categories</th>
          <td mat-cell *matCellDef="let row">
            {{row.categories}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="hover-td q-pointer"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell font-b1 q-center" colspan="100%">
            {{ message.noRecord }}
          </td>
        </tr>
      </table>
    </div>
  </div>
  
  <div class="table-paginator-container" [hidden]="!hasData">
    <div class="paginator-group">
      <table-paginator
        [totalItems]="(table$|async)?.totalCount!"
        [pageSizes]="pageSizes"
        (pageChange)="updatePage($event)"
      ></table-paginator>
    </div>

    <div class="item-per-page-group">
      <app-item-per-page
        (sizeChange)="updateSize($event)"
        [totalItems]="(table$|async)?.totalCount!"
      ></app-item-per-page>
    </div>
  </div>
</div>