import { EChartsOption } from 'echarts';
import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MonthlyActivityGraphService } from './monthly-activity-graph.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DxChartComponent } from 'devextreme-angular';
import { pointers } from 'd3';
import { Subscription } from 'rxjs';

@Component({
	selector: 'monthly-activity-graph',
	templateUrl: './monthly-activity-graph.component.html',
	styleUrls: ['./monthly-activity-graph.component.scss'],
})
export class MonthlyActivityGraphComponent implements OnInit, OnDestroy {
	// injection
	public _monthlyActivity = inject(MonthlyActivityGraphService);
	private _router = inject(Router);
	private _route = inject(ActivatedRoute);

	// props
	private _subscription = new Subscription();

	options: EChartsOption;

	// lifecycle
	ngOnInit() {}

	ngOnDestroy(): void {
		//this._subscription.unsubscribe();
	}

	customizeTooltip = (info: any) => {
		if (info.points.length > 1) {
			return {
				html: `
        <div class="tooltip-container">
          <div class='tooltip-header'>${info.argumentText}</div>
          <div class='tooltip-body'>
            <div class='series'>
              <span class='top-series-name'>${info.points[0].seriesName}:</span>
              <span class='top-series-value'>${(
								info.points[0].valueText as number
							).toLocaleString('en-US')}</span>
            </div>
            <div class='series'>
              <span class='bottom-series-name'>${
								info.points[1].seriesName
							}:</span>
              <span class='bottom-series-value'>${(
								info.points[1].valueText as number
							).toLocaleString('en-US')}</span>
            </div>
             <div class='series'>
              <span class='bottom-series-name'>${
								info.points[2].seriesName
							}:</span>
              <span class='bottom-series-value'>${(
								info.points[2].valueText as number
							).toLocaleString('en-US')}</span>
            </div>
          </div>
        </div>`,
			};
		}

		return { html: '' };
	};

	/*
   argumentText
   points[0].seriesName
    points[0].valueText
  */

	onChartClick(e: any) {
		const [month, year] = (e.target.argument as string).split(' ');
		let { fromDate, toDate } = e.target.data;
		fromDate = (fromDate as string).split('T')[0];
		toDate = (toDate as string).split('T')[0];
		const monthNumber = this.#getMonthFromString(month);
		//console.log('graph data: ', e);

		this._router.navigate(['company-report', year, monthNumber], {
			relativeTo: this._route,
			queryParams: {
				fromDate,
				toDate,
			},
		});
	}
	onPointHoverChanged(e: any) {
		if (e.target.isHovered()) {
			e.element.style.cursor = 'pointer';
		} else {
			e.element.style.cursor = 'auto';
		}
	}

	#getMonthFromString(month: string) {
		return new Date(Date.parse(month + ' 1, 2012')).getMonth() + 1;
	}
}
