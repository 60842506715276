import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import axios from 'axios';

import { FigmaDemoService } from '@app/figma-demo/figma-demo.service';

/**
* @deprecated Use quest-icon instead
*/
@Component({
	selector: 'app-embed-figma',
	templateUrl: './embed-figma.component.html',
	styleUrls: ['./embed-figma.component.scss'],
})
export class EmbedFigmaComponent implements OnInit {
	@Input() data: any;
	@Input() height = 24;
	@Input() width = 24;
	@Input() hasSpaceOnRight = true;
	@Input() hasSpaceOnLeft = false;

	constructor(
		private _cd: ChangeDetectorRef,
		private _figmaService: FigmaDemoService,
		private _sanitizer: DomSanitizer
	) {}

	get safeUrl() {
		return this._sanitizer.bypassSecurityTrustResourceUrl(this.data.iconUrl);
	}

	ngOnInit() {}

	getSvgSrc(figmaIcon: string): SafeResourceUrl {
		return this._figmaService.getSrcByNameSafeResourceUrl(figmaIcon);
	}

	onLoad(embedEl: HTMLElement, row: any) {
		const src = this.data.iconUrl;

		if (src) {
			axios
				.get(src)
				.then((svgEl) => {
					const parser = new DOMParser();
					const html = parser.parseFromString(svgEl.data, 'text/html');
					const svg = html.body.querySelector('svg');
					const dv = embedEl.previousElementSibling;

					if (svg && dv && !dv?.querySelector('svg')) {
						const pathEl = svg.querySelectorAll('path');

						for (const el of pathEl) {
							if (row.color) {
								el.setAttribute('stroke', row.color);
							}
						}

						if (dv.classList.contains('topics')) {
							svg.setAttribute('height', `${this.height}px`);
							svg.setAttribute('width', `${this.width}px`);
						}

						dv.append(svg);
						embedEl.classList.add('d-none');
						this._cd.markForCheck();
					}
				})
				.catch((error) => {
					// handle error
					console.log(error);
				});
		}
	}
}
